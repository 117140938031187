import * as ROUTES from '../constants/routes';

const { API_URL } = ROUTES;
const APPLICATION_ID =
  'a3f17a7b6c212b993618e85fbd9499d239a7a907042982c524a165253db540a7';
const REDIRECT_URL = 'https://api.appmaker.xyz/v2/oauth/login-redirect';
const SCOPE = 'read_user+profile+api+read_api';
/**
 * Gitlab ticket
 */

export const createGitlabTicket = async (projectId, data) => {
  const url = `${API_URL}/v2/gitlab/${projectId}`;
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(data)
    });
    const resp = await response.json();
    return resp;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Check gitlab
 */
export const checkOAuth = async projectId => {
  try {
    const url = `${API_URL}/v2/gitlab/${projectId}/check-oauth`;
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include'
    });
    const respJson = await response.json();
    return Promise.resolve(respJson);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * gitlab login api
 */
export const loginWithGitlab = projectId => {
  let url = 'https://gitlab.com/oauth/authorize';
  url += `?client_id=${APPLICATION_ID}&redirect_uri=${REDIRECT_URL}&response_type=code&state=${projectId}&scope=${SCOPE}`;
  return url;
};
