import React from 'react';

export default () => {
  return (
    <div className="bg-light p-4 text-center rounded-lg mt-3 mb-2">
      <h3>Create a new Firebase Project</h3>
      <p>
        Create New Project from Firebase console.
        <a
          href="https://appmaker.xyz/en/docs/how-to-setup-firebase-account/"
          target="_blank"
          rel="noopener noreferrer"
          className="ml-2"
        >
          Learn more
        </a>
      </p>
      <a
        href="https://console.firebase.google.com/"
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-primary mt-3"
      >
        Go to Firebase Console
      </a>
    </div>
  );
};
