import fetch from 'node-fetch';
import * as ROUTES from '../constants/routes';

const BASE_URL = ROUTES.API_URL;

/**
 *
 * @param {Number} projectId
 * @returns
 */
export const chargeBeeStatus = async projectId => {
  try {
    const url = `${BASE_URL}/v2/customer-data/${projectId}/percentage-billing-invoices`;
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include'
    });
    const respJson = await response.json();
    return Promise.resolve(respJson);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const x = () => {};
