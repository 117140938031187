import React from 'react';
import {
  InspectorControls,
  RichText,
  MediaPlaceholder
} from '@wordpress/block-editor';
import { FormGroup, Input, Label } from 'reactstrap';
import { PanelBody, TimePicker, DateTimePicker } from '@wordpress/components';
// import TimePicker from 'react-time-picker';
import { __ } from '@wordpress/i18n';
import { DatePicker } from '@y0c/react-datepicker';
import ProfileCardImage from '../../../components/ProfileCardImage';
import DeleteActionButton from '../../../components/Toolbar';
import '@y0c/react-datepicker/assets/styles/calendar.scss';
import { AddToCalender } from '../Icon';

export default props => {
  const { attributes, setAttributes } = props;
  const {
    title,
    subtitle,
    description,
    image,
    imageContainerStyle
  } = attributes;
  return (
    <div>
      <InspectorControls>
        <PanelBody title="Schedule Settings">
          <FormGroup className="schedule-date">
            <Label>Start Time</Label>
            <DatePicker
              showTimeOnly
              onChange={(allData, date) => {
                if (date !== undefined) {
                  const reqFormat = date.split(' ');
                  const timeStart = reqFormat[0];
                  setAttributes({ timeStart });
                }
              }}
              value={new Date(attributes.timeStart)}
              dateFormat="hh:mm A"
              clear
            />
          </FormGroup>
          <FormGroup className="schedule-date">
            <Label>End Time</Label>
            <DatePicker
              showTimeOnly
              onChange={(allData, date) => {
                const reqFormat = date.split(' ');
                const timeEnd = reqFormat[0];
                setAttributes({ timeEnd });
              }}
              value={new Date(attributes.timeEnd)}
              dateFormat="hh:mm A"
              clear
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="schedule_title">Title</Label>
            <Input
              id="schedule_title"
              value={title}
              onChange={event => {
                setAttributes({ title: event.target.value });
              }}
              placeholder="Title"
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="schedule_description">Description</Label>
            <Input
              id="schedule_description"
              value={description}
              type="textarea"
              placeholder="Description"
              onChange={event => {
                setAttributes({ description: event.target.value });
              }}
            />
          </FormGroup>

        </PanelBody>
      </InspectorControls>
      <div className="schedul-item-container py-2 row mx-0 mt-2">
        <div className="schedule-time text-primary text-center col-3 p-0 my-auto">
          <h4>{attributes.timeStart || 'Time'}</h4>
        </div>
        <div className="schedule-details col-7">
          <h5 className="mb-0">{title || 'Title'}</h5>
          {description && <small className="text-muted mb-0">{description}</small>}
        </div>
        <div className="add-to-calender text-center my-auto col-2 p-0">
          <AddToCalender />
        </div>
      </div>
    </div>
  );
};
