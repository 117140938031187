// import  from '@wordpress/i18n';
import React from 'react';
import { addFilter } from '@wordpress/hooks';
import { Fragment } from '@wordpress/element';
import { InspectorControls } from '@wordpress/editor';
import { createHigherOrderComponent } from '@wordpress/compose';
import { PanelBody, ToggleControl } from '@wordpress/components';
import { getBlockType } from '@wordpress/blocks';
import { FormGroup, Input, Label } from 'reactstrap';
import { select } from '@wordpress/data';
import ActionForm from './action/components/ActionForm';
/**
 * Add mobile visibility controls on Advanced Block Panel.
 *
 * @param {function} BlockEdit Block edit component.
 *
 * @return {function} BlockEdit Modified block edit component.
 */

const withAdvancedControls = createHigherOrderComponent(BlockEdit => {
  return props => {
    const { attributes, setAttributes, isSelected } = props;
    // used for checking the block need action.
    const blockType = getBlockType(props.name);
    const {
      appmakerAction,
      showViewMoreButton,
      analytics,
      hasAnalytics
    } = attributes;
    const headerName =
      blockType.supports.actionHeaderName !== undefined
        ? blockType.supports.actionHeaderName
        : 'Mobile app Action';

    let hasAction = blockType.supports.hasAction !== false;
    const { skipAnalytics } = blockType.supports;

    // NOTE  In initial the value of showViewMoreButton is string because of the api call return the value as string
    if (
      (showViewMoreButton === true || showViewMoreButton === 'true') &&
      hasAction === false
    ) {
      hasAction = true;
    }
    return (
      <Fragment>
        <BlockEdit {...props} />
        <InspectorControls>
          {isSelected &&
            showViewMoreButton !== false &&
            (hasAction && (
              <PanelBody title={headerName} initialOpen>
                <ActionForm
                  formData={appmakerAction}
                  onChange={formData => {
                    setAttributes({ appmakerAction: formData });
                  }}
                />
              </PanelBody>
            ))}
          {skipAnalytics !== true &&
            select('appmaker/core')
              .getUser()
              .email.includes('@appmaker.xyz') && (
              <PanelBody title="Analytics" initialOpen>
                {setAttributes({
                  page_name: select('appmaker/in-app-page').getCurrentPage(),
                  event_name:
                    attributes.event_name === undefined
                      ? 'widget_clicked'
                      : attributes.event_name,
                  internal_name:
                    attributes.internal_name === undefined
                      ? props.name
                      : attributes.internal_name
                })}
                <ToggleControl
                  label="Add Analytics"
                  checked={hasAnalytics}
                  onChange={() =>
                    setAttributes({ hasAnalytics: !hasAnalytics })}
                />
                {hasAnalytics && (
                  <div>
                    <FormGroup>
                      <Label for="examplePassword">Name:</Label>
                      <Input
                        type="text"
                        name="Title for image"
                        value={attributes.internal_name}
                        onChange={event => {
                          setAttributes({ internal_name: event.target.value });
                        }}
                        required
                      />
                      <Label for="examplePassword">Event Name:</Label>
                      <Input
                        type="text"
                        name=""
                        value={attributes.event_name}
                        onChange={event => {
                          setAttributes({ event_name: event.target.value });
                        }}
                        required
                      />
                    </FormGroup>
                  </div>
                )}
              </PanelBody>
            )}
        </InspectorControls>
      </Fragment>
    );
  };
}, 'withAdvancedControls');

addFilter(
  'editor.BlockEdit',
  'editorskit/custom-advanced-control',
  withAdvancedControls
);
