import { unregisterBlockType, getBlockTypes } from '@wordpress/blocks';
import { registerActionTypes } from './actions';
// import { register as registerStore } from './store';
import allowedBlocks from './allowedBlocks';

export const unRegisterUnWantedBlocks = (data = allowedBlocks()) => {
  getBlockTypes().forEach(blockType => {
    if (data.indexOf(blockType.name) === -1) {
      unregisterBlockType(blockType.name);
    }
  });
};

export default function init() {
  unRegisterUnWantedBlocks();
  registerActionTypes();
}
