export default {
  starter: {
    planName: 'starter',
    monthlyPrice: 9,
    yearlyPrice: 99,
    revenueDisc: 'Upto $10k revenue through app. You will be auto-converted to $29/mo plan + 2.5% once you cross $10000 app sales.',
    perfectFor: 'Small business in starting stage.',
    chargebeePlanId: {
      monthly: {
        usd: 'woocommerce-starter-new',
        inr: 'woocommerce-starter-inr'
      },
      yearly: { usd: 'woocommerce-starter-new', inr: 'woocommerce-starter-inr' }
    },
    skipMonthly: true
  },
  // basic: {
  //   planName: 'basic',
  //   monthlyPrice: 29,
  //   yearlyPrice: 25,
  //   revenueDisc: '+2.5% Revenue through app',
  //   perfectFor: 'Small business to grow your revenue.',
  //   chargebeePlanId: {
  //     monthly: { usd: 'woocommerce-basic', inr: 'woocommerce-basic-inr' },
  //     yearly: {
  //       usd: 'woocommerce-basic-yearly',
  //       inr: 'woocommerce-basic-yearly-inr'
  //     }
  //   },
  //   // Yearly price is also in monthly based charges
  //   yearlyPriceInMonth: true
  // },
  growth: {
    id: 'growth',
    planName: 'growth',
    monthlyPrice: 79,
    yearlyPrice: 66,
    revenueDisc: '+1.5% Revenue through app',
    perfectFor: 'For business in the growth stage.',
    chargebeePlanId: {
      monthly: { usd: 'woocommerce-growth', inr: 'woocommerce-growth-inr' },
      yearly: {
        usd: 'woocommerce-growth-yearly',
        inr: 'woocommerce-growth-yearly-inr'
      }
    },
    yearlyPriceInMonth: true
  },
  scale: {
    id: 'scale',
    planName: 'scale',
    monthlyPrice: 249,
    yearlyPrice: 208,
    revenueDisc: '+0.5% Revenue through app',
    perfectFor: 'For business in the scale stage.',
    chargebeePlanId: {
      monthly: { usd: 'woocommerce-scale', inr: 'woocommerce-scale-inr' },
      yearly: {
        usd: 'woocommerce-scale-yearly',
        inr: 'woocommerce-scale-yearly-inr'
      }
    },
    yearlyPriceInMonth: true
  }
};
