import React, { useState } from 'react';
import { Alert, Button, Spinner } from 'reactstrap';
import constants from '../../../../lib/constants';
import IOSInstructions from '../../uploadToStores/IOSInstructions.js';
import UploadToStores from '../../uploadToStores/UploadToStores.js';
import { downloadApp } from '../../../../lib/apiFunctions';
import PreviewModal from './PreviewModal';
import { trackEvent } from '../../../../utils/tracker';

let previewTime;
export default function AppDownloadCard({
  platform,
  hideUploadButton,
  status,
  errorMessage,
  appId,
  preview,
  token,
  project,
  app
}) {
  const [modal, setModal] = useState(false);
  const { paymentStatus, id } = project;

  const toggle = () => {
    if (modal) {
      const endTime = new Date().getTime();
      try {
        trackEvent('timing_complete', {
          name: 'ios_app_preview',
          value: endTime - previewTime,
          event_label: 'iOS app preview'
        });
      } catch (error) {}
    } else {
      previewTime = new Date().getTime();
    }
    setModal(!modal);
  };
  // temporary fix for numeric status.
  status = status + ''; // eslint-disable-line
  let displayMessage;
  switch (status) {
    case constants.APP_STATUS_BUILDING:
      displayMessage = 'Your app is Building';
      break;
    case constants.APP_STATUS_NOT_STARTED:
      displayMessage = 'Your app is Building...';
      break;
    case constants.APP_STATUS_SUCCESS:
      displayMessage = 'Your app is ready';
      break;
    case constants.APP_STATUS_FAILED:
      displayMessage = 'App build failed';
      break;
    default:
      displayMessage = <Spinner type="grow" color="dark" />;
  }
  return (
    <div className="col-md-6 col-12 my-2">
      <div
        className={`bg-white p-4 rounded-lg dashboard-box h-100 ${
          status === constants.APP_STATUS_FAILED ? 'border-danger' : ''
        }`}
      >
        <div className="text-center">
          <h4>
            {platform === 'ios' && (
              <React.Fragment>
                <i className="fab fa-apple mr-1" /> iOS
              </React.Fragment>
            )}
            {platform === 'android' && (
              <React.Fragment>
                <i className="fab fa-android mr-1" /> Android
              </React.Fragment>
            )}
          </h4>
          <h3
            className={`my-4 ${
              status === constants.APP_STATUS_FAILED ? 'text-danger' : ''
            }`}
          >
            {displayMessage}
          </h3>
          {status === constants.APP_STATUS_SUCCESS && (
            <div>
              <Button
                className="btn btn-success text-uppercase"
                onClick={() => {
                  try {
                    trackEvent('app_preview_clicked', {
                      project_id: id,
                      platform,
                      app_id: appId
                    });
                  } catch (error) {}
                  toggle();
                }}
              >
                <i className="fas fa-mobile-alt mr-1" />
                Preview
              </Button>

              {/* {(platform === 'android' || (platform === 'ios' && !preview)) && (
                <a
                  href={downloadApp(appId, preview, token)}
                  className="btn btn-success text-uppercase"
                  onClick={() => {
                    trackEvent('app_download_clicked', {
                      project_id: id,
                      platform,
                      app_id: appId
                    });
                  }}
                >
                  <div>
                    <i className="fa fa-download mr-1" />
                    Download
                  </div>
                </a>
              )} */}

              {hideUploadButton === true ? (
                ''
              ) : (
                <React.Fragment>
                  {platform === 'ios' && (
                    <IOSInstructions project={project} app={app} />
                  )}
                  {platform === 'android' && (
                    <UploadToStores
                      project={project}
                      appId={appId}
                      platform={platform}
                      className="appupload-card"
                      buttonLabel={[
                        <button
                          type="button"
                          className="btn btn-dark text-uppercase"
                        >
                          {platform === 'ios' && (
                            <React.Fragment>
                              <i className="fab fa-app-store-ios mr-1" />
                              Upload to store
                            </React.Fragment>
                          )}
                          {platform === 'android' && (
                            <React.Fragment>
                              <i className="fab fa-google-play mr-1" />
                              Upload to store
                            </React.Fragment>
                          )}
                        </button>
                      ]}
                    />
                  )}
                </React.Fragment>
              )}
              {/* <a
                href=""
                className="mt-4 d-block text-decoration-none text-secondary"
              >
                <i className="fas fa-reply" />
{' '}
Send to mobile
</a> */}
            </div>
          )}
          {(status === constants.APP_STATUS_BUILDING ||
            status === constants.APP_STATUS_NOT_STARTED) && (
            <div>
              <img
                src="https://storage.googleapis.com/appilder_cdn/wordpress-theme/pwa-apk/images/apploader-optimised.gif"
                width="100px"
                draggable="false"
                className="d-block mx-auto"
                alt="Building..."
              />
              <small className="text-muted">Estimated time: 5min</small>
            </div>
          )}
          {status === constants.APP_STATUS_FAILED && (
            <React.Fragment>
              <Alert color="dark">
                <p className="text-dark">{errorMessage}</p>
                {/* <Button color="primary" className="mr-2">
                  <i className="fas fa-sync mr-1" />
                  UPDATE
                </Button> */}
                <Button
                  color="info"
                  onClick={() => {
                    window.Intercom('show');
                  }}
                >
                  <i className="far fa-comment-alt mr-1" />
                  CONTACT SUPPORT
                </Button>
              </Alert>
            </React.Fragment>
          )}
        </div>
      </div>
      <PreviewModal
        modal={modal}
        appId={appId}
        token={token}
        platform={platform}
        toggle={() => {
          toggle();
        }}
      />
    </div>
  );
}
