import React from 'react';
import { InnerBlocks, InspectorControls } from '@wordpress/block-editor';
import { PanelBody } from '@wordpress/components';
import { Input, FormGroup, Label } from 'reactstrap';
import { __ } from '@wordpress/i18n';

export default props => {
  const { attributes, setAttributes } = props;
  const { title } = attributes;
  return (
    <div>
      <InspectorControls>
        <PanelBody>
          <FormGroup>
            <Label htmlFor="profile_block_title">Profile Block Title</Label>
            <Input
              id="profile_block_title"
              type="text"
              value={title}
              onChange={event => {
                setAttributes({ title: event.target.value });
              }}
              placeholder="Enter a title"
            />
          </FormGroup>
        </PanelBody>
      </InspectorControls>
      <div className="profile-block-container">
        {title && <h6 className="mb-0 ml-3 py-2">{title}</h6>}
        <InnerBlocks allowedBlocks={['appmaker/profile-card-item']} />
      </div>
    </div>
  );
};
