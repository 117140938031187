import { unregisterBlockType, getBlockTypes } from '@wordpress/blocks';
import { registerActionTypes } from './actions';
// import { register as registerStore } from './store';
import allowedBlocks from './allowedBlocks';

export default function init() {
  getBlockTypes().forEach(blockType => {
    if (allowedBlocks().indexOf(blockType.name) === -1) {
      unregisterBlockType(blockType.name);
    }
  });
  registerActionTypes();
}
