import React from 'react';
import {
  InnerBlocks,
  InspectorControls,
  RichText
} from '@wordpress/block-editor';
import { PanelBody, ToggleControl } from '@wordpress/components';
import { __ } from '@wordpress/i18n';
/**
 * Panel body
 * @param {Object} props contains the setAttribute,attributes etc..
 */
export default props => {
  const { setAttributes, attributes, onChangeAttribute = false } = props;
  const { showViewMoreButton, showTitle } = attributes;
  if (props.attributes !== undefined)
    return (
      <InspectorControls>
        <PanelBody>
          <ToggleControl
            label={__('Show View more button', 'newspack-blocks')}
            checked={showViewMoreButton}
            onChange={() => {
              if (onChangeAttribute !== false)
                onChangeAttribute({ showViewMoreButton: !showViewMoreButton });
            }}
          />
          <ToggleControl
            label={__('Show Title', 'newspack-blocks')}
            checked={showTitle}
            onChange={() => {
              if (onChangeAttribute !== false)
                onChangeAttribute({ showTitle: !showTitle });
            }}
          />
        </PanelBody>
      </InspectorControls>
    );
};
