import React from 'react';

export default ({ currentStep = 1 }) => {
  return (
    <React.Fragment>
      <div className="text-left d-flex align-items-center mb-4 pb-2 border-bottom">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          className="mr-3"
        >
          <path
            d="M6.92285 39.6861L12.8886 1.71842C13.0535 0.677455 14.4556 0.430912 14.9504 1.3623L21.1086 12.8129L23.5553 8.15594C23.7445 7.79293 24.1208 7.56512 24.5313 7.56512C24.9418 7.56512 25.3181 7.79293 25.5072 8.15594L42.1122 39.6861H6.92285Z"
            fill="#FFA000"
          />
          <path
            d="M27.4866 24.8935L21.0811 12.8129L6.92285 39.6861L27.4866 24.8935Z"
            fill="#F57C00"
          />
          <path
            d="M42.1122 39.6861L37.5486 11.5528C37.4803 11.1467 37.1907 10.8124 36.7976 10.6856C36.4045 10.5589 35.9732 10.6609 35.6792 10.9501L6.92285 39.6861L22.8405 48.5891C23.8422 49.137 25.0554 49.137 26.0571 48.5891L42.1122 39.6861Z"
            fill="#FFCA28"
          />
        </svg>
        <span>
          <h5 className="font-weight-bold">Firebase Configure</h5>
        </span>
      </div>
      <div className="d-flex justify-content-center small-label-text relative">
        <div
          className={`border px-3 mx-3 rounded-pill bg-blue text-white instruction-step${
            currentStep === 1 ? 'active-instruction-step' : ''
          }`}
        >
          Step 1
        </div>
        <div
          className={`border px-3 mx-3 rounded-pill bg-blue text-white instruction-step${
            currentStep === 2 ? 'active-instruction-step' : ''
          }`}
        >
          Step 2
        </div>
        <div
          className={`border px-3 mx-3 rounded-pill bg-blue text-white instruction-step${
            currentStep === 3 ? 'active-instruction-step' : ''
          }`}
        >
          Step 3
        </div>
        <div
          className={`border px-3 mx-3 rounded-pill bg-blue text-white instruction-step${
            currentStep === 4 ? 'active-instruction-step' : ''
          }`}
        >
          Step 4
        </div>
        <div
          className={`border px-3 mx-3 rounded-pill bg-blue text-white instruction-step${
            currentStep === 5 ? 'active-instruction-step' : ''
          }`}
        >
          Step 5
        </div>
      </div>
    </React.Fragment>
  );
};
