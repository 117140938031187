import { dispatch } from '@wordpress/data';
// import { REDUCER_KEY } from './store/name';
const REDUCER_KEY = 'shopify';
const coreActions = [
  {
    id: 'OPEN_IN_WEBVIEW',
    title: 'Open WebView',
    template: 'common',
    subAction: {
      id: 'url',
      title: 'Choose URL',
      type: 'text'
    }
  },
  {
    id: 'OPEN_URL',
    title: 'Open URL',
    template: 'common',
    subAction: {
      id: 'url',
      title: 'Choose URL',
      type: 'text'
    }
  },
  {
    id: 'OPEN_PRODUCT_LIST',
    title: 'Open product list'
  },
  {
    id: 'OPEN_PRODUCT',
    title: 'Open products',
    subAction: {
      id: 'PRODUCT_ID',
      title: 'Choose product',
      type: 'select',
      dataSource: {
        kind: 'root',
        name: 'products',
        link: 'products',
        store: REDUCER_KEY
      }
    }
  },
  {
    id: 'OPEN_COLLECTION',
    title: 'Open Collections',
    subAction: {
      id: 'ID',
      title: 'Choose collection',
      type: 'select',
      dataSource: {
        kind: 'root',
        name: 'collections',
        link: 'collections',
        store: REDUCER_KEY
      }
    }
  },
  { id: 'NO_ACTION', title: 'No Action', template: 'shopify' }
];
// {
//   id: 'OPEN_PUSH_HISTORY',
//     title: 'Open push notification history',
//       template: 'woocommerce'
// }
export function registerActionType(actionType) {
  return dispatch('core/actions').addActionTypes(actionType);
}
export function registerActionTypes() {
  coreActions.map(registerActionType);
}
