import React from 'react';
import { Row } from 'reactstrap';
import { withSelect } from '@wordpress/data';
import { useToasts } from 'react-toast-notifications';
import ProjectDetails from './components/ProjectDetails';
import UserDetails from './components/UserDetails';
import Diagnostic from './components/diagnostic';

const CustomerData = props => {
  const { projectId, isAdmin = false } = props;
  const { addToast } = useToasts();
  return (
    <React.Fragment>
      {isAdmin && (
        <Row>
          <ProjectDetails projectId={projectId} addToast={addToast} />
          <UserDetails projectId={projectId} />
          <Diagnostic projectId={projectId} />
        </Row>
      )}
    </React.Fragment>
  );
};

export default withSelect(select => {
  const project = select('appmaker/core').getCurrentProject();
  const user = select('appmaker/core').getUser();
  return {
    projectId: project.id,
    isAdmin: user && user.email && user.email.includes('@appmaker.xyz')
  };
})(CustomerData);
