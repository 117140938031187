import React from 'react';
import UpdateApp from '../components/updateApp';
import * as ROUTES from '../../../../constants/routes';
import PushNotification from '../components/pushNotification';
import Subscription from '../components/subscription';
import CustomizeApp from '../components/customizeApp';

const CustomApp = props => {
  const {
    appId,
    subscriptionExpired,
    toggleSideBar,
    history,
    nameChanged,
    hideComponents
  } = props;
  return (
    <React.Fragment>
      {!hideComponents && (
        <React.Fragment>
          <CustomizeApp
            subscriptionExpired={subscriptionExpired}
            history={history}
            toggleSideBar={toggleSideBar}
            nameChanged={nameChanged}
            isCustomApp
          />
          <UpdateApp
            appId={appId}
            link={ROUTES.linkToUpdateApp()}
            subscriptionExpired={subscriptionExpired}
          />
          <PushNotification
            onClick={toggleSideBar}
            link={ROUTES.linkToPushNotification()}
          />
        </React.Fragment>
      )}
      <Subscription
        link={ROUTES.linkToSubscription()}
        onClick={toggleSideBar}
      />
    </React.Fragment>
  );
};

export default CustomApp;
