import { withSelect, useSelect } from '@wordpress/data';
import initWooCommerce from './woocommerce';
import initWordpress from './wordpress';
import initCustomApp from './customapp';
import initShopify from './shopify';

function importPlugins({ project }) {
  if (project.template === 'woocommerce') initWooCommerce();
  if (project.template === 'wordpress') initWordpress();
  if (project.template === 'customapp') initCustomApp();
  if (project.template === 'shopify') initShopify();
  return null;
}

export default withSelect(select => {
  const project = select('appmaker/core').getCurrentProject();
  return { project };
})(importPlugins);
