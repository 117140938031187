import React, { useState } from 'react';
import { Input, Button } from 'reactstrap';
import { withSelect } from '@wordpress/data';
import { addFcmServerKey } from '../../../lib/pushAutomationApi';
import FcmServerKeyDoc from './InnerComponents/fcmKeyDocPage';
import { trackEvent } from '../../../utils/tracker';

import * as ROUTES from '../../../constants/routes';

const StepFCM = ({
  previousStep = () => {},
  fcmKey,
  addToast = () => {},
  id,
  firebaseProjectName,
  afterPushConfigurationDone = () => {}
}) => {
  const [fcmServerKey, setFcmKey] = useState(fcmKey);
  const [isLoading, setLoading] = useState(false);
  const addKeyToBolt = async () => {
    setLoading(true);
    try {
      const response = await addFcmServerKey({
        projectId: id,
        data: { fcmKey: fcmServerKey }
      });
      if (response.status) {
        addToast('Saved FCM key', {
          appearance: 'success',
          autoDismiss: true
        });
        if (response.pushConfigureCompleted) {
          addToast('Firebase configuration success, Please update your App', {
            appearance: 'success',
            autoDismiss: true
          });
          trackEvent('firebase_configure_complete', { project_id: id });
          afterPushConfigurationDone(true);
        } else {
          addToast('Firebase configuration not completed', {
            appearance: 'warning',
            autoDismiss: true
          });
        }
      } else {
        addToast('Something went wrong', {
          appearance: 'error',
          autoDismiss: true
        });
      }
    } catch (error) {
      addToast('Something went wrong', {
        appearance: 'error',
        autoDismiss: true
      });
    }
    setLoading(false);
  };
  return (
    <React.Fragment>
      <FcmServerKeyDoc projectName={firebaseProjectName} />
      <Input
        disabled={isLoading}
        placeholder="Enter FCM server key"
        value={fcmServerKey}
        onChange={value => {
          setFcmKey(value.target.value);
        }}
      />
      <Button
        disabled={isLoading}
        className="btn btn-success btn-block mt-3"
        size="sm"
        onClick={() => {
          addKeyToBolt();
        }}
      >
        Save
      </Button>
      <div className="text-right">
        <Button
          size="sm"
          className="px-4 mt-4"
          color="secondary"
          onClick={previousStep}
          disabled={isLoading}
        >
          <i className="fas fa-angle-left mr-2" />
          Back
        </Button>
      </div>
    </React.Fragment>
  );
};

export default withSelect(select => {
  const project = select('appmaker/core').getCurrentProject();
  let fcmKey;
  if (project !== undefined) {
    fcmKey = project.meta.FCM_AUTH_KEY;
  }
  return { fcmKey };
})(StepFCM);
