export default {
    basic: {
        planName: 'Starter',
        monthlyPrice: 5,
        yearlyPrice: 49,
        revenueDisc: 'Android app',
        perfectFor: 'Small business / personal use',
        chargebeePlanId: {
            monthly: { usd: 'webapp-starter', inr: 'webapp-starter-inr' },
            yearly: {
                usd: 'webapp-starter-yearly',
                inr: 'webapp-starter-yearly-inr'
            }
        }
    },
    business: {
        planName: 'Business',
        monthlyPrice: 49,
        yearlyPrice: 490,
        revenueDisc: 'Android app',
        perfectFor: 'For business in the growth stage.',
        chargebeePlanId: {
            monthly: { usd: 'webapp-business', inr: 'webapp-business-inr' },
            yearly: {
                usd: 'webapp-business-yearly',
                inr: 'webapp-business-yearly-inr'
            }
        }
    }
};