import React from 'react';

const style = {
  container: {
    width: '100%',
    height: '100%',
    maxWidth: 198
  }
};
const GridImage = ({ imageSrc, children }) => {
  return (
    <div className="image-container" style={style.container}>
      <img
        src={imageSrc}
        className="image-uploaded"
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
      {children && <span className="image-title">{children}</span>}
    </div>
  );
};
export default GridImage;
