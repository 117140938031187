import React from 'react';

export default () => (
    <svg width="24" height="24" viewBox="0 0 24 24">
        <g id="slider-gutIcon" transform="translate(-13006 -6799)">
            <rect id="Rectangle_552" data-name="Rectangle 552" width="9" height="14" rx="2" transform="translate(13021 6804)" fill="#4b6efc" opacity="0.61" />
            <rect id="Rectangle_553" data-name="Rectangle 553" width="9" height="14" rx="2" transform="translate(13006 6804)" fill="#4b6efc" opacity="0.61" />
            <rect id="Rectangle_547" data-name="Rectangle 547" width="24" height="24" transform="translate(13006 6799)" fill="none" />
            <rect id="Rectangle_549" data-name="Rectangle 549" width="13" height="16" rx="2" transform="translate(13015 6803)" fill="#4b6efc" opacity="0.84" />
            <rect id="Rectangle_550" data-name="Rectangle 550" width="13" height="16" rx="2" transform="translate(13008 6803)" fill="#4b6efc" opacity="0.84" />
            <rect id="Rectangle_548" data-name="Rectangle 548" width="16" height="20" rx="4" transform="translate(13010 6801)" fill="#6d65fe" />
        </g>
    </svg>

);