import React from 'react';
import { appstoreScreenshot } from '../../../images';
import { Badge } from 'reactstrap';

export default function InstructionsStep(props) {
  return (
    <div className="py-2">
      <ol className="list-unstyled">
        <li>
          <p>
            <b>1. Generate access API key</b>
          </p>
          <ol>
            <li>
              <p>
                Go to
                <a
                  href="https://appstoreconnect.apple.com/access/api"
                  className="p-2 bg-light rounded mx-2"
                  target="_blank"
                >
                  https://appstoreconnect.apple.com/access/api
                </a>
                and log with your Apple ID.
              </p>
            </li>
            <li>
              <p>
                <b>App Store Connect API</b> ⇒ Click{' '}
                <Badge color="primary" pill>
                  +
                </Badge>
              </p>
              <img
                src={appstoreScreenshot.stepApiOne}
                alt="appstore-screenshot"
                className="border border-dark rounded shadow-sm mb-3 img-fluid"
              />
            </li>
            <li>
              <ul>
                <li>
                  <p>
                    Type API key name <i>(Eg: "Appmaker auth key")</i>
                  </p>
                </li>
                <li>
                  <p>
                    Give access as <b>Admin</b>
                  </p>
                </li>
              </ul>
              <img
                src={appstoreScreenshot.stepApiTwo}
                alt="appstore-screenshot"
                className="border border-dark rounded shadow-sm mb-3 img-fluid"
              />
            </li>
            <li>
              <p>
                Click <Badge color="primary">Generate</Badge>
              </p>
              <img
                src={appstoreScreenshot.stepApiThree}
                alt="appstore-screenshot"
                className="border border-dark rounded shadow-sm mb-3 img-fluid"
              />
            </li>
            <li>
              <ul>
                <li>
                  <p>
                    <b>Download API Key</b> <code>.p8 file</code> (Marked 1)
                  </p>
                </li>
                <li>
                  <p>
                    Copy <b>Key ID</b> (Marked 2)
                  </p>
                </li>
                <li>
                  <p>
                    Copy <b>Issuer ID</b> (Marked 3)
                  </p>
                </li>
              </ul>
              <img
                src={appstoreScreenshot.stepApiFour}
                alt="appstore-screenshot"
                className="border border-dark rounded shadow-sm mb-2 img-fluid"
              />
              <p>Proceed to next step with details collected</p>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
}
