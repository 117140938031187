import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { uploadService } from '../../../../lib/pushAutomationApi';

export default ({
  id,
  accept = '.json',
  addToast = () => {},
  afterUpload = () => {}
}) => {
  const [isUploading, setUploading] = useState(false);
  const onDrop = async (acceptedFiles, rejectedFiles) => {
    try {
      setUploading(true);
      const response = await uploadService({
        id,
        file: acceptedFiles[0]
      });
      if (response.status === true) {
        addToast('Upload completed', {
          appearance: 'success',
          autoDismiss: true
        });
        afterUpload();
        setUploading(false);
      } else {
        throw new Error('File upload failed');
      }
    } catch (error) {
      addToast('Something went wrong', {
        appearance: 'error',
        autoDismiss: true
      });
      setUploading(false);
    }
  };

  return (
    <React.Fragment>
      {!isUploading ? (
        <Dropzone
          onDrop={onDrop}
          accept={accept}
          disabled={isUploading}
          className="dropZone"
        >
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className="p-5 text-center bg-light rounded cursor-pointer upload-json"
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop files here...</p>
                ) : (
                  <div className="box_input">
                    <i className="fas fa-cloud-upload-alt uplaod_icon mr-2" />
                    <label htmlFor="file">
                      <strong>Choose a file</strong>
                      <span className="box_dragndrop"> or drag it here</span>
                    </label>
                    <br />
                  </div>
                )}
              </div>
            );
          }}
        </Dropzone>
      ) : (
        <div className="text-center py-3">Uploading...</div>
      )}
    </React.Fragment>
  );
};
