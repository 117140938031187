/**
 * External dependencies
 */
import createSelector from 'rememo';


/**
 * Returns all the available action types.
 *
 * @param {Object} state Data state.
 *
 * @return {Array} Action Types.
 */
export const getActionTypes = createSelector(
  //   (state, template) => filter(Object.values(state.actionTypes), { template }),
  state => Object.values(state.actionTypes),
  state => [state.actionTypes]
);

/**
 * Returns a action type by name.
 *
 * @param {Object} state Data state.
 * @param {string} name Action type name.
 *
 * @return {Object?} Action Type.
 */
export function getActionType(state, name) {
  return state.actionTypes[name];
}
