import React from 'react';

/**
 * WordPress dependencies
 */
import { __ } from '@wordpress/i18n';
import { Component, Fragment } from '@wordpress/element';
import {
  QueryControls as BaseControl,
  ToggleControl,RangeControl
} from '@wordpress/components';
import appmakerApiFetch from '@wordpress/api-fetch';
import { addQueryArgs } from '@wordpress/url';
import { decodeEntities } from '@wordpress/html-entities';

/**
 * Internal dependencies.
 */
import AutocompleteTokenField from './autocomplete-tokenfield';

const apiFetch = params => {
  return appmakerApiFetch(params).then(response => response.body);
};

class QueryControls extends Component {
  fetchPostSuggestions = search => {
    return apiFetch({
      path: addQueryArgs('', {
        search,
        url: 'posts',
        per_page: 20,
        type: 'post'
      })
    }).then(function(posts) {
      const result = posts.map(post => ({
        value: post.id,
        label: decodeEntities(post.label) || __('(no title)', 'newspack-blocks')
      }));
      return result;
    });
  };
  fetchSavedPosts = postIDs => {
    return apiFetch({
      path: addQueryArgs('', {
        per_page: 100,
        include: postIDs.join(','),
        url: 'posts',
      })
    }).then(function(posts) {
      return posts.map(post => ({
        value: post.id,
        label:
          decodeEntities(post.label) ||
          __('(no title)', 'newspack-blocks')
      }));
    });
  };

  fetchCategorySuggestions = search => {
    return apiFetch({
      path: addQueryArgs('', {
        search,
        per_page: 20,
        url: 'category',
        order: 'desc'
      })
    }).then(function(categories) {
      const resp = categories.map(category => ({
        value: category.id,
        label:
          decodeEntities(category.label) || __('(no title)', 'newspack-blocks')
      }));
      return resp;
    });
  };
  fetchSavedCategories = categoryIDs => {
    return apiFetch({
      path: addQueryArgs('', {
        per_page: 100,
        url: 'category',
        include: categoryIDs.join(',')
      })
    }).then(function(categories) {
      return categories.map(category => ({
        value: category.id,
        label:
          decodeEntities(category.label) || __('(no title)', 'newspack-blocks')
      }));
    });
  };

  fetchTagSuggestions = search => {
    return apiFetch({
      path: addQueryArgs('', {
        search,
        per_page: 20,
        url: 'tag',
        orderby: 'count',
        order: 'desc'
      })
    }).then(function(tags) {
      return tags.map(tag => ({
        value: tag.id,
        label: decodeEntities(tag.label) || __('(no title)', 'newspack-blocks')
      }));
    });
  };
  fetchSavedTags = tagIDs => {
    return apiFetch({
      path: addQueryArgs('', {
        per_page: 100,
        url: 'tag',
        include: tagIDs.join(',')
      })
    }).then(function(tags) {
      return tags.map(tag => ({
        value: tag.id,
        label: decodeEntities(tag.label) || __('(no title)', 'newspack-blocks')
      }));
    });
  };

  render = () => {
    const {
      numberOfItems,
      onNumberOfItemsChange,
      specificMode,
      onSpecificModeChange,
      specificPosts,
      onSpecificPostsChange,
      
      categories,
      onCategoriesChange,
      tags,
      onTagsChange,
      enableSpecific
    } = this.props;

    return [
      enableSpecific && (
        <ToggleControl
          key="specificMode"
          checked={specificMode}
          onChange={onSpecificModeChange}
          label={__('Choose specific stories', 'newspack-blocks')}
        />
      ),
      specificMode && (
        <AutocompleteTokenField
          key="posts"
          tokens={specificPosts || []}
          onChange={onSpecificPostsChange}
          fetchSuggestions={this.fetchPostSuggestions}
          fetchSavedInfo={this.fetchSavedPosts}
          label={__('Posts', 'newspack-blocks')}
        />
      ),
      // !specificMode && <BaseControl key="queryControls" {...this.props} />,
      !specificMode && (<>
        Number of items:
        <RangeControl
          onChange={value => {
            onNumberOfItemsChange(value)
          }}
          value={numberOfItems}
          min={1}
          max={100}
          required
        /></>),
      !specificMode && onCategoriesChange && (
        <AutocompleteTokenField
          key="categories"
          tokens={categories || []}
          onChange={onCategoriesChange}
          fetchSuggestions={this.fetchCategorySuggestions}
          fetchSavedInfo={this.fetchSavedCategories}
          label={__('Categories', 'newspack-blocks')}
        />
      ),
      !specificMode && onTagsChange && (
        <AutocompleteTokenField
          key="tags"
          tokens={tags || []}
          onChange={onTagsChange}
          fetchSuggestions={this.fetchTagSuggestions}
          fetchSavedInfo={this.fetchSavedTags}
          label={__('Tags', 'newspack-blocks')}
        />
      )
    ];
  };
}

QueryControls.defaultProps = {
  enableSpecific: true,
  specificPosts: [],
  authors: [],
  categories: [],
  tags: []
};

export default QueryControls;
