import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { withSelect } from '@wordpress/data';
import compareVersions from 'compare-versions';

import EmailFooterPreview from './components/EmailFooterPreview';
import EmailFooterDetails from './components/EmailFooterDetails';

import { getAppSettings, updateAppSetting } from '../../../../lib/apiFunctions';
import { trackEvent } from '../../../../utils/tracker';

const EmailFooter = ({
  project,
  pluginResponse: { version },
  user: { email }
}) => {
  const [settings, setSettings] = useState({});
  const [loading, setLoading] = useState(true);

  async function updateSettings(updatedSettings) {
    setSettings(updatedSettings);
  }

  async function saveSettings(updatedSettings) {
    try {
      trackEvent(
        'promote_app_saved',
        {
          project_id: project.id,
          email,
          type: 'email_footer',
          state: updatedSettings.email_footer_enabled ? 'enabled' : 'disabled'
        },
        {
          mautic: true
        }
      );
    } catch (error) {}
    await updateAppSetting(project.id, updatedSettings);
  }

  useEffect(() => {
    async function loadSettings() {
      setLoading(true);
      const settingsResponse = await getAppSettings(project.id);
      const {
        email_footer_enabled = false,
        email_footer_app_store_url = '',
        email_footer_play_store_url = '',
        email_footer_logo_url = project.icon,
        email_footer_title = project.name,
        email_footer_description = '',
        email_footer_cta_text = 'Download'
      } = settingsResponse.values;

      setSettings({
        email_footer_enabled,
        email_footer_app_store_url,
        email_footer_play_store_url,
        email_footer_logo_url,
        email_footer_title,
        email_footer_description,
        email_footer_cta_text
      });
      setLoading(false);
    }
    loadSettings();
  }, [project]);

  return (
    <div className="row wrap overflow-auto">
      <Helmet>
        <title>Email Footer - Appmaker dashboard </title>
      </Helmet>
      <div className="col-md-6 col-12">
        <div className="bg-white p-4 rounded-lg dashboard-box">
          {compareVersions(version, '1.26.0') > -1 ? (
            <EmailFooterDetails
              updateSettings={updateSettings}
              saveSettings={saveSettings}
              settings={settings}
              loading={loading}
            />
          ) : (
            <div className="text-center py-5">
              <h4>
                <i className="fas fa-exclamation-triangle mr-2 text-primary" />
              </h4>
              <h5>Please update plugin to 1.26.0</h5>
              <a
                href={`${project.meta.URL}wp-admin/plugin-install.php?tab=plugin-information&plugin=appmaker-woocommerce-mobile-app-manager`}
                className="btn btn-primary mt-4"
                target="_blank"
              >
                Update plugin
              </a>
            </div>
          )}
        </div>
        <div className="bg-white p-4 rounded-lg dashboard-box my-3">
          <h6>
            <i className="fas fa-info-circle mr-2" />
{' '}
Order email footer
</h6>
          <img
            src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2020/04/233ffc0c-email_footer_info.png"
            alt="info"
            className="img-fluid my-3"
          />
          <p>
            Attach a footer to your order success email to convert one time
            customer to a loyal customer.
          </p>
        </div>
      </div>
      <div className="col-md-6 col-12">
        <div className="bg-white p-4 rounded-lg dashboard-box">
          <h6 className="text-muted text-uppercase">Preview</h6>
          <EmailFooterPreview
            iconUrl={settings.email_footer_logo_url}
            title={settings.email_footer_title}
            description={settings.email_footer_description}
            btnContent={settings.email_footer_cta_text}
          />
        </div>
      </div>
    </div>
  );
};

export default withSelect(select => {
  const project = select('appmaker/core').getCurrentProject();
  const user = select('appmaker/core').getUser();
  const pluginResponse = select(
    'appmaker/core'
  ).getCurrentProjectPluginResponse();
  return { project, pluginResponse, user: user || {} };
})(EmailFooter);
