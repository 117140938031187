import React from 'react';
import PushConfigureAutomation from '../../PushnotificationAutomation';

export default props => {
  return (
    <div>
      {/* <div className="text-center">
        <img
          src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2020/03/0e46186c-pushnotification-error.svg"
          alt="error"
          className="img-fluid my-5"
          draggable="false"
        />
        <h3>Not Configured</h3>
        <p className="mx-auto">
          You have to configure the app with firebase to send
          push-notifications.
          <br /> Learn how to configure the app from our documentation.
        </p>
        <div className="mt-3 mb-2">
          <a
            href="https://appmaker.xyz/docs/setup-push-notifications-for-android-and-ios-apps-and-firebase-analytics/"
            className="btn btn-primary"
            target="_blank"
          >
            Learn to configure
          </a>
        </div>
        <button
          type="button"
          onClick={() => {
            window.Intercom('show');
          }}
          className="btn btn-link mb-3"
        >
          Contact support
        </button>
      </div> */}
      <PushConfigureAutomation {...props} />
    </div>
  );
};
