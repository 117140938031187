import React from 'react';
import {
  InnerBlocks,
  InspectorControls,
  RichText
} from '@wordpress/block-editor';
import { PanelBody, ToggleControl } from '@wordpress/components';
import { __ } from '@wordpress/i18n';
import { extractTextFromHTML } from '../../helper';

export default props => {
  const { setAttributes, className, attributes } = props;
  const { showTitle, title } = attributes;
  return (
    <div className={className}>
      {showTitle && (
        <div className="block-title-part d-flex p-2 justify-content-between">
          <div className="block-title my-auto">
            {showTitle && (
              <RichText
                allowedFormats={[]}
                value={title}
                withoutInteractiveFormatting
                onChange={text => {
                  setAttributes({ title: extractTextFromHTML(text) });
                }}
                placeholder="Menu title"
              />
            )}
          </div>
        </div>
      )}
      <InnerBlocks allowedBlocks={['appmaker/menu-item']} />
      <InspectorControls>
        <PanelBody>
          <ToggleControl
            label={__('Show Title', 'newspack-blocks')}
            checked={showTitle}
            onChange={() => {
              setAttributes({ showTitle: !showTitle });
            }}
          />
        </PanelBody>
      </InspectorControls>
    </div>
  );
};
