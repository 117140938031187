import React from 'react';
import { RichText } from '@wordpress/block-editor';

export default props => {
  const { attributes, setAttributes, className } = props;
  return (
    <div className={className}>
      <RichText
        value={attributes.content}
        onChange={content => setAttributes({ content })}
      />
    </div>
  );
};
