import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import PlayStore from './components/PlayStore';
import { linkToPaymentPage } from '../../../constants/routes';
import ChoosePlan from '../subscriptionData/choosePlan';

const UploadToStores = forwardRef(
  ({ project, appId, buttonLabel, className, platform }, ref) => {
    const [modal, setModal] = useState(false);
    const paymentRef = React.createRef();

    const toggle = () => {
      setModal(!modal);
    };
    useImperativeHandle(ref, () => ({
      toggle() {
        setModal(!modal);
      }
    }));

    function redirectToSubscription() {
      paymentRef.current.toggle();
    }

    const { paymentStatus, id, template } = project;

    return (
      <React.Fragment>
        <Button
          color="link"
          className="text-decoration-none"
          onClick={() => {
            paymentStatus === '5' ? toggle() : redirectToSubscription();
          }}
        >
          {buttonLabel}
        </Button>
        <Modal isOpen={modal} toggle={toggle} className={className}>
          <ModalHeader className="border-bottom-0" toggle={toggle}>
            Upload to App Store
          </ModalHeader>
          <ModalBody className=" d-flex">
            <div className="w-100 m-auto">
              {platform === 'android' && <PlayStore />}
            </div>
          </ModalBody>
        </Modal>
        <ChoosePlan
          showPaymentButton={false}
          projectId={id}
          ref={paymentRef}
          template={template}
        />
      </React.Fragment>
    );
  }
);

export default UploadToStores;
