import React from 'react';
import { withSelect } from '@wordpress/data';
import Select from 'react-select';
import { Spinner } from 'reactstrap';
import ActionSelect from './NewActionSelect';
import { newApiProxy } from '../../../lib/apiFunctions';

class MultiAction extends React.Component {
  constructor(props) {
    super();
    this.state = {
      dependedValue: false,
      finalValue: false,
      options: false,
      isLoading: false
    };
    this.changeDependent = this.changeDependent.bind(this);
    this.sendDataToForm = this.sendDataToForm.bind(this);
  }

  async componentDidMount() {
    const { formData, uiSchema } = this.props;
    const { data } = uiSchema;
    const { subAction, id } = data;
    this.setState({ isLoading: true });
    if (formData !== undefined) {
      const convertedSubAction = JSON.parse(
        JSON.stringify(subAction.dataSource)
      );
      if (formData[id] !== undefined && subAction.dataSource.depend === true) {
        const link = `${subAction.dataSource.link}${formData[id].id}`;
        convertedSubAction.link = link;
      }
      try {
        const response = await this.getData(convertedSubAction);
        this.setState({
          isLoading: false,
          dependedValue: formData !== undefined ? formData[id] : undefined,
          finalValue:
            formData !== undefined ? formData[subAction.id] : undefined,
          options: response
        });
      } catch (error) {}
    }
    this.setState({ isLoading: false });
  }

  async getData(dataSource, input) {
    const { projectId } = this.props;
    let searchQuery = dataSource.link;
    if (dataSource.depend === true) {
      searchQuery =
        input !== undefined && input.length !== 0
          ? `${searchQuery}&search=${input}`
          : searchQuery;
    } else {
      searchQuery =
        input !== undefined && input.length !== 0
          ? `${searchQuery}?search=${input}`
          : searchQuery;
    }
    try {
      const response = await newApiProxy(projectId, searchQuery);
      return Promise.resolve(response.body);
    } catch (error) {
      return [];
    }
  }

  async changeDependent(value, subAction) {
    try {
      const convertedSubAction = JSON.parse(
        JSON.stringify(subAction.dataSource)
      );
      if (value !== undefined && subAction.dataSource.depend === true) {
        const link = `${subAction.dataSource.link}${value.id}`;
        convertedSubAction.link = link;
      }
      this.setState({ isLoading: true });
      const response = await this.getData(convertedSubAction);
      this.setState({
        dependedValue: value,
        options: response,
        isLoading: false,
        finalValue: undefined
      });
    } catch (error) {}
  }

  async sendDataToForm(value, subAction, id) {
    const { dependedValue } = this.state;
    const { onChange } = this.props;
    if (value !== undefined) {
      this.setState({ finalValue: value });
      onChange({
        [subAction.id]: value,
        [id]: dependedValue
      });
    }
  }

  render() {
    const { projectId, uiSchema, formData } = this.props;
    const { dependedValue, options, isLoading, finalValue } = this.state;
    const { data, parentId } = uiSchema;
    const { subAction, dataSource, id, title } = data;
    return (
      <div>
        {!isLoading && (
          <div>
            {title}
            <ActionSelect
              value={dependedValue}
              onChange={value => {
                this.changeDependent(value, subAction);
              }}
              projectId={projectId}
              dataSource={dataSource}
            />
            {isLoading !== true &&
              (dependedValue !== false || dependedValue !== undefined) &&
              options !== false && (
                <div className="pt-3">
                  {subAction.title}
                  <Select
                    defaultValue={finalValue}
                    options={options}
                    getOptionLabel={option => option.label}
                    getOptionValue={option => option.id}
                    onChange={value => {
                      this.sendDataToForm(value, subAction, id);
                    }}
                  />
                </div>
              )}
          </div>
        )}
        {isLoading && (
          <div className="pt-3 text-center">
            <Spinner type="grow" size="sm" color="dark" />
            <Spinner type="grow" size="sm" color="dark" />
            <Spinner type="grow" size="sm" color="dark" />
          </div>
        )}
      </div>
    );
  }
}
export default withSelect(select => {
  const project = select('appmaker/core').getCurrentProject();
  return { projectId: project.id };
})(MultiAction);
