import React from 'react';

export const DashIcon = props => {
  const { fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 25 22"
      className="mr-3"
    >
      <defs>
        <style>
          {` .cls-1-1 {
                          fill: ${fill}
                    }`}
        </style>
      </defs>
      <path
        id="dashboard"
        className="cls-1-1"
        d="M25,12.377a1.363,1.363,0,0,0-.766-1.226l-1.861-.92,1.861-.92h0a1.364,1.364,0,0,0,0-2.453l-10.509-5.2a2.783,2.783,0,0,0-2.45,0L.766,6.857a1.364,1.364,0,0,0,0,2.453l1.861.92-1.862.92a1.364,1.364,0,0,0,0,2.453l1.861.92-1.861.92a1.364,1.364,0,0,0,0,2.453l10.509,5.2a2.783,2.783,0,0,0,2.45,0l10.509-5.2a1.364,1.364,0,0,0,0-2.453l-1.861-.92,1.862-.92A1.363,1.363,0,0,0,25,12.377ZM1.281,8.293a.233.233,0,0,1,0-.42l10.508-5.2a1.616,1.616,0,0,1,1.423,0l10.508,5.2a.233.233,0,0,1,0,.42l-10.508,5.2a1.616,1.616,0,0,1-1.423,0ZM23.72,16.46a.233.233,0,0,1,0,.419l-10.508,5.2a1.616,1.616,0,0,1-1.423,0L1.281,16.88a.233.233,0,0,1,0-.42l2.632-1.3,7.362,3.64a2.783,2.783,0,0,0,2.45,0l7.362-3.64Zm0-3.874-10.508,5.2a1.616,1.616,0,0,1-1.423,0l-10.508-5.2a.233.233,0,0,1,0-.42l2.632-1.3,7.362,3.64a2.782,2.782,0,0,0,2.45,0l7.362-3.64,2.632,1.3a.233.233,0,0,1,0,.42Z"
        transform="translate(0 -1.376)"
      />
    </svg>
  );
};
export const CustomizeIcon = props => {
  const { fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="22"
      viewBox="0 0 25 22"
      className="mr-3"
    >
      <defs>
        <style>
          {`.cls-1-5 {
                          fill: none;
                      }
              
                      .cls-2-5, .cls-3-5 {
                          stroke: none;
                      }
              
                      .cls-3-5 {
                          fill: ${fill}
                      }`}
        </style>
      </defs>
      <g id="cutomize-ico" transform="translate(2 -217)">
        <g id="rect1" className="cls-1-5" transform="translate(-2 217)">
          <path
            className="cls-2-5"
            d="M2.273,0H9.489a2.149,2.149,0,0,1,2.273,2V8.35a2.149,2.149,0,0,1-2.273,2H2.273A2.149,2.149,0,0,1,0,8.35V2A2.149,2.149,0,0,1,2.273,0Z"
          />
          <path
            className="cls-3-5"
            d="M 2.272722244262695 1.300000190734863 C 1.754542350769043 1.300000190734863 1.300002098083496 1.627099990844727 1.300002098083496 2 L 1.300002098083496 8.350000381469727 C 1.300002098083496 8.722900390625 1.754542350769043 9.050000190734863 2.272722244262695 9.050000190734863 L 9.488632202148438 9.050000190734863 C 10.00681209564209 9.050000190734863 10.4613618850708 8.722900390625 10.4613618850708 8.350000381469727 L 10.4613618850708 2 C 10.4613618850708 1.627099990844727 10.00681209564209 1.300000190734863 9.488632202148438 1.300000190734863 L 2.272722244262695 1.300000190734863 M 2.272722244262695 0 L 9.488632202148438 0 C 10.74383163452148 0 11.76136207580566 0.8954305648803711 11.76136207580566 2 L 11.76136207580566 8.350000381469727 C 11.76136207580566 9.454569816589355 10.74383163452148 10.35000038146973 9.488632202148438 10.35000038146973 L 2.272722244262695 10.35000038146973 C 1.017532348632813 10.35000038146973 1.9073486328125e-06 9.454569816589355 1.9073486328125e-06 8.350000381469727 L 1.9073486328125e-06 2 C 1.9073486328125e-06 0.8954305648803711 1.017532348632813 0 2.272722244262695 0 Z"
          />
        </g>
        <g id="rect3" className="cls-1-5" transform="translate(-2 228.65)">
          <path
            className="cls-2-5"
            d="M2.273,0H9.489a2.149,2.149,0,0,1,2.273,2V8.35a2.149,2.149,0,0,1-2.273,2H2.273A2.149,2.149,0,0,1,0,8.35V2A2.149,2.149,0,0,1,2.273,0Z"
          />
          <path
            className="cls-3-5"
            d="M 2.272722244262695 1.300000190734863 C 1.754542350769043 1.300000190734863 1.300002098083496 1.627099990844727 1.300002098083496 2 L 1.300002098083496 8.350000381469727 C 1.300002098083496 8.722900390625 1.754542350769043 9.050000190734863 2.272722244262695 9.050000190734863 L 9.488632202148438 9.050000190734863 C 10.00681209564209 9.050000190734863 10.4613618850708 8.722900390625 10.4613618850708 8.350000381469727 L 10.4613618850708 2 C 10.4613618850708 1.627099990844727 10.00681209564209 1.300000190734863 9.488632202148438 1.300000190734863 L 2.272722244262695 1.300000190734863 M 2.272722244262695 0 L 9.488632202148438 0 C 10.74383163452148 0 11.76136207580566 0.8954305648803711 11.76136207580566 2 L 11.76136207580566 8.350000381469727 C 11.76136207580566 9.454569816589355 10.74383163452148 10.35000038146973 9.488632202148438 10.35000038146973 L 2.272722244262695 10.35000038146973 C 1.017532348632813 10.35000038146973 1.9073486328125e-06 9.454569816589355 1.9073486328125e-06 8.350000381469727 L 1.9073486328125e-06 2 C 1.9073486328125e-06 0.8954305648803711 1.017532348632813 0 2.272722244262695 0 Z"
          />
        </g>
        <g id="rect2" className="cls-1-5" transform="translate(11.239 217)">
          <path
            className="cls-2-5"
            d="M2.273,0H9.489a2.149,2.149,0,0,1,2.273,2V8.35a2.149,2.149,0,0,1-2.273,2H2.273A2.149,2.149,0,0,1,0,8.35V2A2.149,2.149,0,0,1,2.273,0Z"
          />
          <path
            className="cls-3-5"
            d="M 2.272722244262695 1.300000190734863 C 1.754542350769043 1.300000190734863 1.300002098083496 1.627099990844727 1.300002098083496 2 L 1.300002098083496 8.350000381469727 C 1.300002098083496 8.722900390625 1.754542350769043 9.050000190734863 2.272722244262695 9.050000190734863 L 9.488632202148438 9.050000190734863 C 10.00681209564209 9.050000190734863 10.4613618850708 8.722900390625 10.4613618850708 8.350000381469727 L 10.4613618850708 2 C 10.4613618850708 1.627099990844727 10.00681209564209 1.300000190734863 9.488632202148438 1.300000190734863 L 2.272722244262695 1.300000190734863 M 2.272722244262695 0 L 9.488632202148438 0 C 10.74383163452148 0 11.76136207580566 0.8954305648803711 11.76136207580566 2 L 11.76136207580566 8.350000381469727 C 11.76136207580566 9.454569816589355 10.74383163452148 10.35000038146973 9.488632202148438 10.35000038146973 L 2.272722244262695 10.35000038146973 C 1.017532348632813 10.35000038146973 1.9073486328125e-06 9.454569816589355 1.9073486328125e-06 8.350000381469727 L 1.9073486328125e-06 2 C 1.9073486328125e-06 0.8954305648803711 1.017532348632813 0 2.272722244262695 0 Z"
          />
        </g>
        <g id="rect4" className="cls-1-5" transform="translate(11.239 228.65)">
          <path
            className="cls-2-5"
            d="M2.273,0H9.489a2.149,2.149,0,0,1,2.273,2V8.35a2.149,2.149,0,0,1-2.273,2H2.273A2.149,2.149,0,0,1,0,8.35V2A2.149,2.149,0,0,1,2.273,0Z"
          />
          <path
            className="cls-3-5"
            d="M 2.272722244262695 1.300000190734863 C 1.754542350769043 1.300000190734863 1.300002098083496 1.627099990844727 1.300002098083496 2 L 1.300002098083496 8.350000381469727 C 1.300002098083496 8.722900390625 1.754542350769043 9.050000190734863 2.272722244262695 9.050000190734863 L 9.488632202148438 9.050000190734863 C 10.00681209564209 9.050000190734863 10.4613618850708 8.722900390625 10.4613618850708 8.350000381469727 L 10.4613618850708 2 C 10.4613618850708 1.627099990844727 10.00681209564209 1.300000190734863 9.488632202148438 1.300000190734863 L 2.272722244262695 1.300000190734863 M 2.272722244262695 0 L 9.488632202148438 0 C 10.74383163452148 0 11.76136207580566 0.8954305648803711 11.76136207580566 2 L 11.76136207580566 8.350000381469727 C 11.76136207580566 9.454569816589355 10.74383163452148 10.35000038146973 9.488632202148438 10.35000038146973 L 2.272722244262695 10.35000038146973 C 1.017532348632813 10.35000038146973 1.9073486328125e-06 9.454569816589355 1.9073486328125e-06 8.350000381469727 L 1.9073486328125e-06 2 C 1.9073486328125e-06 0.8954305648803711 1.017532348632813 0 2.272722244262695 0 Z"
          />
        </g>
      </g>
    </svg>
  );
};
export const UpdateIcon = props => {
  const { fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="25"
      viewBox="0 0 25 25"
      className="mr-3"
    >
      <defs>
        <style>
          {`.cls-1-2 {
                          fill: ${fill};
                      }`}
        </style>
      </defs>
      <g id="update-ico" transform="translate(-41 -267)">
        <path
          id="Path_53"
          data-name="Path 53"
          className="cls-1-2"
          d="M38.3,0a.892.892,0,0,0-.923.857V3.988a12.637,12.637,0,0,0-16.936.255A10.718,10.718,0,0,0,17.067,12a.926.926,0,0,0,1.846,0c0-5.207,4.548-9.428,10.156-9.427a10.525,10.525,0,0,1,7.488,3.062L32.467,6.9a.846.846,0,0,0-.585,1.085.942.942,0,0,0,1.169.543l5.538-1.714A.865.865,0,0,0,39.22,6V.857A.892.892,0,0,0,38.3,0Z"
          transform="translate(23.934 267)"
        />
        <path
          id="Path_54"
          data-name="Path 54"
          className="cls-1-2"
          d="M72.429,221.867a.892.892,0,0,0-.923.857c0,5.207-4.548,9.428-10.156,9.427a10.525,10.525,0,0,1-7.488-3.062l4.089-1.266a.846.846,0,0,0,.585-1.085.942.942,0,0,0-1.169-.543l-5.538,1.714a.865.865,0,0,0-.631.816v5.143a.926.926,0,0,0,1.846,0v-3.131a12.637,12.637,0,0,0,16.936-.255,10.718,10.718,0,0,0,3.372-7.757A.892.892,0,0,0,72.429,221.867Z"
          transform="translate(-8.352 56.276)"
        />
      </g>
    </svg>
  );
};
export const PushIcon = props => {
  const { fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="21"
      viewBox="0 0 25 21"
      className="mr-3"
    >
      <defs>
        <style>
          {`.cls-1-3 {
                          fill: none;
                      }
  
                      .cls-2-1, .cls-4-1 {
                          fill: ${fill};
                      }
  
                      .cls-3-1, .cls-4-1 {
                          stroke: none;
                      }`}
        </style>
      </defs>
      <g id="push-ico" transform="translate(5011 -6702)">
        <g id="rect" className="cls-1-3" transform="translate(-5011 6704)">
          <path
            className="cls-3-1"
            d="M4.842,0H18.158C20.832,0,23,1.791,23,4V15c0,2.209-2.168,4-4.842,4H4.842C2.168,19,0,17.209,0,15V4C0,1.791,2.168,0,4.842,0Z"
          />
          <path
            className="cls-4-1"
            d="M 4.842109680175781 1.5 C 3.896589279174805 1.5 3.01991081237793 1.794059753417969 2.373540878295898 2.328020095825195 C 1.810230255126953 2.793359756469727 1.5 3.387149810791016 1.5 4 L 1.5 15 C 1.5 15.61285018920898 1.810230255126953 16.20664024353027 2.373540878295898 16.6719799041748 C 3.01991081237793 17.20594024658203 3.896589279174805 17.5 4.842109680175781 17.5 L 18.15789985656738 17.5 C 19.1034107208252 17.5 19.98008918762207 17.20594024658203 20.62646102905273 16.6719799041748 C 21.18976974487305 16.20664024353027 21.5 15.61285018920898 21.5 15 L 21.5 4 C 21.5 3.387149810791016 21.18976974487305 2.793359756469727 20.62646102905273 2.328020095825195 C 19.98008918762207 1.794059753417969 19.1034107208252 1.5 18.15789985656738 1.5 L 4.842109680175781 1.5 M 4.842109680175781 0 L 18.15789985656738 0 C 20.83210945129395 0 23 1.790859222412109 23 4 L 23 15 C 23 17.20914077758789 20.83210945129395 19 18.15789985656738 19 L 4.842109680175781 19 C 2.167879104614258 19 0 17.20914077758789 0 15 L 0 4 C 0 1.790859222412109 2.167879104614258 0 4.842109680175781 0 Z"
          />
        </g>
        <circle
          id="Ellipse_5"
          data-name="Ellipse 5"
          className="cls-2-1"
          cx="5"
          cy="5"
          r="5"
          transform="translate(-4996 6702)"
        />
      </g>
    </svg>
  );
};
export const PromoteIcon = props => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke={props.fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="mr-3 cls-7-5"
    >
      <path d="M21.21 15.89A10 10 0 1 1 8 2.83" />
      <path d="M22 12A10 10 0 0 0 12 2v10z" />
    </svg>
  );
};
export const SmartBannerIcon = props => {
  return (
    <svg width="22" height="18" viewBox="0 0 20 20" className="mr-3 cls-7-4">
      <g id="sidebar" transform="translate(-2 -2)">
        <rect
          id="Rectangle_664"
          data-name="Rectangle 664"
          width="18"
          height="18"
          rx="2"
          transform="translate(21 3) rotate(90)"
          strokeWidth="1"
          stroke={props.fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <line
          id="Line_55"
          data-name="Line 55"
          y2="18"
          transform="translate(21 9) rotate(90)"
          fill="none"
          stroke={props.fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        />
      </g>
    </svg>
  );
};
export const EmailFooterIcon = props => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 24 24"
      fill="none"
      stroke={props.fill}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="mr-3 cls-7-6"
    >
      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
      <polyline points="14 2 14 8 20 8" />
      <line x1="9" y1="15" x2="15" y2="15" />
    </svg>
  );
};

export const SettingsIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="24"
      viewBox="0 0 24 24"
      className="mr-3"
    >
      <defs>
        <style>
          {`.cls-5-1 {
                      fill: ${props.fill};
                }`}
        </style>
      </defs>
      <g id="settings" transform="translate(-56.695 -56.68)">
        <path
          id="cog"
          className="cls-5-1"
          d="M80.148,66.88,77.5,65.721a8.741,8.741,0,0,0-.489-1.181l1.049-2.687a.914.914,0,0,0-.205-.979l-1.366-1.366a.916.916,0,0,0-.982-.2L72.836,60.36a8.672,8.672,0,0,0-1.187-.489L70.5,57.23a.914.914,0,0,0-.839-.55H67.728a.916.916,0,0,0-.839.549l-1.15,2.639a10.084,10.084,0,0,0-1.179.487L61.878,59.3a.917.917,0,0,0-.981.2l-1.366,1.366a.914.914,0,0,0-.205.979l1.052,2.692a9.694,9.694,0,0,0-.486,1.174L57.242,66.88a.916.916,0,0,0-.547.838v1.924a.916.916,0,0,0,.547.838l2.649,1.161a9.657,9.657,0,0,0,.486,1.173l-1.052,2.692a.915.915,0,0,0,.208.982L60.9,77.845a.915.915,0,0,0,.975.2L64.559,77a9.958,9.958,0,0,0,1.18.487l1.15,2.639a.916.916,0,0,0,.839.549h1.934a.914.914,0,0,0,.839-.55l1.148-2.642A8.664,8.664,0,0,0,72.837,77l2.675,1.049a.916.916,0,0,0,.978-.2l1.366-1.357a.915.915,0,0,0,.208-.982L77.015,72.82a8.777,8.777,0,0,0,.489-1.181l2.643-1.159a.916.916,0,0,0,.547-.838V67.717a.916.916,0,0,0-.547-.838Zm-1.281,2.164-2.483,1.088a.919.919,0,0,0-.515.6,6.79,6.79,0,0,1-.654,1.578.917.917,0,0,0-.057.786l.985,2.52-.518.514-2.513-.985a.908.908,0,0,0-.786.057,6.858,6.858,0,0,1-1.588.653.914.914,0,0,0-.6.518L69.063,78.85h-.736l-1.078-2.473a.913.913,0,0,0-.586-.513,7.984,7.984,0,0,1-1.616-.666.914.914,0,0,0-.767-.049l-2.514.978-.519-.515.985-2.52a.918.918,0,0,0-.052-.775,7.8,7.8,0,0,1-.663-1.6.914.914,0,0,0-.511-.584l-2.483-1.088v-.728l2.483-1.088a.912.912,0,0,0,.511-.585,7.814,7.814,0,0,1,.663-1.6.918.918,0,0,0,.052-.775l-.986-2.522.52-.521,2.511.984a.912.912,0,0,0,.77-.047,7.992,7.992,0,0,1,1.616-.666.913.913,0,0,0,.586-.513l1.078-2.473h.736l1.078,2.481a.912.912,0,0,0,.6.518,6.86,6.86,0,0,1,1.588.653.918.918,0,0,0,.788.055l2.51-.992.52.519-.986,2.522a.918.918,0,0,0,.057.786,6.791,6.791,0,0,1,.654,1.578.916.916,0,0,0,.515.6l2.483,1.088Zm0,0"
          transform="translate(0 0)"
        />
        <path
          id="circle"
          className="cls-5-1"
          d="M130.588,125.383a5.125,5.125,0,1,0,5.123,5.124A5.13,5.13,0,0,0,130.588,125.383Zm0,8.42a3.3,3.3,0,1,1,3.294-3.3A3.3,3.3,0,0,1,130.588,133.8Zm0,0"
          transform="translate(-61.893 -61.83)"
        />
      </g>
    </svg>
  );
};
export const DocsIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="24"
      viewBox="0 0 24 24"
      className="mr-3"
    >
      <defs>
        <style>
          {`.cls-1-4 {
                          fill: ${props.fill};
                      }`}
        </style>
      </defs>
      <g id="docs-ico" transform="translate(-44 -378)">
        <path
          id="Path_44"
          data-name="Path 44"
          className="cls-1-4"
          d="M12.065,16.759a1.258,1.258,0,0,0,0,2.516,1.258,1.258,0,1,0,0-2.516Z"
          transform="translate(43.567 377.33)"
        />
        <path
          id="Path_45"
          data-name="Path 45"
          className="cls-1-4"
          d="M12.2,6.836C10,6.836,8.985,8.143,8.985,9.025a.937.937,0,0,0,.98.931c.882,0,.523-1.258,2.189-1.258.817,0,1.471.359,1.471,1.111,0,.882-.915,1.389-1.454,1.846a2.991,2.991,0,0,0-1.095,2.484c0,.85.229,1.095.9,1.095.8,0,.964-.359.964-.67a2.052,2.052,0,0,1,.915-2.042,4.182,4.182,0,0,0,1.83-2.99C15.685,8,14.3,6.836,12.2,6.836Z"
          transform="translate(43.641 377.727)"
        />
        <path
          id="Path_46"
          data-name="Path 46"
          className="cls-1-4"
          d="M12,0A11.993,11.993,0,0,0,0,12V23.063A.937.937,0,0,0,.938,24H12A12,12,0,0,0,12,0Zm0,22.125H1.875V12A10.125,10.125,0,1,1,12,22.125Z"
          transform="translate(44 378)"
        />
      </g>
    </svg>
  );
};
export const InAppPage = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      className="mr-1"
    >
      <g id="inapp-pages" transform="translate(0 0)">
        <path
          id="Path_59"
          data-name="Path 59"
          d="M6.123,0H.9A.9.9,0,0,0,0,.9v5.22a.9.9,0,0,0,.9.9h5.22a.9.9,0,0,0,.9-.9V.9A.9.9,0,0,0,6.123,0Zm.362,6.123a.362.362,0,0,1-.362.362H.9A.362.362,0,0,1,.54,6.123V.9A.362.362,0,0,1,.9.54h5.22A.362.362,0,0,1,6.485.9Z"
        />
        <path
          id="Path_60"
          data-name="Path 60"
          d="M36.123,0H30.9a.9.9,0,0,0-.9.9v5.22a.9.9,0,0,0,.9.9h5.22a.9.9,0,0,0,.9-.9V.9A.9.9,0,0,0,36.123,0Zm.362,6.123a.362.362,0,0,1-.362.362H30.9a.362.362,0,0,1-.362-.362V.9A.362.362,0,0,1,30.9.54h5.22A.362.362,0,0,1,36.485.9Z"
          transform="translate(-21.894)"
        />
        <path
          id="Path_61"
          data-name="Path 61"
          d="M6.123,30H.9a.9.9,0,0,0-.9.9v5.22a.9.9,0,0,0,.9.9h5.22a.9.9,0,0,0,.9-.9V30.9A.9.9,0,0,0,6.123,30Zm.362,6.123a.362.362,0,0,1-.362.362H.9a.362.362,0,0,1-.362-.362V30.9A.362.362,0,0,1,.9,30.54h5.22a.362.362,0,0,1,.362.362Z"
          transform="translate(0 -21.894)"
        />
        <path
          id="Path_62"
          data-name="Path 62"
          d="M36.755,33.242H33.783V30.27a.27.27,0,0,0-.54,0v2.972H30.27a.27.27,0,0,0,0,.54h2.972v2.972a.27.27,0,0,0,.54,0V33.783h2.972a.27.27,0,0,0,0-.54Z"
          transform="translate(-21.894 -21.894)"
        />
      </g>
    </svg>
  );
};
export const SubscriptionIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.586"
      height="13.89"
      viewBox="0 0 20.586 13.89"
      className="mr-3"
    >
      <defs>
        <style>
          {`.cls-6-1 {
                          fill: ${props.fill};
                      }`}
        </style>
      </defs>
      <g id="subscription" transform="translate(-212.34 -466.04)">
        <path
          id="Path_291"
          data-name="Path 291"
          className="cls-1"
          d="M232.926,469.219l0-.01v-.828a2.357,2.357,0,0,0-2.339-2.341h-15.9a2.357,2.357,0,0,0-2.341,2.342v9.207a2.357,2.357,0,0,0,2.342,2.341h15.9a2.357,2.357,0,0,0,2.341-2.342V471.74l0-.01ZM213.677,472.4h17.91v5.253a1.01,1.01,0,0,1-1,.944h-15.9a1.009,1.009,0,0,1-1.006-1.006Zm17.91-3.847h-17.91v-.169a1.009,1.009,0,0,1,1.005-1h15.9a1.009,1.009,0,0,1,1.005,1Zm0,2.511h-17.91v-1.174h17.91Z"
        />
        <path
          id="Path_292"
          data-name="Path 292"
          className="cls-1"
          d="M215.519,474.746h5.022a.669.669,0,0,0,0-1.337h-5.022a.669.669,0,0,0,0,1.337Z"
        />
        <path
          id="Path_293"
          data-name="Path 293"
          className="cls-1"
          d="M215.519,477.42h5.022a.669.669,0,0,0,0-1.337h-5.022a.669.669,0,0,0,0,1.337Z"
        />
        <path
          id="Path_294"
          data-name="Path 294"
          className="cls-1"
          d="M228.073,477.419h.838a1.509,1.509,0,0,0,1.5-1.505v-.837a1.51,1.51,0,0,0-1.5-1.505h-.838a1.51,1.51,0,0,0-1.505,1.505v.838A1.51,1.51,0,0,0,228.073,477.419Zm-.168-1.505v-.836a.169.169,0,0,1,.168-.169h.837a.169.169,0,0,1,.168.168v.837a.169.169,0,0,1-.168.168h-.836A.169.169,0,0,1,227.9,475.914Z"
        />
      </g>
    </svg>
  );
};
export const BackIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="13"
      viewBox="0 0 19 13"
      className="mr-3"
    >
      <defs>
        <style>
          {`.cls-8 {
                          fill: ${props.fill};
                    }`}
        </style>
      </defs>
      <path
        id="go-back"
        className="cls-8"
        d="M6.2,3.907a.684.684,0,0,1,.957,0,.645.645,0,0,1,0,.922L2.3,9.554H18.32a.666.666,0,0,1,.68.652.674.674,0,0,1-.68.662H2.3l4.855,4.716a.656.656,0,0,1,0,.932.684.684,0,0,1-.957,0l-6-5.843a.645.645,0,0,1,0-.922Z"
        transform="translate(0.001 -3.712)"
      />
    </svg>
  );
};
export const MenuIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.99"
      height="10.397"
      viewBox="0 0 14.99 10.397"
      className="mr-1"
    >
      <g id="menu-icon" transform="translate(0 -78.439)">
        <g id="Group_63" data-name="Group 63" transform="translate(0 83.032)">
          <g id="Group_62" data-name="Group 62">
            <path
              id="Path_63"
              data-name="Path 63"
              d="M14.385,235.318H.606a.606.606,0,1,0,0,1.211H14.385a.606.606,0,1,0,0-1.211Z"
              transform="translate(0 -235.318)"
            />
          </g>
        </g>
        <g id="Group_65" data-name="Group 65" transform="translate(0 78.439)">
          <g id="Group_64" data-name="Group 64">
            <path
              id="Path_64"
              data-name="Path 64"
              d="M14.385,78.439H.606a.606.606,0,0,0,0,1.211H14.385a.606.606,0,0,0,0-1.211Z"
              transform="translate(0 -78.439)"
            />
          </g>
        </g>
        <g id="Group_67" data-name="Group 67" transform="translate(0 87.625)">
          <g id="Group_66" data-name="Group 66">
            <path
              id="Path_65"
              data-name="Path 65"
              d="M14.385,392.2H.606a.606.606,0,0,0,0,1.211H14.385a.606.606,0,0,0,0-1.211Z"
              transform="translate(0 -392.197)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const CustomerDataIcon = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke={fill}
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="mr-3 customer-data-icon"
  >
    <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z" />
  </svg>
);
