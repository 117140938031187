import React from 'react';

const PreLoading = ({ fullpage, single, app, appcard, media }) => {
    if (fullpage === true) {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-6 col-12">
                        <div className="block-loading"></div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="block-loading"></div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-12">
                        <div className="block-loading"></div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
    else if (single === true) {
        return (
            <div className="row mb-2">
                <div className="col-12">
                    <div className="block-loading"></div>
                </div>
            </div>
        );
    }
    else if (app === true) {
        return (
            <div className="container pt-2">
                <div className="row">
                    <div className="col-12">
                        <div className="block-loading"></div>
                    </div>
                </div>
            </div>
        );
    }
    else if (appcard === true) {
        return (
            <div className="container mt-4 d-flex justify-content-start flex-wrap">
                <div className="app-card m-2 position-relative block-loading"></div>
                <div className="app-card m-2 position-relative block-loading"></div>
                <div className="app-card m-2 position-relative block-loading"></div>
            </div>
        );
    }
    else if (media === true) {
        return (
            <React.Fragment>
                <div className="select-img-loader mr-2 mb-2 block-loading p-0"></div>
                <div className="select-img-loader mr-2 mb-2 block-loading p-0"></div>
                <div className="select-img-loader mr-2 mb-2 block-loading p-0"></div>
                <div className="select-img-loader mr-2 mb-2 block-loading p-0"></div>
                <div className="select-img-loader mr-2 mb-2 block-loading p-0"></div>
                <div className="select-img-loader mr-2 mb-2 block-loading p-0"></div>
                <div className="select-img-loader mr-2 mb-2 block-loading p-0"></div>
                <div className="select-img-loader mr-2 mb-2 block-loading p-0"></div>
                <div className="select-img-loader mr-2 mb-2 block-loading p-0"></div>
                <div className="select-img-loader mr-2 mb-2 block-loading p-0"></div>
                <div className="select-img-loader mr-2 mb-2 block-loading p-0"></div>
                <div className="select-img-loader mr-2 mb-2 block-loading p-0"></div>
                <div className="select-img-loader mr-2 mb-2 block-loading p-0"></div>
                <div className="select-img-loader mr-2 mb-2 block-loading p-0"></div>
            </React.Fragment >
        );
    }
    else {
        return (
            <React.Fragment>
                <div className="row mb-3">
                    <div className="col-md-6 col-12">
                        <div className="block-loading"></div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="block-loading"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="block-loading"></div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

export default PreLoading;