import { dispatch } from '@wordpress/data';

const coreActions = [
  {
    id: 'OPEN_INAPP_PAGE',
    title: 'Open In-App pages',
    template: 'common',
    subAction: {
      id: 'id',
      title: 'Choose Page',
      type: 'select',
      dataSource: {
        kind: 'root',
        name: 'inAppPageList',
        store: 'core'
      }
    }
  }
];
export function registerActionType(actionType) {
  return dispatch('core/actions').addActionTypes(actionType);
}
export function registerCoreActionTypes() {
  coreActions.map(registerActionType);
}
