import React from 'react';
import { PanelBody } from '@wordpress/components';
import { FormGroup, Label } from 'reactstrap';
import { InspectorControls } from '@wordpress/block-editor';
import ActionSelect from '../../../hooks/action/components/NewActionSelect';
import ColorPicker from '../../components/colorPicker';

export default props => {
  const { attributes, onChangeAttribute = false, children = false } = props;
  return (
    <InspectorControls>
      <PanelBody title="Tab settings">
        <FormGroup>
          <Label for="examplePassword">Tab position:</Label>
          <ActionSelect
            value={
              attributes.position === undefined ||
              attributes.position.value === undefined
                ? 'material-top'
                : attributes.position.value
            }
            options={[
              { value: 'material-top', label: 'Top' },
              { value: 'default-bottom', label: 'Bottom' }
            ]}
            onChange={value => {
              if (onChangeAttribute !== false)
                onChangeAttribute({ position: value });
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label for="examplePassword">Tab Background Color:</Label>
          <ColorPicker
            defaultValue="#000000"
            value={attributes.backgroundColor}
            onChange={color => {
              if (onChangeAttribute !== false)
                onChangeAttribute({ backgroundColor: color });
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label for="examplePassword">Tab Font Color:</Label>
          <ColorPicker
            defaultValue="#ffffff"
            value={attributes.fontColor}
            onChange={color => {
              if (onChangeAttribute !== false)
                onChangeAttribute({ fontColor: color });
            }}
          />
        </FormGroup>
        {children !== false && children}
      </PanelBody>
    </InspectorControls>
  );
};
