import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Col, Row, Table } from 'reactstrap';
import Loading from '../Loading';
import { chargeBeeStatus } from '../../../../../lib/adminData';

export default ({ projectId, chargebeeId }) => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await chargeBeeStatus(projectId);
      if (response.status && response.body) {
        setData(response.body);
      }
    } catch (error) { }
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);
  if (isLoading) {
    return <Loading bubble />;
  }
  const mapData =
    data &&
    data.map(item => {
      const {
        chargebee_id: chargebeeInvoiceId,
        chargebee_invoice_status: chargebeeInvoiceStatus,
        total_sales_in_usd: totalSalesInUsd,
        app_sales_in_usd: appSalesInUsd,
        appmaker_commission_percentage: appmakerCommissionPercentage,
        appmaker_revenue: appmakerRevenue,
        status,
        month
      } = item || {};
      return (
        <tr>
          <td>{moment(month).format('MMMM YYYY')}</td>
          <td>{status}</td>
          <td>${totalSalesInUsd}</td>
          <td>${appSalesInUsd}</td>
          <td>{appmakerCommissionPercentage}%</td>
          <td>${appmakerRevenue}</td>
          <td>
            {chargebeeInvoiceId ? <a
              href={`https://appmaker.chargebee.com/d/invoices/${chargebeeInvoiceId}`}
            >
              {chargebeeInvoiceStatus}
            </a> : "N/A"}
          </td>
        </tr>
      );
    });
  return (
    <React.Fragment>
      <Row>
        <Col sm="12">
          <Table>
            <thead>
              <tr>
                <th>Month</th>
                <th>Status</th>
                <th>Total Sales</th>
                <th>App Sales</th>
                <th>Commission Percentage</th>
                <th>Appmaker Revenue</th>
                <th>Invoice Status</th>
              </tr>
            </thead>
            <tbody>{mapData}</tbody>
          </Table>
        </Col>
      </Row>
    </React.Fragment>
  );
};
