import React from 'react';

export default (
    <svg width="24" height="24" viewBox="0 0 24 24">
        <g id="menuItem-gutIcon" transform="translate(-13118 -6800)">
            <rect id="Rectangle_573" data-name="Rectangle 573" width="22" height="22" rx="4" transform="translate(13119 6801)" fill="#6d65fe" />
            <rect id="Rectangle_547" data-name="Rectangle 547" width="24" height="24" transform="translate(13118 6800)" fill="none" />
            <rect id="Rectangle_561" data-name="Rectangle 561" width="4" height="4" rx="2" transform="translate(13121 6810)" fill="#fff" />
            <rect id="Rectangle_560" data-name="Rectangle 560" width="12" height="2" rx="1" transform="translate(13127 6811)" fill="#fff" />
        </g>
    </svg>
);