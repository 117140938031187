/**
 * Reducer managing appmaker core data
 *
 * @param {Object} state  Current state.
 * @param {Object} action Dispatched action.
 *
 * @return {Object} Updated state.
 */
export function rootState(
  state = {
    user: {
      loggedIn: false
    },
    currentProject: {},
    pluginStatus: undefined
  },
  action
) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: action.user
      };
    case 'SET_CURRENT_PROJECT':
      return {
        ...state,
        currentProject: action.project
      };
    case 'SET_CURRENT_PROJECT_PLUGIN_STATUS':
      return {
        ...state,
        currentProjectPluginResponse: action.pluginResponse
      };
    case 'LOGOUT': {
      return {
        user: {
          loggedIn: false
        },
        currentProject: {}
      };
    }
  }

  return state;
}

export default rootState;
