import React, { useState, forwardRef, useImperativeHandle } from 'react';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText
} from 'reactstrap';
import ChoosePlan from '../../../subscriptionData/choosePlan';

const UploadToStores = forwardRef((props, ref) => {
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };
  useImperativeHandle(ref, () => ({
    toggle() {
      setModal(!modal);
    }
  }));

  function redirectToSubscription() {
    paymentRef.current.toggle();
    // window.location.href = linkToPaymentPage();
  }

  const { project, appId, showAuthKeyUpload } = props;
  const { paymentStatus, id } = project;

  let paymentRef = React.createRef();

  return (
    <React.Fragment>
      {props.showButton && (
        <Button
          color="link"
          className="text-decoration-none"
          onClick={() => {
            paymentStatus === '5' || true ? toggle() : redirectToSubscription();
          }}
        >
          {props.buttonLabel}
        </Button>
      )}
      <Modal isOpen={modal} toggle={toggle} className={props.className}>
        <ModalHeader className="border-bottom-0" toggle={toggle}>
          Upload to App Store
        </ModalHeader>
        <ModalBody className=" d-flex">
          <div className="w-100 m-auto">
            <Card className="border-0 shadow store-card">
              <CardHeader className="text-center py-4">
                <img
                  src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/06/6b3c4acc-appstore.png"
                  alt="appstore"
                  className="img-fluid"
                  draggable="false"
                />
              </CardHeader>
              <CardBody className="bg-black text-white rounded-bottom">
                <div className="text-center mb-3">
                  <img
                    src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/06/0a9c9eb6-apple-developer.png"
                    alt="apple-developer"
                    draggable="false"
                  />
                </div>
                <CardTitle className="font-weight-bold">
                  Generating Release Build for App Store
                </CardTitle>
                <CardText className="small-label-text">
                  You need to register as an iOS developer to get permission for
                  publishing apps on AppStore. Enrolling in Apple developer
                  program requires you to pay Apple an annual $99 fee.
                  Activation may take up to 2 business days.
                </CardText>
                <div className="d-flex justify-content-end">
                  <Button
                    size="sm"
                    color="primary"
                    className="mx-1"
                    onClick={() => {
                      window.open(
                        'https://developer.apple.com/programs/enroll/'
                      );
                    }}
                  >
                    Get developer account
                  </Button>
                  <Button
                    size="sm"
                    color="light"
                    className="mx-1"
                    onClick={() => {
                      toggle();
                      showAuthKeyUpload();
                    }}
                  >
                    I have an account
                  </Button>
                </div>
              </CardBody>
            </Card>
          </div>
        </ModalBody>
      </Modal>
      <ChoosePlan showPaymentButton={false} projectId={id} ref={paymentRef} />
    </React.Fragment>
  );
});

export default UploadToStores;
