import React, { useState } from 'react';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { linkToUpdateApp } from '../../../constants/routes';

export default function AppCard({
  deleteProject,
  project: { appIcon, name, id, apps },
  template,
  active
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  function toggle() {
    setDropdownOpen(!dropdownOpen);
  }
  return (
    <div className="app-card card border-0 m-2 position-relative">
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle
          tag="span"
          onClick={toggle}
          aria-expanded={dropdownOpen}
          className="position-absolute card-menu"
        >
          <div className="px-2 py-1 bg-light rounded small-label-text">
            <i className="fas fa-ellipsis-v" />
          </div>
        </DropdownToggle>
        <DropdownMenu right className="border-0 shadow">
          <div onClick={toggle}>
            {active && (
              <a className="dropdown-item" href={linkToUpdateApp(id)}>
                <i className="fas fa-sync-alt" />
                &nbsp; Update
              </a>
            )}

            {/* {active && (
              <button
                type="button"
                className="dropdown-item"
                onClick={() => {
                  deleteProject(false);
                }}
              >
                <i className="fas fa-trash-alt text-danger" />
                &nbsp; Delete Project
              </button>
            )} */}
            {!active && (
              <button
                type="button"
                className="dropdown-item"
                onClick={() => {
                  deleteProject(true);
                }}
              >
                <i className="fas fa-undo-alt" />
                &nbsp; Restore Project
              </button>
            )}
          </div>
        </DropdownMenu>
      </Dropdown>
      <div className="card-body">
        <div className="app-details text-center">
          <img
            src={
              appIcon ||
              'https://storage.googleapis.com/stateless-appmaker-pages-wp/2020/02/98fa1037-default-applogo.png'
            }
            width="100"
            height="100"
            alt="app-logo"
          />
          <h5 className="mt-3 mb-0">{name}</h5>
          <span
            className={`badge badge-${
              template === 'WooCommerce' ? 'woocommerce' : 'wordpress'
              } my-2`}
          >
            {template}
          </span>
          <Link
            to={`/apps/${id}/subscription`}
            className="text-decoration-none"
          >
          </Link>
        </div>
      </div>
      {/* <Router>
                    <Link to='/apps/'>CLICK ME</Link>
                    <Route exact path="/apps/" component={Dashboard} />
               </Router> */}

      {active && (
        <Link
          to={`/apps/${id}`}
          className="manage-app-button text-center text-decoration-none"
        >
          <h6 className="text-white mb-0 py-3">
            <i className="fas fa-layer-group mr-2" />
            Manage App
          </h6>
        </Link>
      )}
      {!active && (
        <button
          type="button"
          className="manage-app-button text-center text-decoration-none"
          onClick={() => {
            deleteProject(true);
          }}
        >
          <h6 className="text-white mb-0 py-3">
            <i className="fas fa-undo-alt" />
            &nbsp; Restore App
          </h6>
        </button>
      )}
      {/* <div className="d-flex justify-content-around text-center py-2">
        {apps.map(app => (
          <div>
            <a href={downloadApp(app.id)} className="text-dark">
              <h4 className="mb-0">
                <i
                  className={`fab ${
                    app.platformId === 3 ? 'fa-ios' : 'fa-android'
                  }`}
                />
              </h4>
              <h6 className="mb-0 small-label-text">Download</h6>
            </a>
          </div>
        ))}
      </div> */}
      {/* <div className="border-top text-center py-3">
        <a href="#" className="text-decoration-none hover-item">
          <UploadToStores
            buttonLabel={[
              <h6 className="text-dark mb-0">
                <i className="fas fa-rocket" /> Publish in stores
              </h6>
            ]}
          />
        </a>
      </div> */}
    </div>
  );
}
