/* eslint-disable react/prefer-stateless-function */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Button } from 'reactstrap';
import { withSelect } from '@wordpress/data';
import { BallGridBeat } from 'react-pure-loaders';
import {
  uploadMedia,
  apiProxy,
  getImageDetailsV2,
  uploadMediaV2
} from '../../../lib/apiFunctions';
import PreLoading from '../../dashboard/layout/PreLoading';

function ImageListing({ status, action = false, query, project }) {
  const [images, setImages] = useState();
  const [isLoading, setIsLoading] = useState();
  const [uploading, setUploading] = useState();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      // NOTE  For serching the image based on the title
      try {
        let response;
        if (project.template === 'shopify') {
          response = await getImageDetailsV2(project.id, query.search);
        } else {
          response = await apiProxy(
            project.id,
            'uploadMedia',
            query.search,
            'per_page=24'
          );
        }

        const images = response.body.map(data => {
          return { title: data.label, url: data.id.url, meta: data.id.meta };
        });
        setImages(images);
        setIsLoading(false);
      } catch (error) {}
    })();
  }, [query.search]);

  return (
    <div className="image-listing d-flex flex-wrap p-3">
      {!uploading && (
        <Dropzone onDrop={onDrop} multiple={false} accept="image/*" style={{}}>
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps({
                className:
                  'upload-new text-center cursor-pointer py-2 px-1 rounded border-primary bg-light mr-2 mb-2'
              })}
            >
              <div className="text-primary h-100">
                <div className="mt-4">
                  <i className="fas fa-upload" />
                  <br />
                  <input {...getInputProps()} />
                  Click to Upload Media
                </div>
              </div>
            </div>
          )}
        </Dropzone>
      )}
      {uploading && (
        <Button
          type="button"
          color="info"
          className="select-img mr-2 mb-2"
          disabled
        >
          <i className="fas fa-circle-notch fa-spin" />
          <br />
          Uploading...
        </Button>
      )}
      {isLoading && <PreLoading media />}
      {!isLoading &&
        images &&
        images.map((data, index) => (
          <div
            className="select-img mr-2 mb-2 border border-info rounded"
            key={index}
          >
            <input
              type="radio"
              name="imgbackground"
              id={index}
              className="d-none"
              value
            />
            <label
              htmlFor={index}
              className="image-container position-relative my-auto"
            >
              <img
                src={data.url}
                onClick={() => {
                  if (action !== false) {
                    action({ data });
                  }
                }}
              />
              <div className="tritick-container">
                <div className="tick small-label-text">
                  <i className="fa fa-check" />
                </div>
              </div>
            </label>
          </div>
        ))}
      {/* FIXME Show loading when image uploading */}
      {uploading && <BallGridBeat color="#4b6efc" />}
    </div>
  );

  async function onDrop(files) {
    const appIdd = project.id;
    const { template } = project;
    setUploading(true);
    try {
      let response;
      if (template === 'shopify') {
        response = await uploadMediaV2(appIdd, files[0]);
      } else {
        response = await uploadMedia(appIdd, files[0]);
      }
      // const response = {
      //   id: 350,
      //   meta: { width: 213, height: 272 },
      //   title: 'A_AbhijithV-5',
      //   url:
      //     'http://dashboardtest.d.shopilder.com/wp-content/uploads/2019/11/A_AbhijithV-5.jpg'
      // };
      /*
       convert the response to the required format.
       Upload media returns {id:12,meta:{},title:'sampleTitle' url:'http://sample'}
       The Api should be converted to
       {
         id:{
           id:350,
           meta: { width: 213, height: 272 },
           title: 'A_AbhijithV-5',
           url: 'http://dashboardtest.d.shopilder.com/wp-content/uploads/2019/11/A_AbhijithV-5.jpg'
         },
         label:'A_AbhijithV-5'
       }
     */
      /** * FOR WooCommerce
    * const convertedResponse = {
      id: {
        id: response.id,
        meta: response.meta,
        title: response.title,
        url: response.url
      },
      label: response.title
    };
    */
      // for WooCommerce and custom app different response
      const convertedResponse = {
        id: {
          id: response.meta.id === undefined ? response.id : response.meta.id,
          meta: response.meta,
          title:
            response.meta.id === undefined ? response.title : response.meta.id,
          url: response.url
        },
        label:
          response.meta.id === undefined ? response.title : response.meta.id
      };
      // if (action !== false) {
      //   action({ data: convertedResponse.id, upload: true });
      // }
      const img = {
        title: convertedResponse.label,
        url: convertedResponse.id.url,
        meta: convertedResponse.id.meta,
        closePane: true
      };
      const currentImages = images;
      currentImages.push(img);
      if (action !== false) {
        action({ data: img });
      }
      setImages(currentImages);
      setUploading(false);
    } catch (error) {
      console.log(error);
    }
  }
}

ImageListing.propTypes = {
  status: PropTypes.bool,
  // images: PropTypes.shape({
  //   id: PropTypes.object.isRequired,
  //   label: PropTypes.string.isRequired
  // }).isRequired,
  action: PropTypes.func
};
ImageListing.defaultProps = {
  status: false,
  action: false
};

export default withSelect((select, { query = {} }) => {
  return {
    // images: select('core').getMediaItems(query),
    status: false,
    project: select('appmaker/core').getCurrentProject()
  };
})(ImageListing);
