import React, { useEffect, useState } from 'react';
import { Col } from 'reactstrap';
import Card from './Card';
import Loading from './Loading';
import { getDiagnostic } from '../../../../lib/diagnosticApi';

export const printReport = report => {
  return (
    report.length > 0 && (
      <div>
        {report.map(item => {
          return (
            <div>
              <br />
              {item}
            </div>
          );
        })}
      </div>
    )
  );
};
export default ({ projectId }) => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [report, setReport] = useState([]);
  const [errorData, setErrorData] = useState(false);
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getDiagnostic({ projectId });
      if (response.status) {
        setReport(response.report);
        setData(response.serverDiagnostic);
      } else {
        setErrorData('Something went wrong');
      }
    } catch (error) {
      console.log(error);
      setErrorData(error.message || 'Something went wrong');
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);
  const { status, url } = data;
  return (
    <Col sm="12" md="7" className="my-3">
      <Card attributes={{ title: 'Diagnostic Tool' }}>
        <span>
          <a
            href={`https://api.appmaker.xyz/v2/check/${projectId}`}
            className="text-grey"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fas fa-link mr-2" />
            Diagnostic Link
          </a>
        </span>
        {isLoading && <Loading />}
        {errorData && (
          <div>
            {errorData}
            <br />
          </div>
        )}
        {!isLoading && (
          <div>
            Report:
            {printReport(report)}
            <br />
            Status:
            {status}
            <br /> URL:
            {url}
          </div>
        )}
      </Card>
    </Col>
  );
};
