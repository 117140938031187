import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { downloadApp } from '../../../../lib/apiFunctions';

const PreviewModal = props => {
  const { modal, toggle, appId, platform, token } = props;
  let downloadUrl = downloadApp(appId, platform === 'ios', token);
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} className="border-bottom-0">
          Generate
          {platform === 'ios' && <> iOS </>}
          {platform === 'android' && <> Android </>}
          Preview
        </ModalHeader>
        <ModalBody>
          {/* <iframe
            title="ios-preview"
            src={previewApp(appId)}
            frameBorder="0"
            scrolling="no"
            height="700px"
            width="308px"
          /> */}
          <ul className="py-5">
            <li>
              <p>
                Download your
                {platform === 'ios' && (
                  <>
                    app bundle <code> .zip </code>file
                  </>
                )}
                {platform === 'android' && (
                  <>
                    <code> .apk </code>file
                  </>
                )}
              </p>
              <a href={downloadUrl} className="btn btn-success mb-4">
                <i
                  className={
                    platform === 'android'
                      ? 'fab fa-android mr-2'
                      : 'fab fa-apple mr-2'
                  }
                />
                Download app file
              </a>
            </li>
            <li>
              <p>
                Go to
                <a
                  href="https://appetize.io/upload"
                  className="btn btn-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://appetize.io/upload
                </a>
                and upload the app
                {platform === 'ios' && <code> .zip </code>}
                {platform === 'android' && <code> .apk </code>}
                file, follow the steps mentioned in appetize.io
              </p>
            </li>

            {platform === 'android' && (
              <li>
                <p>
                  If you have an android phone, you can install the app on your
                  phone and test.
                  <a
                    href="https://appmaker.xyz/docs/how-to-install-apk-on-android-device/"
                    className="btn btn-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Steps to install <code>apk</code> file on android device
                  </a>
                </p>
              </li>
            )}
          </ul>
        </ModalBody>
        <div className="bg-light border-top-0 rounded p-3">
          <div className="text-center">
            <a
              href="https://www.youtube.com/watch?v=IvUY1y1o1Pw&list=PLhz_T78BZ7cOCumP-x8_Udmy0BxVxDqth&index=8&t=19s"
              target="_blank"
              rel="noopener noreferrer"
            >
              Video tutorial
              <i className="fas fa-external-link-alt ml-2" />
            </a>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PreviewModal;
