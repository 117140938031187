import React, { useState, useEffect } from 'react';
import { Row, Col, Badge } from 'reactstrap';
import { publishDataCustomerTab } from '../../../../lib/apiFunctions';
import Loading from './Loading';

export default props => {
  const [isLoading, setLoading] = useState(false);
  const [publishData, setData] = useState({});
  const fetchData = async projectId => {
    try {
      setLoading(true);
      const publishDataResponse = await publishDataCustomerTab(projectId);
      setData(publishDataResponse.body);
      setLoading(false);
    } catch (error) {}
  };
  useEffect(() => {
    const { projectId } = props;
    fetchData(projectId);
  }, []);

  const {
    isIOSPublished = false,
    isAndroidPublished = false,
    androidLink = '',
    iosLink = ''
  } = publishData;
  const iosStatus = isIOSPublished ? 'Published' : 'Not published';
  const androidStatus = isAndroidPublished ? 'Published' : 'Not published';
  const pluginStatus = 'Active';
  const iosColor = isIOSPublished ? 'success' : 'danger';
  const androidColor = isAndroidPublished ? 'success' : 'danger';
  return (
    <div className="appmaker-card-attr">
      {isLoading && <Loading bubble />}
      {!isLoading && (
        <React.Fragment>
          {isIOSPublished && (
            <a href={iosLink}>
              <Badge className="ml-2 text-white" color={iosColor}>
                <i className="fab fa-apple mr-1" />
                {iosStatus}
              </Badge>
            </a>
          )}
          {!isIOSPublished && (
            <Badge className="ml-2 text-white" color={iosColor}>
              <i className="fab fa-apple mr-1" />
              {iosStatus}
            </Badge>
          )}

          {isAndroidPublished && (
            <a href={androidLink} target="_blank">
              <Badge className="ml-2" color={androidColor}>
                <i className="fab fa-android mr-1" />
                {androidStatus}
              </Badge>
            </a>
          )}
          {!isAndroidPublished && (
            <Badge className="ml-2" color={androidColor}>
              <i className="fab fa-android mr-1" />
              {androidStatus}
            </Badge>
          )}
          <Badge className="ml-2" color="success">
            <i className="fas fa-puzzle-piece mr-1" />
            {pluginStatus}
          </Badge>
        </React.Fragment>
      )}
    </div>
  );
};
