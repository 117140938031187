import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import * as ROUTES from "../../../constants/routes";

class ManageNavBar extends Component {
    render() {
        return (
            <div className="dashboard-navbar bg-white mt-4">
                <ul className="container list-unstyled list-group list-group-horizontal">
                    <li className="list-group-item border-0 position-relative">
                        <NavLink
                            className="py-3 text-decoration-none text-dark hover-item"
                            exact to={ROUTES.ACTIVE_APPS}
                            activeClassName="manage-active"
                        >
                            <i className="fas fa-th mr-2"></i>Active Apps
                        </NavLink>
                    </li>
                    <li className="list-group-item border-0 ">
                        <NavLink
                            className="py-3 text-decoration-none text-dark hover-item"
                            to={ROUTES.DELETED_APPS}
                            activeClassName="manage-active"
                        >
                            <i className="far fa-trash-alt mr-2"></i>Trash
                        </NavLink>
                    </li>
                    <li className="list-group-item border-0 ">
                        <NavLink
                            className="py-3 text-decoration-none text-dark hover-item"
                            to={ROUTES.ACCOUNT_SETTINGS}
                            activeClassName="manage-active"
                        >
                            <i className="far fa-user mr-2"></i>Account Settings
                        </NavLink>
                    </li>
                </ul>
            </div>
        );
    }
}

export default ManageNavBar;