import React from 'react';

export const ScheduleIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24">
        <rect width="24" height="24" fill="none" />
        <rect width="24" height="20" rx="4" transform="translate(0 2)" fill="#4d6ffb" />
        <g transform="translate(5 5)">
            <path d="M11.813,1.094H11.1V.547a.547.547,0,0,0-1.094,0v.547H7.52V.547a.547.547,0,1,0-1.094,0v.547H3.965V.547a.547.547,0,1,0-1.094,0v.547H2.188A2.19,2.19,0,0,0,0,3.281v8.531A2.19,2.19,0,0,0,2.188,14H6.371a.547.547,0,0,0,0-1.094H2.188a1.1,1.1,0,0,1-1.094-1.094V3.281A1.1,1.1,0,0,1,2.188,2.188h.684v.547a.547.547,0,1,0,1.094,0V2.188H6.426v.547a.547.547,0,1,0,1.094,0V2.188h2.488v.547a.547.547,0,0,0,1.094,0V2.188h.711a1.1,1.1,0,0,1,1.094,1.094V6.4A.547.547,0,0,0,14,6.4V3.281A2.19,2.19,0,0,0,11.813,1.094Z" fill="#fff" />
            <path d="M273.309,270a3.309,3.309,0,1,0,3.309,3.309A3.312,3.312,0,0,0,273.309,270Zm0,5.523a2.215,2.215,0,1,1,2.215-2.215A2.217,2.217,0,0,1,273.309,275.523Z" transform="translate(-262.617 -262.617)" fill="#fff" />
            <path d="M372.34,331.121h-.246v-.574a.547.547,0,1,0-1.094,0v1.121a.547.547,0,0,0,.547.547h.793a.547.547,0,0,0,0-1.094Z" transform="translate(-360.855 -320.977)" fill="#fff" />
            <circle cx="1" cy="1" r="1" transform="translate(7 5)" fill="#fff" />
            <circle cx="1" cy="1" r="1" transform="translate(3 9)" fill="#fff" />
            <circle cx="1" cy="1" r="1" transform="translate(3 5)" fill="#fff" />
        </g>
    </svg>
);

export const ScheduleItemIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24">
        <rect width="24" height="24" fill="none" />
        <rect width="24" height="20" rx="4" transform="translate(0 2)" fill="#4d6ffb" />
        <g transform="translate(3 3)">
            <circle cx="7" cy="7" r="7" transform="translate(2 2)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
            <path d="M12,6v4.2l2.8,1.4" transform="translate(-3 -1.2)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
        </g>
    </svg>
);

export const AddToCalender = () => (
    <svg width="24" height="24" viewBox="0 0 24 24">
        <defs>
            <linearGradient id="a" x1="-0.145" y1="1.705" x2="-0.113" y2="1.654" gradientUnits="objectBoundingBox">
                <stop offset="0" stop-opacity="0.102" />
                <stop offset="1" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="b" x1="-0.094" y1="1.251" x2="-0.043" y2="1.227" gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="#fff" stop-opacity="0.2" />
                <stop offset="1" stop-color="#fff" stop-opacity="0" />
            </linearGradient>
        </defs>
        <path d="M22,4a2,2,0,0,0-2-2H17V.5a.5.5,0,0,0-1,0V2H7V.5a.5.5,0,1,0-1,0V2H2A2,2,0,0,0,0,4V20a2,2,0,0,0,2,2H16.5a.5.5,0,0,0,.354-.146l5-5a.484.484,0,0,0,.127-.354H22V4Z" fill="#303c42" />
        <path d="M22.333,64h4v1a1,1,0,1,0,1,1V64h9v1a1,1,0,1,0,1,1V64h3a1,1,0,0,1,1,1v3h-20V65A1,1,0,0,1,22.333,64Z" transform="translate(-20.333 -61)" fill="#d32f2f" />
        <g transform="translate(1 3)">
            <rect width="9" height="0.5" transform="translate(6)" fill="#fff" opacity="0.2" />
            <path d="M22.334,64.5h4V64h-4a1,1,0,0,0-1,1v.5A1,1,0,0,1,22.334,64.5Z" transform="translate(-21.334 -64)" fill="#fff" opacity="0.2" />
            <path d="M365.667,64h-3v.5h3a1,1,0,0,1,1,1V65A1,1,0,0,0,365.667,64Z" transform="translate(-346.667 -64)" fill="#fff" opacity="0.2" />
            <path d="M36.334,180.667v3h-14a1,1,0,0,1-1-1v-12h20v8h-3A2,2,0,0,0,36.334,180.667Z" transform="translate(-21.334 -165.667)" fill="#fff" />
        </g>
        <path d="M362.667,365.96v-2.293a1,1,0,0,1,1-1h2.293Z" transform="translate(-345.667 -345.667)" fill="#e6e6e6" />
        <path d="M36.333,183.667v-3a2,2,0,0,1,2-2h3v-8h-20l13,13Z" transform="translate(-20.333 -162.667)" fill="url(#a)" />
        <g transform="translate(7 11)">
            <path d="M151.333,234.667a2,2,0,0,0-2,2,.5.5,0,1,0,1,0,1,1,0,1,1,1,1,.5.5,0,1,0,0,1,1,1,0,1,1-1,1,.5.5,0,0,0-1,0,2,2,0,1,0,3.321-1.5,2,2,0,0,0-1.321-3.5Z" transform="translate(-149.333 -234.667)" fill="#303c42" />
            <path d="M257.519,234.667h-1a.5.5,0,1,0,0,1h.5v5.5a.5.5,0,0,0,1,0v-6a.5.5,0,0,0-.5-.5Z" transform="translate(-251.019 -234.667)" fill="#303c42" />
            <circle cx="5.5" cy="5.5" r="5.5" transform="translate(6 2)" fill="#303c42" />
        </g>
        <circle cx="4.5" cy="4.5" r="4.5" transform="translate(14 14)" fill="#7cb342" />
        <path d="M345.832,343.333h-1.5v-1.5a.5.5,0,0,0-1,0v1.5h-1.5a.5.5,0,0,0,0,1h1.5v1.5a.5.5,0,0,0,1,0v-1.5h1.5a.5.5,0,0,0,0-1Z" transform="translate(-325.332 -325.333)" fill="#303c42" />
        <path d="M22,14.261V4a2,2,0,0,0-2-2H17V.5a.5.5,0,0,0-1,0V2H7V.5a.5.5,0,1,0-1,0V2H2A2,2,0,0,0,0,4V20a2,2,0,0,0,2,2H14.261A5.5,5.5,0,1,0,22,14.261Z" fill="url(#b)" />
    </svg>

);