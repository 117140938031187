/* eslint-disable react/jsx-indent */
import React, { useState } from 'react';
import {
  InnerBlocks,
  InspectorControls,
  RichText
} from '@wordpress/block-editor';
import { Pacman } from 'react-pure-loaders';
import { PanelBody, ToggleControl } from '@wordpress/components';
import { __ } from '@wordpress/i18n';
import { withSelect } from '@wordpress/data';
import ActionSelect from '../../../hooks/action/components/ActionSelect';
import {
  getPreBuildProductScrollerData,
  newApiProxy
} from '../../../lib/apiFunctions';
import {
  preBuildProductScrollerAction,
  getLink
} from '../../../plugins/woocommerce/staticActions';
import { extractTextFromHTML } from '../../helper';

const generateImageBlocks = (imageBlocks, isLoading) => {
  // FIXME LOADING
  if (isLoading) {
    return (
      <div
        style={{
          height: '190px',
          background: '#f5f7fb',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        Loading...
      </div>
    );
  }
  if (imageBlocks.length === 0) {
    return (
      <div
        style={{
          height: '190px',
          background: '#f5f7fb',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        No Products Available in this category
      </div>
    );
  }
  const out = imageBlocks.map(item => {
    return (
      <div className="product-card rounded shadow-sm p-2 mr-2" key={item.id}>
        <img
          src="item"
          width="100px"
          height="auto"
          alt="product"
          src={item.image}
        />
        <h6>{item.name}</h6>
        <p
          className="small-label-text"
          dangerouslySetInnerHTML={{ __html: item.price_display }}
        />
        {/* <div className="count-add-cart text-center">
          <a href="#" className="btn btn-primary btn-sm">
            <i className="fas fa-cart-plus mr-1" /> Add
                    </a>
        </div> */}
      </div>
    );
  });
  return out;
};
const RenderPrebuildProcuctScroller = props => {
  const {
    setAttributes,
    className,
    attributes,
    imageBlocks,
    action,
    projectId
  } = props;
  const {
    showTitle,
    showViewMoreButton,
    title,
    ctaText,
    id,
    link,
    hasChild,
    childId
  } = attributes;
  return (
    <div className={className}>
      {/* <InnerBlocks
        allowedBlocks={['appmaker/pre-build-product-scroller-item']}
      /> */}
      <div className="block-title-part d-flex p-2 justify-content-between">
        <div className="block-title my-auto">
          {showTitle && (
            <RichText
              allowedFormats={[]}
              value={title}
              withoutInteractiveFormatting
              onChange={text => {
                setAttributes({ title: extractTextFromHTML(text) });
              }}
              placeholder="Product Scroller title"
            />
          )}
        </div>
        <div className="view-more-button">
          <div className="btn btn-light btn-sm">
            {showViewMoreButton && (
              <RichText
                allowedFormats={[]}
                value={ctaText}
                withoutInteractiveFormatting
                onChange={ctaTexts => {
                  setAttributes({ ctaText: extractTextFromHTML(ctaTexts) });
                }}
                placeholder="View More Button"
              />
            )}
          </div>
        </div>
      </div>
      <InspectorControls>
        <PanelBody>
          <ToggleControl
            label={__('Show View more button', 'newspack-blocks')}
            checked={showViewMoreButton}
            onChange={() => {
              setAttributes({ showViewMoreButton: !showViewMoreButton });
            }}
          />
          <ToggleControl
            label={__('Show Title', 'newspack-blocks')}
            checked={showTitle}
            onChange={() => {
              setAttributes({ showTitle: !showTitle });
            }}
          />
          Choose Product Scroller
          <ActionSelect
            options={preBuildProductScrollerAction}
            value={id}
            onChange={data => {
              if (data.hasChild !== true) {
                setAttributes({
                  id: data.value,
                  labelId: data.label,
                  hasChild: false,
                  childId: undefined
                });
                action(data.value);
              } else {
                setAttributes({
                  id: data.value,
                  link: data.link,
                  labelId: data.label,
                  hasChild: true,
                  childId: undefined
                });
              }
            }}
          />
          {hasChild && (
            <div>
              Choose
              <ActionSelect
                dataSource={{ link }}
                projectId={projectId}
                value={childId}
                onChange={data => {
                  setAttributes({ childId: data });
                  action(
                    data.id,
                    'api_proxy',
                    `product_list?${getLink(id)}=${data.id}`
                  );
                }}
              />
            </div>
          )}
        </PanelBody>
      </InspectorControls>
      <div className={className}>
        <div className="product-scroller">
          {/* <h6>Featured Products</h6> */}
          <div className="product-scroller-container">
            {imageBlocks !== '' &&
              generateImageBlocks(imageBlocks, props.isLoading)}
          </div>
        </div>
      </div>
    </div>
  );
};
class PreBuildProductScroller extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      imageBlocks: ''
    };
  }

  // eslint-disable-next-line react/sort-comp
  async loadData(changeId) {
    this.setState({ isLoading: true });
    try {
      const response = await getPreBuildProductScrollerData(
        this.props.url,
        this.props.apiKey,
        changeId === undefined ? this.props.attributes.id : changeId,
        this.props.projectId
      );
      const out = response.map(items => {
        return {
          id: items.id,
          image: items.images[0],
          name: items.name,
          price_display: items.price_display
        };
      });
      this.setState({ isLoading: false, imageBlocks: out });
    } catch (error) {
      console.error(error);
    }
  }

  async loadDataFromApiProxy(url) {
    this.setState({ isLoading: true });
    const { projectId } = this.props;
    try {
      const response = await newApiProxy(projectId, url);
      if (response.status === true) {
        const out = response.body.map(items => {
          return {
            id: items.id,
            image: items.images[0],
            name: items.name,
            price_display: items.price_display
          };
        });

        this.setState({ isLoading: false, imageBlocks: out });
      }
    } catch (error) {
      console.error(error);
    }
    this.setState({ isLoading: false });
  }

  async componentDidMount() {
    const { isLoading } = this.state;
    const { hasChild, id, childId } = this.props.attributes;
    let response;
    if (hasChild) {
      await this.loadDataFromApiProxy(
        `product_list?${getLink(id)}=${childId.id}`
      );
    } else {
      await this.loadData();
    }
  }

  render() {
    const { isLoading } = this.state;
    if (isLoading) {
      return (
        <div>
          <Pacman loading={isLoading} />
        </div>
      );
    }
    return (
      <RenderPrebuildProcuctScroller
        {...this.props}
        {...this.state}
        action={(changeId, value, url) => {
          if (value !== 'api_proxy') this.loadData(changeId);
          else this.loadDataFromApiProxy(url);
        }}
      />
    );
  }
}
export default withSelect((select, props) => {
  const project = select('appmaker/core').getCurrentProject();
  const url = project.meta.URL;
  const apiKey = project.meta.API_KEY;
  return { url, apiKey, projectId: project.id };
})(PreBuildProductScroller);
