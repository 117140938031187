import React from 'react';

export const ProfileIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24">
        <rect width="24" height="24" fill="none" />
        <rect width="24" height="20" rx="4" transform="translate(0 2)" fill="#4d6ffb" />
        <g transform="translate(2.509 7.027)">
            <path d="M3.889,0A3.889,3.889,0,0,1,7.778,3.889C7.778,6.037,0,6.037,0,3.889A3.889,3.889,0,0,1,3.889,0Z" transform="translate(0 4.445)" fill="#8e87ff" />
            <circle cx="2.778" cy="2.778" transform="translate(1.111 0)" fill="#cbd3f6" r="2.778" />
        </g>
        <g transform="translate(13.713 7.027)">
            <path d="M3.889,0A3.889,3.889,0,0,1,7.778,3.889C7.778,6.037,0,6.037,0,3.889A3.889,3.889,0,0,1,3.889,0Z" transform="translate(0 4.445)" fill="#8e87ff" />
            <circle cx="2.778" cy="2.778" transform="translate(1.111 0)" fill="#cbd3f6" r="2.778" />
        </g>
    </svg>
);

export const ProfileItemIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24">
        <rect width="24" height="24" rx="12" fill="#4d6ffb" />
        <g transform="translate(7 5.608)">
            <path d="M5,0a5,5,0,0,1,5,5C10,7.761,0,7.761,0,5A5,5,0,0,1,5,0Z" transform="translate(0 5.714)" fill="#8e87ff" />
            <circle cx="3.571" cy="3.571" transform="translate(1.429 0)" fill="#cbd3f6" r="3.571" />
        </g>
    </svg>
);

export const ProfileImagePlace = () => {
    return (
        <svg width="60" height="60" viewBox="0 0 60 60">
            <g transform="translate(-11781 -7882)">
                <g transform="translate(11781 7882)">
                    <g fill="none" stroke="#4d6ffb" stroke-width="2">
                        <rect width="60" height="60" rx="10" stroke="none" />
                        <rect x="1" y="1" width="58" height="58" rx="9" fill="none" />
                    </g>
                    <g transform="translate(9.767 17.234)">
                        <path d="M10,0a10,10,0,0,1,10,10C19.991,15.515,0,15.515,0,10A10,10,0,0,1,10,0Z" transform="translate(0 11.424)" fill="#4d6ffb" />
                        <circle cx="7.139" cy="7.139" transform="translate(2.856 0)" fill="#1b38ac" r="7.139" />
                    </g>
                </g>
                <rect width="2" height="16" transform="translate(11822 7905)" fill="#4d6ffb" />
                <rect width="2" height="16" transform="translate(11831 7912) rotate(90)" fill="#4d6ffb" />
            </g>
        </svg>
    )
}