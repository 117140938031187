import {
  createApp,
  operations,
  uploadAppToCloud
} from '../../../../lib/pushAutomationApi';

export default async ({
  accessToken,
  platform,
  addToast = () => {},
  projectId,
  id,
  afterComplete = () => {},
  setLoading = () => {}
}) => {
  try {
    setLoading(true);
    const response = await createApp({
      accessToken,
      projectName: projectId,
      id,
      platform
    });
    if (response.status && response.body) {
      const operationName = response.body.name;
      const operationResponse = await operations({
        accessToken,
        operationId: operationName,
        id
      });
      if (
        operationResponse.status &&
        operationResponse.body &&
        operationResponse.body.done
      ) {
        addToast(
          `${platform === 'android' ? 'Android' : 'IOS'} app create success`,
          {
            appearance: 'success',
            autoDismiss: true
          }
        );
        setLoading(false);
      } else {
        addToast(
          `${
            platform === 'android' ? 'Android' : 'IOS'
          } app create in-progress`,
          {
            appearance: 'info',
            autoDismiss: true
          }
        );
      }
      // Upload to google cloud storage
      try {
        const uploadResponse = await uploadAppToCloud({
          accessToken,
          projectName: projectId,
          id,
          platform
        });
        if (uploadResponse.status && uploadResponse.body) {
          addToast(
            `${
              platform === 'android' ? 'Android' : 'IOS'
            } App configuration success`,
            {
              appearance: 'success',
              autoDismiss: true
            }
          );
          setLoading(false);
          afterComplete(true);
        } else {
          addToast(
            `${
              platform === 'android' ? 'Android' : 'IOS'
            } App configuration failed`,
            {
              appearance: 'error',
              autoDismiss: true
            }
          );
          setLoading(false);
        }
      } catch (error) {
        addToast(
          `${
            platform === 'android' ? 'Android' : 'IOS'
          } App configuration failed`,
          {
            appearance: 'error',
            autoDismiss: true
          }
        );
      }

      return;
    }
    addToast(
      `${platform === 'android' ? 'Android' : 'IOS'} App configuration failed`,
      {
        appearance: 'error',
        autoDismiss: true
      }
    );
    setLoading(false);
    return;
  } catch (error) {
    addToast(error.message || `Something went wrong`, {
      appearance: 'error',
      autoDismiss: false
    });
    setLoading(false);
  }
};
