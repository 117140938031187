import React from 'react';
import { InnerBlocks } from '@wordpress/block-editor';

import PanelComponent from './panel';

const Tab = props => {
  const { setAttributes, className } = props;
  return (
    <div className={className}>
      <InnerBlocks allowedBlocks={['appmaker/tab-item']} />
      <PanelComponent
        {...props}
        onChangeAttribute={value => {
          setAttributes(value);
        }}
      />
    </div>
  );
};

export default Tab;
