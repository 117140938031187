import * as actions from './actions';

export function* getMenu(menuKey) {
  const path = `navigationMenu/${menuKey}`;
  const menuList = yield actions.fetchFromAPI(path);
  return actions.setMenu(menuList);
}
getMenu.shouldInvalidate = (action, kind, name) => {
  return action.type === 'SET_MENU';
};
