import React from 'react';
import Login from './InnerComponents/login';

export default ({
  setAccessToken = () => {},
  id,
  addToast = () => {},
  nextStep = () => {}
}) => {
  return (
    <Login
      id={id}
      getUserData={data => {
        setAccessToken(
          data && data.credential && data.credential.accessToken
            ? data.credential.accessToken
            : false
        );
        addToast('Login success', {
          appearance: 'success',
          autoDismiss: true
        });
        nextStep();
      }}
      addToast={addToast}
    />
  );
};
