import React from 'react';

export default ({ projectName }) => {
  return (
    <div className="my-3">
      <p>
        <b>Step 1: </b>
        Navigate to Cloud Messaging tab in your project settings on Firebase
        console.
      </p>
      <p>
        <b>Step 2: </b>
        Copy Server key from Firebase console and paste in Appmaker dashboard.
      </p>
      {projectName && (
        <a
          href={`https://console.firebase.google.com/project/${projectName}/settings/cloudmessaging`}
          target="_blank"
          rel="noopener noreferrer"
          className="ml-2"
        >
          Link to FCM server key
        </a>
      )}
    </div>
  );
};
