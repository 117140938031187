import { castArray } from 'lodash';

export function addActionTypes(blockTypes) {
  return {
    type: 'ADD_ACTION_TYPES',
    actionTypes: castArray(blockTypes)
  };
}

/**
 * Returns an action object used to remove a registered action type.
 *
 * @param {string|Array} names Action name.
 *
 * @return {Object} Action object.
 */
export function removeActionTypes(ids) {
  return {
    type: 'REMOVE_ACTION_TYPES',
    ids: castArray(ids).map(item => `${item.template}_${item.id}`)
  };
}
