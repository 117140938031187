import { combineReducers, select, dispatch } from '@wordpress/data';

/* eslint-disable default-case */
import { keyBy, map, omit, isEmpty, find } from 'lodash';
/**
 * Module Constants
 */
export const DEFAULT_CATEGORIES = [{ slug: 'common', title: 'common' }];
window.select = select;
window.dispatch = dispatch;
/**
 * Reducer managing the block types
 *
 * @param {Object} state  Current state.
 * @param {Object} action Dispatched action.
 *
 * @return {Object} Updated state.
 */
export function actionTypes(state = {}, action) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case 'ADD_ACTION_TYPES':
      return {
        ...state,
        ...keyBy(
          map(action.actionTypes, actionType => omit(actionType, 'styles ')),
          item => `${item.template}_${item.id}`
        )
      };
    case 'REMOVE_ACTION_TYPES':
      return omit(state, action.ids);
  }

  return state;
}

/**
 * Reducer managing the categories
 *
 * @param {Object} state  Current state.
 * @param {Object} action Dispatched action.
 *
 * @return {Object} Updated state.
 */
export function categories(state = DEFAULT_CATEGORIES, action) {
  switch (action.type) {
    case 'SET_CATEGORIES':
      return action.categories || [];
    case 'UPDATE_CATEGORY': {
      if (!action.category || isEmpty(action.category)) {
        return state;
      }
      const categoryToChange = find(state, ['slug', action.slug]);
      if (categoryToChange) {
        return map(state, category => {
          if (category.slug === action.slug) {
            return {
              ...category,
              ...action.category
            };
          }
          return category;
        });
      }
    }
  }
  return state;
}
export default combineReducers({ actionTypes, categories });
