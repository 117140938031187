import React from 'react';
import { Alert } from 'reactstrap';

export default ({ children }) => {
  return (
    <div>
      <Alert color="danger">
        <i className="fas fa-exclamation-triangle mr-2" />
        Sync with plugin required. Press Save to sync
      </Alert>
      {children && children}
    </div>
  );
};
