const actions = {
  setInAppPage(key, inAppPage) {
    return {
      type: 'SET_IN_APP_PAGE',
      key,
      inAppPage
    };
  },
  setCurrentPage(key) {
    return {
      type: 'SET_CURRENT_PAGE',
      key
    };
  },
  setCurrentLanguage(key) {
    return {
      type: 'SET_LANGUAGE_KEY',
      key
    };
  },
  *saveInAppPage(key, inAppPage) {
    const request = {
      path: `in-app-pages/${key}`,
      credentials: 'include',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(inAppPage)
    };

    const response = yield {
      type: 'API_FETCH',
      request
    };

    if (!inAppPage.is_new) {
      yield {
        type: 'SET_IN_APP_PAGE',
        key,
        inAppPage: response
      };
    }
    return response;
  },
  setInAppPageList(inAppPageList) {
    return {
      type: 'SET_IN_APP_PAGE_LIST',
      inAppPageList
    };
  },
  deleteInAppPage(key) {
    return {
      type: 'DELETE_IN_APP_PAGE',
      key
    };
  },
  *deleteInAppPages(key, inAppPage) {
    const request = {
      path: `in-app-pages/${key}`,
      credentials: 'include',
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };
    const response = yield {
      type: 'API_FETCH',
      request
    };
    return response;
  },
  fetchFromAPI(path) {
    return {
      type: 'FETCH_FROM_API',
      path
    };
  }
};
export default actions;
