import { select } from '@wordpress/data';

export const BASE_URL = 'https://beta.manage.appmaker.xyz';
export const URL = 'https://appmaker.xyz';
// NOTE TO test in localhost
// export const API_URL = 'https://manage.appmaker.xyz';
// export const API_URL = 'http://localhost:3000';
// https://staging.api.appmaker.xyz

export let API_URL; // eslint-disable-line

if (process.env.REACT_APP_RUN_ENV === 'staging') {
  API_URL = 'https://staging.api.appmaker.xyz';
} else if (process.env.NODE_ENV === 'production') {
  API_URL = 'https://api.appmaker.xyz';
} else {
  API_URL = 'http://localhost:3000';
  // API_URL = 'https://api.appmaker.xyz';
}

export const CREATE = '/create';
export const CHOOSE_METHOD = '/create/choose-method';
export const BOOK_DEMO = '/create/book-demo';
export const STEP_TWO = '/create/step-2';
export const THANK_YOU = '/create/thank-you';
export const PLUGIN_CHECK = '/create/plugin-check';

export const DASHBOARD = '/apps/:id/(.*)';
export const DASHBOARD_MAIN = '/apps/:id';
export const CUSTOMIZE_APP = '/customize-app';
export const BUILD_VIEW = '/customize-app';
export const ADD_NEW_PAGE = '/customize-app/add-new-page';
export const PUSH_NOTIFICATION = '/apps/:id/push-notification';
export const SMART_BANNER = '/apps/:id/smart-banner';
export const EMAIL_FOOTER = '/apps/:id/email-footer';
export const SETTINGS = '/apps/:id/settings/:tab?';
export const SUBSCRIPTION = '/apps/:id/subscription';
export const MAKE_PAYMENT = '/dashboard/make-payment';
export const SUBSCRIPTION_EXPIRED = '/apps/:id/subscription-expired';
export const ACCOUNT_SUSPENDED = '/apps/:id/suspended';
export const PUSH_AUTOMATION = '/apps/:id/firebase-configure';
export const MANAGE = '/dashboard';
export const ACTIVE_APPS = '/dashboard';
export const DELETED_APPS = '/dashboard/apps/trash';
export const ACCOUNT_SETTINGS = '/dashboard/account-settings';
export const CUSTOMER_DATA = '/admin/:id';
export const SETTING = '/dashboard/settings';
export const PLAYGROUND = '/playground';

export const linkToConnectStore = projectId => {
  return `/apps/${projectId}/connect-store`;
};

export const linkToManageApp = projectId => {
  return `/apps/${projectId}/`;
};

export const linkToInAppPage = pageId => {
  const project = select('appmaker/core').getCurrentProject();
  return `/apps/${project.id}/inAppPage/${pageId}`;
};

export const linkToInMenu = () => {
  const project = select('appmaker/core').getCurrentProject();
  return `/apps/${project.id}/menu/`;
};

export const linkToUpdateApp = projectId => {
  const project = select('appmaker/core').getCurrentProject();
  // return `/apps/${project.id}/update-app/`;
  return `https://appmaker.xyz/dashboard/apps/update/${
    projectId === undefined ? project.id : projectId
  }`;
};

export const linkToPushNotification = () => {
  const project = select('appmaker/core').getCurrentProject();
  return `/apps/${project.id}/push-notification`;
};
export const linkToSmartBanner = () => {
  const project = select('appmaker/core').getCurrentProject();
  return `/apps/${project.id}/smart-banner`;
};
export const linkToEmailFooter = () => {
  const project = select('appmaker/core').getCurrentProject();
  return `/apps/${project.id}/email-footer`;
};

export const linkToSettings = () => {
  const project = select('appmaker/core').getCurrentProject();
  return `/apps/${project.id}/settings`;
};
export const linkToCustomerData = () => {
  const project = select('appmaker/core').getCurrentProject();
  return `/apps/${project.id}/customer-data`;
};
export const linkToSettingsInnerTabs = (projectId, tabName) => {
  return `/apps/${projectId}/settings/${tabName}`;
};
export const linkToSubscription = () => {
  const project = select('appmaker/core').getCurrentProject();
  return `/apps/${project.id}/subscription`;
};
export const linkToPaymentPage = () => {
  const project = select('appmaker/core').getCurrentProject();
  return `https://appmaker.xyz/payment/pay-for-project?projectId=${project.id}`;
};
export const linkToPaymentPageUsingInvoiceId = invoiceId => {
  return `https://appmaker.xyz/payment/pay?invoice=${invoiceId}&gateway=twocheckout`;
};

export const downloadAppBundle = () => {
  const project = select('appmaker/core').getCurrentProject();
  return `https://appmaker.xyz/dashboard/apps/download/?preview=false&bundle=true&project_id=${project.id}`;
};
export const linkToPushAutomation = () => {
  const project = select('appmaker/core').getCurrentProject();
  return `/apps/${project.id}/firebase-configure`;
};

export const linkToPushAutomationRoutes = (projectId, route) => {
  return `/apps/${projectId}/push-automation/${route}`;
};
