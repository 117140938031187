/* eslint-disable default-case */
import { registerStore } from '@wordpress/data';
import apiFetch from '@wordpress/api-fetch';
import 'whatwg-fetch';
// import fetch from 'fetch';

const DEFAULT_STATE = {
  images: []
};

const actions = {
  /**
   * Save in-app page data ( blocks & config )
   *
   * @param {*} key
   * @param {*} page
   */
  async uploadMedia(id, files) {
    const url = `https://manage.appmaker.xyz/remote_store/${id}/media/uploadMedia`;
    const data = new FormData();
    data.append('file', files);
    try {
      const option = {
        credentials: 'include',
        method: 'POST',
        body: data
      };
      const responseRaw = await fetch(url, option);
      const response = await responseRaw.json();
      return { type: 'MEDIA_UPLOADED', response };
    } catch (error) {
      return Promise.reject(new Error('Error when calling upload media'));
    }
  },

  /**
   * Set in-app pages list
   *
   * @param {*} key
   * @param {*} page
   */
  setMediaList(images) {
    // console.log('setMediaList');
    return {
      type: 'SET_MEDIA_LIST',
      images
    };
  },

  getMediaList(path) {
    // console.log('getMediaList');
    return {
      type: 'FETCH_FROM_API',
      path
    };
  },

  fetchFromAPI(path) {
    // console.log('action fetchFromAPI', path);
    return {
      type: 'FETCH_FROM_API',
      path
    };
  }
};

registerStore('appmaker-media', {
  reducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
      case 'SET_MEDIA_LIST':
        return {
          ...state,
          images: action.images
        };
    }
    return state;
  },
  actions,
  selectors: {
    getMediaList(state) {
      return state.images;
    }
  },
  controls: {
    FETCH_FROM_API(action) {
      // console.log('ctr FETCH_FROM_API', action);
      return apiFetch({ path: action.path });
    }
  },

  resolvers: {
    *getMediaList({ search = '', page = 1 }) {
      const path = `https://manage.appmaker.xyz/api_proxy/29223?url=uploadMedia&search=${search}&page=${page}`;
      const images = yield actions.fetchFromAPI(path);
      return actions.setMediaList(images.body);
    }
  }
});
