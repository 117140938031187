import React, { useState } from 'react';
import { Label, Input, FormFeedback, Tooltip } from 'reactstrap';
import '../style.css';

const PlanListing = ({
  id,
  planName,
  price,
  revenueDisc,
  perfectFor,
  defaultChecked = false,
  invalid = false,
  onSelect,
  showYearOnly
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <React.Fragment>
      <Input
        type="radio"
        id={id}
        name="choose_plan"
        className="d-none plan-check-box"
        defaultChecked={defaultChecked}
        invalid={invalid}
        disabled={invalid}
        onChange={() => {
          onSelect();
        }}
      />
      <Label htmlFor={id} className="choose-plan-container cursor-pointer">
        <h6 className="text-primary text-uppercase">{planName}</h6>
        <span className="plan-content">
          <h4>
            <small>$ </small>
            {price}
            <small>{showYearOnly ? '/y.' : '/m.'}</small>
          </h4>
          <p className="text-primary revenue-share">{revenueDisc}</p>
          <p>
            <b>Perfect for:</b>
            <br />
            <small>{perfectFor}</small>
          </p>
          <h4>
            <i className="fas fa-check-circle" />
          </h4>
        </span>
      </Label>
      {invalid === true ? (
        <React.Fragment>
          <FormFeedback className="text-center">
            You cant choose this plan
{' '}
            <i
              className="fas fa-question-circle cursor-pointer"
              id="errorMessage"
            />
          </FormFeedback>
          <Tooltip
            placement="bottom"
            isOpen={tooltipOpen}
            target="errorMessage"
            toggle={toggle}
          >
            Page limit exceeded
          </Tooltip>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default PlanListing;
