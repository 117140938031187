import React from 'react';
import { PanelBody } from '@wordpress/components';
import ActionForm from '../../../../hooks/action/components/customActionForm';
import tabAction from '../../../../plugins/wordpress/tabActions';

/**
 * Custom action for tab pages
 */
export default ({ attributes = {}, setAttributes = false }) => {
  return (
    <PanelBody title="Mobile app action">
      <ActionForm
        onChange={data => {
          if (setAttributes !== false) {
            setAttributes({ appmakerAction: data });
          }
        }}
        actionTypes={tabAction}
        formData={attributes.appmakerAction}
      />
    </PanelBody>
  );
};
