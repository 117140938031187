import * as React from 'react';

// @flow strict

class IconScout extends React.Component<Props> {
  componentDidMount() {
    window.addEventListener('message', this.handleIframeTask);
  }
  handleIframeTask = e => {
    // console.log(e);
    if (e.data.action === 'insertImage') {
      this.props.action &&
        this.props.action({ url: e.data.file.url, closePane: true });
    }
  };
  render() {
    return (
      <iframe
        height="500"
        width="100%"
        src="https://gsuite.iconscout.com/#/icons?sort=latest"
      />
    );
  }
}

export default IconScout;
