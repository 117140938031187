import React from 'react';
import { InspectorControls, InnerBlocks } from '@wordpress/block-editor';
import { PanelBody, RangeControl, ToggleControl } from '@wordpress/components';
import { __ } from '@wordpress/i18n';
import { withSelect } from '@wordpress/data';
import SliderImage from '../../components/SliderImage';

const renderImage = innerBlocks => {
  const out = innerBlocks.map((block, index) => {
    return (
      <div
        className={`carousel-item ${index === 0 ? 'active' : ''}`}
        key={`slide-${index}`}
      >
        {block.attributes.image !== undefined && (
          <SliderImage imageSrc={block.attributes.image.url} />
        )}
      </div>
    );
  });
  return out;
};
const AppmakerSlider = props => {
  const {
    setAttributes,
    className,
    attributes,
    isParentOrChildSelected,
    block
  } = props;
  console.log();
  const { autoPlay, delay } = attributes;
  if (props.isParentOrChildSelected)
    return (
      <div className={className}>
        <InnerBlocks allowedBlocks={['appmaker/slider-item']} />
        <InspectorControls>
          <PanelBody>
            {/* <input
            type="number"
            placeholder="delay"
            onChange={data => {
              setAttributes({ delay: data.target.value });
            }}
          /> */}
            {/* <ToggleControl
              label={__('Autoplay', 'newspack-blocks')}
              checked={autoPlay}
              onChange={() => setAttributes({ autoPlay: !autoPlay })}
            />
            <RangeControl
              help={__('Set the delay for the slider', 'newspack-blocks')}
              value={delay}
              onChange={value => setAttributes({ delay: value })}
              min={0}
              max={5}
              required
            /> */}
          </PanelBody>
        </InspectorControls>
      </div>
    );
  return (
    <div>
      <div id="appmakerSlider" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">{renderImage(block.innerBlocks)}</div>
      </div>
    </div>
  );
};

export default withSelect((select, ownProps) => {
  const {
    isBlockSelected,
    hasSelectedInnerBlock,
    getBlock,
    getBlockListSettings,
    getBlockRootClientId,
    getTemplateLock,
    isNavigationMode
  } = select('core/block-editor');
  const { clientId, isSmallScreen } = ownProps;
  const block = getBlock(clientId);
  const rootClientId = getBlockRootClientId(clientId);

  return {
    block,
    blockListSettings: getBlockListSettings(clientId),
    isParentOrChildSelected:
      isBlockSelected(clientId) || hasSelectedInnerBlock(clientId, true),
    parentLock: getTemplateLock(rootClientId),
    enableClickThrough: isNavigationMode() || isSmallScreen
  };
})(AppmakerSlider);
