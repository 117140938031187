import React from 'react';

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <g id="postList-gutIcon" transform="translate(-13341 -6798)">
      <rect id="Rectangle_572" data-name="Rectangle 572" width="24" height="24" transform="translate(13341 6798)" fill="none" />
      <g id="document-layout-left_2_" data-name="document-layout-left (2)" transform="translate(13342 6802)">
        <path id="Path_3974" data-name="Path 3974" d="M23.789,7.814H12.907a.907.907,0,1,1,0-1.814H23.789a.907.907,0,1,1,0,1.814Zm0,3.627H12.907a.907.907,0,0,1,0-1.814H23.789a.907.907,0,1,1,0,1.814Z" transform="translate(-2.932 -5.093)" fill="#a6a1ff" />
        <rect id="Rectangle_571" data-name="Rectangle 571" width="7" height="7" rx="1" fill="#6c63ff" />
      </g>
      <g id="document-layout-left_2_2" data-name="document-layout-left (2)" transform="translate(13342 6810.672)">
        <path id="Path_3974-2" data-name="Path 3974" d="M21.975,7.814H12.907a.907.907,0,1,1,0-1.814h9.068a.907.907,0,1,1,0,1.814Zm0,3.627H12.907a.907.907,0,0,1,0-1.814h9.068a.907.907,0,1,1,0,1.814Z" transform="translate(-2.932 -4.685)" fill="#a6a1ff" />
        <rect id="Rectangle_571-2" data-name="Rectangle 571" width="7" height="7" rx="1" transform="translate(0 0.328)" fill="#6c63ff" />
      </g>
    </g>
  </svg>

);
