import React from 'react';
import { Spinner } from 'reactstrap';
import PreLoading from '../../layout/PreLoading';

export default ({ bubble = false }) => {
  if (!bubble) return <PreLoading single />;
  if (bubble) {
    return <Spinner size="sm" color="primary" />;
  }
};
