import React, { useState } from 'react';
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Table
} from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';
import FreshDesk from './InnerComponents/freshdesk';

export default props => {
  const { intercomActivity = [], projectData = [], projectId } = props;
  const [activeTab, setActiveTab] = useState('0');
  const tabs = [
    {
      key: 1,
      title: 'Intercom Activity'
    },
    {
      key: 2,
      title: 'Projects'
    },
    {
      key: 3,
      title: 'Freshdesk'
    }
  ];
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="detail-tabs">
      <Nav tabs>
        {tabs.map((item, i) => (
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === i.toString() })}
              onClick={() => {
                toggle(i.toString());
              }}
            >
              {item.title}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="0">
          <Row>
            <Col sm="12">
              <ul className="user-activity">
                {intercomActivity &&
                  intercomActivity.map(item => {
                    return (
                      <li className="user-activity-item">
                        {item.eventName}
                        ->
                        {moment(item.createdAt * 1000).format('LL')}
                      </li>
                    );
                  })}
              </ul>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              {projectData !== false &&
                projectData.map(project => {
                  return (
                    <div className="card mb-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <a
                            href={`https://manage.appmaker.xyz/apps/${project.projectId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h5>
                              <b>{project.projectId}</b>
                            </h5>
                          </a>
                          <p>
                            Status:{' '}
                            {
                              {
                                '0': (
                                  <span className="text-secondary">
                                    Not Started
                                  </span>
                                ),
                                '1': (
                                  <span className="text-success">Building</span>
                                ),
                                '2': (
                                  <span className="text-primary">Active</span>
                                ),
                                '-1': (
                                  <span className="text-warning">Failed</span>
                                ),
                                '-2': (
                                  <span className="text-danger">Deleted</span>
                                ),
                                '-3': (
                                  <span className="text-warning">
                                    No Certificate
                                  </span>
                                )
                              }[project.status]
                            }
                          </p>
                        </div>
                        <h5 className="card-title mb-0">{project.name}</h5>
                        <small className="d-block">
                          {project.templateName}
                        </small>
                        <div className="mt-2">
                          <a
                            href={`https://manage.appmaker.xyz/apps/${project.projectId}`}
                            className="btn btn-secondary btn-sm mr-2"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Dashboard
                          </a>
                          <a
                            href={project.url}
                            className="btn btn-outline-secondary btn-sm"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Web site
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <FreshDesk projectId={projectId} />
        </TabPane>
      </TabContent>
    </div>
  );
};
