import React from 'react';

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <g id="banner-gutIcon" transform="translate(-13071 -6799)">
      <rect id="Rectangle_547" data-name="Rectangle 547" width="24" height="24" transform="translate(13071 6799)" fill="none" />
      <rect id="Rectangle_548" data-name="Rectangle 548" width="22" height="20" rx="4" transform="translate(13072 6801)" fill="#6c63ff" />
      <circle id="Ellipse_87" data-name="Ellipse 87" cx="3" cy="3" r="3" transform="translate(13077 6811)" fill="#fff" opacity="0.23" />
      <circle id="Ellipse_88" data-name="Ellipse 88" cx="2" cy="2" r="2" transform="translate(13083 6807)" fill="#fff" opacity="0.23" />
      <circle id="Ellipse_89" data-name="Ellipse 89" cx="1" cy="1" r="1" transform="translate(13087 6805)" fill="#fff" opacity="0.23" />
    </g>
  </svg>
);
