import React from 'react';
import {
  InnerBlocks,
  InspectorControls,
  RichText
} from '@wordpress/block-editor';
import { PanelBody, RangeControl, ToggleControl } from '@wordpress/components';
import { __ } from '@wordpress/i18n';
// Panel body component
import PanelComponent from './panel';
import { extractTextFromHTML } from '../../helper';

// To set the attributes given from panel body component
const onChangeAttribute = (value, setAttributes) => {
  setAttributes(value);
};
export default props => {
  const { setAttributes, attributes } = props;
  const { showViewMoreButton, showTitle } = attributes;
  return (
    <div className={props.className}>
      {(showTitle || showViewMoreButton) && (
        <div className="block-title-part d-flex p-2 justify-content-between">
          <div className="block-title my-auto">
            {showTitle && (
              <RichText
                allowedFormats={[]}
                value={attributes.title}
                withoutInteractiveFormatting
                onChange={title => {
                  setAttributes({ title: extractTextFromHTML(title) });
                }}
                placeholder="Image scroller title"
              />
            )}
          </div>
          <div className="view-more-button">
            <div className="btn btn-light btn-sm">
              {showViewMoreButton && (
                <RichText
                  allowedFormats={[]}
                  value={attributes.ctaText}
                  withoutInteractiveFormatting
                  onChange={ctaText => {
                    setAttributes({ ctaText: extractTextFromHTML(ctaText) });
                  }}
                  placeholder="View More Button"
                />
              )}
            </div>
          </div>
        </div>
      )}
      <InnerBlocks allowedBlocks={['appmaker/imagescroller-item']} />
      {/* <PanelComponent
        {...props}
        onChangeAttribute={value => {
          onChangeAttribute(value, setAttributes);
        }}
      /> */}
    </div>
  );
};
