import React, { useState } from 'react';
import {
  Spinner,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import ActionSelect from '../../hooks/action/components/NewActionSelect';

export default ({
  createPage = false,
  isLoading,
  buttonLabel,
  className,
  onClick,
  template,
  limitCreatePage = false
}) => {
  const [modal, setModal] = useState(false);
  const [pageType, setPageType] = useState('NORMAL');
  const toggle = () => setModal(!modal);
  if (onclick) {
    toggle();
  }
  const widgetData = [
    {
      data: [
        {
          data: {
            title: {
              value: 'Home',
              display_value: 'Home',
              label: 'Title',
              display: true
            },
            image: '',
            in_app_page_id: {
              value: 'home',
              display_value: 'Home',
              label: 'In-App Page',
              display: true
            }
          },
          image: ''
        }
      ],
      expanded: true,
      title: 'Tabs',
      type: 'tab',
      data_main: {},
      canDelete: false,
      canEditTitle: false,
      key: 'widget_key_tab'
    }
  ];
  return (
    <div>
      {!limitCreatePage && (
        <button
          type="button"
          className="btn-block text-decoration-none rounded d-block add-new-page border-dashed text-center p-1 mt-1"
          onClick={() => {
            if (limitCreatePage) {
              return;
            }
            if (template === 'wordpress') toggle();
            else if (createPage !== false) {
              createPage({ type: 'NORMAL' });
            }
          }}
        >
          {isLoading ? (
            <React.Fragment>
              <Spinner size="sm" color="primary" />
              Creating...
            </React.Fragment>
          ) : (
            '+ Add new page'
          )}
        </button>
      )}
      {limitCreatePage && (
        <button
          type="button"
          className="btn-block text-decoration-none rounded d-block add-new-page border-dashed text-center p-1 mt-1"
          disabled={limitCreatePage}
        >
          Limit exceeded can't create new page
        </button>
      )}
      <div>
        <Modal isOpen={modal} toggle={toggle} className={className}>
          <ModalHeader toggle={toggle}>Select Page type</ModalHeader>
          <ModalBody>
            {/* <button
              type="button"
              onClick={() => {
                createPage({
                  type: 'TABBED',
                  widgets: widgetData,
                  title: 'New Tab page'
                });
              }}
            >
              Tabbed
            </button>
            <button
              type="button"
              onClick={() => {
                createPage({ type: 'NORMAL' });
              }}
            >
              Normal
            </button> */}
            <ActionSelect
              value={pageType}
              options={[
                { value: 'NORMAL', label: 'Normal' },
                { value: 'TABBED', label: 'Tabbed' }
              ]}
              onChange={value => {
                setPageType(value.value);
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                if (pageType === 'NORMAL') {
                  createPage({ type: 'NORMAL' });
                  toggle();
                } else {
                  createPage({
                    type: 'TABBED',
                    widgets: widgetData,
                    title: 'New Tab page'
                  });
                  toggle();
                }
              }}
            >
              Create Page
{' '}
            </Button>
{' '}
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};
