import React, { Component } from 'react';
import Select from 'react-select';

const actionOptions = [
    { value: 'in-app-page', label: 'Open In-App page' },
    { value: 'web-view', label: 'Open Web View' },
    { value: 'url-view', label: 'Open URL' },
    { value: 'no-action', label: 'No action' }
];
const inappPageOptions = [
    { value: 'home', label: 'Home' },
    { value: 'offers', label: 'Offers' }
];

class BlockAction extends Component {
    state = {
        selectedOption: null,
        selectedInappPage: null,
    }
    handleActionChange = (selectedOption) => {
        this.setState({ selectedOption });
    }
    handleInappPageChange = (selectedInappPage) => {
        this.setState({ selectedInappPage });
    }
    getInappPage() {
        const { selectedInappPage } = this.state;
        if (this.state.selectedOption) {
            switch (this.state.selectedOption.value) {
                case 'in-app-page':
                    return (
                        <div>
                            <label htmlFor="set-action">Click action</label>
                            <div className="mb-2">
                                <Select
                                    value={selectedInappPage}
                                    onChange={this.handleInappPageChange}
                                    options={inappPageOptions}
                                    placeholder="Select In-App page"
                                />
                            </div>
                        </div>
                    )

                default:
                    return null;
            }
        }
    }
    getWebUrl() {
        if (this.state.selectedOption) {
            switch (this.state.selectedOption.value) {
                case 'web-view':
                    return (
                        <div>
                            <label htmlFor="webviewUrl">Web view URL</label>
                            <input className="form-control" type="url" placeholder="Enter webview URL" id="webviewUrl" />
                        </div>
                    )

                default:
                    return null

            }
        }
    }
    getUrl() {
        if (this.state.selectedOption) {
            switch (this.state.selectedOption.value) {
                case 'url-view':
                    return (
                        <div>
                            <label htmlFor="openUrl">Web view URL</label>
                            <input className="form-control" type="url" placeholder="Enter URL" id="openUrl" />
                        </div>
                    )


                default:
                    return null

            }
        }
    }

    render() {
        const { selectedOption } = this.state;
        const webViewField = this.getWebUrl();
        const inAppPage = this.getInappPage();
        const urlViewField = this.getUrl();
        return (
            <div className="block-action mb-3">
                <label htmlFor="set-action">Click action</label>
                <div className="mb-2">
                    <Select
                        value={selectedOption}
                        onChange={this.handleActionChange}
                        options={actionOptions}
                        placeholder="Select/Search action"
                    />
                </div>
                {webViewField}
                {inAppPage}
                {urlViewField}
            </div>
        );
    }
}

export default BlockAction;