import React from 'react';

export default () => (
    <svg width="24" height="24" viewBox="0 0 24 24">
        <g id="productScrollerItem-gutIcon" transform="translate(-13207 -6800)">
            <rect id="Rectangle_568" data-name="Rectangle 568" width="24" height="24" transform="translate(13207 6800)" fill="none" />
            <g id="box_2_" data-name="box (2)" transform="translate(13206.067 6799.292)" opacity="0.7">
                <path id="Path_3967" data-name="Path 3967" d="M21.389,7.574l-8.81,5.1-8.81-5.1a2.12,2.12,0,0,1,.749-.76l6.99-4.036a2.179,2.179,0,0,1,2.141,0l6.99,4.036a2.12,2.12,0,0,1,.749.76Z" transform="translate(0.019 0)" fill="#c4c0ff" />
                <path id="Path_3968" data-name="Path 3968" d="M12.6,12.335V22.5a2.236,2.236,0,0,1-.974-.225L4.57,18.2A2.144,2.144,0,0,1,3.5,16.349V8.321A2.205,2.205,0,0,1,3.789,7.24Z" transform="translate(0 0.334)" fill="#a6a1ff" />
                <path id="Path_3969" data-name="Path 3969" d="M21.1,8.321v8.028a2.144,2.144,0,0,1-1.07,1.852L12.942,22.29A2.241,2.241,0,0,1,12,22.5V12.335l8.81-5.1A2.205,2.205,0,0,1,21.1,8.321Z" transform="translate(0.599 0.334)" fill="#6c63ff" />
            </g>
        </g>
    </svg>

);