import React from 'react';
import UpdateApp from '../components/updateApp';
import * as ROUTES from '../../../../constants/routes';
import PushNotification from '../components/pushNotification';
import Subscription from '../components/subscription';

const WebApp = props => {
  const { appId, subscriptionExpired, toggleSideBar, hideComponents } = props;
  return (
    <React.Fragment>
      {!hideComponents && (
        <React.Fragment>
          <UpdateApp
            appId={appId}
            link={ROUTES.linkToUpdateApp()}
            subscriptionExpired={subscriptionExpired}
          />
          <PushNotification
            onClick={toggleSideBar}
            link={ROUTES.linkToPushNotification()}
          />
        </React.Fragment>
      )}
      <Subscription
        link={ROUTES.linkToSubscription()}
        onClick={toggleSideBar}
      />
    </React.Fragment>
  );
};

export default WebApp;
