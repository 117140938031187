import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import StepCount from './Components/stepCount';
import StepOne from './Components/stepLogin';
import StepTwo from './Components/stepListProject';
import StepThree from './Components/stepUpload';
import StepFour from './Components/stepFCM';
import StepFive from './Components/stepUpdateApp';

import { trackEvent } from '../../utils/tracker';

export default props => {
  const { addToast } = useToasts();
  const { match = {} } = props;
  const { params = {} } = match;
  const { id } = params;
  const { afterPushConfigurationDone = () => {} } = props;
  const [currentStep, setCurrentStep] = useState(1);
  const [accessToken, setAccessToken] = useState(false);
  const [firebaseProjectName, setFirebaseProjectName] = useState(false);

  trackEvent('firebase_configure_step', { step: currentStep, project_id: id });

  const nextStep = () => {
    // TODO check last step condition
    setCurrentStep(currentStep + 1);
  };
  const previousStep = () => {
    if (currentStep !== 1) setCurrentStep(currentStep - 1);
  };
  return (
    <div>
      <StepCount currentStep={currentStep} />
      {/* Login */}
      {currentStep === 1 && (
        <StepOne
          setAccessToken={setAccessToken}
          id={id}
          nextStep={nextStep}
          addToast={addToast}
        />
      )}
      {/* List and create app */}
      {currentStep === 2 && (
        <StepTwo
          previousStep={previousStep}
          nextStep={nextStep}
          accessToken={accessToken}
          id={id}
          addToast={addToast}
          setFirebaseProjectNameAfterSelecting={data => {
            if (data) {
              setFirebaseProjectName(data);
            }
          }}
        />
      )}
      {/* Upload service account */}
      {currentStep === 3 && (
        <StepThree
          firebaseProjectName={firebaseProjectName}
          addToast={addToast}
          id={id}
          previousStep={previousStep}
          nextStep={nextStep}
        />
      )}
      {/* Save FCM */}
      {currentStep === 4 && (
        <StepFour
          afterPushConfigurationDone={nextStep}
          addToast={addToast}
          id={id}
          previousStep={previousStep}
          firebaseProjectName={firebaseProjectName}
        />
      )}
      {currentStep === 5 && (
        <StepFive
          id={id}
          afterPushConfigurationDone={afterPushConfigurationDone}
        />
      )}
    </div>
  );
};
