import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { setAsHomePage } from '../../lib/apiFunctions';

export default ({
  pageKey,
  projectId,
  disabled,
  addToast = () => {},
  afterComplete = () => {}
}) => {
  const [isLoading, setLoading] = useState(false);
  const homPageRequest = async () => {
    try {
      setLoading(true);
      const response = await setAsHomePage(projectId, pageKey);
      if (!response.status) {
        setLoading(false);
        return addToast(response.message || 'Something went wrong', {
          appearance: response.type || 'error'
        });
      }
      const { body = {} } = response;
      setLoading(false);
      addToast('Home page updated', {
        appearance: 'success'
      });
      return afterComplete(body.pageKey);
    } catch (error) {
      setLoading(false);
      return addToast(error.message || 'Something went wrong', {
        appearance: error.type || 'error'
      });
    }
  };
  const buttonLabel = isLoading ? 'Setting Homepage...' : 'Set as home page';
  return (
    <div>
      <Button
        disabled={disabled || isLoading}
        className="btn btn-sm m-1"
        onClick={homPageRequest}
      >
        {' '}
        <i
          className={`fas ${isLoading ? 'fa-spinner fa-spin' : 'fa-home'} mr-2`}
        />
        {buttonLabel}
      </Button>
    </div>
  );
};
