// @flow strict
// @flow strict

import * as React from 'react';
import '../store/in-app-page';
import ActionForm from '../hooks/action/components/ActionForm';
import ImageUpload from '../components/mediaLibrary';
import InAppPageEditor from '../components/InAppPageEditor';
import { withSelect, useSelect, dispatch } from '@wordpress/data';
import Editor from './Editor';
import IconScout from '../components/mediaLibrary';

class PlayGround extends React.Component {
  state = {
    image: {}
  };

  saveInAppPage() {
    const inAppPage = dispatch('appmaker/in-app-page').saveInAppPage('ymIz1R');
  }
  render() {
    // console.log(Editor);
    return (
      <div>
        JSON : {JSON.stringify(this.props.inAppPage)}

        <button onClick={this.saveInAppPage} >SAVE</button>
      </div>
    );
  }
}

// export default PlayGround;
export default withSelect(select => {
  console.log('asdsad');
  const inAppPage = select('appmaker/in-app-page').getInAppPage('ymIz1R');
  console.log("AAA", inAppPage)
  return {
    'inAppPage': inAppPage
  };
})(PlayGround);

