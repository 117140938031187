import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'reactstrap';
import moment from 'moment';
import { freshDeskCustomerTab } from '../../../../../lib/apiFunctions';
import Loading from '../Loading';

export default props => {
  const { projectId } = props;
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await freshDeskCustomerTab(projectId);
      if (response.status) {
        setData(response.body);
      }
      setLoading(false);
    } catch (error) { }
  };
  useEffect(() => {
    fetchData();
  }, []);
  if (isLoading) {
    return (
      <React.Fragment>
        <Loading />
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Row>
        <Col sm="12">
          <Table>
            <thead>
              <tr>
                <th>Subject</th>
                <th>Created At</th>
                <th>Updated At</th>
              </tr>
            </thead>
            <tbody>
              {data.map(ticket => {
                return (
                  <tr>
                    <td><a target="_blank" href={`https://appmaker.freshdesk.com/a/tickets/${ticket.id}`}>{ticket.subject} {ticket.status === 2 ? "(Open)" : ""}</a></td>
                    <td>{moment(ticket.createdAt).format('LL')}</td>
                    <td>{moment(ticket.updatedAt).format('LL')}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </React.Fragment>
  );
};
