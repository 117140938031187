import React from 'react';
import { withSelect } from '@wordpress/data';
import { Fragment } from '@wordpress/element';
import { InspectorControls } from '@wordpress/editor';
import { PanelBody } from '@wordpress/components';
import { getProductScrollerData } from '../../../../lib/apiFunctions';
import ActionSelect from '../../../../hooks/action/components/ActionSelect';
import '../style.css';
import PanelBodyComponent from '../panel';

const generateProducts = (products, id, isLoading) => {
  // FIXME  for loading
  if (isLoading) {
    return <div>Loading ...</div>;
  }
  if (products.length === 0 || typeof products !== 'object') {
    return <div>No Products available</div>;
  }
  let data;
  if (products !== undefined && products.body !== undefined)
    data = products.body.find(item => item.id === id);
  if (data === undefined) {
    return <div>No Products available</div>;
  }
  return (
    <div className="product-card rounded shadow-sm p-2 mr-2">
      <img
        src={`${data.images[0]}`}
        width="100px"
        height="auto"
        alt="product"
      />
      <h6>{data.name}</h6>
      <p
        className="small-label-text"
        dangerouslySetInnerHTML={{ __html: data.price_display }}
      />
      <div className="count-add-cart text-center" />
    </div>
  );
};
const RenderProcuctScroller = props => {
  const {
    className,
    attributes,
    setAttributes,
    products,
    isLoading,
    action
  } = props;
  const { id } = attributes;
  return (
    <div className={className}>
      <div className="product-scroller">
        {/* <h6>Featured Products</h6> */}
        <div className="product-scroller-container">
          {generateProducts(products, id, isLoading)}
        </div>
      </div>
      <Fragment>
        <InspectorControls>
          <PanelBody title="Choose product">
            <ActionSelect
              projectId={props.projectId}
              dataSource={{
                link: 'products'
              }}
              onChange={data => {
                action(data.id, data.label);
              }}
              value={
                products.body !== undefined && products.body.length !== 0
                  ? { label: products.body[0].name, id }
                  : ''
              }
            />
          </PanelBody>
        </InspectorControls>
      </Fragment>
    </div>
  );
};
class ProductScroller extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      products: ''
    };
  }

  async componentDidMount() {
    const { attributes } = this.props;
    await this.loadData(attributes.id);
  }

  async loadData(id) {
    this.setState({ isLoading: true });
    try {
      const response = await getProductScrollerData(
        this.props.url,
        this.props.apiKey,
        id,
        this.props.projectId
      );
      this.setState({ isLoading: false, products: response });
    } catch (error) {}
  }

  render() {
    return (
      <div>
        <RenderProcuctScroller
          {...this.props}
          {...this.state}
          action={(id, label) => {
            this.props.setAttributes({ id, labelId: label });
            this.loadData(id);
          }}
        />
        <PanelBodyComponent
          attributes={this.props.parentAttributes}
          onChangeAttribute={value => {
            this.props.onChangeParent(value);
          }}
        />
      </div>
    );
  }
}
export default withSelect((select, props, dispatch) => {
  const project = select('appmaker/core').getCurrentProject();
  const url = project.meta.URL;
  const apiKey = project.meta.API_KEY;
  const parentId = select('core/block-editor').getBlockRootClientId(
    props.clientId
  );
  const parentAttributes = select('core/block-editor').getBlockAttributes(
    parentId
  );
  // change the value of the parent attributes
  const onChangeParent = value => {
    dispatch
      .dispatch('core/block-editor')
      .updateBlockAttributes(parentId, value);
  };
  return {
    url,
    apiKey,
    projectId: project.id,
    parentAttributes,
    parentId,
    onChangeParent
  };
})(ProductScroller);
