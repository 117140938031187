import React from 'react';
import './style.css';

const DashboardNotice = ({ storeUrl, projectId }) => {
  return (
    <div className="row mb-2">
      <div className="col">
        <div className="dashboard-notice-wrapper row m-0">
          <div className="col-lg-8 col-md-6 col-12 my-2">
            <p className="mb-0">
              This is the app you already created using URL:
{' '}
              <a href={storeUrl} target="_blank" rel="noopener noreferrer">
                {storeUrl}
              </a>
              . Update App to modify the app or Contact Support
            </p>
          </div>
          <div className="col-lg-4 col-md-6 col-12 my-auto text-right">
            <a
              href={`https://appmaker.xyz/dashboard/apps/update/${projectId}`}
              target="_blank"
              className="btn btn-primary"
            >
              Update App
            </a>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {
                if (window.Intercom) window.Intercom('show');
              }}
            >
              Contact Support
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardNotice;
