import Moment from 'moment';

export const dateExtract = dateInp => {
  const date = new Date(dateInp);
  return {
    year: date.getFullYear(),
    month: date.getMonth(),
    date: date.getDate()
  };
};

export const momentDate = dateInp => {
  let date = '';
  if (dateInp != null) {
    date = Moment(dateInp).fromNow();
  }
  return date;
};
export function getFormattedDate(date) {
  const dateString = date;
  const dateObj = new Date(dateString);
  const momentObj = Moment(dateObj);
  const momentString = momentObj.format('Do MMM YYYY'); // 2016-07-15
  return momentString;
}
