import React, { useState } from 'react';
import Form from 'react-jsonschema-form';

import { withSelect } from '@wordpress/data';

import {
  convertSchema,
  convertUISchema,
  convertFormDataWithParams
} from './convertor';

const ActionForm = ({ schema, uiSchema, onChange, formData }) => {
  // const [localFormData, setFormData] = useState(formData);
  return (
    <Form
      showErrorList={false}
      schema={schema}
      uiSchema={uiSchema}
      formData={formData}
      onChange={({ formData }) => {
        onChange && onChange(convertFormDataWithParams(formData));
        // setFormData(convertFormDataWithParams(formData));
      }}
    >
      <button hidden="hidden" />
    </Form>
  );
};
export default withSelect((select, props) => {
  let actionTypes;
  if (props.actionTypes === undefined)
    actionTypes = select('core/actions').getActionTypes();
  else actionTypes = props.actionTypes;

  const project = select('appmaker/core').getCurrentProject();
  return {
    schema: convertSchema(actionTypes),
    uiSchema: convertUISchema(actionTypes, project.id)
  };
})(ActionForm);
