import React, { useState, useCallback, useEffect } from 'react';
import { debounce } from 'lodash';


const ImageModalHeader = ({ onSearch }) => {
  const [openedTab, setTab] = useState('media-library');
  const [query, setQuery] = useState('');
  const searchHandler = useCallback(debounce(onSearch, 800), []);
  useEffect(() => {
    searchHandler({ searchTerm: query, tab: openedTab });
  }, [query]);
  return (
    <React.Fragment>
      <div className="d-flex bg-light p-3">
        <div className="mr-4">
          <h5 className="mb-0">Media Library</h5>
          <small>Select or upload media</small>
        </div>
        {openedTab === 'icon-scout' ? (
          ''
        ) : (
          <div className="my-auto position-relative flex-grow-1">
            <input
              type="text"
              onChange={event => {
                setQuery(event.target.value);
              }}
              className="form-control"
              placeholder={
                openedTab === 'unsplash'
                  ? 'Search Unsplash free images'
                  : 'Search your wordpress media'
              }
              aria-label="Search"
            />
            <button className="btn btn-link text-dark search-button" onClick={() => {
              onSearch({ searchTerm: query, tab: openedTab });
            }}>
              <i className="fas fa-search" />
            </button>
          </div>
        )}
      </div>
      <ul className="nav nav-tabs bg-light" id="myTab" role="tablist">
        <li className="nav-item ml-3">
          <a
            className="nav-link active"
            id="image-tab"
            data-toggle="tab"
            href="#image"
            role="tab"
            aria-controls="image"
            aria-selected="true"
            onClick={() => {
              setTab('media-library');
            }}
          >
            Media Library
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="Icon-tab"
            data-toggle="tab"
            href="#Icon"
            role="tab"
            aria-controls="Icon"
            aria-selected="false"
            onClick={() => {
              setTab('unsplash');
            }}
          >
            Unsplash
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="IconScout-tab"
            data-toggle="tab"
            href="#IconScout"
            role="tab"
            aria-controls="Icon"
            aria-selected="false"
            onClick={() => {
              setTab('icon-scout');
            }}
          >
            IconScout
          </a>
        </li>
      </ul>
    </React.Fragment>
  );
};

export default ImageModalHeader;
