import React, { useEffect } from 'react';
import './App.css';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Helmet } from 'react-helmet';
import AuthenticatedRoute from './components/RouterHOC/AuthenticatedRoute';

// import ReactGA from 'react-ga';
import * as ROUTES from './constants/routes';
// import InitialStep from './components/initialSteps/InitialStep';
// import StepTwo from './components/initialSteps/StepTwo';
// import ChooseMethod from './components/initialSteps/ChooseMethod';
// import BookDemo from './components/initialSteps/BookDemo';

// import ThankYou from './components/initialSteps/ThankYou';
// import PluginCheck from './components/initialSteps/pluginCheck/PluginCheck';
import Dashboard from './components/dashboard/Dashboard';
import 'react-sortable-tree/style.css';
import ManageApps from './components/manageApps/ManageApps';
import { logCurrentPage } from './utils/tracker';

import './store';
import './hooks';
import Plugins from './plugins';
import { api } from './store/actions';
import Playground from './playground';
import CustomerData from './components/dashboard/customerData';

api.registerCoreActionTypes();
// ReactGA.initialize('UA-53887153-1');

const history = createBrowserHistory();

export default function App() {
  useEffect(() => {
    logCurrentPage();
    history.listen(() => {
      logCurrentPage();
    });
  }, [history]);

  return (
    <Router history={history}>
      <Helmet>
        <title>Appmaker dashboard</title>
        <meta charSet="utf-8" />
        <meta name="description" content="A React.js application" />
      </Helmet>
      {/* <div className="container ">
        <Route path={ROUTES.CREATE} exact component={InitialStep} />
        <Route path={ROUTES.STEP_TWO} exact component={StepTwo} />
        <Route path={ROUTES.CHOOSE_METHOD} exact component={ChooseMethod} />
        <Route path={ROUTES.BOOK_DEMO} exact component={BookDemo} />
        <Route path={ROUTES.THANK_YOU} exact component={ThankYou} />
        <Route path={ROUTES.PLUGIN_CHECK} exact component={PluginCheck} />
  </div> */}
      <div className="container-fluid">
        <AuthenticatedRoute
          path={[ROUTES.DASHBOARD, ROUTES.DASHBOARD_MAIN]}
          exact
          component={Dashboard}
        />
      </div>
      <AuthenticatedRoute
        path={[
          '/',
          ROUTES.MANAGE,
          ROUTES.ACTIVE_APPS,
          ROUTES.DELETED_APPS,
          ROUTES.ACCOUNT_SETTINGS
        ]}
        exact
        component={ManageApps}
      />
      <AuthenticatedRoute
        path={[ROUTES.CUSTOMER_DATA]}
        exact
        component={CustomerData}
      />
      <Route path={ROUTES.PLAYGROUND} component={Playground} />
      <Plugins />
    </Router>
  );
}
