import React from 'react';

const SinglePostItem = props => {
  return (
    <div className="news-card-6 card bg-dark text-white border-0 rounded-0">
      <img
        src={props.featureImageSrc}
        className="card-img rounded-0"
        alt="..."
      />
      <div className="card-img-overlay">
        <div className="card-title">
          <div className="text-area m-2 text-wrap position-relative">
            <div className="author-pdate d-flex">
              <span className="icon mr-1">
                <img src={props.userImgSrc} alt="" />
              </span>
              <p className="">
                {props.author} . {props.date}
              </p>
            </div>
            <div className="post-title">
              <h6 className="ellipsis font-weight-bold">{props.title}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SinglePostItem;
