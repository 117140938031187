import React from 'react';

export const FeedIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24">
        <g transform="translate(-11680 -7851)">
            <rect width="24" height="24" transform="translate(11680 7851)" fill="none" />
            <rect width="24" height="20" rx="4" transform="translate(11680 7853)" fill="#4d6ffb" />
            <rect width="2" height="6" transform="translate(11684 7856)" fill="#8ba1fd" />
            <rect width="13" height="2" rx="1" transform="translate(11688 7857)" fill="#8ba1fd" />
            <rect width="13" height="2" rx="1" transform="translate(11688 7865)" fill="#8ba1fd" />
            <rect width="13" height="1" rx="0.5" transform="translate(11688 7860)" fill="#8ba1fd" />
            <rect width="13" height="1" rx="0.5" transform="translate(11688 7868)" fill="#8ba1fd" />
            <rect width="2" height="6" transform="translate(11684 7864)" fill="#8ba1fd" />
        </g>
    </svg>
);

export const FeedItemIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24">
        <g transform="translate(-11680 -7851)">
            <rect width="24" height="24" transform="translate(11680 7851)" fill="none" />
            <rect width="24" height="20" rx="4" transform="translate(11680 7853)" fill="#4d6ffb" />
            <rect width="2" height="6" transform="translate(11684 7856)" fill="#8ba1fd" />
            <rect width="13" height="2" rx="1" transform="translate(11688 7857)" fill="#8ba1fd" />
            <rect width="13" height="1" rx="0.5" transform="translate(11688 7860)" fill="#8ba1fd" />
            <rect width="17" height="1" rx="0.5" transform="translate(11684 7865)" fill="#8ba1fd" />
            <rect width="17" height="1" rx="0.5" transform="translate(11684 7867)" fill="#8ba1fd" />
            <rect width="17" height="1" rx="0.5" transform="translate(11684 7869)" fill="#8ba1fd" />
        </g>
    </svg>
);