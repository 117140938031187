import React from 'react';

export default () => (
    <svg width="24" height="24" viewBox="0 0 24 24">
        <g id="gridItem-gutIcon" transform="translate(-13035 -6799)">
            <rect id="Rectangle_554" data-name="Rectangle 554" width="24" height="24" transform="translate(13035 6799)" fill="none" />
            <g id="Group_392" data-name="Group 392" transform="translate(-0.678 0.322)">
                <rect id="Rectangle_555" data-name="Rectangle 555" width="22" height="22" rx="2" transform="translate(13036.678 6799.678)" fill="#4d6ffb" />
            </g>
            <rect id="Rectangle_574" data-name="Rectangle 574" width="18" height="4" rx="2" transform="translate(13038 6815)" fill="#fff" opacity="0.35" />
        </g>
    </svg>

);