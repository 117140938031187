import React, { useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import BlockAction from '../customizeApp/components/configComponents/BlockAction';


export default function UpdateApp({
  match: {
    params: { id = false }
  }
}) {
  useEffect(() => {
    const frame = document.getElementById('update-app-frame');
    frame.onload = ((frm) => {
      // console.log(frame.contentWindow);
    })
    // code to run on component mount
  }, [])
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="dashboard-box rounded-lg" style={{ margin: '0px', padding: '0px', overflow: 'hidden' }}>
          <iframe
            onLoad={(frame) => {
              console.log("KKK", frame)
            }}
            id="update-app-frame"
            title="Update app"
            src={`https://appmaker.xyz/dashboard/apps/update/${id}?iframe=1`}
            frameBorder="0"
            className="iframe-height"
            style={{ overflow: 'hidden', width: '100%' }}
            height="100%"
            width="100%"
          />
        </div>
      </div>
    </div>
  );
}
