import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default function CopyField({ id, label, value }) {
  const [copied, setCopied] = useState(false);
  return (
    <div className="key-fields">
      <label htmlFor={id}>{label}</label>
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="API key"
          id={id}
          defaultValue={value}
          disabled
        />
        <div className="input-group-append">
          <CopyToClipboard
            text={value}
            onCopy={() => {
              setCopied(true);
              setTimeout(() => {
                setCopied(false);
              }, 3000);
            }}
          >
            <button
              className="btn btn-dark border-0"
              type="button"
              id="copy_project_id"
            >
              <span>
                <i className="fas fa-clipboard mr-2" />
              </span>
              {copied ? 'Copied' : 'Copy'}
            </button>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
}
