import React, { useState } from 'react';
import { Table } from 'reactstrap';
import '../style.css';

const DataAccordion = ({ attributes }) => {
  const { title, iconName, salesData = {} } = attributes;
  const {
    last_month: lastMonth = {},
    last_day: lastDay = {},
    all_time: allTime = {}
  } = salesData;
  const {
    total_sales: lastMonthTotalSales = null,
    total_orders: lastMonthTotalOrders = null
  } = lastMonth !== null ? lastMonth : {};
  const {
    total_sales: lastDayTotalSales = null,
    total_orders: lastDayTotalOrders = null
  } = lastDay !== null ? lastDay : {};
  const {
    total_sales: allTimeTotalSales = null,
    total_orders: allTimeTotalOrders = null
  } = allTime !== null ? allTime : {};
  const [expand, setExpand] = useState(false);
  return (
    <div className="mb-3">
      <h6 className="text-grey">{title}</h6>
      <div className="data-accordion">
        <button
          type="button"
          className="accordion-header btn d-flex align-items-center justify-content-between w-100"
          onClick={() => setExpand(!expand)}
        >
          <span className="d-flex align-items-center">
            <h2 className="mb-0 text-white bg-grey rounded accordion-icon">
              <i className={`fas fa-${iconName}`} />
            </h2>
            <span className="text-left ml-4">
              <h4 className="mb-0">{lastMonthTotalSales}</h4>
              <small className="text-grey">Last Month Sales</small>
            </span>
            <span className="text-left ml-4">
              <h4 className="mb-0">{lastMonthTotalOrders}</h4>
              <small className="text-grey">Last Month Orders</small>
            </span>
          </span>
          <i
            className={`fas ${
              expand ? 'fa-chevron-up' : 'fa-chevron-down'
            } text-grey`}
          />
        </button>
        {expand && (
          <Table striped>
            <thead>
              <tr>
                <th />
                <th>Orders</th>
                <th>Sales</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Last Day</th>
                <td>{lastDayTotalOrders}</td>
                <td>{lastDayTotalSales}</td>
              </tr>
              <tr>
                <th scope="row">Last month</th>
                <td>{lastMonthTotalOrders}</td>
                <td>{lastMonthTotalSales}</td>
              </tr>
              <tr>
                <th scope="row">All-time</th>
                <td>{allTimeTotalOrders}</td>
                <td>{allTimeTotalSales}</td>
              </tr>
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};
export default DataAccordion;
