/* eslint-disable import/prefer-default-export */
export const sendToIntercom = async (eventName, params) => {
  try {
    if (window.Intercom) {
      window.Intercom('trackEvent', eventName, params);
    }
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};
