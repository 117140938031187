import React from 'react';

export default () => (
    <svg width="24" height="24" viewBox="0 0 24 24">
        <g id="imageSlider-gutIcon" transform="translate(-13163 -6799)">
            <rect id="Rectangle_565" data-name="Rectangle 565" width="24" height="24" transform="translate(13163 6799)" fill="none" />
            <rect id="Rectangle_566" data-name="Rectangle 566" width="18" height="17" rx="4" transform="translate(13163 6803)" fill="#6d65fe" />
            <path id="Rectangle_567" data-name="Rectangle 567" d="M4,0H4A0,0,0,0,1,4,0V17a0,0,0,0,1,0,0H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0Z" transform="translate(13183 6803)" fill="#6d65fe" />
        </g>
    </svg>

);