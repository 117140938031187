/**
 * External dependencies
 */
import React, { Component } from 'react';
import { every, get, isArray, noop, startsWith } from 'lodash';
import classnames from 'classnames';

/**
 * WordPress dependencies
 */
import {
  Button,
  FormFileUpload,
  Placeholder,
  DropZone,
  IconButton
} from '@wordpress/components';
// import { __ } from '@wordpress/i18n';
// import { Component } from '@wordpress/element';

import {
  MediaUpload,
  MediaUploadCheck,
  URLPopover
} from '@wordpress/block-editor';
/**
 * Internal dependencies
 */

const InsertFromURLPopover = ({ src, onChange, onSubmit, onClose }) => (
  <URLPopover onClose={onClose}>
    <form
      className="editor-media-placeholder__url-input-form block-editor-media-placeholder__url-input-form"
      onSubmit={onSubmit}
    >
      <input
        className="editor-media-placeholder__url-input-field block-editor-media-placeholder__url-input-field"
        type="url"
        aria-label="URL"
        placeholder="Paste or type URL"
        onChange={onChange}
        value={src}
      />
      <IconButton
        className="editor-media-placeholder__url-input-submit-button block-editor-media-placeholder__url-input-submit-button"
        icon="editor-break"
        label="Apply"
        type="submit"
      />
    </form>
  </URLPopover>
);

export class MediaPlaceholder extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      src: '',
      isURLInputVisible: false
    };
    this.onChangeSrc = this.onChangeSrc.bind(this);
    this.onSubmitSrc = this.onSubmitSrc.bind(this);
    this.onUpload = this.onUpload.bind(this);
    this.onFilesUpload = this.onFilesUpload.bind(this);
    this.openURLInput = this.openURLInput.bind(this);
    this.closeURLInput = this.closeURLInput.bind(this);
  }

  onlyAllowsImages() {
    const { allowedTypes } = this.props;
    if (!allowedTypes) {
      return false;
    }
    return every(allowedTypes, allowedType => {
      return allowedType === 'image' || startsWith(allowedType, 'image/');
    });
  }

  componentDidMount() {
    this.setState({ src: get(this.props.value, ['src'], '') });
  }

  componentDidUpdate(prevProps) {
    if (
      get(prevProps.value, ['src'], '') !== get(this.props.value, ['src'], '')
    ) {
      this.setState({ src: get(this.props.value, ['src'], '') });
    }
  }

  onChangeSrc(event) {
    this.setState({ src: event.target.value });
  }

  onSubmitSrc(event) {
    event.preventDefault();
    if (this.state.src && this.props.onSelectURL) {
      this.props.onSelectURL(this.state.src);
      this.closeURLInput();
    }
  }

  onUpload(event) {
    this.onFilesUpload(event.target.files);
  }

  onFilesUpload(files) {
    const {
      addToGallery,
      allowedTypes,
      mediaUpload,
      multiple,
      onError,
      onSelect,
      value = []
    } = this.props;
    let setMedia;
    if (multiple) {
      if (addToGallery) {
        const currentValue = value;
        setMedia = newMedia => {
          onSelect(currentValue.concat(newMedia));
        };
      } else {
        setMedia = onSelect;
      }
    } else {
      setMedia = ([media]) => onSelect(media);
    }
    mediaUpload({
      allowedTypes,
      filesList: files,
      onFileChange: setMedia,
      onError
    });
  }

  openURLInput() {
    this.setState({ isURLInputVisible: true });
  }

  closeURLInput() {
    this.setState({ isURLInputVisible: false });
  }

  renderPlaceholder(content, onClick) {
    const {
      allowedTypes = [],
      className,
      icon,
      isAppender,
      labels = {},
      onDoubleClick,
      mediaPreview,
      notices,
      onSelectURL,
      mediaUpload,
      children
    } = this.props;

    let { instructions } = labels;
    let { title } = labels;

    if (!mediaUpload && !onSelectURL) {
      instructions = 'To edit this block, you need permission to upload media.';
    }

    if (instructions === undefined || title === undefined) {
      const isOneType = allowedTypes.length === 1;
      const isAudio = isOneType && allowedTypes[0] === 'audio';
      const isImage = isOneType && allowedTypes[0] === 'image';
      const isVideo = isOneType && allowedTypes[0] === 'video';

      if (instructions === undefined && mediaUpload) {
        instructions =
          'Upload a media file or pick one from your media library.';

        if (isAudio) {
          instructions =
            'Upload an audio file, pick one from your media library, or add one with a URL.';
        } else if (isImage) {
          instructions =
            'Upload an image file, pick one from your media library, or add one with a URL.';
        } else if (isVideo) {
          instructions =
            'Upload a video file, pick one from your media library, or add one with a URL.';
        }
      }

      if (title === undefined) {
        title = 'Media';

        if (isAudio) {
          title = 'Audio';
        } else if (isImage) {
          title = 'Image';
        } else if (isVideo) {
          title = 'Video';
        }
      }
    }

    const placeholderClassName = classnames(
      'block-editor-media-placeholder',
      'editor-media-placeholder',
      className,
      { 'is-appender': isAppender }
    );

    return (
      <Placeholder
        icon={icon}
        label={title}
        instructions={instructions}
        className={placeholderClassName}
        notices={notices}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        preview={mediaPreview}
      >
        {content}
        {children}
      </Placeholder>
    );
  }

  renderDropZone() {
    const { disableDropZone, onHTMLDrop = noop } = this.props;

    if (disableDropZone) {
      return null;
    }

    return (
      <DropZone onFilesDrop={this.onFilesUpload} onHTMLDrop={onHTMLDrop} />
    );
  }

  renderCancelLink() {
    const { onCancel } = this.props;
    return (
      onCancel && (
        <Button
          className="block-editor-media-placeholder__cancel-button"
          title="Cancel"
          isLink
          onClick={onCancel}
        >
          Cancel
        </Button>
      )
    );
  }

  renderUrlSelectionUI() {
    const { onSelectURL } = this.props;
    if (!onSelectURL) {
      return null;
    }
    const { isURLInputVisible, src } = this.state;
    return (
      <div className="editor-media-placeholder__url-input-container block-editor-media-placeholder__url-input-container">
        <Button
          className="editor-media-placeholder__button block-editor-media-placeholder__button"
          onClick={this.openURLInput}
          isToggled={isURLInputVisible}
          isLarge
        >
          Insert from URL
        </Button>
        {isURLInputVisible && (
          <InsertFromURLPopover
            src={src}
            onChange={this.onChangeSrc}
            onSubmit={this.onSubmitSrc}
            onClose={this.closeURLInput}
          />
        )}
      </div>
    );
  }

  renderMediaUploadChecked() {
    const {
      accept,
      addToGallery,
      allowedTypes = [],
      isAppender,
      mediaUpload,
      multiple = false,
      onSelect,
      value = {}
    } = this.props;

    const mediaLibraryButton = (
      <MediaUpload
        addToGallery={addToGallery}
        gallery={multiple && this.onlyAllowsImages()}
        multiple={multiple}
        onSelect={onSelect}
        allowedTypes={allowedTypes}
        value={isArray(value) ? value.map(({ id }) => id) : value.id}
        render={({ open }) => {
          return (
            <Button
              isLarge
              className={classnames(
                'editor-media-placeholder__button',
                'editor-media-placeholder__media-library-button'
              )}
              onClick={event => {
                event.stopPropagation();
                open();
              }}
            >
              Media Library
            </Button>
          );
        }}
      />
    );

    if (mediaUpload && isAppender) {
      return (
        <>
          {this.renderDropZone()}
          <FormFileUpload
            onChange={this.onUpload}
            accept={accept}
            multiple={multiple}
            render={({ openFileDialog }) => {
              const content = (
                <>
{/*                  <IconButton
                    isLarge
                    className={classnames(
                      'block-editor-media-placeholder__button',
                      'editor-media-placeholder__button',
                      'block-editor-media-placeholder__upload-button'
                    )}
                    icon="upload"
                  >
                    Upload
                    </IconButton> */}
                  {mediaLibraryButton}
                  {this.renderUrlSelectionUI()}
                  {this.renderCancelLink()}
                </>
              );
              return this.renderPlaceholder(content, openFileDialog);
            }}
          />
        </>
      );
    }

    if (mediaUpload) {
      const content = (
        <>
          {this.renderDropZone()}
          {/* <FormFileUpload
            isLarge
            className={classnames(
              'block-editor-media-placeholder__button',
              'editor-media-placeholder__button',
              'block-editor-media-placeholder__upload-button'
            )}
            onChange={this.onUpload}
            accept={accept}
            multiple={multiple}
          >
            Upload
          </FormFileUpload> */}

          {mediaLibraryButton}
          {this.renderUrlSelectionUI()}
          {this.renderCancelLink()}
        </>
      );

      return this.renderPlaceholder(content);
    }

    return this.renderPlaceholder(mediaLibraryButton);
  }

  render() {
    const { disableMediaButtons, dropZoneUIOnly } = this.props;

    if (dropZoneUIOnly || disableMediaButtons) {
      if (dropZoneUIOnly) {
        // deprecated('wp.blockEditor.MediaPlaceholder dropZoneUIOnly prop', {
        //   alternative: 'disableMediaButtons'
        // });
      }

      return <MediaUploadCheck>{this.renderDropZone()}</MediaUploadCheck>;
    }

    return this.renderMediaUploadChecked();
  }
}

/**
 * @see https://github.com/WordPress/gutenberg/blob/master/packages/block-editor/src/components/media-placeholder/README.md
 */
export default MediaPlaceholder;
