import React from 'react';

const SinglePostItem = props => {
  return (
    <div className="news-card-4 bg-white d-inline-block">
      <div className="row m-0 w-auto">
        <div className="col p-0 ">
          <div className="text-area m-2 text-wrap position-relative">
            <div className="category">
              <p className="text-muted mb-1">{props.category}</p>
            </div>
            <div className="post-title">
              <h6 className="ellipsis text-dark font-weight-bold">
                {props.title}
              </h6>
            </div>
            <div className="author-pdate d-flex position-absolute">
              <span className="icon my-auto mr-1">
                <img src={props.userImgSrc} alt="" width="10px" height="10px" />
              </span>
              <p className="text-muted my-auto">
                {props.author} . {props.date}
              </p>
            </div>
          </div>
        </div>
        <div className="col-5 p-2">
          <picture>
            <img
              className="img-fluid"
              src={props.featureImageSrc}
              alt="Card image cap"
            />
          </picture>
        </div>
      </div>
    </div>
  );
};

export default SinglePostItem;
