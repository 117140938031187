import React from 'react';
import { withSelect } from '@wordpress/data';
import { Route, Redirect } from 'react-router-dom';
import CONSTANT from '../../../lib/constants';


function AccountSuspended({ project }) {
  if (project.status !== CONSTANT.ACCOUNT_SUSPENDED) {
    return (
      <Route
        render={props => {
          return <Redirect push to={`/apps/${project.id}`} />;
        }}
      />
    );
  }

  return (
    <div className="wrap overflow-auto pr-3">
      <div className="row">
        <div className="col-md-7 mx-auto mt-3">
          <div className="dashboard-box rounded bg-white text-center p-3">
            <img
              src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2020/03/b3e71c37-subscription-expired.png"
              className="img-fluid w-75"
              alt="expired"
              draggable="false"
            />
            <div className="mb-5">
              <h3 className="text-muted">Thanks for trying appmaker!</h3>
              <p className="w-75 mx-auto">Your project has been suspended</p>
            </div>
            <div className="mb-4">
              <p className="w-75 mx-auto">
                Please contact our customer support to unblock.
              </p>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => {
                  window.Intercom('show');
                }}
              >
                Contact support
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default withSelect((select, ownProps) => {
  const project = select('appmaker/core').getCurrentProject();
  const user = select('appmaker/core').getUser();
  return { project, user: user || {} };
})(AccountSuspended);