// @flow strict

import * as React from 'react';
import SinglePostItem from '../../components/post/SinglePostItem';
import { convertPostAppmaker } from './utils/WorpressConvertor';
import _ from 'lodash';

function Carousel(props) {
  const { postsList, attributes } = props;
  const {
    authors,
    specificPosts,
    postsToShow,
    categories,
    sectionHeader,
    columns,
    showImage,
    showCaption,
    imageScale,
    mobileStack,
    minHeight,
    moreButton,
    moreButtonText,
    showExcerpt,
    typeScale,
    showDate,
    showAuthor,
    showAvatar,
    showCategory,
    postLayout,
    mediaPosition,
    specificMode,
    tags,
    url,
    template
  } = attributes;
  //   const postsList = _.slice(posts, 1, 3);

  const itemTemplate =
    attributes.className && attributes.className.replace('is-style-', '');
  return (
    <div id="appmakerSlider" className="carousel slide mb-3" data-ride="carousel">
      <ol className="carousel-indicators">
        {postsList.map((item, key) => {
          return (
            <li
              key={`slider-handle-${key}`}
              data-target="#appmakerSlider"
              data-slide-to={key}
              className={key === 0 ? 'active' : ''}
            />
          );
        })}
      </ol>
      <div className="carousel-inner">
        {postsList.map((item, key) => {
          const postItem = convertPostAppmaker(item);
          return (
            <div className={`carousel-item ${key === 0 ? 'active' : ''}`}>
              <SinglePostItem
                key={`post-item${key}`}
                itemTemplate={itemTemplate}
                featureImageSrc={showImage && postItem.featured_media}
                userImgSrc={showAuthor && showAvatar && postItem.authorImage}
                author={showAuthor && postItem.author}
                date={showDate && postItem.date}
                category={showCategory && postItem.category}
                title={postItem.title}
                excerpt={showExcerpt && postItem.content}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Carousel;
