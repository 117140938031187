import React from 'react';
import { NavLink } from 'react-router-dom';
import { SubscriptionIcon } from '../Icons';

const DocComponent = props => {
  return (
    <li className="nav-item">
      <NavLink
        onClick={props.onClick}
        to={props.link}
        activeClassName="active"
        className="vertical-nav-hover nav-link text-dark rounded-lg text-decoration-none"
      >
        <SubscriptionIcon fill="#202020" />
        Subscription
      </NavLink>
    </li>
  );
};
export default DocComponent;
