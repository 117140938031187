import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge } from 'reactstrap';
import { downloadAppBundle } from '../../../constants/routes';
import { playScreenshot } from './images';

function StepsCount(props) {
    return (
        <React.Fragment>
            <h5 className="font-weight-bold ml-3 mb-3">Upload to Play Store</h5>
            <div className="d-flex justify-content-center small-label-text">
                <div className={"border px-3 mx-3 rounded-pill bg-primary text-white instruction-step" + (props.currentStep === 1 ? 'active-instruction-step' : '')}>
                    Step 1
                </div>
                <div className={"border px-3 mx-3 rounded-pill bg-primary text-white instruction-step" + (props.currentStep === 2 ? 'active-instruction-step' : '')}>
                    Step 2
                </div>
                <div className={"border px-3 mx-3 rounded-pill bg-primary text-white instruction-step" + (props.currentStep === 3 ? 'active-instruction-step' : '')}>
                    Step 3
                </div>
                <div className={"border px-3 mx-3 rounded-pill bg-primary text-white instruction-step" + (props.currentStep === 4 ? 'active-instruction-step' : '')}>
                    Step 4
                </div>
                <div className={"border px-3 mx-3 rounded-pill bg-primary text-white instruction-step" + (props.currentStep === 5 ? 'active-instruction-step' : '')}>
                    Step 5
                </div>
                <div className={"border px-3 mx-3 rounded-pill bg-primary text-white instruction-step" + (props.currentStep === 6 ? 'active-instruction-step' : '')}>
                    Step 6
                </div>
            </div>
        </React.Fragment>
    );
}
function StepOne(props) {
    if (props.currentStep !== 1) {
        return null
    }
    return (
        <div className="py-5">
            <ul>
                <li>
                    <p>Download Android App Bundle(.aab)</p>
                    <a href={downloadAppBundle()} className="btn btn-success mb-3">
                        <i className="fab fa-android mr-2" />Download App Bundle
                    </a>
                </li>
                <li>
                    <p>Log in to your Google Play developer account
                            <a
                            href="https://play.google.com/apps/publish"
                            className="p-2 bg-light rounded"
                            target="_blank"
                            rel="noopener noreferrer"
                        >https://play.google.com/apps/publish</a>
                    </p>
                </li>
                <li>
                    <p>Click on <kbd>Creating An Application</kbd> button.</p>
                    <img
                        src={playScreenshot.stepOne}
                        alt="screenshot"
                        className="img-fluid border border-dark rounded shadow-sm"
                    />
                </li>
            </ul>
        </div>
    );
}
function StepTwo(props) {
    if (props.currentStep !== 2) {
        return null
    }
    return (
        <div className="py-2">
            <ul>
                <li>
                    <p>Choose Default language and fill up the Title of your app.</p>
                    <img
                        src={playScreenshot.stepTwo}
                        alt="screenshot"
                        className="img-fluid border border-dark rounded shadow-sm"
                    />
                </li>
                <li className="mt-3">
                    <p>
                        Click <kbd>Make</kbd>
                    </p>
                </li>
            </ul>
        </div>
    );
}
function StepThree(props) {
    if (props.currentStep !== 3) {
        return null
    }
    return (
        <div className="py-2">
            <ul>
                <li>
                    <p>Go to <b>App Release ⇒ Production version(MANAGE) </b></p>
                    <img
                        src={playScreenshot.stepThreeA}
                        alt="screenshot"
                        className="img-fluid border border-dark rounded shadow-sm mb-3"
                    />
                </li>
                <li>
                    <p><b>Create edition⇒ set⇒ Browse file(upload app file(aab/apk)</b></p>
                    <img
                        src={playScreenshot.stepThreeB}
                        alt="screenshot"
                        className="img-fluid border border-dark rounded shadow-sm"
                    />
                </li>
            </ul>
        </div>
    );
}
function StepFour(props) {
    if (props.currentStep !== 4) {
        return null
    }
    return (
        <div className="py-2">
            <ul>
                <li className="mb-2">
                    <p>Select the option, <q>Please provide the key to sign the app that google play uses for this app</q></p>
                    <img
                        src={playScreenshot.stepFourA}
                        alt="screenshot"
                        className="img-fluid border border-dark rounded shadow-sm mb-3"
                    />
                </li>
                <li>
                    <p>Upload the previously downloaded <code>.aab</code> file here.</p>
                    <img
                        src={playScreenshot.stepFourB}
                        alt="screenshot"
                        className="img-fluid border border-dark rounded shadow-sm my-2"
                    />
                    <img
                        src={playScreenshot.stepFourC}
                        alt="screenshot"
                        className="img-fluid border border-dark rounded shadow-sm my-2"
                    />
                    <img
                        src={playScreenshot.stepFourD}
                        alt="screenshot"
                        className="img-fluid border border-dark rounded shadow-sm my-2"
                    />
                    <img
                        src={playScreenshot.stepFourE}
                        alt="screenshot"
                        className="img-fluid border border-dark rounded shadow-sm my-2"
                    />
                    <p>"Whats new in this release":  Enter the detail and press Save.</p>
                </li>
            </ul>
        </div>
    );
}
function StepFive(props) {
    if (props.currentStep !== 5) {
        return null
    }
    return (
        <div className="py-2">
            <ul>
                <li className="mb-2">
                    <p>Once uploaded you will see green tick.</p>
                    <img
                        src={playScreenshot.stepFiveA}
                        alt="screenshot"
                        className="img-fluid border border-dark rounded shadow-sm mb-3"
                    />
                </li>
                <li>
                    <h6 className="font-weight-bold">Fill up all necessary details until you see green tick in all 5 tabs.</h6>
                    <img
                        src={playScreenshot.stepFiveB}
                        alt="screenshot"
                        className="img-fluid border border-dark rounded shadow-sm"
                    />
                </li>
            </ul>
        </div>
    );
}
function StepSix(props) {
    if (props.currentStep !== 6) {
        return null
    }
    return (
        <div className="py-2">
            <ul>
                <li className="mb-2">
                    <h6 className="font-weight-bold">Roll out  the app</h6>
                    <ol>
                        <li>
                            <p>App release⇒  Production ( Manage)</p>
                            <img
                                src={playScreenshot.stepSixA}
                                alt="screenshot"
                                className="img-fluid border border-dark rounded shadow-sm mb-3"
                            />
                        </li>
                        <li>
                            <p>When you Click on the Manage, a new page appears for roll out. Click on <kbd>Edit Release</kbd></p>
                            <img
                                src={playScreenshot.stepSixB}
                                alt="screenshot"
                                className="img-fluid border border-dark rounded shadow-sm mb-3"
                            />
                        </li>
                        <li>
                            <p>You will get another window. click on <kbd>Start Roll out to production</kbd></p>
                            <img
                                src={playScreenshot.stepSixC}
                                alt="screenshot"
                                className="img-fluid border border-dark rounded shadow-sm mb-3"
                            />
                        </li>
                        <li>
                            <p>another window appears, click on "CONFIRM"</p>
                            <img
                                src={playScreenshot.stepSixD}
                                alt="screenshot"
                                className="img-fluid border border-dark rounded shadow-sm mb-3"
                            />
                        </li>
                    </ol>
                </li>
                <li className="mb-2">
                    <h6 className="font-weight-bold">Published App</h6>
                    <img
                        src={playScreenshot.stepSixE}
                        alt="screenshot"
                        className="img-fluid border border-dark rounded shadow-sm mb-3"
                    />
                    <h6><Badge color="info">Note</Badge>{' '}It might take few hours to get published in store.</h6>
                </li>
            </ul>
        </div>
    );
}

class PlayStoreInstructions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            currentStep: 1,
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    next = () => {
        let currentStep = this.state.currentStep
        currentStep = currentStep + 1
        this.setState({
            currentStep: currentStep
        })
    }

    prev = () => {
        let currentStep = this.state.currentStep
        currentStep = currentStep <= 1 ? 1 : currentStep - 1
        this.setState({
            currentStep: currentStep
        })
    }
    previousButton() {
        let currentStep = this.state.currentStep;
        if (currentStep !== 1) {
            return (
                <Button size="sm" className="px-4" color="secondary" onClick={this.prev}><i className="fas fa-angle-left"></i>  Back</Button>
            )
        }
        return null;
    }

    nextButton() {
        let currentStep = this.state.currentStep;
        if (currentStep < 6) {
            return (
                <Button size="sm" className="px-4" color="primary" onClick={this.next}>Next  <i className="fas fa-angle-right"></i></Button>
            )
        } else if (currentStep === 6) {
            return (
                <Button size="sm" className="px-4" color="success" onClick={this.toggle}>Done</Button>
            )
        }
        return null;
    }
    render() {
        return (
            <React.Fragment>
                <Button size="sm" color="light" className="mx-1" onClick={this.toggle}>{this.props.buttonLabel}</Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} scrollable>
                    <ModalHeader className="border-bottom-0 shadow-sm" toggle={this.toggle}>
                        <StepsCount currentStep={this.state.currentStep} />
                    </ModalHeader>
                    <ModalBody className="p-3">
                        <StepOne
                            currentStep={this.state.currentStep}
                        />
                        <StepTwo
                            currentStep={this.state.currentStep}
                        />
                        <StepThree
                            currentStep={this.state.currentStep}
                        />
                        <StepFour
                            currentStep={this.state.currentStep}
                        />
                        <StepFive
                            currentStep={this.state.currentStep}
                        />
                        <StepSix
                            currentStep={this.state.currentStep}
                        />
                    </ModalBody>
                    <ModalFooter>
                        {this.previousButton()}
                        {this.nextButton()}
                    </ModalFooter>
                </Modal>
            </React.Fragment >
        );
    }
}

export default PlayStoreInstructions;