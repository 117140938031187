import fetch from 'node-fetch';
import * as ROUTES from '../constants/routes';

const { API_URL } = ROUTES;

export const addSubscriptionBanner = async projectId => {
  try {
    const response = await fetch(
      `${API_URL}/v2/subscription-expired/${projectId}/in-app-page-add-subscription-banner`,
      {
        method: 'GET',
        credentials: 'include'
      }
    );
    const respJson = await response.json();
    return Promise.resolve(respJson);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const removeSubscriptionBanner = async projectId => {
  try {
    const response = await fetch(
      `${API_URL}/v2/subscription-expired/${projectId}/in-app-page-remove-subscription-banner`,
      {
        method: 'GET',
        credentials: 'include'
      }
    );
    const respJson = await response.json();
    return Promise.resolve(respJson);
  } catch (error) {
    return Promise.reject(error);
  }
};
