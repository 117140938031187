import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { withSelect } from '@wordpress/data';
import { withToastManager } from 'react-toast-notifications';
import * as ROUTES from '../../constants/routes';
import { addNewPage } from '../../lib/apiFunctions';
import CreatePage from './createPage';
import { trackEvent } from '../../utils/tracker';

class InAppPageDropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      inAppPages: ''
    };
    this.create = this.create.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { inAppPages, newPage, afterNewPage = false } = newProps;
    if (newProps.nameChanged !== false) {
      this.changeInAppPageName(newProps.nameChanged);
    }
    if (inAppPages !== undefined) {
      this.setState({ inAppPages });
    }
    if (newPage !== undefined) {
      const pages = this.state.inAppPages;
      if (pages === '') {
        this.setState({ inAppPages: [newPage] }, () => {
          if (afterNewPage !== false) {
            afterNewPage(true);
          }
        });
      } else {
        pages.push(newPage);
        this.setState({ inAppPages: pages }, () => {
          if (afterNewPage !== false) {
            afterNewPage(true);
          }
        });
      }
    }
  }

  // To change the name of inapp page
  changeInAppPageName(data) {
    const { inAppPages } = this.state;
    const newInappPage = inAppPages.map(page => {
      if (page.id === data.id) {
        page.label = data.title;
        return page;
      }

      return page;
    });
    this.setState({ inAppPages: newInappPage });
  }

  // Create a new Page
  async create({ type, widgets = [], title = 'New Page' }) {
    const { history, toastManager, user = {} } = this.props;
    const { inAppPages } = this.state;

    const { project } = this.props;
    const { id } = project;
    this.setState({ isLoading: true });
    const data = {
      is_new: true,
      language: 'default',
      parentID: false,
      title,
      type: type === undefined ? 'NORMAL' : type,
      widgets
    };

    try {
      const response = await addNewPage(id, data);
      // window.location.assign(ROUTES.linkToInAppPage(response.id));
      if (response.id && response.status !== false) {
        const newData = inAppPages;
        newData.push({
          id: response.id,
          key: response.id,
          label: response.title
        });
        this.setState({ isLoading: false, inAppPages: newData });
        toastManager.add('Page created', {
          appearance: 'success',
          autoDismiss: true
        });
        history.push(ROUTES.linkToInAppPage(response.id));
      } else {
        toastManager.add(response.message || 'Something went wrong', {
          appearance: response.type || 'error',
          autoDismiss: true
        });
        this.setState({ isLoading: false });
      }
    } catch (error) {
      // NOTE Error notification
      console.error(error);
      toastManager.add(error.message || 'Something went wrong', {
        appearance: error.type || 'error',
        autoDismiss: true
      });
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { isLoading, inAppPages } = this.state;
    const { project, inAppPagesLimit } = this.props;
    const items =
      inAppPages !== '' &&
      inAppPages.map(item => (
        <NavLink
          to={ROUTES.linkToInAppPage(item.id)}
          className="text-decoration-none text-white"
          key={`item-${item.id}`}
        >
          <div className="sub-nav-link-subgroup bg-dark p-1 rounded mt-1 text-white">
            {item.title || item.label}
          </div>
        </NavLink>
      ));
    return (
      <div>
        {items}
        <span className="border-top border-dark" />
        {/* <NavLink to={ROUTES.ADD_NEW_PAGE} className="text-decoration-none rounded d-block add-new-page border-dashed text-center p-1 mt-1">
        + Add new page
        </NavLink> */}
        <CreatePage
          limitCreatePage={
            inAppPagesLimit !== 0 &&
            inAppPagesLimit !== false &&
            inAppPages.length > inAppPagesLimit
          }
          createPage={this.create}
          isLoading={isLoading}
          template={project.template}
        />
      </div>
    );
  }
}

// export default InAppPageEditor;
export default withSelect((select, props, dispatch) => {
  const project = select('appmaker/core').getCurrentProject();
  const user = select('appmaker/core').getUser();
  const { feature = {} } = project;
  const { inAppPagesLimit = false } = feature;
  const inAppPages = select('appmaker/in-app-page').getInAppPageList();
  const setNewInAppPage = data => {
    dispatch.dispatch('appmaker/in-app-page').setInAppPageList(data);
  };
  return {
    inAppPages: (inAppPages != null && inAppPages) || [],
    toggleModal: props.toggleModal,
    project,
    setNewInAppPage,
    inAppPagesLimit,
    user
  };
})(withToastManager(InAppPageDropDown));
