import React, { Component } from 'react';
import { Col, Row, Button, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { getBilling, updateBillingSettings } from '../../../lib/apiFunctions';
import PreLoading from '../../dashboard/layout/PreLoading';
import { withToastManager } from 'react-toast-notifications';
class BillingAddress extends Component {
    constructor(props) {
        super(props)
        this.options = countryList().getData()
        this.state = {
            loading: true,
            updating: false,
            modal: false,
            data: {},
            options: this.options,
            value: null
        }
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.updateBilling = this.updateBilling.bind(this);
    }

    changeHandler = value => {
        //this.setState({ value })
        const currentState = this.state;
        currentState.data.country = value.label;
        this.setState(currentState);
    }
    handleChange(event) {
        const target = event.target;
        const stateData = this.state;
        stateData.data[target.id] = event.target.value;
        // console.log(this.state);
        this.setState({ data: stateData.data });
    }
    toggle() {
        // console.log({data:this.state.data})
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }
    modalBox() {
        return (<div>
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                <ModalHeader toggle={this.toggle}>Enter password</ModalHeader>
                <ModalBody>
                    <Input type="password" id="password" placeholder="Current password" />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => {
                        this.updateBilling(document.getElementById('password').value)
                    }}>{this.state.updating === true ? 'updating' : 'update'}</Button>{' '}
                    <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
        )
    }
    updateBilling(currentPassword) {
        const { toastManager } = this.props;
        const currentState = this.state;
        currentState['loading'] = true;
        currentState['updating'] = true;
        const formData = new FormData();
        formData.append('BillingAddress[first_name]', this.state.data.first_name);
        formData.append('BillingAddress[last_name]', this.state.data.last_name);
        formData.append('BillingAddress[address]', this.state.data.address);
        formData.append('BillingAddress[zip]', this.state.data.zip);
        formData.append('BillingAddress[city]', this.state.data.city);
        formData.append('BillingAddress[country]', this.state.data.country);
        formData.append('BillingAddress[state]', this.state.data.state);
        formData.append('BillingAddress[phone]', this.state.data.phone);
        updateBillingSettings(formData).then(response => response.json())
            .then(resp => {
                if (resp.status === 'success') {
                    const currentState = this.state;
                    currentState['loading'] = false;
                    this.setState(currentState);
                    // this.toggle();
                    toastManager.add('Updated Successfully', {
                        appearance: 'success',
                        autoDismiss: true
                    });
                }
                else {
                    const currentState = this.state;
                    currentState['loading'] = false;
                    this.setState(currentState);
                    // this.toggle();
                    toastManager.add('Something went wrong', {
                        appearance: 'error',
                        autoDismiss: true
                    });
                }
            }).catch(error => {
                const currentState = this.state;
                currentState['loading'] = false;
                this.setState(currentState);
                // this.toggle();
                toastManager.add('Something went wrong', {
                    appearance: 'error',
                    autoDismiss: true
                });
            });


    }
    loadData() {
        getBilling().then(response => response.json())
            .then(data => {
                const currentState = this.state;
                currentState['loading'] = false;
                currentState['data'] = data
                this.setState(currentState)
            })
    }
    componentDidMount() {
        this.loadData();
    }
    render() {
        if (this.state.loading)
            return (<div className="mt-4"><PreLoading single /></div>);
        return (
            <div className="dashboard-box bg-white rounded mt-4 mb-2 account-settings-box p-4">
                <h5 className="font-weight-bold mb-3">Billing Address</h5>
                <Form>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label htmlFfor="firstname">First Name</Label>
                                <Input type="text" name="fname" id="first_name" value={this.state.data.first_name} onChange={this.handleChange} />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label htmlFfor="lastname">Last Name</Label>
                                <Input type="text" name="lname" id="last_name" value={this.state.data.last_name} onChange={this.handleChange} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Label htmlFfor="billingAddress">Address</Label>
                        <Input type="text" name="address" id="address" value={this.state.data.address} onChange={this.handleChange} />
                    </FormGroup>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label htmlFfor="billingCity">City</Label>
                                <Input type="text" name="city" id="city" value={this.state.data.city} onChange={this.handleChange} />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label htmlFfor="billingState">State</Label>
                                <Input type="text" name="state" id="state" value={this.state.data.state} onChange={this.handleChange} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={4}>
                            <FormGroup>
                                <Label htmlFor="billingCountry">Contry</Label>
                                <Select
                                    options={this.state.options}
                                    placeholder={this.state.data.country}
                                    value={this.state.data.country}
                                    onChange={this.changeHandler}

                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="zip">Zip</Label>
                                <Input type="text" name="zip" id="zip" value={this.state.data.zip} onChange={this.handleChange}/>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label htmlFor="billingPhone">Phone</Label>
                                <Input type="text" name="phone" id="phone" value={this.state.data.phone} onChange={this.handleChange} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <div className="text-right mt-4">
                        <Button color="primary" disabled={this.state.loading} className="px-5" onClick={this.updateBilling}>{this.state.loading ? 'Updating' :'Update'}</Button>
                        {this.modalBox()}
                    </div>
                </Form>
            </div>
        );
    }
}

export default withToastManager(BillingAddress);