import { some } from 'lodash';
import React, { useRef, useState } from 'react';

import {
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Spinner
} from 'reactstrap';
import { withToastManager } from 'react-toast-notifications';

const SmartBannerDetails = ({
  toastManager,
  updateSettings,
  saveSettings,
  settings,
  loading
}) => {
  const formRef = useRef(null);
  const [saving, setSaving] = useState(false);

  function onChange(event) {
    updateSettings({
      ...settings,
      [event.target.name]: event.target.value
    });
  }

  async function save(e) {
    try {
      e.preventDefault();
      if (!formRef.current.reportValidity()) {
        toastManager.add('All fields are required', {
          appearance: 'error',
          autoDismiss: true
        });
      } else {
        setSaving(true);
        await saveSettings(settings);
        toastManager.add('Saved successfully', {
          appearance: 'success',
          autoDismiss: true
        });
        setSaving(false);
      }
    } catch (error) {
      setSaving(false);
      toastManager.add('Unable to Save', {
        appearance: 'error',
        autoDismiss: true
      });
    }
  }

  const {
    smart_banner_app_store_enabled: enabled = false,
    smart_banner_app_store_url: appStoreUrl = '',
    smart_banner_play_store_url: playStoreUrl = '',
    smart_banner_logo_url: logoUrl = '',
    smart_banner_title: title = '',
    smart_banner_description: description = '',
    smart_banner_cta_text: ctaText = '',
    force_smart_banner: forceSmartBanner = false,
    limit_smart_banner_preview: limitSmartBannerPreview = ''
  } = settings;

  if (loading === true) {
    return (
      <span className="d-flex justify-content-center">
        <Spinner color="secondary" size="sm" type="grow" />
        <Spinner color="secondary" size="sm" type="grow" />
        <Spinner color="secondary" size="sm" type="grow" />
      </span>
    );
  }
  return (
    <React.Fragment>
      <CustomInput
        type="switch"
        id="enable_smartBanner"
        name="smart_banner_app_store_enabled"
        label="Enable Smart Banner"
        checked={enabled}
        onChange={() => {
          if (enabled) {
            saveSettings({ smart_banner_app_store_enabled: !enabled });
          }
          updateSettings({
            ...settings,
            smart_banner_app_store_enabled: !enabled
          });
        }}
      />
      {enabled === true ? (
        <Form action="" className="mt-3" innerRef={formRef}>
          <FormGroup>
            <Label for="appStoreUrl">
              <i className="fab fa-app-store-ios mr-2" />
              App Store URL (iOS)
            </Label>
            <Input
              type="url"
              name="smart_banner_app_store_url"
              id="appStoreUrl"
              placeholder="App Store url of your app"
              value={appStoreUrl}
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="playStoreUrl">
              <i className="fab fa-google-play mr-2" />
              Play Store URL (Android)
            </Label>
            <Input
              type="url"
              name="smart_banner_play_store_url"
              id="playStoreUrl"
              placeholder="Play Store url of your app"
              value={playStoreUrl}
              onChange={onChange}
            />
          </FormGroup>
          <hr className="mt-3" />
          <h6 className="text-muted mb-3 text-uppercase">Banner content</h6>
          <FormGroup>
            <Label for="logoUrl">App logo URL</Label>
            <Input
              type="url"
              name="smart_banner_logo_url"
              id="logoUrl"
              placeholder="Icon URL"
              required
              value={logoUrl}
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="title">Title / App Name</Label>
            <Input
              type="text"
              name="smart_banner_title"
              id="title"
              placeholder="Enter a your app name/title"
              required
              value={title}
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="Description">
              Description
              <small className="text-muted" />
            </Label>
            <Input
              type="text"
              name="smart_banner_description"
              id="Description"
              placeholder="Enter a description"
              value={description}
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="smart_banner_cta_text">
              Call to action (Button text)
            </Label>
            <Input
              type="text"
              name="smart_banner_cta_text"
              id="smart_banner_cta_text"
              placeholder="Enter call to action text"
              value={ctaText}
              required
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="force_smart_banner">Force smart banner</Label>
            <CustomInput
              type="switch"
              id="force_smart_banner"
              name="force_smart_banner"
              label="Show the smart banner always"
              checked={forceSmartBanner}
              onChange={event => {
                updateSettings({
                  ...settings,
                  force_smart_banner: !forceSmartBanner
                });
              }}
            />
          </FormGroup>
          {forceSmartBanner && (
            <FormGroup>
              <Label for="smart_banner_cta_text">
                Smart banner preview limit
              </Label>
              <Input
                type="number"
                name="limit_smart_banner_preview"
                id="limit_smart_banner_preview"
                placeholder="Enter limit of preview"
                value={limitSmartBannerPreview}
                required
                onChange={onChange}
              />
            </FormGroup>
          )}
          <Button disabled={saving} onClick={save} color="primary">
            {saving ? 'Saving' : 'Save & Enable Banner'}
          </Button>
        </Form>
      ) : null}
    </React.Fragment>
  );
};

export default withToastManager(SmartBannerDetails);
