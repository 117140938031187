import { decodeEntities } from '@wordpress/html-entities';

/**
 * Extract feature image URL from post item
 * @param {*} item
 */
const getFeatureImageUrl = item => {
  const featuredmedia = item._embedded && item._embedded['wp:featuredmedia'];
  let imageUrl;

  featuredmedia &&
    featuredmedia.map(image => {
      if (image.id === item.featured_media) imageUrl = image.source_url;
    });
  // console.log(item.featured_media);
  return imageUrl;
};

const convertPost = item => {
  const featured_media = getFeatureImageUrl(item);

  const newPost = {
    title: item.title.rendered,
    content: decodeEntities(item.content.rendered),
    authorImage:
      'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=100',
    featured_media,
    status: 'publish',
    category: 'Change this category',
    author: 'Change Author',
    date: '10 Nov 2019'
  };

  return newPost;
};

const convertPostAppmaker = item => {
  return {
    title: item.title.rendered,
    content: decodeEntities(item.content.rendered),
    authorImage: item.avatar,
    featured_media: item.featured_media,
    status: 'publish',
    category: 'Change this category',
    author: item.author_name,
    date: '10 Nov 2019',
    excerpt: item.excerpt.rendered
  };
};

export { convertPost, getFeatureImageUrl, convertPostAppmaker };
