import React from 'react';
import TemplateFive from './TemplateFive';
import TemplateFour from './TemplateFour';
import TemplateSeven from './TemplateSeven';
import TemplateSix from './TemplateSix';

const SinglePostItem = props => {
  let component;
  switch (props.itemTemplate) {
    case 'template-5':
      component = <TemplateFive {...props} />;
      break;
    case 'template-4':
      component = <TemplateFour {...props} />;
      break;
    case 'template-7':
      component = <TemplateSeven {...props} />;
      break;
    case 'template-6':
      component = <TemplateSix {...props} />;
      break;

    default:
      component = <TemplateFive {...props} />;

      break;
  }

  return component;
};

export default SinglePostItem;
