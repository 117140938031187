const DEFAULT_STATE = {
  menu: [],
  languages: {},
  menuKey: 'mainmenu'
};
export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case 'SET_MENU': {
      return { ...state, menu: action.menuList };
    }
    case 'SET_MENU_KEY': {
      return {
        ...state,
        menuKey: action.menuKey
      };
    }
    default:
      return state;
  }
};
