import React from 'react';

export default () => (
    <svg width="24" height="24" viewBox="0 0 24 24">
        <g id="productScroller-gutIcon" transform="translate(-13207 -6800)">
            <rect id="Rectangle_568" data-name="Rectangle 568" width="24" height="24" transform="translate(13207 6800)" fill="none" />
            <g id="box_2_" data-name="box (2)" transform="translate(13216.452 6798.837)" opacity="0.7">
                <path id="Path_3967" data-name="Path 3967" d="M13.9,5.417,8.836,8.346,3.77,5.417A1.219,1.219,0,0,1,4.2,4.98L8.22,2.659a1.253,1.253,0,0,1,1.231,0L13.471,4.98a1.219,1.219,0,0,1,.431.437Z" transform="translate(-0.104 0)" fill="#c4c0ff" />
                <path id="Path_3968" data-name="Path 3968" d="M8.732,10.17v5.847a1.286,1.286,0,0,1-.56-.129L4.116,13.543A1.233,1.233,0,0,1,3.5,12.478V7.862a1.268,1.268,0,0,1,.166-.622Z" transform="translate(0 -1.823)" fill="#a6a1ff" />
                <path id="Path_3969" data-name="Path 3969" d="M17.232,7.862v4.616a1.233,1.233,0,0,1-.616,1.065l-4.075,2.351a1.289,1.289,0,0,1-.542.123V10.17l5.066-2.93A1.268,1.268,0,0,1,17.232,7.862Z" transform="translate(-3.268 -1.823)" fill="#6c63ff" />
            </g>
            <g id="box_2_2" data-name="box (2)" transform="translate(13204.58 6802.474)">
                <path id="Path_3967-2" data-name="Path 3967" d="M18.977,6.879l-7.6,4.4-7.6-4.4a1.83,1.83,0,0,1,.647-.656L10.45,2.74a1.881,1.881,0,0,1,1.848,0L18.33,6.223a1.83,1.83,0,0,1,.647.656Z" transform="translate(-0.021)" fill="#c4c0ff" />
                <path id="Path_3968-2" data-name="Path 3968" d="M11.353,11.638v8.777a1.93,1.93,0,0,1-.841-.194L4.424,16.7A1.851,1.851,0,0,1,3.5,15.1V8.173a1.9,1.9,0,0,1,.249-.933Z" transform="translate(0 -0.361)" fill="#a6a1ff" />
                <path id="Path_3969-2" data-name="Path 3969" d="M19.853,8.173V15.1a1.851,1.851,0,0,1-.924,1.6L12.813,20.23a1.934,1.934,0,0,1-.813.185V11.638l7.6-4.4A1.9,1.9,0,0,1,19.853,8.173Z" transform="translate(-0.647 -0.361)" fill="#6c63ff" />
            </g>
        </g>
    </svg>

);