import React, { Component } from 'react';

function MenuHam(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="12.118" viewBox="0 0 18.851 12.118" className="my-auto mr-3">
            <defs>
                <style>
                    {`.rect-menu{
                        fill: ${props.fill};
                    }`}
                </style>
            </defs>
            <g id="menu-hambar" transform="translate(-742 -262)">
                <rect id="Rectangle_108" className="rect-menu" data-name="Rectangle 108" width="19" height="1.3" transform="translate(742 262)" />
                <rect id="Rectangle_109" className="rect-menu" data-name="Rectangle 109" width="19" height="1.3" transform="translate(742 267.386)" />
                <rect id="Rectangle_110" className="rect-menu" data-name="Rectangle 110" width="19" height="1.3" transform="translate(742 272.772)" />
            </g>
        </svg>
    )
}
class PhoneMenuBar extends Component {
    state = {}
    render() {
        return (
            <div className="phone-menubar m-auto px-3 py-2 border d-flex bg-white">
                <MenuHam fill="#202020" />
                <div className="m-0 font-weight-bold">{this.props.title === undefined ? 'Text/Logo':this.props.title}</div>
            </div>
        );
    }
}

export default PhoneMenuBar;