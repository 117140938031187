import React, { useState, useEffect } from 'react';
import { Label } from 'reactstrap';
import { socialMediaCustomerTab } from '../../../../../lib/apiFunctions';
import Loading from '../Loading';

export default props => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const { projectId } = props;
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await socialMediaCustomerTab(projectId);
      if (response.status) {
        setData({ ...response.body });
      }
    } catch (error) {}
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);
  if (isLoading) {
    return <Loading bubble />;
  }
  return (
    <React.Fragment>
      {data.facebook && (
        <a href={data.facebook} target="_blank">
          <i className="fab fa-facebook" />
        </a>
      )}
      {data.instagram && (
        <a href={data.instagram} target="_blank">
          <i className="fab fa-instagram" />
        </a>
      )}
      {data.twitter && (
        <a href={data.twitter} target="_blank">
          <i className="fab fa-twitter" />
        </a>
      )}
    </React.Fragment>
  );
};
