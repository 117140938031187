import React, { useState, useEffect } from 'react';
import { Tooltip, Button } from 'reactstrap';
import ServiceUpload from './InnerComponents/serviceUpload';
import { serviceUploadStatus } from '../../../lib/pushAutomationApi';
import Loading from './helperComponents/loadingPage';
import ServiceAccountDoc from './InnerComponents/serviceAccountDoc';

export default ({
  id,
  addToast = () => {},
  previousStep = () => {},
  nextStep = () => {},
  firebaseProjectName
}) => {
  const [isLoading, setLoading] = useState(false);
  // needUploading= true => File upload required
  const [needUploading, setNeedUploading] = useState(true);
  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await serviceUploadStatus({ projectId: id });
      if (response.status) {
        setNeedUploading(response && response.needUploading);
      }
    } catch (error) {}
    setLoading(false);
  };
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  useEffect(() => {
    fetchData();
  }, []);
  if (isLoading) {
    return <Loading loading={isLoading} />;
  }
  return (
    <React.Fragment>
      <div className="mt-3">
        <h5>
          Upload service account
          <a href="#" id="infoToolTip" className="ml-2">
            <i className="fas fa-info-circle" />
          </a>
        </h5>
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          autohide={false}
          target="infoToolTip"
          toggle={toggle}
        >
          Go to your selected firebase project Settings > Service accounts and
          generate a new key
        </Tooltip>
        <ServiceAccountDoc
          projectName={firebaseProjectName}
          showWarningUploaded={!needUploading}
        />

        <ServiceUpload
          id={id}
          accept=".json"
          addToast={addToast}
          afterUpload={nextStep}
        />
        <div className="mt-4 text-right">
          <Button
            size="sm"
            className="px-4 mr-3"
            color="secondary"
            onClick={previousStep}
          >
            <i className="fas fa-angle-left mr-2" />
            Back
          </Button>
          {!needUploading && (
            <Button
              size="sm"
              className="px-4"
              color="primary"
              onClick={nextStep}
            >
              Next
              <i className="fas fa-angle-right ml-2" />
            </Button>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
