import React from 'react';
import { Row, Col, Button, ButtonGroup, Spinner } from 'reactstrap';
import moment from 'moment';
import { BallBeat } from 'react-pure-loaders';
import getSymbolFromCurrency from 'currency-symbol-map';
import { getSalesData } from '../../../../lib/apiFunctions';

const DisplayCard = ({ title, data, extraData, growth, negetive, loading }) => (
  <div className="sale-data-widget rounded text-dark p-3 h-100">
    <div className="d-flex justify-content-between">
      <p className="mb-0 text-uppercase">{title}</p>
      {/* {growth && (
        <p className="mb-0">
          <i
            className={`fas ${negetive ? 'fa-arrow-down' : 'fa-arrow-up'} mr-2`}
          />
          {growth}
        </p>
      )} */}
    </div>
    <h4 className="my-2">
      {loading ? <BallBeat color="#f1f2f5" loading={loading} /> : data}
    </h4>
    {/* <small>
      {loading ? <BallBeat color={'#f1f2f5'} loading={loading} /> : extraData}
    </small> */}
  </div>
);
class SaleDataWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      appSales: false,
      appOrders: false,
      appSalesAllTime: false,
      appOrderAllTime: false,
      showPluginUpdate: false,
      currency: '',
      activeClass: 'today'
    };
    this.loadSalesData = this.loadSalesData.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.loadSalesData({});
  }

  async loadSalesData(prop) {
    const { startDate, endDate } = prop;
    const { projectId } = this.props;
    try {
      const response = await getSalesData(projectId, startDate, endDate);
      if (response.status === true && response.data !== false) {
        const {
          appSales,
          appOrders,
          appSalesAllTime,
          appOrderAllTime,
          convertedValue,
          currency
        } = response.data;
        if (convertedValue === false) {
          this.setState({
            isLoading: false,
            appSales,
            appOrders,
            appSalesAllTime,
            appOrderAllTime,
            currency,
            showPluginUpdate: false
          });
        } else {
          this.setState({
            isLoading: false,
            appSales: convertedValue.appSales,
            appOrders,
            appSalesAllTime: convertedValue.appSalesAllTime,
            appOrderAllTime,
            currency: convertedValue.currency,
            showPluginUpdate: false
          });
        }
      } else {
        this.setState({
          isLoading: false,
          showPluginUpdate: true
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
        showPluginUpdate: true
      });
    }
  }

  async filter(props) {
    const { activeClass } = props;
    this.setState({ isLoading: true, activeClass });
    this.loadSalesData(props);
  }

  render() {
    const {
      appSales,
      appOrders,
      appSalesAllTime,
      appOrderAllTime,
      showPluginUpdate,
      currency,
      loading,
      isLoading,
      activeClass
    } = this.state;
    const { url } = this.props;
    return (
      <div className="dashboard-box rounded bg-primary text-white mt-2 p-3 position-relative sales-data-card overflow-hidden h-100">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="mb-0">
            <i className="fas fa-chart-line mr-2" />
            Mobile App Sales
          </h5>
          {!showPluginUpdate && (
            <ButtonGroup size="sm">
              <Button
                className={activeClass === 'today' ? 'active-button' : null}
                type="button"
                color="light"
                onClick={() => {
                  this.filter({ activeClass: 'today' });
                }}
              >
                Today
              </Button>
              <Button
                className={activeClass === '7-days' ? 'active-button' : null}
                color="light"
                type="button"
                onClick={() => {
                  this.filter({
                    activeClass: '7-days',
                    endDate: moment(new Date()).format('L'),
                    startDate: moment(new Date())
                      .subtract(7, 'days')
                      .calendar()
                  });
                }}
              >
                Last 7 days
              </Button>
              <Button
                className={activeClass === '30-days' ? 'active-button' : null}
                color="light"
                type="button"
                onClick={() => {
                  this.filter({
                    activeClass: '30-days',
                    endDate: moment(new Date()).format('L'),
                    startDate: moment(new Date())
                      .subtract(30, 'days')
                      .calendar()
                  });
                }}
              >
                Last 30 days
              </Button>
            </ButtonGroup>
          )}
        </div>
        {!showPluginUpdate ? (
          <Row>
            <Col>
              <DisplayCard
                loading={isLoading}
                title="Total Sales"
                data={`${
                  getSymbolFromCurrency(currency) === undefined
                    ? currency
                    : getSymbolFromCurrency(currency)
                } ${appSales === false || appSales === null ? 0 : appSales}`}
                extraData={`Total sale: ${
                  currency === 'USD' ? '$' : currency
                } ${
                  appSalesAllTime === null || appSalesAllTime === false
                    ? 0
                    : appSalesAllTime
                }`}
                negetive
                growth="20%"
              />
            </Col>
            <Col>
              <DisplayCard
                loading={isLoading}
                title="Total orders"
                data={appOrders === null || appOrders === false ? 0 : appOrders}
                growth="220%"
                extraData={`Total orders: ${
                  appOrderAllTime === null || appOrderAllTime === false
                    ? 0
                    : appOrderAllTime
                }`}
              />
            </Col>
          </Row>
        ) : (
          <div className="text-center py-2">
            <h4>
              <i className="fas fa-exclamation-triangle mr-2" />
            </h4>
            <h5>Please update plugin to 1.25.3</h5>
            <a
              href={`${url}wp-admin/plugin-install.php?tab=plugin-information&plugin=appmaker-woocommerce-mobile-app-manager`}
              className="btn btn-light mt-3"
              target="_blank"
            >
              Update plugin
            </a>
          </div>
        )}
      </div>
    );
  }
}

export default SaleDataWidget;
