import React, { Component } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import { withToastManager } from 'react-toast-notifications';
import {
  getAccoutSetting,
  updateAccountSettings
} from '../../../lib/apiFunctions';
import PreLoading from '../../dashboard/layout/PreLoading';

class ProfileSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: false,
      loading: true,
      modal: false,
      data: {
        fullname: '',
        email: '',
        username: '',
        avatar: ''
      }
    };
    this.handleChange = this.handleChange.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  modalBox() {
    return (
      <Modal isOpen={this.state.modal} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle}>Enter password</ModalHeader>
        <div className="modal-body p-4">
          <Form>
            <FormGroup>
              <Input
                type="password"
                id="password"
                placeholder="Current password"
              />
            </FormGroup>
          </Form>
        </div>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              this.updateAccount(document.getElementById('password').value);
            }}
          >
            {this.state.updating === true ? 'updating' : 'update'}
          </Button>
{' '}
          <Button color="secondary" onClick={this.toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  loadData() {
    getAccoutSetting()
      .then(response => response.json())
      .then(data => {
        this.setState({
          loading: false,
          modal: false,
          updating: false,
          data
        });
      });
  }

  updateAccount(currentPassword) {
    const { toastManager } = this.props;
    const currentState = this.state;
    currentState.updating = true;
    currentState.loading = true;
    const formData = new FormData();
    formData.append('User[currentPassword]', currentPassword);
    formData.append('Profile[full_name]', currentState.data.fullname);
    formData.append('User[username]', currentState.data.username);
    formData.append('User[email]', currentState.data.email);
    this.setState(currentState);
    updateAccountSettings(formData)
      .then(response => response.json())
      .then(resp => {
        if (resp.status === 'success') {
          const currentState = this.state;
          currentState.loading = false;
          this.setState(currentState);
          this.toggle();
          toastManager.add('Updated Successfully', {
            appearance: 'success',
            autoDismiss: true
          });
        } else {
          const currentState = this.state;
          currentState.loading = false;
          this.setState(currentState);
          this.toggle();
          toastManager.add('Failed..incorrect password', {
            appearance: 'error',
            autoDismiss: true
          });
        }
      })
      .catch(error => {
        const currentState = this.state;
        currentState.loading = false;
        this.setState(currentState);
        this.toggle();
        toastManager.add('Something went wrong', {
          appearance: 'error',
          autoDismiss: true
        });
      });
  }

  handleChange(event) {
    const { target } = event;
    const stateData = this.state;
    stateData.data[target.id] = event.target.value;
    this.setState({ data: stateData.data });
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    if (this.state.loading)
      return (
        <div className="mt-4">
          <PreLoading single />
        </div>
      );
    return (
      <div className="dashboard-box bg-white rounded mt-4 mb-2 account-settings-box p-4">
        <h5 className="font-weight-bold mb-3">Profile Settings</h5>
        <Form>
          <FormGroup>
            <Label>Profile picture</Label>
            <div>
              <img
                src={this.state.data.avatar}
                alt=""
                width="70px"
                className="mr-3"
              />
              <Button color="light">Update from gravatar</Button>
            </div>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="fullname">Full name</Label>
            <Input
              type="text"
              id="fullname"
              value={this.state.data.fullname}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="username">Username</Label>
            <Input
              type="text"
              id="username"
              value={this.state.data.username}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="usermail">Email</Label>
            <Input
              type="email"
              id="email"
              value={this.state.data.email}
              onChange={this.handleChange}
            />
          </FormGroup>
          <div className="text-right">
            <Button color="primary" className="px-5" onClick={this.toggle}>
              {this.props.buttonLabel}
              Update
            </Button>
            {this.modalBox()}
          </div>
        </Form>
      </div>
    );
  }
}

export default withToastManager(ProfileSettings);
