import React, { useState, useEffect } from 'react';
import AppDownloadCard from './AppDownloadCard';
import Constants from '../../../../lib/constants';
import { appBuildStatus } from '../../../../lib/apiFunctions';

let pluginStatusCheck = false;

export default function DownloadWidget({ project }) {
  let buildComplete = true;
  const [buildStatus, setBuildStatus] = useState({});
  async function fetchBuildStatus() {
    try {
      const response = await appBuildStatus(project.id);
      setBuildStatus(response);
    } catch (error) {
      this.setState({});
    }
  }

  function startPluginStatusCheck() {
    fetchBuildStatus();
    pluginStatusCheck = setInterval(() => {
      fetchBuildStatus();
    }, 10000);
  }
  const appDownloadCard =
    project.apps &&
    project.apps.map(app => {
      let status;
      const { token } = app;
      const preview =
        app.billingStatus !== Constants.BILLING_PAID || app.platformId === Constants.PLATFORM_IOS; // &&  app.iosCertUploaded === '0'
      if (buildStatus !== undefined && buildStatus.hasOwnProperty('status')) {
        status = preview
          ? buildStatus.preview[app.id]
          : buildStatus.status[app.id];
        if (typeof status !== 'string') {
          status = `${status}`;
        }
        buildComplete =
          buildComplete && status !== Constants.APP_STATUS_BUILDING;
      } else {
        status = preview ? app.previewBuildStatus : app.appStatus;
        if (typeof status !== 'string') {
          status = `${status}`;
        }
        buildComplete =
          buildComplete && status !== Constants.APP_STATUS_BUILDING;
      }
      return (
        <AppDownloadCard
          project={project}
          app={app}
          preview={preview}
          appId={app.id}
          token={token}
          platform={
            app.platformId === Constants.PLATFORM_ANDROID ? 'android' : 'ios'
          }
          status={status}
          errorMessage={app.error_message}
        />
      );
    });

  if (buildComplete) {
    clearInterval(pluginStatusCheck);
  } else if (!pluginStatusCheck) {
    startPluginStatusCheck();
  }

  return <div className="row justify-content-md-center">{appDownloadCard}</div>;
}
