import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import ImageModalHeader from './components/ImageModalHeader';
import ImageListing from './components/ImageListing';
import IconListing from './components/IconListing';
import IconScout from './components/IconScout';

class ImageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: true,
      selectedImage: {},
      searchTerm: '',
      openedTab: ''
    };

    this.toggle = this.toggle.bind(this);
  }

  /**
   * To get the data back when the image is clicked in the modal
   * @param {*} data
   */
  onMediaSelected(data) {
    this.setState({ selectedImage: data });
    if (data.closePane === true) {
      this.onSelect();
    }
  }

  /**
   * To return the selected Image when press add button
   */
  onSelect() {
    const { selectedImage } = this.state;
    const { onSelect } = this.props;
    if(Object.keys(selectedImage).length !==0)
    {
      onSelect(selectedImage);
    }
    this.toggle();
  }

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
    if (this.state.modal) {
      this.props.onClose && this.props.onClose();
    }
  };

  render() {
    const { className } = this.props;
    const { modal } = this.state;
    return (
      <Modal
        isOpen={modal}
        toggle={this.toggle}
        className={className}
        scrollable
      >
        <ImageModalHeader
          onSearch={value => {
            this.setState({ searchTerm: value.searchTerm, openedTab: value.tab });
          }}
        />
        <ModalBody>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="image"
              role="tabpanel"
              aria-labelledby="image-tab"
            >
              <ImageListing
                query={{ search: this.state.openedTab === 'media-library' ? this.state.searchTerm : '' }}
                action={({ data, upload = false }) => {
                  this.onMediaSelected(data, upload);
                }}
              />
            </div>
            <div
              className="tab-pane fade"
              id="Icon"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <IconListing
                query={{ search: this.state.openedTab === 'unsplash' ? this.state.searchTerm : '' }}
                action={data => {
                  this.onMediaSelected(data);
                }}
              />
            </div>
            <div
              className="tab-pane fade"
              id="IconScout"
              role="tabpanel"
              aria-labelledby="IconScout-tab"
            >
              <IconScout
                query={{ search: this.state.openedTab === 'icon-scout' ? this.state.searchTerm : '' }}
                action={data => {
                  this.onMediaSelected(data);
                }}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="link"
            className="text-dark"
            onClick={this.toggle}
            size="sm"
          >
            Cancel
            </Button>
          <Button
            color="success"
            className="px-5"
            onClick={() => {
              this.onSelect();
            }}
            size="sm"
          >
            Add
            </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
ImageModal.propTypes = {
  className: PropTypes.string,
  action: PropTypes.func.isRequired
};
ImageModal.defaultProps = {
  className: ''
};

export default ImageModal;
