import React, { useEffect, useState } from 'react';
import { Col } from 'reactstrap';
import moment from 'moment';
import Card from './Card';
import { userDetailsCustomerTab } from '../../../../lib/apiFunctions';
import UserDetail from './InnerComponents/innerUserDetails';
import Loading from './Loading';
import Tabs from './Tabs';

export default props => {
  const { projectId } = props;
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const fetchData = async () => {
    try {
      setLoading(true);
      const userDataResponse = await userDetailsCustomerTab(projectId);
      if (userDataResponse.status) {
        setData(userDataResponse.body);
      }
      setLoading(false);
    } catch (error) {}
  };
  useEffect(() => {
    fetchData();
  }, []);
  const { userData = {}, intercomData = [], projectData = [] } = data;
  const { name, userId, email, username, phone, lastLogin } = userData;
  return (
    <Col sm="12" md="5">
      <Card attributes={{ title: 'User Details' }}>
        {isLoading && <Loading />}
        {!isLoading && (
          <React.Fragment>
            <UserDetail
              userImg="https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/05/6bae3f87-b1bbec499a0d66e5403480e8cda1bcbe.png"
              userName={name}
              email={email}
              phone={phone}
              lastLogin={moment(lastLogin).format('LL')}
              activeProjects={projectData.length}
            />
            <Tabs
              intercomActivity={intercomData}
              projectData={projectData}
              projectId={projectId}
            />
          </React.Fragment>
        )}
      </Card>
    </Col>
  );
};
