import _ from 'lodash';
import { select } from '@wordpress/data';
import apiFetch from '@wordpress/api-fetch';
import * as ROUTES from '../constants/routes';

// import { registerCoreBlocks } from '@wordpress/block-library';

// registerCoreBlocks();

apiFetch.use((options, next) => {
  const project = select('appmaker/core').getCurrentProject();
  if (!_.startsWith(options.path, 'http')) {
    if (options.path.match('url='))
      options.path = `${ROUTES.API_URL}/api_proxy/${project.id}/${options.path}`;
    else if (
      options.path.match('in-app-pages') ||
      options.path.match('navigationMenu')
    )
      options.path = `${ROUTES.API_URL}/v2/projects/${project.id}/${options.path}`;
    else
      options.path = `${ROUTES.API_URL}/remote_store/${project.id}/${options.path}`;
  }
  return next(options);
});
