/**
 * Returns a action type by name.
 *
 * @param {Object} state Data state.
 *
 * @return {Object?} Action Type.
 */
export function getUser(state) {
  return state.user;
}

/**
 * Returns a action type by name.
 *
 * @param {Object} state Data state.
 * @param {string} name Action type name.
 *
 * @return {Object?} Action Type.
 */
export function getCurrentProject(state) {
  return state.currentProject;
}

/**
 * Returns a action type by name.
 *
 * @param {Object} state Data state.
 * @param {string} name Action type name.
 *
 * @return {Object?} Action Type.
 */
export function getCurrentProjectPluginResponse(state) {
  return state.currentProjectPluginResponse;
}
