export default {
  basic: {
    planName: 'Basic plan',
    monthlyPrice: 29,
    yearlyPrice: 290,
    revenueDisc: 'Android app',
    perfectFor: 'Small business',
    chargebeePlanId: {
      monthly: { usd: 'customapp-basic', inr: 'customapp-basic-inr' },
      yearly: {
        usd: 'customapp-basic-yearly',
        inr: 'customapp-basic-yearly-inr'
      }
    }
  }
};
