import React from 'react';

export default () => (
    <svg width="24" height="24" viewBox="0 0 24 24">
        <g id="html--gutIcon" transform="translate(-13297 -6799)">
            <rect id="Rectangle_570" data-name="Rectangle 570" width="24" height="24" transform="translate(13297 6799)" fill="none" />
            <line id="Line_50" data-name="Line 50" y1="14.147" transform="translate(13318.084 6803.804)" fill="none" stroke="#4b6efc" stroke-width="1" />
            <line id="Line_51" data-name="Line 51" x2="2.855" transform="translate(13316.657 6803.804)" fill="none" stroke="#4b6efc" stroke-width="0.5" />
            <line id="Line_52" data-name="Line 52" x2="2.855" transform="translate(13316.657 6817.952)" fill="none" stroke="#4b6efc" stroke-width="0.5" />
            <path id="Path_3973" data-name="Path 3973" d="M9.824-3.959,6.04-13.811c-.121-.333-.288-.409-.636-.409H4.6a.554.554,0,0,0-.636.424L.184-3.959c-.121.333-.03.5.348.5h.7c.636,0,.817-.091.938-.454l.711-1.952H7.054l.7,1.952c.121.363.3.454.938.454h.787C9.854-3.46,9.945-3.626,9.824-3.959ZM5.375-10.618l1.15,3.193H3.407l1.165-3.208a6.6,6.6,0,0,0,.333-1.831h.106A8.5,8.5,0,0,0,5.375-10.618Zm5.266.666c-.182.348-.076.53.257.681l.605.257c.348.136.439.091.651-.227a1.74,1.74,0,0,1,1.589-.726c1.135,0,1.513.545,1.513,1.5v.424C15-8.06,14.394-8.121,13.8-8.121c-2.421,0-3.435.62-3.435,2.437,0,1.574.7,2.452,3.375,2.452a7.332,7.332,0,0,0,2.724-.393c.424-.182.545-.333.545-.863v-3.92c0-1.786-.757-3.118-3.239-3.118A3.249,3.249,0,0,0,10.641-9.952Zm4.616,3.345v1.589c0,.136-.045.166-.166.212a7.236,7.236,0,0,1-1.513.121c-1.18,0-1.423-.393-1.423-1.014s.3-.984,1.453-.984C14.2-6.683,14.939-6.623,15.257-6.608Z" transform="translate(13298 6820.276)" fill="#6c63ff" />
        </g>
    </svg>
);