import { Alert } from 'reactstrap';
import React from 'react';

export default ({ projectName, showWarningUploaded = false }) => {
  return (
    <div className="my-3">
      <p>
        <b>Step 1: </b>
        Navigate to service account tab in your project settings on Firebase
        console.
        <span>
          {projectName && (
            <a
              href={`https://console.firebase.google.com/project/${projectName}/settings/serviceaccounts/adminsdk`}
              rel="noopener noreferrer"
              target="_blank"
              className="ml-2"
            >
              Link to firebase service account
            </a>
          )}
        </span>
      </p>
      <p>
        <b>Step 2: </b>
        Click the Generate New Private Key button at the bottom of the Firebase
        Admin SDK section of the Service Accounts tab
      </p>
      <p>
        <b>Step 3: </b>
        Upload the .json file in Appmaker dashboard.
      </p>
      <div>
        {showWarningUploaded && (
          <Alert color="info" className="d-flex align-items-center">
            <h5 className="mb-0">
              <i className="fas fa-info-circle mr-2" />
            </h5>
            <span>
              <h6 className="mb-0">Already Uploaded?</h6>
              <p className="mb-0">Press next for next step</p>
            </span>
          </Alert>
        )}
      </div>
    </div >
  );
};
