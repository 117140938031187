import React, { useState } from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import CopyField from '../copyField';

export default function ConfigurePlugin({ response, project }) {
  const [copy, setCopied] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);
  const {
    id,
    meta: { API_SECRET, API_KEY }
  } = project;
  return (
    <div className="bg-light rounded p-3">
      <h5 className="mb-4">
        <i className="fas fa-puzzle-piece mr-2 text-purple" />
        Configure plugin 
{' '}
<span>Go to dashboard</span>
      </h5>
      <p>
        Go to Your&nbsp;
        <b>WordPress admin</b>
        &nbsp; &gt;
        <b>WooCommerce</b>
        &nbsp; &gt;
        <b>Appmaker App Settings</b>
        &nbsp;
      </p>
      <a
        href={`${response.url}/wp-admin/admin.php?page=appmaker-wc-admin&tab=step2`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Go to plugin settings
        <i className="fas fa-arrow-right ml-2" />
      </a>
      {/* <div className="access-key-container position-relative">
                  <label htmlFor="accessKey">Enter access key on plugin page <button className="btn btn-link" id="Popover1"><i className="fas fa-info-circle text-dark"></i></button>
                      <Popover placement="bottom" isOpen={popoverOpen} target="Popover1" toggle={toggle}>
                          <PopoverBody>Copy below access key into </PopoverBody>
                      </Popover>
                  </label>
                  <textarea className="access-key" value={Base64} id="exampleFormControlTextarea1" rows="3" disabled />
                  <CopyToClipboard text={Base64} onCopy={() => {
                      setCopied(true);
                      setTimeout(() => setCopied(false), 3000);
                  }}>
                      <button className="btn btn-blue copy-button"><i className="fas fa-clipboard mr-2"></i>{copy === false ? 'Copy access key' : 'Copied to clipboard'}</button>
                  </CopyToClipboard>
              </div> */}
      <h6 className="mt-3">
        Enter access key on plugin page
        <button type="button" className="btn btn-link" id="Popover1">
          <i className="fas fa-info-circle text-dark" />
        </button>
        <Popover
          placement="bottom"
          isOpen={popoverOpen}
          target="Popover1"
          toggle={toggle}
        >
          <PopoverBody>
            <img
              src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2020/02/8e34efd7-annotation-2020-02-13-162406.png"
              className="img-fluid"
              alt=""
            />
          </PopoverBody>
        </Popover>
      </h6>
      <CopyField id="project_id" label="Project ID" value={id} />

      <CopyField id="api_key" label="API Key" value={API_KEY} />

      <CopyField id="api_secret" label="API Secret" value={API_SECRET} />
    </div>
  );
}
