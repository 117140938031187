import React, { useState } from 'react';
import Form from 'react-jsonschema-form';

import { withSelect } from '@wordpress/data';

import {
  convertSchema,
  convertUISchema,
  convertFormDataWithParams
} from './convertor';
// import MultipleActions from './MultipleActions';
import MultipleAction from './MultipleAction-3';
import simpleMultiSelect from './MultipleActions';

const ActionForm = ({ schema, uiSchema, onChange, formData }) => {
  // const [localFormData, setFormData] = useState(formData);
  const fields = {
    multiple: MultipleAction,
    'simple-multiple': simpleMultiSelect
  };

  return (
    <Form
      noValidate
      showErrorList={false}
      schema={schema}
      uiSchema={uiSchema}
      fields={fields}
      formData={formData}
      onChange={({ formData }) => {
        onChange && onChange(convertFormDataWithParams(formData));
        // setFormData(convertFormDataWithParams(formData));
      }}
    >
      <button hidden="hidden" />
    </Form>
  );
};
export default withSelect((select, props) => {
  const actionTypes = select('core/actions').getActionTypes();
  const project = select('appmaker/core').getCurrentProject();
  return {
    schema: convertSchema(actionTypes),
    uiSchema: convertUISchema(actionTypes, project.id)
  };
})(ActionForm);
