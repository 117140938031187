import apiFetch from '@wordpress/api-fetch';

const controls = {
  FETCH_FROM_API(action) {
    return apiFetch({ path: action.path });
  },
  API_FETCH({ request }) {
    return apiFetch(request);
  }
};
export default controls;
