export function setCurrentProjectPluginResponse(pluginResponse) {
  return {
    type: 'SET_CURRENT_PROJECT_PLUGIN_STATUS',
    pluginResponse
  };
}

export function setCurrentProject(project) {
  return {
    type: 'SET_CURRENT_PROJECT',
    project
  };
}

export function setUser(user) {
  return {
    type: 'SET_USER',
    user
  };
}
export function logout() {
  return {
    type: 'LOGOUT'
  };
}
