import React, { useState, useEffect, useReducer } from 'react';
import Helmet from 'react-helmet';
import { Spinner } from 'reactstrap';
import { withSelect, useDispatch } from '@wordpress/data';
import { API_URL } from '../../../constants/routes';
import { checkPluginStatus, sendPluginStatus } from '../../../lib/apiFunctions';
import PluginInstallWooCommerce from './WooCommerce/PluginInstall';
import PluginInstallWordPress from './WordPress/PluginInstall';
import ConfigurePluginWooCommerce from './WooCommerce/ConfigurePlugin';
import ConfigurePluginWordPress from './WordPress/ConfigurePlugin';
import { trackEvent } from '../../../utils/tracker';

let validateChecker = false;
const initialState = {
  step: 1,
  response: {},
  loading: true,
  error: false,
  urlErrorEventSent: false
};
function reducer(state, action) {
  let error;
  let { step } = state;
  switch (action.type) {
    case 'loading':
      return { ...state, loading: true };
    case 'error':
      return { ...state, error: true, loading: false };
    case 'set-response':
      error = false;
      if (
        !action.response.validWp ||
        ((state.step >= 2 &&
          state.project.template === 'woocommerce' &&
          !action.response.hasAppmakerWcPlugin) ||
          (state.step >= 2 &&
            state.project.template === 'wordpress' &&
            !action.response.hasAppmakerWpPlugin))
      ) {
        if (state.step === 1 && !state.urlErrorEventSent) {
          try {
            trackEvent('url_validation_failed', {
              project_id: state.project.id,
              template: state.project.template,
              url: state.project.meta.URL,
              error: action.response.debugInfo.error,
              response: action.response
            });
          } catch (error) {}
        }

        error = true;
      } else if (
        state.step === 1 &&
        ((state.project.template === 'woocommerce' &&
          action.response.hasAppmakerWcPlugin) ||
          (state.project.template === 'wordpress' &&
            action.response.hasAppmakerWpPlugin))
      ) {
        step = 3;
      } else {
        step = state.step + 1;
      }
      return {
        ...state,
        response: action.response,
        loading: false,
        error,
        step,
        urlErrorEventSent: true
      };
    case 'success':
      return { ...state, step: 4, error: false, loading: false };
    case 'proceed':
      if (state.step === 1 || state.step === 2) {
        if (
          (state.project.template === 'woocommerce' &&
            action.response.hasAppmakerWcPlugin) ||
          (state.project.template === 'wordpress' &&
            action.response.hasAppmakerWpPlugin)
        ) {
          return { ...state, step: 3 };
        }
        if (state.response.validWp) {
          return { ...state, step: 2 };
        }
      } else if (state.step === 3) {
        return { ...state }; //  step: 4
      } else if (state.step === 4) {
        // setCurrentProjectPluginStatus(true);
      }
      return { ...state };
    default:
      // eslint-disable-next-line no-console
      console.error(action);
      throw new Error();
  }
}

const ConnectStore = ({ project }) => {
  const { setCurrentProjectPluginResponse } = useDispatch('appmaker/core');

  const [state, dispatch] = useReducer(reducer, { ...initialState, project });
  const [pluginResponse, setPluginResponse] = useState(false);
  const { loading, response, error, step } = state;
  const { template } = project;

  function proceed() {
    dispatch({ type: 'proceed' });
    if (step === 4 || step > 4) {
      setCurrentProjectPluginResponse(pluginResponse);
    }
  }

  function pluginStatus() {
    dispatch({ type: 'loading' });
    return checkPluginStatus(project.id)
      .then(res => {
        dispatch({ type: 'success' });
        clearInterval(validateChecker);
        try {
          trackEvent('plugin_configuration_success', {
            project_id: project.id,
            template,
            url: project.meta.URL
          });
        } catch (error) {}

        fetch(`${API_URL}/v2/projects/${project.id}/plugin-configured`, {
          method: 'POST',
          credentials: 'include'
        }); // Log plugin configured to server

        sendPluginStatus(project.id, true);
        setPluginResponse(res);
        // history.push(linkToManageApp(project.id));
      })
      .catch(() => {
        dispatch({ type: 'error' });
      });
  }

  let urlValidationEventSend = false;
  function validateUrl() {
    dispatch({ type: 'loading' });
    sendPluginStatus(project.id, false);
    return fetch(`${API_URL}/v2/projects/${project.id}/validate-url`, {
      credentials: 'include'
    })
      .then(res => res.json())
      .then(res => {
        if (step === 1 && res.validWp && !urlValidationEventSend) {
          urlValidationEventSend = true;
          try {
            trackEvent('url_validation_success', {
              project_id: project.id,
              template,
              url: project.meta.URL,
              response: res,
              hasAppmakerWcPlugin: res.hasAppmakerWcPlugin,
              hasAppmakerWpPlugin: res.hasAppmakerWpPlugin
            });
          } catch (error) {}
        }

        dispatch({ type: 'set-response', response: res });

        if (template === 'woocommerce' && res.hasAppmakerWcPlugin) {
          clearInterval(validateChecker);
          pluginStatus();
          validateChecker = setInterval(() => {
            pluginStatus();
          }, 8000);
        }

        if (template === 'wordpress' && res.hasAppmakerWpPlugin) {
          clearInterval(validateChecker);
          pluginStatus();
          validateChecker = setInterval(() => {
            pluginStatus();
          }, 8000);
        }
      })
      .catch(fetchError => {
        dispatch({ type: 'error' });
        // eslint-disable-next-line no-console
        console.error(fetchError);
        try {
          trackEvent('url_validation_failed', {
            project_id: project.id,
            template,
            url: project.meta.URL,
            error: fetchError.message
          });
        } catch (error) {}
      });
  }

  async function validateUrlChecker() {
    clearInterval(validateChecker);
    validateUrl();
    validateChecker = setInterval(() => {
      validateUrl();
    }, 8000);
  }

  useEffect(() => {
    validateUrlChecker();
  }, ['step', 'response', 'loading']);

  return (
    <div className="container py-4">
      <Helmet>
        <title>Connect plugin - Appmaker dashboard </title>
      </Helmet>
      <div className="row justify-content-center">
        <div className="col-md-6 col-12 bg-white dashboard-box rounded-lg p-4">
          <div className="d-flex justify-content-between">
            <h6 className="my-auto">
              <i className="fas fa-cog mr-2" />
              Connect app to your site
            </h6>
            {/* loading */}
            {loading && <Spinner size="sm" color="blue" />}
            {/* Success */}
            {!loading && !error && (
              <button type="button" className="btn btn-sm btn-success">
                <i className="fas fa-check" />
              </button>
            )}
            {/* Retry button on fail */}
            {/* !loading && error && (
              <button
                type="button"
                className="btn btn-sm btn-danger"
                onClick={() => ()=>{}}
              >
                <i className="fas fa-sync mr-2" />
                Retry
              </button>
            ) */}

            {/* Retry button on incorrect access key */}
            {/* step === 3 && error && (
              <button
                className="btn btn-sm btn-orange text-white"
                onClick={() => pluginStatus()}
              >
                <i className="fas fa-sync mr-2"></i>Incorrect access key
              </button>
            ) */}
          </div>

          <div className="content my-4">
            {step === 1 && (
              <URLValidation
                error={error}
                response={response}
                project={project}
              />
            )}
            {step === 2 && template === 'woocommerce' && (
              <PluginInstallWooCommerce project={project} response={response} />
            )}
            {step === 2 && template === 'wordpress' && (
              <PluginInstallWordPress project={project} response={response} />
            )}
            {step === 3 && template === 'woocommerce' && (
              <ConfigurePluginWooCommerce
                error={error}
                project={project}
                response={response}
              />
            )}
            {step === 3 && template === 'wordpress' && (
              <ConfigurePluginWordPress
                error={error}
                project={project}
                response={response}
              />
            )}
            {step === 4 && (
              <ConfigureSuccess project={project} response={response} />
            )}
          </div>

          <div className="d-flex justify-content-between">
            <small className="text-muted step-count my-auto">
              {step !== 4 && `${step} / 3`}
            </small>
            {step === 1 && (
              <button
                type="button"
                disabled={response.urlChanged || !response.validWp}
                className="btn btn-primary"
                onClick={() => {
                  proceed();
                }}
              >
                Proceed
              </button>
            )}
            {step === 2 &&
              ((template === 'woocommerce' && response.hasAppmakerWcPlugin) ||
                (template === 'wordpress' && response.hasAppmakerWpPlugin)) && (
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() => {
                    proceed();
                  }}
                >
                  <i className="fas fa-key mr-2" />
                  Get Access keys
                </button>
              )}
            {(step === 3 || step === 4 || step > 4) && (
              <button
                disabled={false}
                type="button"
                onClick={() => {
                  proceed();
                }}
                className="btn btn-primary"
              >
                <i className="fas fa-layer-group mr-2" />
                Go to dashboard
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const URLValidation = ({
  response,
  error,
  project: {
    meta: { URL, WORDPRESS_URL },
    template
  }
}) => {
  return (
    <div className="bg-light rounded p-3">
      <h5 className="mb-4">
        <i className="fas fa-link mr-2 text-purple" />
        Validating URL
      </h5>
      <p className="mb-0">
        {!error && (
          <React.Fragment>
            <span className="text-dark">Your URL : </span>
            {template === 'woocommerce' && URL}
            {template === 'wordpress' && WORDPRESS_URL}
          </React.Fragment>
        )}
        {/* <a href="#">update</a> TODO create update URL option */}
      </p>
      {error && (
        <div className="alert alert-danger" role="alert">
          {URL} is not reachable or not a valid WordPress website
        </div>
      )}
    </div>
  );
};

const ConfigureSuccess = ({ project }) => {
  return (
    <div className="setup-completed text-center">
      <img
        src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2020/02/0fb3be34-plugin-success.gif"
        className="img-fluid rounded-pill"
        width="200px"
        alt=""
      />
      <p className="py-3 mb-0">
        You are all set and ready to customize your app.
      </p>
    </div>
  );
};

export default withSelect((select, ownProps) => {
  const project = select('appmaker/core').getCurrentProject();
  return {
    project
  };
})(ConnectStore);
