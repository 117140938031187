import React from 'react';
import UpdateApp from '../components/updateApp';
import * as ROUTES from '../../../../constants/routes';
import PushNotification from '../components/pushNotification';
import Subscription from '../components/subscription';
import Settings from '../components/Settings';
import CustomizeApp from '../components/customizeApp';
import PromoteApp from '../components/PromoteApp';
import CustomerData from '../components/CustomerData';
import PushAutomation from '../components/PushAutomation';

const WooCommerce = props => {
  const {
    appId,
    subscriptionExpired,
    toggleSideBar,
    history,
    nameChanged,
    newPage,
    afterNewPage = false,
    isAdmin,
    hideComponents
  } = props;
  return (
    <React.Fragment>
      {!hideComponents && (
        <React.Fragment>
          <CustomizeApp
            subscriptionExpired={subscriptionExpired}
            history={history}
            toggleSideBar={toggleSideBar}
            nameChanged={nameChanged}
            newPage={newPage}
            afterNewPage={data => {
              if (afterNewPage !== false) afterNewPage(data);
            }}
          />
          <UpdateApp
            appId={appId}
            link={ROUTES.linkToUpdateApp()}
            subscriptionExpired={subscriptionExpired}
          />
          <PushNotification
            onClick={toggleSideBar}
            link={ROUTES.linkToPushNotification()}
          />
          <PromoteApp
            link={ROUTES.linkToSmartBanner()}
            onClick={toggleSideBar}
            history={history}
          />
        </React.Fragment>
      )}
      <Subscription
        link={ROUTES.linkToSubscription()}
        onClick={toggleSideBar}
      />
      {!hideComponents && (
        <Settings link={`${ROUTES.linkToSettings()}`} onClick={toggleSideBar} />
      )}
      {isAdmin && (
        <React.Fragment>
          {' '}
          <CustomerData
            link={`${ROUTES.linkToCustomerData()}`}
            onClick={toggleSideBar}
          />
          {/* <PushAutomation
            link={ROUTES.linkToPushAutomation()}
            onClick={toggleSideBar}
          /> */}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default WooCommerce;
