/* eslint-disable import/prefer-default-export */
export const extractTextFromHTML = data => {
  try {
    const div = document.createElement('div');
    div.innerHTML = data;
    return div.innerText;
  } catch (error) {
    return '';
  }
};
