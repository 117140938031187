import React from 'react';
import { BallBeat } from 'react-pure-loaders';

export default ({ loading }) => {
  return (
    <div className="text-center my-5">
      <BallBeat color="#4B6EFC" loading={loading} />
    </div>
  );
};
