import React, { useState, useEffect } from 'react';
import { getAllProject } from '../../../../lib/pushAutomationApi';
import ListProject from '../helperComponents/listProject';
import LoadingPage from '../helperComponents/loadingPage';
// import CreateFirebaseProject from './createFirebaseProject';
import FirebaseCreateProjectDoc from './firebaseCreateProjectDoc';

export default ({
  accessToken,
  id,
  addToast = () => {},
  afterSelect = () => {},
  showDocPage = () => {}
}) => {
  const [isLoading, setLoading] = useState(false);
  const [firebaseProjects, setFirebaseProjects] = useState([]);
  const getProjectData = async () => {
    const response = await getAllProject({
      accessToken,
      id
    });

    if (response.status && response.body) {
      // To get only active project. The request include the delete project data also
      const activeProjectList = response.body.filter(
        project => project.state === 'ACTIVE'
      );
      setFirebaseProjects(activeProjectList);

      if (activeProjectList.length === 0) {
        // SHOW doc page
        showDocPage(true);
      }
    }
  };
  const fetchAllProjects = async () => {
    try {
      setLoading(true);
      if (!accessToken) {
        // Return to previous tab (Login tab) TODO
        return undefined;
      }
      await getProjectData();
    } catch (error) {
      addToast(error.message || 'Something went wrong', {
        appearance: 'error',
        autoDismiss: true
      });
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchAllProjects();
    setInterval(() => {
      getProjectData();
    }, 6000);
  }, []);
  if (isLoading) {
    return <LoadingPage loading={isLoading} />;
  }

  return (
    <div className="text-left">
      <div>
        <FirebaseCreateProjectDoc />
      </div>
      <div className="mb-1 mt-3">
        <h4 className="mb-0">Existing Firebase projects</h4>
        <small>Please choose the project associated with this app.</small>
      </div>
      <div className="mt-3">
        <ListProject
          projects={firebaseProjects}
          projectClicked={data => {
            afterSelect(data);
          }}
        />
      </div>
      {/* {firebaseProjects.length > 0 && (
        <CreateFirebaseProject
          id={id}
          accessToken={accessToken}
          setLoading={setLoading}
          addToast={addToast}
          afterCreate={() => {
            fetchAllProjects();
          }}
        />
      )} */}
    </div>
  );
};
