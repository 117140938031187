import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import Dropzone from 'react-dropzone';
import { uploadIosAuthKey, iosCreateCertStatus } from '../../../../../../lib/apiFunctions';

const UploadAuthKey = forwardRef(
  ({ toastManager, appId, finished, setIsUploading }, ref) => {
    const [authKey, setAuthKey] = useState(false);
    const [apiKey, setApiKey] = useState('');
    const [issuerId, setIssuerId] = useState('');

    function onDrop(files) {
      if (files[0].name.indexOf('.p8') >= 0) {
        const apiKeyMatch = files[0].name.match(/AuthKey_(.*).p8/i);
        if (apiKeyMatch) {
          setApiKey(apiKeyMatch[1]);
        }
        setAuthKey(files[0]);
      }
    }



    function startCertBuildStatusCheck() {
      const certStatusCheck = setInterval(async () => {
        const response = await iosCreateCertStatus(appId);
        if (response.status === '-1') {
          clearInterval(certStatusCheck);
          setIsUploading(false);
          toastManager.add(
            'Unable to generate certificate, If this issue continues, contact support',
            {
              appearance: 'error',
              autoDismiss: false
            });
        } else if (response.status === '2') {
          clearInterval(certStatusCheck);
          setIsUploading(false);
          toastManager.add('Successfully generated certificate', {
            appearance: 'success',
            autoDismiss: true
          });
          finished();
        }
      }, 2000);
    }

    useImperativeHandle(ref, () => ({
      submit() {
        if (authKey === false || apiKey === false || issuerId === false) {
          toastManager.add('All fields are mandatory', {
            appearance: 'error',
            autoDismiss: true
          });
          return false;
        }
        setIsUploading(true);
        uploadIosAuthKey({ appId, authKey, apiKey, issuerId }).then(
          response => {
            if (response.status === true) {
              startCertBuildStatusCheck();
            } else {
              toastManager.add('Something went wrong', {
                appearance: 'error',
                autoDismiss: true
              });
            }
          }
        );
      }
    }));

    return (
      <div className="py-2">
        <ol className="list-unstyled">
          <li className="new-file-input">
            <p><b>2. Upload .p8 file</b></p>
            <Dropzone
              accept=".p8"
              multiple={false}
              className="dropZone"
              onDrop={onDrop}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <label htmlFor="csrfile py-5" className="drop-zone">
                      <i className="fas fa-file-upload mr-2" />
                      {authKey !== false
                        ? authKey.name
                        : 'Drag here or click to Select file'}
                    </label>
                  </div>
                </section>
              )}
            </Dropzone>

            <FormGroup className="mt-3">
              <Label for="apiKey">Enter Key ID</Label>
              <Input
                type="text"
                name="apiKey"
                id="apiKey"
                value={apiKey}
                placeholder="Enter API Key ID"
                onChange={event => {
                  setApiKey(event.target.value);
                }}
              />
            </FormGroup>

            <FormGroup>
              <Label for="issuerId">Issurer ID</Label>

              <Input
                type="text"
                name="issuerId"
                id="issuerId"
                value={issuerId}
                placeholder="Enter Issuer ID"
                onChange={event => {
                  setIssuerId(event.target.value);
                }}
              />
            </FormGroup>
          </li>
        </ol>
      </div>
    );
  }
);
export default UploadAuthKey;
