import fetch from 'node-fetch';

function serializeData(obj) {
  if (typeof obj === 'string') {
    return obj;
  }
  return Object.keys(obj)
    .map(function(key) {
      return `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`;
    })
    .join('&');
}

export const sendToMautic = async data => {
  const url = 'https://crm.appmaker.xyz/form/submit';
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest'
      },
      body: serializeData(data)
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const mauticFormSubmit = async data => {
  const url = 'https://crm.appmaker.xyz/form/submit';
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const mauticFormIdList = {
  inAppPageSave: 16,
  inAppPageCreate: 17,
  menuSave: 18,
  sendPush: 20,
  settingsSave: 23,
  paymentStart: 21,
  paymentFailed: 27,
  promoteAppSaved: 24
};
