import React from 'react';
import { Button } from 'reactstrap';
import Form from 'react-jsonschema-form';
import PropTypes from 'prop-types';
import SyncWithPlugin from '../../MessageScreens/SyncWithPlugin';

const DynamicComponent = props => {
  const sendDataToParent = dataFromChild => {
    props.action(dataFromChild.formData);
  };
  const {
    uiSchema,
    convertedApi,
    isSaving,
    title,
    componentId,
    requiredSyncWithPlugin = false
  } = props;
  return (
    <div id="social_login">
      <div className="border-bottom border-light mb-4">
        <h5>{title}</h5>
      </div>
      {convertedApi[`${componentId}`] !== undefined && (
        <Form
          className="form-json"
          noValidate
          onSubmit={sendDataToParent}
          schema={convertedApi[`${componentId}`]}
          uiSchema={uiSchema}
        >
          <div className="text-right mt-5">
            {requiredSyncWithPlugin && <SyncWithPlugin />}
            <Button color="primary" className="px-5" disabled={isSaving}>
              {isSaving ? 'saving....' : 'save'}
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
};
DynamicComponent.propTypes = {
  componentId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isSaving: PropTypes.bool.isRequired,
  uiSchema: PropTypes.func.isRequired,
  convertedApi: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired
};
export default DynamicComponent;
