import React from 'react';
import { NavLink } from 'react-router-dom';
import { SmartBannerIcon, EmailFooterIcon, PromoteIcon } from '../Icons';
import * as ROUTES from '../../../../constants/routes';

const PromoteApp = props => {
  const { history, toggleSideBar } = props;
  return (
    <li className="nav-item">
      <a
        className="vertical-nav-hover nav-link text-dark rounded-lg text-decoration-none expanding-menu d-flex justify-content-between collapsed"
        data-toggle="collapse"
        href="#promotAppExpand"
        role="button"
        aria-expanded="false"
        aria-controls="promotAppExpand"
        onClick={() => history.push(ROUTES.linkToSmartBanner())}
      >
        <span>
          <PromoteIcon fill="#202020" />
          Promote App
        </span>
        <i className="fas fa-chevron-down my-auto text-dark" />
      </a>
      <div className="collapse" id="promotAppExpand">
        <div className="d-flex flex-column justify-content-end bg-white rounded-lg pb-1">
          <NavLink
            onClick={toggleSideBar}
            to={ROUTES.linkToSmartBanner()}
            activeClassName="active"
            className="vertical-nav-hover nav-link text-dark rounded-lg text-decoration-none mx-2"
          >
            <SmartBannerIcon fill="#202020" />
            Smart Banner
          </NavLink>
          <NavLink
            onClick={toggleSideBar}
            to={ROUTES.linkToEmailFooter()}
            activeClassName="active"
            className="vertical-nav-hover nav-link text-dark rounded-lg text-decoration-none mx-2"
          >
            <EmailFooterIcon fill="#202020" /> Email footer
          </NavLink>
        </div>
      </div>
    </li>
  );
};

export default PromoteApp;
