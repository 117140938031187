import React, { useReducer } from 'react';
import { Button, Label } from 'reactstrap';
import Loading from './Loading';
import {
  addSubscriptionBanner,
  removeSubscriptionBanner
} from '../../../../lib/subscriptionExpiredBanner';

const initialState = {
  isLoading: false,
  isLoadingAdd: false,
  isLoadingRemove: false
};
const reducer = (state, action) => {
  const { type, value } = action;
  switch (type) {
    case 'set_add_loading': {
      return { ...state, isLoadingAdd: value };
    }
    case 'set_loader': {
      return { ...state, isLoadingAdd: false, isLoadingRemove: false };
    }
    case 'set_remove_loading': {
      return { ...state, isLoadingRemove: value };
    }
    default:
      return state;
  }
};
export default ({ projectId, addToast = () => {} }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isLoading, isLoadingAdd, isLoadingRemove } = state;

  /**
   * To add subscription expired banner
   */
  const addBanner = async () => {
    dispatch({ type: 'set_add_loading', value: true });
    if (
      window.confirm(
        'Are you sure ? (This action will affect the in-app page in app)'
      )
    ) {
      try {
        const response = await addSubscriptionBanner(projectId);
        if (response.status) {
          addToast('In-app page updated in app', {
            appearance: 'success',
            autoDismiss: true
          });
          dispatch({ type: 'set_add_loading', value: false });
        } else {
          addToast(response.debugInfo || 'Something went wrong', {
            appearance: 'error',
            autoDismiss: false
          });
          dispatch({ type: 'set_add_loading', value: false });
        }
      } catch (error) {
        addToast(error.debugInfo || 'Something went wrong', {
          appearance: 'error',
          autoDismiss: false
        });
        dispatch({ type: 'set_add_loading', value: false });
      }
    } else {
      dispatch({ type: 'set_loader', value: false });
    }
  };

  /**
   * To remove subscription expired banner
   */
  const removeBanner = async () => {
    dispatch({ type: 'set_remove_loading', value: true });
    if (
      window.confirm(
        'Are you sure ? (This action will affect the in-app page in app)'
      )
    ) {
      try {
        const response = await removeSubscriptionBanner(projectId);
        if (response.status) {
          addToast('In-app page updated in app', {
            appearance: 'success',
            autoDismiss: true
          });
          dispatch({ type: 'set_remove_loading', value: false });
        } else {
          addToast(response.debugInfo || 'Something went wrong', {
            appearance: 'error',
            autoDismiss: false
          });
          dispatch({ type: 'set_remove_loading', value: false });
        }
      } catch (error) {
        addToast(error.debugInfo || 'Something went wrong', {
          appearance: 'error',
          autoDismiss: false
        });
        dispatch({ type: 'set_remove_loading', value: false });
      }
    } else {
      dispatch({ type: 'set_remove_loading', value: false });
    }
  };
  return (
    <div className="mt-3 app-details pt-3 border-top border-light">
      <h6>Add/Remove Subscription Epired Banner to App</h6>
      <div className="mb-3 mt-2">
        <Button
          color="danger"
          size="sm"
          onClick={addBanner}
          disabled={isLoading || isLoadingAdd || isLoadingRemove}
        >
          {isLoadingAdd ? (
            <Loading bubble />
          ) : (
            'Add subscription expired banner'
          )}
        </Button>
        <small className="text-muted d-block">
          <i className="fas fa-info-circle mr-1" />
          Add subscription expired banner in APP
        </small>
      </div>
      <div>
        <Button
          onClick={removeBanner}
          color="warning"
          size="sm"
          disabled={isLoading || isLoadingAdd || isLoadingRemove}
        >
          {isLoadingRemove ? (
            <Loading bubble />
          ) : (
            'Remove subscription expired banner'
          )}
        </Button>
        <small className="text-muted d-block">
          <i className="fas fa-info-circle mr-1" />
          Remove subscription expired banner from the APP.
        </small>
      </div>
    </div>
  );
};
