import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import NavbarStart from '../initialSteps/navbar/NavbarStart';
import ManageNavBar from './layout/ManageNavBar';
import * as ROUTES from '../../constants/routes';
import Apps from './layout/Apps';
import AccountSettings from './layout/AccountSettings';

export default function ManageApps({ match: { url } }) {
  if (window.Intercom) {
    window.Intercom('update', {
      hide_default_launcher: true
    });
  }
  return (
    <React.Fragment>
      <div className="sticky-top bg-light">
        <NavbarStart />
        <ManageNavBar />
      </div>
      <Route path={['/', ROUTES.MANAGE, ROUTES.ACTIVE_APPS]} exact>
        <Apps />
      </Route>
      <Route path={ROUTES.DELETED_APPS} exact>
        <Apps active={false} />
      </Route>
      <Route path={ROUTES.ACCOUNT_SETTINGS} exact component={AccountSettings} />
    </React.Fragment>
  );
}
