import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { BallBeat } from 'react-pure-loaders';
import { dispatch, withSelect } from '@wordpress/data';
import * as ROUTES from '../../constants/routes';
import { setUser } from '../../utils/tracker';
import 'whatwg-fetch';
/**
 *
 *
 * @param {*} param0
 */

function LoginCheck({ projectId = false, user }) {
  if (window.Intercom) {
    window.Intercom('update', {
      hide_default_launcher: true
    });
  }
  const url = `${ROUTES.API_URL}/v2/user`;
  //  const url = `${ROUTES.API_URL}/v2/projects/${appId}`;
  Promise.resolve(user)
    .then(user => {
      if (user.email) return Promise.resolve(true);
      return fetch(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Cache: 'no-cache'
        },
        cache: 'no-store',
        credentials: 'include'
      })
        .then(response => {
          return response.json();
        })
        .then(user => {
          if (!user.logged_in) {
            const ssoLoginUrl = `${ROUTES.API_URL}/v2/user/sso-login`;
            window.location.href = `${ssoLoginUrl}?return_to=${window.location.href}`;
          } else {
            if (user.id) {
              setUser(user.id, user); // Analytics
              dispatch('appmaker/core').setUser({
                ...user,
                last_used_project: projectId
              });
            }
            return Promise.resolve(false);
          }
        });
    })
    .then(getProject => {
      if (getProject && projectId) {
        const projectUrl = `${ROUTES.API_URL}/v2/projects/${projectId}`;
        fetch(projectUrl, {
          credentials: 'include'
        })
          .then(response => {
            return response.json();
          })
          .then(project => {
            dispatch('appmaker/core').setCurrentProject(project);
          });
      }
      return Promise.resolve();
    });
  return (
    <div className="wrap overflow-auto pr-3 d-flex justify-content-center align-items-center">
      <BallBeat color="#123abc" loading />
    </div>
  );
}

const AuthenticatedRoute = ({
  user,
  project,
  match,
  component: Component,
  ...otherProps
}) => {
  return (
    <Route
      {...otherProps} // eslint-disable-line
      render={props => {
        const projectID = props.match.params.id;
        return user.email && (!projectID || (projectID && project.id)) ? (
          <Component {...props} user={user} project={project} />
        ) : (
          <LoginCheck user={user} projectId={projectID} />
        );
      }}
    />
  );
};

export default withSelect((select, ownProps) => {
  const user = select('appmaker/core').getUser();
  const project = select('appmaker/core').getCurrentProject();
  return {
    user,
    project
  };
})(AuthenticatedRoute);
