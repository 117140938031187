import fetch from 'node-fetch';
import * as ROUTES from '../constants/routes';

const { API_URL } = ROUTES;

export const getDiagnostic = async ({ projectId }) => {
  try {
    const response = await fetch(`${API_URL}/v2/check/${projectId}`, {
      method: 'GET',
      credentials: 'include'
    });
    const respJson = await response.json();
    return Promise.resolve(respJson);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const x = () => {};
