import React, { useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/firestore';

export default ({ getUserData = () => {}, addToast = () => {} }) => {
  const [isLoading, setLoading] = useState(false);

  const LoginWithGoogle = () => {
    setLoading(true);
    const firebaseConfig = {
      apiKey: 'AIzaSyAaEw3Ww9i_cnASBp7GqY5ajFKnXBzvMsY',
      authDomain: 'appmaker-core.firebaseapp.com',
      databaseURL: 'https://appmaker-core.firebaseio.com',
      projectId: 'appmaker-core',
      storageBucket: 'appmaker-core.appspot.com',
      messagingSenderId: '746381077870',
      appId: '1:746381077870:web:f32630c1388af42116a57f',
      measurementId: 'G-FHS41KX4S6'
    };
    // scopes: ['email', 'profile', 'https://www.googleapis.com/auth/calendar']
    let app;
    const firebaseApps = firebase.apps.filter(
      // eslint-disable-next-line no-underscore-dangle
      data => data.name_ === 'push-automation'
    );
    if (firebaseApps.length === 0) {
      app = firebase.initializeApp(firebaseConfig, 'push-automation');
    } else {
      app = firebase.app('push-automation');
    }
    const provider = new firebase.auth.GoogleAuthProvider();

    // provider.addScope(
    //   'https://www.googleapis.com/auth/cloud-platform.read-only'
    // );
    // provider.addScope('https://www.googleapis.com/auth/cloudplatformprojects');
    provider.addScope('https://www.googleapis.com/auth/firebase');
    app
      .auth()
      .signInWithPopup(provider)
      .then(result => {
        // result  includes {user:{}, credential:{ accessToken},operationType }
        getUserData(result);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        addToast(error.message || 'Something went wrong', {
          appearance: 'error',
          autoDismiss: true
        });
        setLoading(false);
      });
  };
  return (
    <div className="text-center justify-content-center align-items-center py-4">
      <div className="my-3">
        <svg width="58" height="60" viewBox="0 0 58 60" fill="none">
          <rect
            y="5.73438"
            width="54.0563"
            height="54.0563"
            rx="10"
            fill="#F1F1F180"
          />
          <path
            d="M32.4592 42.462C31.6251 41.9445 31.3676 40.8449 31.8851 40.0109L35.1698 34.7168C36.3007 32.8943 36.652 30.7408 36.1592 28.6532C35.8554 27.3661 35.2531 26.2005 34.408 25.232C34.7704 25.0198 35.0881 24.7195 35.3246 24.3385C36.1008 23.0874 35.7145 21.4381 34.4634 20.6618C33.2124 19.8856 31.563 20.2719 30.7868 21.523C30.549 21.9062 30.4208 22.3269 30.3927 22.7483C27.0778 21.8928 23.4274 23.2552 21.5158 26.3361L18.2719 31.5644C17.7544 32.3985 16.6548 32.656 15.8208 32.1385C15.4031 31.8794 14.8544 32.0079 14.5952 32.4256L12.7183 35.4507C12.4591 35.8684 12.5876 36.4171 13.0053 36.6763L18.7047 40.2125C18.6099 40.3258 18.5221 40.4461 18.4429 40.5738C17.5373 42.0333 17.988 43.9576 19.4475 44.8632C20.9071 45.7687 22.8313 45.318 23.7369 43.8585C23.8161 43.7308 23.885 43.5988 23.9443 43.4635L29.6437 46.9997C30.0614 47.2589 30.6101 47.1303 30.8692 46.7127L32.7462 43.6875C33.0054 43.2698 32.8769 42.7211 32.4592 42.462V42.462ZM33.5249 22.1744C33.9419 22.4332 34.0707 22.983 33.812 23.4C33.5533 23.817 33.0034 23.9457 32.5864 23.687C32.1694 23.4283 32.0407 22.8785 32.2994 22.4615C32.5581 22.0445 33.1079 21.9157 33.5249 22.1744ZM22.2243 42.92C21.8362 43.5456 21.0116 43.7387 20.386 43.3506C19.7605 42.9624 19.5673 42.1378 19.9554 41.5123C20.0365 41.3816 20.1374 41.2678 20.2544 41.174L22.3946 42.5019C22.3626 42.6485 22.3054 42.7893 22.2243 42.92ZM29.8259 45.0179L23.7756 41.2639L20.7504 39.3869L14.7001 35.6329L15.698 34.0246C17.2102 34.4967 18.9114 33.91 19.7844 32.5029L23.0284 27.2746C24.8558 24.3295 28.7139 23.3933 31.6289 25.1878C31.6353 25.1918 31.6417 25.1957 31.648 25.1996C33.0587 26.0749 34.0452 27.4458 34.4267 29.0621C34.8101 30.6859 34.5368 32.3608 33.6573 33.7784L30.3725 39.0724C29.4995 40.4794 29.7293 42.2643 30.8238 43.4096L29.8259 45.0179Z"
            fill="#B6B6B6"
          />
          <path
            d="M24.8688 29.3729C24.4511 29.1138 24.3226 28.5651 24.5817 28.1474C25.8755 26.0623 28.6243 25.4185 30.7094 26.7122C31.1271 26.9713 31.2556 27.52 30.9965 27.9377C30.7373 28.3554 30.1886 28.4839 29.7709 28.2248C28.5199 27.4485 26.8705 27.8348 26.0943 29.0859C25.8352 29.5035 25.2864 29.6321 24.8688 29.3729Z"
            fill="#B6B6B6"
          />
          <circle cx="48" cy="10" r="10" fill="#2550FB" />
        </svg>
      </div>
      <h3>Configure to engage with users</h3>
      <p>
        You have to configure the app with firebase to send push-notifications.
        <br />
        Login with google to firebase to enable push-notification.
      </p>
      <button
        type="button"
        // onClick={() => signInWithProvider(SIGNIN_PROVIDERS.GOOGLE)}
        onClick={() => LoginWithGoogle()}
        className="btn btn-primary my-3"
        disabled={isLoading}
      >
        <i className="fab fa-google mr-2" />
        {isLoading ? 'Signing in...' : 'Sign in with Google'}
      </button>
    </div>
  );
};
