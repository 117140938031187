import React from 'react';
import {
  RichText,
  InspectorControls,
  InnerBlocks
} from '@wordpress/block-editor';
import { withSelect, select } from '@wordpress/data';
import { PanelBody } from '@wordpress/components';
import { Label, FormGroup } from 'reactstrap';
import ActionSelect from '../../../hooks/action/components/ActionSelect';
import SinglePostItem from '../../components/post/TemplateFive';
import { convertPostAppmaker } from '../postList/utils/WorpressConvertor';

const SinglePostEdit = props => {
  const { attributes, setAttributes, className, id } = props;
  return (
    <div className={className}>
      <InspectorControls>
        <PanelBody title="Single Post Settings">
          <FormGroup>
            <Label>Select Post/page</Label>
            <ActionSelect
              value={attributes.postId}
              projectId={id}
              dataSource={{
                link: 'posts'
              }}
              onChange={data => {
                setAttributes({ postId: data });
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>Select Template</Label>
            <ActionSelect
              value={
                attributes.template !== undefined
                  ? attributes.template.value
                  : ''
              }
              projectId={id}
              onChange={data => {
                setAttributes({ template: data });
              }}
              options={[
                { value: 'post-list-1', label: 'Simple' },
                { value: 'post-list-2', label: 'Banner' }
              ]}
            />
          </FormGroup>
        </PanelBody>
      </InspectorControls>
      <div className="single-post-container">
        <div className="single-post-block-title p-2">
          <RichText
                              value={attributes.title}
                              onChange={content => setAttributes({ title: content })}
                              placeholder="Post List Title"
                    />

        </div>
        <div className="single-post">
          {attributes.template &&
          attributes.template.value === 'post-list-2' ? (
            <SinglePostItem
              featureImageSrc="http://wandertrails.w.shopilder.com/wp-content/uploads/2019/04/Banner-Valley-of-flowers-trek-768x432.jpg"
              category="category"
              title="Lorem lipsum dolor sit amet"
              excerpt="Lorem lipsum dolor sit amet, dolor sit amet"
              userImgSrc="http://2.gravatar.com/avatar/e880c95ab1275a7de86727c10560b8f5?s=96&d=mm&r=g"
              author="admin"
              date="12 dec 2012"
            />
          ) : (
            <SinglePostItem
              category="category"
              title="Lorem lipsum dolor sit amet"
              excerpt="Lorem lipsum dolor sit amet, dolor sit amet"
              userImgSrc="http://2.gravatar.com/avatar/e880c95ab1275a7de86727c10560b8f5?s=96&d=mm&r=g"
              author="admin"
              date="12 dec 2012"
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default withSelect(select => {
  const project = select('appmaker/core').getCurrentProject();
  return { id: project.id };
})(SinglePostEdit);
