import React from 'react';

export default props => {
  const { userImg, userName, email, phone, lastLogin, activeProjects } = props;
  return (
    <React.Fragment>
      <div className="d-flex align-items-end justify-content-between">
        <span className="d-flex align-items-center">
          <img
            src={userImg}
            alt="user-dp"
            width="70px"
            height="70px"
            className="mr-3"
          />
          <span>
            <h6 className="mb-0">{userName}</h6>
            <small className="d-block text-grey">{email}</small>
            <small className="d-block text-grey">{phone}</small>
            <small className="d-block text-grey">
              Last login:
              {lastLogin}
            </small>
          </span>
        </span>
        <span className="text-right">
          <h4 className="mb-0">{activeProjects}</h4>
          <small className="text-grey">Active Projects</small>
        </span>
      </div>
    </React.Fragment>
  );
};
