import React, { Component } from 'react';
import { Card, Button, CardHeader, CardBody, CardTitle, CardText } from 'reactstrap';
import PlayStoreInstructions from '../PlayStoreInstructions';

class PlayStore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: 1,
        };
    }
    render() {
        return (
            <Card className="border-0 shadow store-card">
                <CardHeader className="text-center py-4">
                    <img
                        src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/06/3fada343-playstore.png"
                        alt="playstore"
                        className="img-fluid"
                        draggable="false"
                    />
                </CardHeader>
                <CardBody className="bg-black text-white rounded-bottom">
                    <div className="text-center mb-3">
                        <img src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/06/0c36a3ec-google-developer-console.png"
                            alt="google-developer-console"
                            draggable="false"
                        />
                    </div>
                    <CardTitle className="font-weight-bold">Google Developer Account</CardTitle>
                    <CardText className="small-label-text">To publish Android apps on Google Play, you'll need to create a Google Play Developer account. Google Play requires a one time $25 registration fee charged for a Google Play Developer Console account.</CardText>
                    <div className="d-flex justify-content-end">
                        <Button size="sm" color="primary" className="mx-1">Get developer account</Button>
                        <PlayStoreInstructions
                            buttonLabel="I have an account"
                            className="instruction-modal"
                        />
                    </div>
                </CardBody>
            </Card >
        );
    }
}

export default PlayStore;