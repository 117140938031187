import React from 'react';
import Select from 'react-select';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label
} from 'reactstrap';

const options = [
  { value: 'Incident', label: 'Incident', color: '#00B8D9' },
  { value: 'Issue', label: 'Issue', color: '#00B8D9' },
  { value: 'Feature', label: 'Feature', color: '#00B8D9' }
];

export default ({
  buttonLabel = '',
  toggle = () => {},
  modal = false,
  className = '',
  description,
  setDescription = () => {},
  createTicket = () => {},
  isLoading,
  setTitle = () => {},
  title,
  labels,
  setLabels = () => {},
  issueOrFeature,
  setIssueOrFeature = () => {}
}) => {
  return (
    <div>
      <Button color="danger" onClick={toggle}>
        {buttonLabel}
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Create Gitlab ticket</ModalHeader>
        <ModalBody>
          <form>
            <Label>Enter title</Label>
            <br />
            <input
              type="text"
              value={title}
              onChange={event => {
                setTitle(event.target.value);
              }}
            />
            <br />
            <Label>Enter description</Label>
            <br />
            <textarea
              rows="4"
              cols="50"
              value={description}
              onChange={event => {
                setDescription(event.target.value);
              }}
            />
            <br />
            <Label>
              Labels for ticket (eg: urgent,high-priority)(Comma-separated list
              of label names)
            </Label>
            <br />
            <textarea
              type="textarea"
              rows="2"
              cols="30"
              value={labels}
              onChange={event => {
                setLabels(event.target.value);
              }}
            />
            <br />
            <br />
            <Label>Issue Type</Label>
            <br />
            <Select
              defaultValue={options[0]}
              options={options}
              onChange={value => {
                setIssueOrFeature(value.value);
              }}
            />
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={createTicket} disabled={isLoading}>
            {isLoading ? 'creating ...' : 'Create ticket'}
          </Button>
{' '}
          <Button color="secondary" onClick={toggle} disabled={isLoading}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
