export const getInAppPageList = state => {
  const { inAppPagesList } = state;
  return inAppPagesList;
};
export const getInAppPage = (state, key) => {
  const { inAppPages } = state;
  return inAppPages[key];
};
export const getCurrentPage = state => {
  const { currentPage } = state;
  return currentPage;
};
export const getCurrentLanguageKey = state => {
  const { language } = state;
  return language;
};
