import React from 'react';
import {
  InnerBlocks,
  InspectorControls,
  RichText
} from '@wordpress/block-editor';
import { PanelBody, RangeControl, ToggleControl } from '@wordpress/components';
import { __ } from '@wordpress/i18n';
import PanelBodyComponent from './panel';
import { extractTextFromHTML } from '../../helper/index';

export default props => {
  const { attributes, className, setAttributes } = props;
  const { itemsPerLine, showViewMoreButton, showTitle, image } = attributes;
  return (
    <div className={className}>
      <PanelBodyComponent
        {...props}
        onChangeAttribute={value => {
          setAttributes(value);
        }}
      />
      {(showTitle || showViewMoreButton) && (
        <div className="block-title-part d-flex p-2 justify-content-between">
          <div className="block-title my-auto">
            {showTitle && (
              <RichText
                allowedFormats={[]}
                value={attributes.title}
                withoutInteractiveFormatting
                onChange={title => {
                  setAttributes({ title: extractTextFromHTML(title) });
                }}
                placeholder="Grid title"
              />
            )}
          </div>
          <div className="view-more-button">
            <div className="btn btn-light btn-sm">
              {showViewMoreButton && (
                <RichText
                  allowedFormats={[]}
                  value={attributes.ctaText}
                  withoutInteractiveFormatting
                  onChange={ctaText => {
                    setAttributes({ ctaText: extractTextFromHTML(ctaText) });
                  }}
                  placeholder="View More Button"
                />
              )}
            </div>
          </div>
        </div>
      )}
      <div className={`blocks-gallery-grid has-${itemsPerLine}-item`}>
        <InnerBlocks allowedBlocks={['appmaker/grid-item']} />
      </div>
    </div>
  );
};
