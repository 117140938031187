import React, { Component } from "react";
import logo from "../../../images/logo.svg";
import { dispatch, withSelect } from '@wordpress/data';
import * as ROUTES from '../../../constants/routes'

const NavbarStart = props => {
  return (
    <div className="container nav pt-4 d-flex justify-content-between">
      <a href="https://appmaker.xyz">
        <img src={logo} alt="Appmaker.xyz" className="appmaker-logo" />
      </a>
      <div className="dropdown d-inline-flex">
        <a
          className="nav-link py-0 dropdown-toggle text-dark cursor-pointer"
          id="navbarDropdownMenuLink"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img
            src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/05/6bae3f87-b1bbec499a0d66e5403480e8cda1bcbe.png"
            height="30"
            width="30"
            alt="user"
            className="rounded-circle mr-2"
          />
        </a>
        <div
          className="dropdown-menu dropdown-menu-right border-0 shadow"
          aria-labelledby="navbarDropdownMenuLink"
        >
          <a href={ROUTES.MANAGE} className="dropdown-item">
            Manage apps
          </a>
          <a className="dropdown-item" href={ROUTES.ACCOUNT_SETTINGS}>
            Account settings
          </a>
          <button
            type="button"
            className="dropdown-item"
            onClick={() => {
              props.logout().then(() => {
                window.location.href = 'https://appmaker.xyz/logout';
              });
            }}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}

export default withSelect((select, ownProps, dispatch) => {
  const logout = () => {
    return dispatch.dispatch('appmaker/core').logout();
  };
  return { logout };
})(NavbarStart);
