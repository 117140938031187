import React from 'react';
import { NavLink } from 'react-router-dom';
import { withSelect } from '@wordpress/data';
import * as ROUTES from '../../../../constants/routes';

function SettingsNavbar({ projectId, data: { fields }, email, isAdmin }) {
  const tabs = fields.map(field => {
    const hide = isAdmin === true ? isAdmin : field.id !== 'advanced';
    if (hide) {
      return (
        <li className="py-2 mb-2">
          <NavLink
            to={ROUTES.linkToSettingsInnerTabs(projectId, field.id)}
            activeClassName="settings-active-nav font-weight-bold"
            className="text-dark text-decoration-none"
          >
            {field.title}
          </NavLink>
        </li>
      );
    }

    return null;
  });
  return (
    <nav className="mr-0">
      <div className="border-bottom text-dark pt-3 mb-2">
        <h5>Settings</h5>
      </div>
      <ul className="list-unstyled">
        <li>{tabs}</li>
      </ul>
    </nav>
  );
}
export default withSelect(select => {
  const user = select('appmaker/core').getUser();
  let email;
  if (user) {
    email = user.email;
  }
  const isAdmin = email && email.includes('appmaker.xyz');
  return { email, isAdmin };
})(SettingsNavbar);
