const constants = {
  BILLING_PAID: '1',
  BILLING_PENDING: '0',

  PLATFORM_ANDROID: '1',
  PLATFORM_IOS: '3',

  APP_STATUS_NOT_STARTED: '0',
  APP_STATUS_BUILDING: '1',
  APP_STATUS_SUCCESS: '2',
  APP_STATUS_FAILED: '-1',
  APP_STATUS_DELETED: '-2',
  APP_STATUS_CERT_NOT_UPLOADED: '-3',

  PROJECT_PAYMENT_PAID: '5',
  PROJECT_PAYMENT_TRIAL: '9',
  PROJECT_PAYMENT_EXPIRED: '6',
  PROJECT_PAYMENT_EXPIRED_COMPLETE: '7',
  PAYMENT_TYPE_FREE: '1',
  PAYMENT_TYPE_ONETIME: '2',
  PAYMENT_TYPE_SAAS: '3',

  // ORDER_

  ORDER_STATUS_DRAFT: '1',
  ORDER_STATUS_PENDING: '2',
  ORDER_STATUS_ERROR: '3',
  ORDER_STATUS_COMPLETE: '5',
  ORDER_STATUS_EXPIRED: '6',
  ORDER_STATUS_EXPIRED_COMPLETE: '7',
  ORDER_STATUS_CANCELED: '8',

  // ACCOUNT
  ACCOUNT_SUSPENDED: -5
};
export default constants;
