import React, { Component } from 'react';
import ProfileSettings from '../components/ProfileSettings';
import BillingAddress from '../components/BillingAddress';
import ChangePass from '../components/ChangePass';

class AccountSettings extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-6 col-12">
                        <ProfileSettings />
                    </div>
                    <div className="col-md-6 col-lg-6 col-12">
                        <BillingAddress />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-6 col-lg-6 col-12">
                        <ChangePass />
                    </div>
                </div>
            </div>
        );
    }
}

export default AccountSettings;