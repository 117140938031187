// NOTE  only add action if has child
export const mapActionValueToSubAction = action => {
  switch (action) {
    case 'OPEN_IN_WEBVIEW': {
      return 'OPEN_IN_WEBVIEW_url';
    }
    case 'OPEN_URL': {
      return 'OPEN_URL_url';
    }
    case 'OPEN_INAPP_PAGE': {
      return 'OPEN_INAPP_PAGE_id';
    }
    case 'OPEN_PRODUCT_BY_CATEGORY': {
      return 'OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID';
    }
    case 'OPEN_POST_PAGE': {
      return 'OPEN_POST_PAGE_PRODUCT_ID';
    }
    case 'LIST_PRODUCT': {
      return 'OPEN_CATEGORY_PRODUCT_ID';
    }
    case 'OPEN_PRODUCT': {
      return 'OPEN_PRODUCT_PRODUCT_ID';
    }
    case 'OPEN_ATTRIBUTE': {
      return 'OPEN_ATTRIBUTE_ATTRIBUTE_ID';
    }
    case 'OPEN_CATEGORY': {
      return 'OPEN_CATEGORY_PRODUCT_ID';
    }
    case 'OPEN_PRODUCT_TAG': {
      return 'OPEN_PRODUCT_TAG_PRODUCT_ID';
    }
    case 'OPEN_SHOP_PAGE': {
      return 'OPEN_SHOP_PAGE_PRODUCT_ID';
    }
    case 'OPEN_BY_BRAND': {
      return 'OPEN_BY_BRAND_CATEGORY_ID';
    }
    case 'OPEN_PRODUCT_BRAND': {
      return 'OPEN_PRODUCT_BRAND_BRAND_ID';
    }
    case 'OPEN_AUTHENTICATED_WEBVIEW': {
      return 'OPEN_AUTHENTICATED_WEBVIEW_url';
    }
    case 'LIST_NEW_PRODUCTS': {
      return 'LIST_NEW_PRODUCTS_PRODUCT_ID';
    }
    case 'OPEN_PRE_BUILD': {
      return 'OPEN_PRE_BUILD_OPTION_ID';
    }
    case 'OPEN_COLLECTION': {
      return 'OPEN_COLLECTION_ID';
    }
    default: {
      return undefined;
    }
  }
};
const subActions = [
  'OPEN_IN_WEBVIEW_url',
  'OPEN_URL_url',
  'OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID',
  'OPEN_POST_PAGE_id',
  'OPEN_INAPP_PAGE_id',
  'OPEN_CATEGORY_PRODUCT_ID',
  'OPEN_PRODUCT_PRODUCT_ID',
  'OPEN_ATTRIBUTE_ATTRIBUTE_ID',
  'OPEN_PRODUCT_TAG_PRODUCT_ID',
  'OPEN_SHOP_PAGE_PRODUCT_ID',
  'OPEN_BY_BRAND_CATEGORY_ID',
  'OPEN_PRODUCT_BRAND_BRAND_ID',
  'OPEN_AUTHENTICATED_WEBVIEW_url',
  'LIST_NEW_PRODUCTS_PRODUCT_ID',
  'OPEN_COLLECTION_ID'
];

const verifyAttributeProperties = attributes => {
  if (Object.keys(attributes).length !== 0) {
    const { appmakerAction } = attributes;
    if (appmakerAction === undefined) return { flag: 0 };
    let action;
    try {
      action = appmakerAction.action;
    } catch (error) {
      action = undefined;
    }
    if (
      Object.keys(appmakerAction).includes('action') &&
      action === undefined
    ) {
      return { message: 'Please select valid action', flag: 1 };
    }
    const subAction = mapActionValueToSubAction(action);

    if (
      Object.keys(appmakerAction).includes('action') &&
      subAction !== undefined &&
      appmakerAction[`${subAction}`] === undefined
    ) {
      return { message: 'Sub Action is empty', flag: 1 };
    }
    const flag1 = 0;
    const subOut = subActions.map(act => {
      if (Object.keys(appmakerAction).includes(act) && action === undefined) {
        return 1;
      }
      return 0;
    });
    if (subOut.includes(1)) {
      return { flag: 1 };
    }
  }
  return { flag: 0 };
};

// TO verify the block ----pass the whole block
export const verifyBlocks = blocks => {
  let flag = 0;
  const out = blocks.map(_block => {
    // check in the parent component
    const { clientId, name, attributes, innerBlocks } = _block;
    const verifyResponse = verifyAttributeProperties(attributes);
    flag = verifyResponse.flag;
    const innerOut = innerBlocks.map(_inner => {
      const { attributes } = _inner;
      const resp1 = verifyAttributeProperties(attributes);
      return resp1.flag;
    });
    if (innerOut.includes(1)) {
      return 1;
    }
    return flag;
  });
  if (out.includes(1)) {
    return { status: false };
  }
  return { status: true };
};
