import React, { Component } from 'react';

const SinglePostItem = props => {
  return (
    <div className="news-card-7 bg-white d-inline-block ml-4">
      <figure>
        <img src={props.featureImageSrc} alt="Card image cap" />
      </figure>
      <div className="text-area m-3 text-wrap">
        <div className="post-title">
          <h6 className="ellipsis text-dark font-weight-bold">{props.title}</h6>
        </div>
        <div className="author-pdate d-flex">
          <span className="icon my-auto mr-1">
            <img src={props.userImgSrc} alt="" />
          </span>
          <p className="text-muted my-auto">
            {props.author} . {props.date}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SinglePostItem;
