import React from 'react';
import { Button } from 'reactstrap'; // InputGroup, InputGroupAddon, Input

export default function() {
  return (
    <div className="py-4 bg-light shadow-sm">
      <div className="container d-flex">
        <div className="flex-grow-1 m-auto">
          <h4 className="m-auto">My Apps</h4>
        </div>
        <div className="w-25 mr-3">
          {/* <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <span className="input-group-text bg-white border-0" id="basic-addon1"><i className="fas fa-search"></i></span>
                            </InputGroupAddon>
                            <Input placeholder="Search app..." className="border-0" />
                        </InputGroup> */}
        </div>
        <Button tag="a" href="https://create.appmaker.xyz" color="primary">
          + Create New App
        </Button>
      </div>
    </div>
  );
}
