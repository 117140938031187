import { some } from 'lodash';
import React, { useRef, useState } from 'react';

import { CustomInput, Form, FormGroup, Input, Label, Button, Spinner } from 'reactstrap';
import { withToastManager } from 'react-toast-notifications';

const EmailFooterDetails = ({
  toastManager,
  updateSettings,
  saveSettings,
  settings,
  loading
}) => {
  const formRef = useRef(null);
  const [saving, setSaving] = useState(false);

  function onChange(event) {
    updateSettings({ ...settings, [event.target.name]: event.target.value });
  }

  async function save(e) {
    try {
      e.preventDefault();
      if (!formRef.current.reportValidity()) {
        toastManager.add('All fields are required', {
          appearance: 'error',
          autoDismiss: true
        });
      } else {
        setSaving(true);
        await saveSettings(settings);
        toastManager.add('Saved successfully', {
          appearance: 'success',
          autoDismiss: true
        });
        setSaving(false);
      }
    } catch (error) {
      setSaving(false);
      toastManager.add('Unable to Save', {
        appearance: 'error',
        autoDismiss: true
      });
    }
  }

  const {
    email_footer_enabled: enabled = false,
    email_footer_app_store_url: appStoreUrl = '',
    email_footer_play_store_url: playStoreUrl = '',
    email_footer_logo_url: logoUrl = '',
    email_footer_title: title = '',
    email_footer_description: description = ''
  } = settings;


  if (loading === true) {
    return (
      <span className="d-flex justify-content-center">
        <Spinner color="secondary" size="sm" type="grow" />
        <Spinner color="secondary" size="sm" type="grow" />
        <Spinner color="secondary" size="sm" type="grow" />
      </span>
    )
  } else {
    return (
      <React.Fragment>
        <CustomInput
          type="switch"
          id="enable_emailFooter"
          name="email_footer_enabled"
          label="Enable Order Email Footer"
          checked={enabled}
          onChange={() => {
            if (enabled) {
              saveSettings({ email_footer_enabled: !enabled });
            }
            updateSettings({
              ...settings,
              email_footer_enabled: !enabled
            });
          }}
        />
        {
          enabled === true ? (
            <Form action="" className="mt-3" innerRef={formRef}>
              <FormGroup>
                <Label for="appStoreUrl">
                  <i className="fab fa-app-store-ios mr-2" />
                App Store URL (iOS)
              </Label>
                <Input
                  type="url"
                  name="email_footer_app_store_url"
                  id="appStoreUrl"
                  placeholder="App Store url of your app"
                  value={appStoreUrl}
                  onChange={onChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="playStoreUrl">
                  <i className="fab fa-google-play mr-2" />
                Play Store URL (Android)
              </Label>
                <Input
                  type="url"
                  name="email_footer_play_store_url"
                  id="playStoreUrl"
                  placeholder="Play Store url of your app"
                  value={playStoreUrl}
                  onChange={onChange}
                />
              </FormGroup>
              <hr className="mt-3" />
              <h6 className="text-muted mb-3 text-uppercase">Banner content</h6>
              <FormGroup>
                <Label for="logoUrl">App logo URL</Label>
                <Input
                  type="url"
                  name="email_footer_logo_url"
                  id="logoUrl"
                  placeholder="Icon URL"
                  required
                  value={logoUrl}
                  onChange={onChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="title">Title / App Name</Label>
                <Input
                  type="text"
                  name="email_footer_title"
                  id="title"
                  placeholder="Enter a your app name/title"
                  required
                  value={title}
                  onChange={onChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="Description">
                  Description
                <small className="text-muted" />
                </Label>
                <Input
                  type="text"
                  name="email_footer_description"
                  id="Description"
                  placeholder="Now available on iOS and Android"
                  value={description}
                  onChange={onChange}
                />
              </FormGroup>
              <Button disabled={saving} onClick={save} color="primary">
                {saving ? 'Saving' : 'Save & Enable'}
              </Button>
            </Form>
          ) : null
        }
      </React.Fragment >
    );
  }
};

export default withToastManager(EmailFooterDetails);
