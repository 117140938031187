import React from 'react';
import {
  InnerBlocks,
  InspectorControls,
  RichText
} from '@wordpress/block-editor';
import { PanelBody, RangeControl, ToggleControl } from '@wordpress/components';
import { __ } from '@wordpress/i18n';
import { FormGroup, Label, Input } from 'reactstrap';
import { DatePicker } from '@y0c/react-datepicker';
import '@y0c/react-datepicker/assets/styles/calendar.scss';
import './style.css'

export default props => {
  const { attributes, className, setAttributes } = props;
  const { title } = attributes;
  return (
    <div className={className}>
      <InspectorControls>
        <PanelBody title="Schedule Settings">
          <FormGroup className="schedule-date">
            <Label>Date</Label>
            <DatePicker
              value={`${attributes.date}`}
              onChange={(allFormat, date) => {
                if (date !== undefined) {
                  setAttributes({ date });
                }
              }}
              dateFormat="MMMM DD, dddd"
              placeholder="Select a date"
              clear
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="schedule_title">Schedule title</Label>
            <Input
              type="text"
              id="schedule_title"
              value={title}
              onChange={event => {
                setAttributes({ title: event.target.value });
              }}
              placeholder="Enter a title"
            />
          </FormGroup>
        </PanelBody>
      </InspectorControls>
      <div className="schedule-container p-2">
        <div className="schedule-title d-flex justify-content-between">
          <h6 className="border-bottom border-primary">{attributes.date || 'Schedule date'}</h6>
          <h6>{title || 'Schedule title'}</h6>
        </div>
        <InnerBlocks allowedBlocks={['appmaker/schedule-item']} />
      </div>
    </div>
  );
};
