import React from 'react';

export default ({ projects = [], projectClicked = () => {} }) => {
  return projects.map(project => {
    return (
      <button
        type="button"
        className="btn btn-light p-3 shadow-sm mr-2 mb-2"
        key={project.projectNumber}
        // value={`${project.name}  (${project.projectId})`}
        onClick={() => {
          projectClicked(project.projectId);
        }}
      >
        <h6>{project.displayName}</h6>
        <small>{project.projectId}</small>
      </button>
    );
  });
};
