import React from 'react';
import { withSelect } from '@wordpress/data';
import ActionSelect from './NewActionSelect';
import SimpleSelect from './simpleSelect';

class MultipleAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dependedValue: false
    };
  }

  render() {
    const { projectId, uiSchema, formData } = this.props;
    const { dependedValue } = this.state;
    const { data, parentId } = uiSchema;
    const { subAction, dataSource, id, title } = data;
    // To avoid shallow copy
    const convertedSubAction = JSON.parse(JSON.stringify(subAction.dataSource));
    if (dependedValue !== false && subAction.dataSource.depend === true) {
      const link = `${subAction.dataSource.link}${dependedValue.id}`;
      convertedSubAction.link = link;
    }
    return (
      <div>
        {title}
        <ActionSelect
          onChange={data => {
            if (formData === undefined) {
              this.setState({ dependedValue: data });
            } else {
              this.setState({ dependedValue: data }, () => {
                this.props.onChange({
                  [id]: data
                });
              });
            }
          }}
          value={formData !== undefined ? formData[id] : undefined}
          projectId={projectId}
          dataSource={dataSource}
        />
        {(dependedValue !== false || formData !== undefined) && (
          <div>
            {subAction.title}
            {convertedSubAction.options === undefined && (
              <ActionSelect
                value={
                  formData !== undefined ? formData[subAction.id] : undefined
                }
                onChange={data => {
                  this.props.onChange({
                    [subAction.id]: data,
                    [id]: this.state.dependedValue
                  });
                }}
                projectId={projectId}
                dataSource={convertedSubAction}
              />
            )}
            {convertedSubAction.options !== undefined && (
              <SimpleSelect
                value={
                  formData !== undefined ? formData[subAction.id] : undefined
                }
                options={convertedSubAction.options}
                onChange={data => {
                  this.props.onChange({
                    [subAction.id]: data,
                    [id]: this.state.dependedValue
                  });
                }}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}
export default withSelect(select => {
  const project = select('appmaker/core').getCurrentProject();
  return { projectId: project.id };
})(MultipleAction);
