import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import Card from './Card';
import ProjectDetail from './InnerComponents/innerProjectDetails';
import {
  customerDataTabData,
  salesDataCustomerTab
} from '../../../../lib/apiFunctions';
import Loading from './Loading';
import PublishData from './publishData';
import SocialMedia from './InnerComponents/socialMedia';

export default props => {
  const { projectId, addToast = () => {} } = props;
  const [isLoadingProjectData, setLoadingProjectData] = useState(false);
  const [isLoadingSalesData, setLoadingSalesData] = useState(false);
  const [projectData, setProjectData] = useState({});
  const [storeSalesData, setSalesData] = useState({});
  const fetchData = async () => {
    setLoadingProjectData(true);
    setLoadingSalesData(true);
    try {
      const projectDataResponse = await customerDataTabData(projectId);
      setProjectData(projectDataResponse.body);
      setLoadingProjectData(false);
      const salesDataResponse = await salesDataCustomerTab(projectId);
      if (salesDataResponse.status === true) {
        setSalesData(salesDataResponse.body);
      }
      setLoadingSalesData(false);
    } catch (error) {}
  };
  useEffect(() => {
    fetchData();
  }, []);
  const {
    icon,
    name,
    url,
    chargebeeId,
    userId,
    lastActivity,
    createDate,
    expiryDate,
    paymentDate,
    plan
  } = projectData;
  const {
    appmakerVersion,
    woocommerceVersion,
    currency,
    country,
    salesData = {}
  } = storeSalesData;
  const { all, app } = salesData;
  return (
    <Col sm="12" md="7">
      <Card
        attributes={{
          title: 'Project Details',
          headerRight: (
            <React.Fragment>
              <PublishData projectId={projectId} />
              <SocialMedia projectId={projectId} />
            </React.Fragment>
          )
        }}
      >
        {!isLoadingProjectData && (
          <ProjectDetail
            addToast={addToast}
            appIcon={icon}
            appName={name}
            storeUrl={url}
            projectId={projectId}
            chargebeeId={chargebeeId}
            userId={userId}
            lastActivity={moment(lastActivity).format('LL')}
            createDate={moment(createDate).format('LL')}
            expiryDate={moment(expiryDate).format('LL')}
            paymentDate={moment(paymentDate).format('LL')}
            isLoadingSalesData={isLoadingSalesData}
            pluginVersion={appmakerVersion}
            wooVersion={woocommerceVersion}
            activePlan={plan}
            currency={currency}
            country={country}
            storeSales={all}
            appSales={app}
          />
        )}
        {isLoadingProjectData && <Loading />}
      </Card>
    </Col>
  );
};
