import React from 'react';
import { Table } from 'reactstrap';
import Loading from '../Loading';

export default ({ storeSales = {}, appSales = {}, isLoading = false }) => {
  const {
    all_time: storeAllTime = {},
    last_day: storeLastDay = {},
    last_month: storeLastMonth = {}
  } = storeSales;
  const {
    all_time: appAllTime = {},
    last_day: appLastDay = {},
    last_month: appLastMonth = {}
  } = appSales;

  const lastDayPercentage =
    appLastDay !== null && storeLastDay !== null
      ? Math.round(
          (appLastDay.total_orders / storeLastDay.total_orders) * 100 * 1000
        ) / 1000
      : null;

  const lastMonthPercentage =
    appLastMonth !== null && storeLastMonth !== null
      ? Math.round(
          (appLastMonth.total_orders / storeLastMonth.total_orders) * 100 * 1000
        ) / 1000
      : null;
  const allTimePercentage =
    appAllTime !== null && storeAllTime !== null
      ? Math.round(
          (appAllTime.total_orders / storeAllTime.total_orders) * 100 * 1000
        ) / 1000
      : null;
  return (
    <div>
      {isLoading && <Loading />}
      {!isLoading && (
        <React.Fragment>
          <Table striped size="sm">
            <thead>
              <tr>
                <th>Order Percentage Through App</th>
              </tr>
              <tr>
                <th />
                <th>%</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Last Day</td>
                <td>{lastDayPercentage}</td>
              </tr>
              <tr>
                <td>Last Month</td>
                <td>{lastMonthPercentage}</td>
              </tr>
              <tr>
                <td>All Time</td>
                <td>{allTimePercentage}</td>
              </tr>
            </tbody>
          </Table>
        </React.Fragment>
      )}
    </div>
  );
};
