import React from 'react';

const SinglePostItem = props => {
  return (
    <div className="news-card bg-white d-inline-block">
      {props.featureImageSrc && (<figure>
        <img src={props.featureImageSrc} alt="Card image cap" />
      </figure>)}
      <div className="text-area m-3 text-wrap">
        <div className="category">
          <p className="text-muted mb-1">{props.category}</p>
        </div>
        <div className="post-title">
          <h6 className="ellipsis text-dark font-weight-bold">{props.title}</h6>
        </div>
        <p className="small-text ellipsis">{props.excerpt}</p>
        <div className="author-pdate d-flex">
          <span className="icon my-auto mr-1">
            <img src={props.userImgSrc} alt="" />
          </span>
          <p className="text-muted my-auto">
            {props.author} . {props.date}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SinglePostItem;
