import React, { useState, useEffect } from 'react';
import {
  Nav,
  NavItem,
  NavLink,
  TabPane,
  Table,
  TabContent,
  Row,
  Col
} from 'reactstrap';
import classnames from 'classnames';
import DataAccordion from '../DataAccordion';
import { websiteAndPluginCustomerTab } from '../../../../../lib/apiFunctions';
import Loading from '../Loading';
import PercentageSales from './percentageSales';
import PercentageBillingInvoices from './percentageBillingInvoices';

export default props => {
  const { projectId, isLoadingSalesData, storeSales, appSales } = props;
  const tabs = [
    {
      key: 0,
      title: 'Sales Data'
    },
    {
      key: 1,
      title: 'Website Meta'
    },
    {
      key: 2,
      title: 'Plugin data'
    },
    {
      key: 3,
      title: '% Billing invoices'
    }
  ];
  const [activeTab, setActiveTab] = useState('0');
  const [isLoadingWebsiteMeta, setWebsiteMetaLoading] = useState(false);
  const [websiteMeta, setWebsiteMeta] = useState(undefined);
  const [pluginMeta, setPluginMeta] = useState(undefined);
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const fetchData = async () => {
    try {
      setWebsiteMetaLoading(true);
      const websiteMetadata = await websiteAndPluginCustomerTab(projectId);
      setWebsiteMeta(websiteMetadata.websiteData);
      setPluginMeta(websiteMetadata.pluginData);
      setWebsiteMetaLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="detail-tabs">
      {!isLoadingWebsiteMeta && (
        <React.Fragment>
          <PercentageSales
            storeSales={storeSales}
            appSales={appSales}
            isLoading={isLoadingSalesData && storeSales && appSales}
          />
          <Nav tabs>
            {tabs.map((item, i) => (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === i.toString()
                  })}
                  onClick={() => {
                    toggle(i.toString());
                  }}
                >
                  {item.title}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="0">
              <div className="sales-data">
                {isLoadingSalesData && <Loading />}
                {!isLoadingSalesData && storeSales && (
                  <DataAccordion
                    attributes={{
                      title: 'Store Sales',
                      iconName: 'store',
                      salesData: storeSales
                    }}
                  />
                )}
                {!isLoadingSalesData && appSales && (
                  <DataAccordion
                    attributes={{
                      title: 'App Sales',
                      iconName: 'mobile-alt',
                      salesData: appSales
                    }}
                  />
                )}
              </div>
            </TabPane>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  {websiteMeta && !isLoadingWebsiteMeta && (
                    <div className="d-flex flex-wrap justify-content-center">
                      <div className="p-3 m-1 border border-light bg-dark rounded shadow-sm text-center">
                        <h6 className="text-light">Alexa Rank</h6>
                        <h4 className="mb-0 text-white">
                          {websiteMeta.alexaRank}
                        </h4>
                      </div>
                      <div className="p-3 m-1 border border-light bg-dark rounded shadow-sm text-center">
                        <h6 className="text-light">Alexa Reach</h6>
                        <h4 className="mb-0 text-white">
                          {websiteMeta.alexaReach}
                        </h4>
                      </div>
                      <div className="p-3 m-1 border border-light bg-dark rounded shadow-sm text-center">
                        <h6 className="text-light">Country Code</h6>
                        <h4 className="mb-0 text-white">
                          {websiteMeta.countryCode}
                        </h4>
                      </div>
                      <div className="p-3 m-1 border border-light bg-dark rounded shadow-sm text-center">
                        <h6 className="text-light">Country Name</h6>
                        <h4 className="mb-0 text-white">
                          {websiteMeta.countryName}
                        </h4>
                      </div>
                      <div className="p-3 m-1 border border-light bg-dark rounded shadow-sm text-center">
                        <h6 className="text-light">Total products</h6>
                        <h4 className="mb-0 text-white">
                          {websiteMeta.totalProduct}
                        </h4>
                      </div>
                      <div className="p-3 m-1 border border-light bg-dark rounded shadow-sm text-center">
                        <h6 className="text-light">Total Category</h6>
                        <h4 className="mb-0 text-white">
                          {websiteMeta.totalCategory}
                        </h4>
                      </div>
                      <div className="p-3 m-1 border border-light bg-dark rounded shadow-sm text-center">
                        <h6 className="text-light">Total tag</h6>
                        <h4 className="mb-0 text-white">
                          {websiteMeta.totalTag}
                        </h4>
                      </div>
                    </div>
                  )}
                  {isLoadingWebsiteMeta && <Loading />}
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="12">
                  {pluginMeta && !isLoadingWebsiteMeta && (
                    <Table className="overflow-auto">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Activated</th>
                          <th>Version</th>
                          <th>Author</th>
                          <th>Plugin URL</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pluginMeta &&
                          pluginMeta.map(plugin => {
                            return (
                              <tr>
                                <td>{plugin.name}</td>
                                <td>
                                  {plugin.activated === 1
                                    ? 'Activated'
                                    : 'Not Activated'}
                                </td>
                                <td>{plugin.version}</td>
                                <td>{plugin.author}</td>
                                <td>
                                  <a href={plugin.pluginUrl} target="_blank">
                                    Goto plugin page
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  )}
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <PercentageBillingInvoices projectId={projectId} />
            </TabPane>
          </TabContent>
        </React.Fragment>
      )}
      {isLoadingWebsiteMeta && <Loading />}
    </div>
  );
};
