export const playScreenshot = {
    stepOne: require('./playstoreScreenshots/playstore-1.png'),
    stepTwo: require('./playstoreScreenshots/playstore-2.png'),
    stepThreeA: require('./playstoreScreenshots/playstore-3a.png'),
    stepThreeB: require('./playstoreScreenshots/playstore-3b.png'),
    stepFourA: require('./playstoreScreenshots/playstore-4a.png'),
    stepFourB: require('./playstoreScreenshots/playstore-4b.png'),
    stepFourC: require('./playstoreScreenshots/playstore-4c.png'),
    stepFourD: require('./playstoreScreenshots/playstore-4d.png'),
    stepFourE: require('./playstoreScreenshots/playstore-4e.png'),
    stepFiveA: require('./playstoreScreenshots/playstore-5a.png'),
    stepFiveB: require('./playstoreScreenshots/playstore-5b.png'),
    stepSixA: require('./playstoreScreenshots/playstore-6a.png'),
    stepSixB: require('./playstoreScreenshots/playstore-6b.png'),
    stepSixC: require('./playstoreScreenshots/playstore-6c.png'),
    stepSixD: require('./playstoreScreenshots/playstore-6d.png'),
    stepSixE: require('./playstoreScreenshots/playstore-6e.png'),
};
export const appstoreScreenshot = {
    stepOneA: require('./appstoreScreenshots/appstore-1a.png'),
    stepOneB: require('./appstoreScreenshots/appstore-1b.png'),
    stepOneC: require('./appstoreScreenshots/appstore-1c.png'),
    stepOneD1: require('./appstoreScreenshots/appstore-1d1.png'),
    stepOneD2: require('./appstoreScreenshots/appstore-1d2.png'),
    stepOneE: require('./appstoreScreenshots/appstore-1e.png'),
    stepOneF: require('./appstoreScreenshots/appstore-1f.png'),
    stepTwoA: require('./appstoreScreenshots/appstore-2a.png'),
    stepTwoB: require('./appstoreScreenshots/appstore-2b.png'),
    stepTwoC: require('./appstoreScreenshots/appstore-2c.png'),
    stepTwoD: require('./appstoreScreenshots/appstore-2d.png'),
    stepTwoE: require('./appstoreScreenshots/appstore-2e.png'),
    stepFourA: require('./appstoreScreenshots/appstore-4a.png'),
    stepFourB: require('./appstoreScreenshots/appstore-4b.png'),
    stepFourC: require('./appstoreScreenshots/appstore-4c.png'),
    stepFourD: require('./appstoreScreenshots/appstore-4d.png'),
    stepFourE1: require('./appstoreScreenshots/appstore-4e1.png'),
    stepFourE2: require('./appstoreScreenshots/appstore-4e2.png'),
    stepSixA: require('./appstoreScreenshots/appstore-6a.png'),
    stepSixB: require('./appstoreScreenshots/appstore-6b.png'),
    stepSixC: require('./appstoreScreenshots/appstore-6c.png'),
    stepSixD: require('./appstoreScreenshots/appstore-6d.png'),
    stepSixE: require('./appstoreScreenshots/appstore-6e.png'),
    stepSixF: require('./appstoreScreenshots/appstore-6f.png'),
    stepSixG: require('./appstoreScreenshots/appstore-6g.png'),
    stepApiOne: require('./appstoreScreenshots/appstore-api-step-1.png'),
    stepApiTwo: require('./appstoreScreenshots/appstore-api-step-2.png'),
    stepApiThree: require('./appstoreScreenshots/appstore-api-step-3.png'),
    stepApiFour: require('./appstoreScreenshots/appstore-api-step-4.png'),
};