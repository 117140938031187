import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { withSelect } from '@wordpress/data';
import ChoosePlan from '../subscriptionData/choosePlan';
import CONSTANT from '../../../lib/constants';

function SubscriptionExpired({
  match: {
    params: { id = '' }
  },
  project: { template, paymentStatus },
  user: { email }
}) {
  let isExpired =
    paymentStatus === CONSTANT.PROJECT_PAYMENT_EXPIRED ||
    paymentStatus === CONSTANT.PROJECT_PAYMENT_EXPIRED_COMPLETE;

  if (!isExpired) {
    return (
      <Route
        render={props => {
          return <Redirect push to={`/apps/${id}`} />;
        }}
      />
    );
  }

  return (
    <div className="wrap overflow-auto pr-3">
      <div className="row">
        <div className="col-md-7 mx-auto mt-3">
          <div className="dashboard-box rounded bg-white text-center p-3">
            <img
              src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2020/03/b3e71c37-subscription-expired.png"
              className="img-fluid w-75"
              alt="expired"
              draggable="false"
            />
            <div className="mb-5">
              <h3 className="text-muted">Thanks for trying appmaker!</h3>
              <p className="w-75 mx-auto">
                Your subscription has expired. You can continue using the
                appmaker with a paid subscription.
              </p>
              <ChoosePlan
                template={template}
                projectId={id}
                email={email}
                paymentText="Make Payment"
                paymentButtonClass="btn btn-primary mt-2"
              />
            </div>
            <div className="mb-4">
              <h5 className="text-muted">Still setting up your app?</h5>
              <p className="w-75 mx-auto">
                Please contact our customer support to extend your trial period.
              </p>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => {
                  window.Intercom('show');
                }}
              >
                Contact support
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withSelect((select, ownProps) => {
  const project = select('appmaker/core').getCurrentProject();
  const user = select('appmaker/core').getUser();
  return { project, user: user || {} };
})(SubscriptionExpired);
