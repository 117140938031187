import React, { useState } from 'react';
import ListFirebaseProject from './InnerComponents/listFirebaseProject';
import Documentation from './InnerComponents/createDoc';
import ProcessApp from './InnerComponents/processApps';

export default ({
  id,
  addToast = () => {},
  accessToken = false,
  previousStep = () => {},
  nextStep = () => {},
  setFirebaseProjectNameAfterSelecting = () => {}
}) => {
  const [showDocPage, setShowDocPage] = useState(false);
  const [firebaseProjectName, setFirebaseProjectName] = useState(false);
  const afterSelectFirebaseProject = selectedProjectName => {
    setFirebaseProjectNameAfterSelecting(selectedProjectName);
    setFirebaseProjectName(selectedProjectName);
  };
  if (accessToken === false) {
    previousStep();
    return null;
  }
  return (
    <React.Fragment>
      {accessToken !== false &&
        showDocPage === false &&
        firebaseProjectName === false && (
          <ListFirebaseProject
            id={id}
            addToast={addToast}
            accessToken={accessToken}
            afterSelect={afterSelectFirebaseProject}
            showDocPage={setShowDocPage}
          />
        )}
      {showDocPage && (
        <Documentation
          accessToken={accessToken}
          id={id}
          passProjects={data => {
            showDocPage(false);
          }}
        />
      )}
      {accessToken !== false && firebaseProjectName !== false && (
        <ProcessApp
          nextStep={nextStep}
          accessToken={accessToken}
          id={id}
          addToast={addToast}
          projectName={firebaseProjectName}
          previousStep={previousStep}
        />
      )}
    </React.Fragment>
  );
};
