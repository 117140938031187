import React from 'react';

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <g id="prebuiltScroller-gutIcon" transform="translate(-13247 -6799)">
      <rect
        id="Rectangle_569"
        data-name="Rectangle 569"
        width="24"
        height="24"
        transform="translate(13247 6799)"
        fill="none"
      />
      <g id="layer-group" transform="translate(13247 6799.195)">
        <path
          id="Path_3970"
          data-name="Path 3970"
          d="M12,14.2a1,1,0,0,1-.5-.134l-9-5.2a1,1,0,0,1,0-1.732l9-5.193a1,1,0,0,1,1,0l9,5.193a1,1,0,0,1,0,1.732l-9,5.2a1,1,0,0,1-.5.134Z"
          fill="#6c63ff"
        />
        <path
          id="Path_3971"
          data-name="Path 3971"
          d="M21.5,11.132,19.536,10,12.5,14.061a1,1,0,0,1-1,0L4.464,10,2.5,11.132a1,1,0,0,0,0,1.732l9,5.2a1,1,0,0,0,1,0l9-5.2a1,1,0,0,0,0-1.732Z"
          fill="#a6a1ff"
        />
        <path
          id="Path_3972"
          data-name="Path 3972"
          d="M21.5,15.132,19.536,14,12.5,18.062a1,1,0,0,1-1,0L4.464,14,2.5,15.132a1,1,0,0,0,0,1.732l9,5.2a1,1,0,0,0,1,0l9-5.2a1,1,0,0,0,0-1.732Z"
          fill="#a6a1ff"
          opacity="0.19"
        />
        />
      </g>
    </g>
  </svg>
);
