import React from 'react';
import { InspectorControls } from '@wordpress/block-editor';
import { Input, FormGroup, Label } from 'reactstrap';
import { PanelBody } from '@wordpress/components';
import { __ } from '@wordpress/i18n';
import '../style.css';

export default props => {
  const { attributes, setAttributes } = props;
  const { title, subtitle, description } = attributes;
  return (
    <div>
      <InspectorControls>
        <PanelBody title="Feed Settings">
          <FormGroup>
            <Label htmlFor="feed_title">Title</Label>
            <Input
              value={title}
              id="feed_title"
              onChange={event => {
                setAttributes({ title: event.target.value });
              }}
              placeholder="Enter feed title"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="feed_subtitle">Subtitle</Label>
            <Input
              value={subtitle}
              id="feed_subtitle"
              onChange={event => {
                setAttributes({ subtitle: event.target.value });
              }}
              placeholder="Enter feed subtitle"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="">Discription</Label>
            <Input
              value={description}
              id=""
              type="textarea"
              placeholder="Description"
              onChange={event => {
                setAttributes({ description: event.target.value });
              }}
            />
          </FormGroup>
        </PanelBody>
      </InspectorControls>
      <div className="feed-item position-relative p-2">
        <div className="feed-title position-relative mb-2">
          <h5 className="mb-0 ml-3">{title || 'Title'}</h5>
          {subtitle && <small className="text-muted ml-3">{subtitle}</small>}
        </div>
        <p>{description || 'Description'}</p>
        <hr className="mb-0" />
      </div>
    </div>
  );
};
