import { registerStore } from '@wordpress/data';
import reducer from './reducer';
import actions from './action';
import * as selectors from './selectors';
import controls from './controls';
import * as resolvers from './resolver';

registerStore('appmaker/in-app-page', {
  reducer,
  actions,
  selectors,
  controls,
  resolvers
});
