export default {
    basic: {
        planName: 'Wordpress Basic',
        monthlyPrice: 49,
        yearlyPrice: 490,
        revenueDisc: 'Android app',
        perfectFor: 'Small business',
        chargebeePlanId: {
            monthly: { usd: 'wordpress-basic', inr: 'wordpress-basic-inr' },
            yearly: {
                usd: 'wordpress-basic-yearly',
                inr: 'wordpress-basic-yearly-inr'
            }
        }
    },
    growth: {
        planName: 'Wordpress Growth',
        monthlyPrice: 199,
        yearlyPrice: 1990,
        revenueDisc: 'Android & iOS app',
        perfectFor: 'Business in the growth stage.',
        chargebeePlanId: {
            monthly: { usd: 'wordpress-growth', inr: 'wordpress-growth-inr' },
            yearly: {
                usd: 'wordpress-growth-yearly',
                inr: 'wordpress-growth-yearly-inr'
            }
        }
    }
};