import React from 'react';
import {
  BlockControls,
  RichText,
  InspectorControls
} from '@wordpress/block-editor';
import { Fragment } from '@wordpress/element';
import {
  Button,
  ButtonGroup,
  PanelBody,
  PanelRow,
  RangeControl,
  Toolbar,
  ToggleControl,
  BaseControl,
  Path,
  SVG
} from '@wordpress/components';

import _ from 'lodash';
import { __ } from '@wordpress/i18n';
import { withSelect } from '@wordpress/data';
import QueryControls from '../../components/post/query-controls';
import Carousel from './Carousel';
import SinglePostItem from '../../components/post/SinglePostItem';
import { getPostListData } from '../../../lib/apiFunctions';

import { convertPostAppmaker } from './utils/WorpressConvertor';
import ActionForm from '../../../hooks/action/components/customActionForm';
import {
  action,
  getDisplayValue
} from '../../../plugins/customAction/postlist';

const landscapeIcon = (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <Path d="M0 0h24v24H0z" fill="none" />
    <Path d="M19 5H5c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 12H5V7h14v10z" />
  </SVG>
);

const coverIcon = (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <Path d="M0 0h24v24H0z" fill="none" />
    <Path d="M4 4h7V2H4c-1.1 0-2 .9-2 2v7h2V4zm6 9l-4 5h12l-3-4-2.03 2.71L10 13zm7-4.5c0-.83-.67-1.5-1.5-1.5S14 7.67 14 8.5s.67 1.5 1.5 1.5S17 9.33 17 8.5zM20 2h-7v2h7v7h2V4c0-1.1-.9-2-2-2zm0 18h-7v2h7c1.1 0 2-.9 2-2v-7h-2v7zM4 13H2v7c0 1.1.9 2 2 2h7v-2H4v-7z" />
  </SVG>
);

const PostListEdit = props => {
  const { attributes, setAttributes, className, styles } = props;
  const onChangeTemplate = data => {
    setAttributes({ template: data.id });
  };
  const renderInspectorControls = props => {
    const {
      attributes,
      setAttributes,
      latestPosts,
      isSelected,
      textColor,
      setTextColor
    } = props;
    const hasPosts = Array.isArray(latestPosts) && latestPosts.length;

    const {
      authors,
      specificPosts,
      postsToShow,
      categories,
      sectionHeader,
      columns,
      showImage,
      showCaption,
      imageScale,
      mobileStack,
      minHeight,
      moreButton,
      moreButtonText,
      showExcerpt,
      typeScale,
      showDate,
      showAuthor,
      showAvatar,
      showCategory,
      listTypeValue,
      listTypeDisplayValue,
      postLayout,
      mediaPosition,
      specificMode,
      tags,
      url,
      limit,
      offset,
      showHeaderSection,
      metaData
    } = attributes;

    const imageSizeOptions = [
      {
        value: 1,
        label: /* translators: label for small size option */ __(
          'Small',
          'newspack-blocks'
        ),
        shortName: /* translators: abbreviation for small size */ __(
          'S',
          'newspack-blocks'
        )
      },
      {
        value: 2,
        label: /* translators: label for medium size option */ __(
          'Medium',
          'newspack-blocks'
        ),
        shortName: /* translators: abbreviation for medium size */ __(
          'M',
          'newspack-blocks'
        )
      },
      {
        value: 3,
        label: /* translators: label for large size option */ __(
          'Large',
          'newspack-blocks'
        ),
        shortName: /* translators: abbreviation for large size */ __(
          'L',
          'newspack-blocks'
        )
      },
      {
        value: 4,
        label: /* translators: label for extra large size option */ __(
          'Extra Large',
          'newspack-blocks'
        ),
        shortName: /* translators: abbreviation for extra large size */ __(
          'XL',
          'newspack-blocks'
        )
      }
    ];
    const MAX_POSTS_COLUMNS = 6;
    return (
      <Fragment>
        <PanelBody
          title={__('Display Settings', 'newspack-blocks')}
          initialOpen
        >
          {postsToShow && (
            <QueryControls
              numberOfItems={postsToShow}
              onNumberOfItemsChange={value => {
                setAttributes({ postsToShow: value });
              }}
              specificMode={specificMode}
              onSpecificModeChange={value =>
                setAttributes({ specificMode: value })
              }
              specificPosts={specificPosts}
              onSpecificPostsChange={value =>
                setAttributes({ specificPosts: value })
              }
              authors={authors}
              onAuthorsChange={value => setAttributes({ authors: value })}
              categories={categories}
              onCategoriesChange={value => setAttributes({ categories: value })}
              tags={tags}
              onTagsChange={value => setAttributes({ tags: value })}
            />
          )}
          {!specificMode && (
            <ToggleControl
              label={__('Show "View More" Button', 'newspack-blocks')}
              checked={moreButton}
              onChange={() => setAttributes({ moreButton: !moreButton })}
              help={__(
                'Only available for non-AMP requests.',
                'newspack-blocks'
              )}
            />
          )}
          Select Post List
          <ActionForm
            actionTypes={action}
            formData={listTypeValue}
            onChange={data => {
              if (
                data.action !== undefined &&
                getDisplayValue(data.action) !== undefined
              ) {
                setAttributes({
                  metaData: getDisplayValue(data.action),
                  listTypeDisplayValue: getDisplayValue.display_value,
                  listTypeValue: data
                });
              }
            }}
          />
          Limit:
          <RangeControl
            onChange={limit => {
              setAttributes({ limit });
            }}
            value={limit}
            min={1}
            max={100}
            required
          />
          offset:
          <RangeControl
            onChange={offset => {
              setAttributes({ offset });
            }}
            value={offset}
            min={1}
            max={100}
            required
          />
          <ToggleControl
            label={__('Show Header Button', 'newspack-blocks')}
            checked={showHeaderSection}
            onChange={() =>
              setAttributes({ showHeaderSection: !showHeaderSection })
            }
          />
        </PanelBody>
        <PanelBody title={__('Featured Image Settings', 'newspack-blocks')}>
          <PanelRow>
            <ToggleControl
              label={__('Show Featured Image', 'newspack-blocks')}
              checked={showImage}
              onChange={() => setAttributes({ showImage: !showImage })}
            />
          </PanelRow>

          {showImage && (
            <PanelRow>
              <ToggleControl
                label={__('Show Featured Image Caption', 'newspack-blocks')}
                checked={showCaption}
                onChange={() => setAttributes({ showCaption: !showCaption })}
              />
            </PanelRow>
          )}

          {showImage && mediaPosition !== 'top' && mediaPosition !== 'behind' && (
            <Fragment>
              <PanelRow>
                <ToggleControl
                  label={__('Stack on mobile', 'newspack-blocks')}
                  checked={mobileStack}
                  onChange={() => setAttributes({ mobileStack: !mobileStack })}
                />
              </PanelRow>
              <BaseControl label={__('Featured Image Size', 'newspack-blocks')}>
                <PanelRow>
                  <ButtonGroup
                    aria-label={__('Featured Image Size', 'newspack-blocks')}
                  >
                    {imageSizeOptions.map(option => {
                      const isCurrent = imageScale === option.value;
                      return (
                        <Button
                          isLarge
                          isPrimary={isCurrent}
                          aria-pressed={isCurrent}
                          aria-label={option.label}
                          onClick={() =>
                            setAttributes({ imageScale: option.value })
                          }
                        >
                          {option.shortName}
                        </Button>
                      );
                    })}
                  </ButtonGroup>
                </PanelRow>
              </BaseControl>
            </Fragment>
          )}

          {showImage && mediaPosition === 'behind' && (
            <RangeControl
              label={__('Minimum height', 'newspack-blocks')}
              help={__(
                "Sets a minimum height for the block, using a percentage of the screen's current height.",
                'newspack-blocks'
              )}
              value={minHeight}
              onChange={value => setAttributes({ minHeight: value })}
              min={0}
              max={100}
              required
            />
          )}
        </PanelBody>
        <PanelBody title={__('Post Control Settings', 'newspack-blocks')}>
          <PanelRow>
            <ToggleControl
              label={__('Show Excerpt', 'newspack-blocks')}
              checked={showExcerpt}
              onChange={() => setAttributes({ showExcerpt: !showExcerpt })}
            />
          </PanelRow>
          <RangeControl
            className="type-scale-slider"
            label={__('Type Scale', 'newspack-blocks')}
            value={typeScale}
            onChange={value => setAttributes({ typeScale: value })}
            min={1}
            max={10}
            beforeIcon="editor-textcolor"
            afterIcon="editor-textcolor"
            required
          />
        </PanelBody>
        {/* <PanelColorSettings
          title={__('Color Settings', 'newspack-blocks')}
          initialOpen
          colorSettings={[
            {
              // value: textColor.color,
              // onChange: setTextColor,
              label: __('Text Color', 'newspack-blocks')
            }
          ]}
        /> */}
        <PanelBody title={__('Post Meta Settings', 'newspack-blocks')}>
          <PanelRow>
            <ToggleControl
              label={__('Show Date', 'newspack-blocks')}
              checked={showDate}
              onChange={() => setAttributes({ showDate: !showDate })}
            />
          </PanelRow>
          <PanelRow>
            <ToggleControl
              label={__('Show Category', 'newspack-blocks')}
              checked={showCategory}
              onChange={() => setAttributes({ showCategory: !showCategory })}
            />
          </PanelRow>
          <PanelRow>
            <ToggleControl
              label={__('Show Author', 'newspack-blocks')}
              checked={showAuthor}
              onChange={() => setAttributes({ showAuthor: !showAuthor })}
            />
          </PanelRow>
          {showAuthor && (
            <PanelRow>
              <ToggleControl
                label={__('Show Author Avatar', 'newspack-blocks')}
                checked={showAvatar}
                onChange={() => setAttributes({ showAvatar: !showAvatar })}
              />
            </PanelRow>
          )}
        </PanelBody>
      </Fragment>
    );
  };

  const {
    authors,
    specificPosts,
    postsToShow,
    categories,
    sectionHeader,
    columns,
    showImage,
    showCaption,
    imageScale,
    mobileStack,
    minHeight,
    moreButton,
    moreButtonText,
    showExcerpt,
    typeScale,
    showDate,
    showAuthor,
    showAvatar,
    showCategory,
    postLayout,
    mediaPosition,
    specificMode,
    tags,
    url,
    template,
    showHeaderSection,
    limit,
    offset
  } = attributes;
  const blockControls = [
    {
      icon: 'list-view',
      title: __('List View', 'newspack-blocks'),
      onClick: () => setAttributes({ postLayout: 'list' }),
      isActive: postLayout === 'list'
    },
    {
      icon: 'slides',
      title: __('Carousel View', 'newspack-blocks'),
      onClick: () => setAttributes({ postLayout: 'carousel' }),
      isActive: postLayout === 'carousel'
    },
    {
      icon: 'editor-insertmore',
      title: __('Scroller View', 'newspack-blocks'),
      onClick: () => setAttributes({ postLayout: 'scroller' }),
      isActive: postLayout === 'scroller'
    }
  ];

  let postsList = props.data || [];
  let finalOffset = parseInt(offset);
  finalOffset = finalOffset === 0 ? 1 : finalOffset;
  postsList = postsList.slice(finalOffset, finalOffset + parseInt(limit));

  const itemTemplate =
    attributes.className && attributes.className.replace('is-style-', '');
  const containerClassName = {
    scroller: 'template-seven-container scrolling-content'
  };
  return (
    <div>
      <BlockControls>
        <Toolbar controls={blockControls} />
      </BlockControls>
      <InspectorControls>{renderInspectorControls(props)}</InspectorControls>
      <div className={className}>
        <div className="d-flex justify-content-between p-2">
          {showHeaderSection && (
            <RichText
              allowedFormats={[]}
              onChange={value => setAttributes({ sectionHeader: value })}
              placeholder={__('Write header…', 'newspack-blocks')}
              value={sectionHeader}
              tagName="h6"
              className="my-auto"
            />
          )}
          {moreButton && (
            <div className="btn btn-light btn-sm">
              <RichText
                onChange={value => setAttributes({ moreButtonText: value })}
                placeholder={__('View More Button Title', 'newspack-blocks')}
                value={moreButtonText}
                tagName="small"
              />
            </div>
          )}
        </div>
        {postLayout && postLayout === 'carousel' && (
          <Carousel
            postsList={postsList}
            {...props}
            itemTemplate={itemTemplate}
          />
        )}
        {postLayout && postLayout !== 'carousel' && (
          <div className={containerClassName[postLayout] || ''}>
            {postsList.map((item, key) => {
              const postItem = convertPostAppmaker(item);
              return (
                <SinglePostItem
                  key={`post-item${key}`}
                  itemTemplate={itemTemplate}
                  featureImageSrc={showImage && postItem.featured_media}
                  userImgSrc={showAuthor && showAvatar && postItem.authorImage}
                  author={showAuthor && postItem.author}
                  date={showDate && postItem.date}
                  category={showCategory && postItem.category}
                  title={postItem.title}
                  excerpt={showExcerpt && postItem.excerpt}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
let postListResponse;

class PostListEditContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  async componentDidMount() {
    const { project } = this.props;
    this.setState({ isLoading: true });
    if (postListResponse === undefined) {
      try {
        const response = await getPostListData(project.id);
        if (response && response.body) this.setState({ data: response.body });
      } catch (error) {
        this.setState({ data: postListResponse.body });
      }
    } else {
      this.setState({ data: postListResponse.body });
    }
  }

  render() {
    const { data } = this.state;
    return <PostListEdit data={data} {...this.props} {...this.state} />;
  }
}

export default withSelect(select => {
  const project = select('appmaker/core').getCurrentProject();
  return { project };
})(PostListEditContainer);
