import React from 'react';

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <g id="grid-gutIcon" transform="translate(-13035 -6799)">
      <rect id="Rectangle_554" data-name="Rectangle 554" width="24" height="24" transform="translate(13035 6799)" fill="none" />
      <g id="Group_392" data-name="Group 392" transform="translate(-0.678 0.322)">
        <rect id="Rectangle_555" data-name="Rectangle 555" width="11" height="11" rx="2" transform="translate(13036.678 6799.678)" fill="#4d6ffb" />
        <rect id="Rectangle_558" data-name="Rectangle 558" width="11" height="10" rx="2" transform="translate(13036.678 6811.678)" fill="#6d65fe" />
        <rect id="Rectangle_556" data-name="Rectangle 556" width="10" height="11" rx="2" transform="translate(13048.678 6799.678)" fill="#6d65fe" />
        <rect id="Rectangle_557" data-name="Rectangle 557" width="10" height="10" rx="2" transform="translate(13048.678 6811.678)" fill="#6281fe" />
      </g>
    </g>
  </svg>
);
