import React from 'react';
import '../style.css';

const EmailFooterPreview = ({ iconUrl, title, description }) => {
  return (
    <div className="email-footer-preview-container border border-light rounded py-4 text-center bg-light">
      <img
        className="apmkr-img"
        src={
          iconUrl ||
          'https://appilder_cdn.storage.googleapis.com/app_files/2230c0510a725ec54fb9f41f4e1eb18b/appicon_ios_android.png_150x150.png'
        }
        width="120px"
        alt="icon"
      />
      <h5 className="mt-3 mb-4">{title || 'App Name'}</h5>
      <p>{description || 'Now available on iOS and Android'}</p>
      <span className="d-flex justify-content-center">
        <a href=""><img src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2020/04/4ba0c3f6-google-play-badge.png" alt="" width="150px" /></a>
        <a href=""><img src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2020/04/d0648a50-app-store-badge.png" alt="" width="150px" /></a>
      </span>
    </div>
  );
};

export default EmailFooterPreview;
