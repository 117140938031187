const DEFAULT_STATE = {
  inAppPagesList: [],
  inAppPages: {},
  currentPage: undefined,
  language: 'default'
};
export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case 'SET_IN_APP_PAGE':
      return {
        ...state,
        inAppPages: {
          ...state.inAppPages,
          [action.key]: action.inAppPage
        }
      };
    case 'SET_IN_APP_PAGE_LIST':
      return {
        ...state,
        inAppPagesList: action.inAppPageList
      };
    case 'SET_CURRENT_PAGE': {
      return {
        ...state,
        currentPage: action.key
      };
    }
    case 'SET_LANGUAGE_KEY': {
      return {
        ...state,
        language: action.key
      };
    }

    default:
      break;
  }
  return state;
};
