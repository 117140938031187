import actions from './action';

export function* getInAppPageList() {
  const path = `in-app-pages/`;
  const inAppPageList = yield actions.fetchFromAPI(path);
  return actions.setInAppPageList(inAppPageList);
}
export function* getInAppPage(key) {
  const path = `in-app-pages/${key}/`;
  const inAppPage = yield actions.fetchFromAPI(path);
  yield actions.setCurrentPage(key);
  if (inAppPage && inAppPage.language) {
    yield actions.setCurrentLanguage(inAppPage.language);
  }
  return actions.setInAppPage(key, inAppPage);
}

getInAppPage.shouldInvalidate = (action, kind, name) => {
  return action.type === 'SET_IN_APP_PAGE';
};
