import React from 'react';

export default ({ className, buttonLabel, loginUrl }) => {
  return (
    <div>
      <a href={loginUrl} className={className} target="_blank">
        {buttonLabel}
      </a>
    </div>
  );
};
