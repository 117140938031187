import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import CONSTANT from '../../lib/constants';

export default function ({
  project,
  match,
  isExpired: expired,
  component: Component,
  ...otherProps
}) {
  if (
    expired &&
    !otherProps.isAdmin &&
    project.status === CONSTANT.ACCOUNT_SUSPENDED
  ) {
    return (
      <Route
        {...otherProps}
        render={props => {
          return <Redirect push to={`/apps/${project.id}/suspended`} />;
        }}
      />
    );
  }
  if (!expired || otherProps.isAdmin) {
    return (
      <Route
        {...otherProps}
        render={props => {
          return <Component project={project} {...otherProps} {...props} />;
        }}
      />
    );
  }
  return (
    <Route
      {...otherProps}
      render={props => {
        return (
          <Redirect push to={`/apps/${project.id}/subscription-expired`} />
        );
      }}
    />
  );
}
