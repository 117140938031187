import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle
} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withToastManager } from 'react-toast-notifications';
import UploadAuthKey from './components/UploadAuthKey';
import InstructionsStep from './components/InstructionsStep';
const AppStoreInstructions = forwardRef(
  ({ toastManager, appId, showIpaUpload, buttonLabel, className }, ref) => {
    const [modal, setModal] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [currentStep, setStep] = useState(1);
    const authUploadRef = useRef(null);

    useImperativeHandle(ref, () => ({
      toggle() {
        setModal(!modal);
      }
    }));

    function toggle() {
      setModal(!modal);
    }

    function next() {
      setStep(currentStep + 1);
    }
    function prev() {
      const step = currentStep <= 1 ? 1 : currentStep - 1;
      setStep(step);
    }
    function onFinished() {
      toggle();
      showIpaUpload();
    }
    function onSetIsUploading(value = true) {
      setIsUploading(value);
    }
    function previousButton() {
      if (currentStep !== 1) {
        return (
          <Button size="sm" className="px-4" color="secondary" onClick={prev}>
            <i className="fas fa-angle-left" /> Back
          </Button>
        );
      }
      return null;
    }

    function nextButton() {
      if (currentStep < 2) {
        return (
          <Button size="sm" className="px-4" color="primary" onClick={next}>
            Next <i className="fas fa-angle-right" />
          </Button>
        );
      }
      if (currentStep === 2) {
        return (
          <Button
            size="sm"
            className="px-4"
            color="success"
            onClick={() => {
              authUploadRef.current.submit();
            }}
          >
            {isUploading ? 'Loading ...' : 'Build'}
          </Button>
        );
      }
      return null;
    }

    return (
      <React.Fragment>
        <Modal isOpen={modal} toggle={toggle} className={className} scrollable>
          <ModalHeader className="border-bottom-0 shadow-sm" toggle={toggle}>
            <h5 className="font-weight-bold ml-3 mb-3">
              Generate certificate and provisioning profile
            </h5>
            <div className="d-flex small-label-text">
              <div
                className={`border px-3 mx-3 rounded-pill bg-primary text-white instruction-step${
                  currentStep === 1 ? 'active-instruction-step' : ''
                }`}
              >
                Step 1
              </div>
              <div
                className={`border px-3 mx-3 rounded-pill bg-primary text-white instruction-step${
                  currentStep === 2 ? 'active-instruction-step' : ''
                }`}
              >
                Step 2
              </div>
            </div>
          </ModalHeader>
          <ModalBody className="p-3 position-relative">
            {/* <h5 className="font-weight-bold">Generating Release Build for App Store</h5> */}
            {currentStep === 1 && <InstructionsStep />}
            {currentStep === 2 && (
              <UploadAuthKey
                ref={authUploadRef}
                appId={appId}
                toastManager={toastManager}
                finished={onFinished}
                setIsUploading={onSetIsUploading}
              />
            )}
          </ModalBody>
          <ModalFooter>
            {previousButton()}
            {nextButton()}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
);

export default withToastManager(AppStoreInstructions);
