import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip } from 'reactstrap';

export default function PluginInstall({ project, response }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <React.Fragment>
      <div className="bg-light rounded p-3">
        <h5 className="mb-4">
          <i className="fas fa-puzzle-piece mr-2 text-purple" />
          Connect store automatically
        </h5>
        <div className="text-center">
          <a
            className="btn btn-purple"
            target="_blank"
            href={`${response.url}/wp-admin/plugin-install.php?tab=plugin-information&plugin=appmaker-woocommerce-mobile-app-manager`}
          >
            Connect
            <img
              src="https://storage.googleapis.com/stateless-appmaker-pages-wp//2020/02/woocommerce-1.svg"
              width="35px"
              className="mx-1"
              alt="WooCommerce"
            />
            Store
          </a>
        </div>
      </div>
      <div className="text-center my-3 seperation-line">
        <span>OR</span>
      </div>
      <div className="bg-light rounded p-3">
        <h5 className="mb-4">
          <i className="fas fa-puzzle-piece mr-2 text-purple" />
          Install and activate plugin manually
        </h5>
        <span className="setup-steps mb-3">
          <p className="text-blue mb-1">
            <i className="fas fa-hand-point-right mr-2" />
            Step 1
          </p>
          <p>
            Go to Your &nbsp;
            <b>WordPress admin</b>
            &gt;
            <b>Plugins</b>
            &gt;
            <b>Add New</b>
          </p>
        </span>
        <span className="setup-steps mb-3">
          <p className="text-blue mb-1">
            <i className="fas fa-hand-point-right mr-2" />
            Step 2
          </p>
          <p>
            Search for{' '}
            <b>
              Appmaker WooCommerce
              <CopyToClipboard text="Appmaker WooCommerce">
                <button type="button" className="btn btn-link" id="CopyTooltip">
                  <i className="far fa-copy" />
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen}
                    target="CopyTooltip"
                    toggle={toggle}
                  >
                    Copy
                  </Tooltip>
                </button>
              </CopyToClipboard>
            </b>
          </p>
        </span>
        <span className="setup-steps">
          <p className="text-blue mb-1">
            <i className="fas fa-hand-point-right mr-2" />
            Step 3
          </p>
          <p className="mb-0">Click install and activate plugin</p>
        </span>
      </div>
    </React.Fragment>
  );
}
