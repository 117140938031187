import React, { useEffect, useState } from 'react';
import { withSelect } from '@wordpress/data';
import { BallBeat } from 'react-pure-loaders';

import { API_URL, linkToManageApp } from '../../../constants/routes';
import SubscriptionData from './SubscriptionData';

const Subscriptions = ({ projectId, history }) => {
  const [loading, setLoading] = useState(true);
  const [hasChargebee, setHasChargebee] = useState(true);

  useEffect(() => {
    setLoading(true);
    const cbInstance = window.Chargebee.init({
      site: process.env.NODE_ENV === 'production' ? 'appmaker' : 'appmaker-test'
    });

    fetch(`${API_URL}/v2/payment/${projectId}/chargebee-portal-session`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(portalSession => {
        setLoading(false);
        if (portalSession.status !== false) {
          setHasChargebee(true);
          cbInstance.setPortalSession(portalSession.portal_session);
          const cbPortal = cbInstance.createChargebeePortal();
          cbPortal.open({
            close() {
                history.push(linkToManageApp(projectId));
            }
          });
        } else {
         setHasChargebee(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, [projectId]);

  return (
    <React.Fragment>
      {loading && (
        <div className="wrap overflow-auto pr-3 d-flex justify-content-center align-items-center">
          <BallBeat color="#123abc" loading />
        </div>
      )}
      {!hasChargebee && <SubscriptionData />}
    </React.Fragment>
  );
};

export default withSelect(select => {
  const project = select('appmaker/core').getCurrentProject();
  return { projectId: project.id };
})(Subscriptions);
