/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withSelect } from '@wordpress/data';
import SettingsNavbar from './layout/SettingsNavbar';
import SettingsView from './layout/SettingsView';
import { getAppSettings } from '../../../lib/apiFunctions';
import PreLoading from '../layout/PreLoading';

class SettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isLoading: false,
      data: ''
    };
    this.childHandler = this.childHandler.bind(this);
    // this.callbackFunction = this.callbackFunction.bind(this);
  }

  childHandler(dataFromChild) {
    if (dataFromChild.status === true) this.loadData();
  }

  async componentDidMount() {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    this.setState({ isLoading: true });
    try {
      const response = await getAppSettings(id);
      this.setState({ data: response, isLoading: false });
    } catch (error) {
      console.log(error); // TODO Handle error
    }
  }

  render() {
    const {
      match: {
        params: { id, tab }
      }
    } = this.props;
    const { data, isLoading } = this.state;
    const { user = {} } = this.props;
    if (isLoading) return <PreLoading single />;
    return (
      <div className="row wrap">
        <Helmet>
          <title>App Settings - Appmaker dashboard </title>
        </Helmet>
        <div className="col-md-3 bg-white">
          {data !== '' && !isLoading && (
            <SettingsNavbar projectId={id} data={data} />
          )}
        </div>
        <div className="col-md-9 wrap overflow-auto">
          {data !== '' && !isLoading && (
            <SettingsView
              appId={id}
              tab={tab}
              {...this.state}
              actions={this.childHandler}
              email={user.email}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withSelect((select, props, withSelectProps) => {
  const user = select('appmaker/core').getUser();
  return { user };
})(SettingsPage);
