import React, { useState, useEffect } from 'react';
import { getAllProject } from '../../../../lib/pushAutomationApi';

export default ({ accessToken, id, passProjects = () => {} }) => {
  const [isLoading, setLoading] = useState(false);

  const fetchFirebaseData = async () => {
    setLoading(true);
    try {
      const response = await getAllProject({ accessToken, id });
      if (response.status && response.body && response.body.projects) {
        const filterProjects = response.body.projects.filter(
          project => project.lifecycleState === 'ACTIVE'
        );
        if (filterProjects.length > 0) {
          passProjects(filterProjects);
        }
      }
    } catch (error) {}
    setLoading(false);
  };
  useEffect(() => {
    if (accessToken) {
      setInterval(() => {
        fetchFirebaseData();
      }, 15000);
    }
  }, []);
  return (
    <div className="p-4 text-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        className="mb-3"
      >
        <path
          d="M6.92285 39.6861L12.8886 1.71842C13.0535 0.677455 14.4556 0.430912 14.9504 1.3623L21.1086 12.8129L23.5553 8.15594C23.7445 7.79293 24.1208 7.56512 24.5313 7.56512C24.9418 7.56512 25.3181 7.79293 25.5072 8.15594L42.1122 39.6861H6.92285Z"
          fill="#FFA000"
        />
        <path
          d="M27.4866 24.8935L21.0811 12.8129L6.92285 39.6861L27.4866 24.8935Z"
          fill="#F57C00"
        />
        <path
          d="M42.1122 39.6861L37.5486 11.5528C37.4803 11.1467 37.1907 10.8124 36.7976 10.6856C36.4045 10.5589 35.9732 10.6609 35.6792 10.9501L6.92285 39.6861L22.8405 48.5891C23.8422 49.137 25.0554 49.137 26.0571 48.5891L42.1122 39.6861Z"
          fill="#FFCA28"
        />
      </svg>
      <h3>Create a new Firebase Project</h3>
      <p>
        It seems you dont have created a project in firebase console. Please
        create firebase project to get started.
        <a
          href="https://appmaker.xyz/en/docs/how-to-setup-firebase-account/"
          target="_blank"
          rel="noopener noreferrer"
          className="ml-2"
        >
          Learn more
        </a>
      </p>
      <a
        href="https://console.firebase.google.com/"
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-primary my-3"
      >
        Go to Firebase Console
      </a>
      <br />
      {accessToken && (
        <button
          type="button"
          className="btn btn-link"
          disabled={isLoading}
          onClick={() => {
            fetchFirebaseData();
          }}
        >
          <i className={`fas fa-sync-alt ${isLoading && 'fa-spin'} mr-2`} />
          {isLoading ? 'Loading....' : 'Refresh project list'}
        </button>
      )}
    </div>
  );
};
