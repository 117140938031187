const REDUCER_KEY = 'wordpress';
const coreActions = [
  {
    id: 'OPEN_INAPP_PAGE',
    title: 'Open In-App pages',
    template: 'common',
    subAction: {
      id: 'id',
      title: 'Choose Page',
      type: 'select',
      dataSource: {
        kind: 'root',
        name: 'inAppPageList',
        store: 'core'
      }
    }
  },
  {
    id: 'OPEN_CATEGORY',
    title: 'Open Post category',
    template: 'wordpress',
    subAction: {
      id: 'PRODUCT_ID',
      title: 'Choose category',
      type: 'select',
      dataSource: {
        kind: 'root',
        name: 'post_category',
        link: 'category',
        store: REDUCER_KEY
      }
    }
  },
  {
    id: 'OPEN_POST_PAGE',
    title: 'Open Post/Page',
    template: 'wordpress',
    subAction: {
      id: 'PRODUCT_ID',
      title: 'Choose Post/Page',
      type: 'select',
      dataSource: {
        kind: 'root',
        name: 'post_page',
        link: 'posts',
        store: REDUCER_KEY
      }
    }
  },
  {
    id: 'OPEN_TAG',
    title: 'Open Tag',
    template: 'wordpress',
    subAction: {
      id: 'ID',
      title: 'Choose tag',
      type: 'select',
      dataSource: {
        kind: 'root',
        name: 'tag',
        link: 'tag',
        store: REDUCER_KEY
      }
    }
  },
  { id: 'NO_ACTION', title: 'No Action', template: 'wordpress' }
];
export default coreActions;
