import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import DownloadWidget from './components/DownloadWidget';
import ApiCredential from './components/ApiCredential';
import ViewSubscriptionWidget from './components/ViewSubscriptionWidget';
// import {
//   getApiForDataByProjectId,
//   getSalesData
// } from '../../../lib/apiFunctions';
// import PreLoading from '../layout/PreLoading';
// import CONSTANT from '../../../lib/constants';
import SaleDataWidget from './components/SaleDataWidget';
import DashboardNotice from '../MessageScreens/dashboardNotice';

// class DashboardMain extends Component {
// constructor(props) {
//   super(props);
//   this.state = {
//     loading: true,
//     project: {},
//     message: ''
//   };
// }

// componentDidMount() {
//   this.loadData();
// }

// loadData() {
//   const {
//     match: {
//       params: { id }
//     }
//   } = this.props;
//   getApiForDataByProjectId(id)
//     .then(response => response.json())
//     .then(response => {
//       this.setState({
//         project: response.project,
//         message: response.message,
//         loading: false
//       });
//     })
//     .catch(console.error);
// }

export default function DashboardMain({ project, location }) {
  // const { project, location } = this.props;
  if (project === undefined || project === false || project === null)
    return <div>Unable to load project</div>;

  const { meta, template, paymentStatus } = project;
  // if (loading) return <PreLoading fullpage />;
  return (
    <div className="wrap overflow-auto pr-3">
      <div>
        {location && location.search === '?already_exists=true' && (
          <DashboardNotice storeUrl={meta.URL} projectId={project.id} />
        )}
        <DownloadWidget project={project} />
        <Row>
          <Col>
            <ViewSubscriptionWidget project={project} />
          </Col>
          {template === 'woocommerce' && paymentStatus === '5' && (
            <Col xs="12" sm="12" md="8" lg="8">
              <SaleDataWidget projectId={project.id} url={meta.URL} />
            </Col>
          )}
        </Row>
        <ApiCredential
          projectId={project.id}
          packageName={meta ? meta.packagename : undefined}
          apiKey={meta ? meta.API_KEY : undefined}
          apiSecret={meta ? meta.API_SECRET : undefined}
        />
      </div>
    </div>
  );
}
// }

// export default DashboardMain;
