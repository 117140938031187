// NOTE  Inner block items should be placed along with the parent item to allow in template
export default () => {
  return [
    'appmaker/banner',
    'appmaker/grid',
    'appmaker/grid-item',
    'appmaker/menu',
    'appmaker/menu-item',
    'appmaker/slider',
    'appmaker/slider-item',
    'appmaker/html',
    'appmaker/imagescroller',
    'appmaker/imagescroller-item',
    'appmaker/post-list',
    'appmaker/schedule',
    'appmaker/profile-card',
    'appmaker/profile-card-item',
    'appmaker/feed',
    'appmaker/feed-item',
    'appmaker/single-post-list',
    'appmaker/tab',
    'appmaker/tab-item'
  ];
};
