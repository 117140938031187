import { registerStore } from '@wordpress/data';
import reducer from './reducer';
import * as selectors from './selectors';
import * as actions from './actions';
import * as controls from './controls';
import * as resolvers from './resolvers';

registerStore('appmaker/core', {
  actions,
  selectors,
  reducer,
  controls,
  resolvers
});
