import React, { Component,useState } from 'react';

import { withSelect } from '@wordpress/data';
import { addFilter } from '@wordpress/hooks';

import ImageModal from '../../components/mediaLibrary';
import AppmakerMediaPlaceholder from './AppmakerMediaPlaceholder';

class MediaUpload extends Component {
  constructor(props) {
    super(props);
    this.state = { isVisible: false };
    this.openPopover = this.openPopover.bind(this);
    this.closePopover = this.closePopover.bind(this);
  }

  openPopover() {
    this.setState({ isVisible: true });
  }

  closePopover() {
    this.setState({ isVisible: false });
  }

  render() {
    const { onSelect = false } = this.props;
    const { isVisible } = this.state;

    return (
      <>
        {isVisible && (
          <ImageModal
            onClose={this.closePopover}
            buttonLabel="Image upload"
            onSelect={onSelect}
          />
        )}
        {this.props.render({ open: this.openPopover })}
      </>
    );
  }
}



const replaceMediaUpload = () =>
  withSelect(select => ({
    // mediaLibrary: select('core/editor').getEditorSettings().mediaLibrary,
    mediaLibrary: true
  }))(MediaUpload);

addFilter(
  'editor.MediaUpload',
  'core/edit-post/components/media-upload/replace-media-upload',
  replaceMediaUpload
);

const replaceMediaPlaceholder = () =>
  withSelect(select => ({
    // mediaLibrary: select('core/editor').getEditorSettings().mediaLibrary,
    mediaLibrary: true,
    mediaUpload: () => {}
  }))(AppmakerMediaPlaceholder);

addFilter('editor.MediaPlaceholder', 'editor-app', replaceMediaPlaceholder);
