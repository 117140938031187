import React from 'react';
import Helmet from 'react-helmet';

import InAppPageEditor from '../../InAppPageEditor';

/** const CustomizeApp = ({
  match: {
    params: { inAppPageId = 'home' }
  }
}) => (
  <React.Fragment>
    <CustomizerTopbar />

    <InAppPageEditor inAppPageId={inAppPageId} /> */
const CustomizeApp = props => {
  const {
    match: {
      params: { inAppPageId = 'home' }
    },
    history,
    pageNameChange,
    newPage = false
  } = props;
  return (
    <React.Fragment>
      <Helmet>
        <title>In-App Pages - Appmaker dashboard</title>
      </Helmet>
      {/* <CustomizerTopbar /> */}
      <InAppPageEditor
        inAppPageId={inAppPageId}
        history={history}
        pageNameChange={pageNameChange}
        newPage={data => {
          if (newPage !== false) {
            newPage(data);
          }
        }}
      />
    </React.Fragment>
  );
};
export default CustomizeApp;
