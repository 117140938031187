import Select from 'react-select';
import React from 'react';
import { Input } from 'reactstrap';
import { encode, decode } from 'base-64';
// widget for select box with multiple values
export const selectMulti = (id, defaultValue, options) => {
  /* sample input of the value data: 
    data={
        options:[{value:value1 , label:label1}, {value:value2 , label:label2}]
    }
    */
  const selectMultiWidget = {
    'ui:widget': props => {
      return (
        <div>
          <Select
            name={id}
            defaultValue={defaultValue}
            options={options}
            isMulti
            onChange={event => {
              // to change the input of the multi-select to the required api
              const returnArray = [];
              event.forEach(element => {
                // returnArray.push({ id: element.value, label: element.label });
                returnArray.push(element.value);
              });
              props.onChange(returnArray);
            }}
          />
        </div>
      );
    }
  };
  return selectMultiWidget;
};

export const textAreaWithEncoding = (id, defaultValue) => {
  return {
    'ui:widget': props => {
      return (
        <div>
          <Input
            type="textarea"
            name={id}
            id="exampleText"
            value={
              props.value !== undefined
                ? decode(props.value)
                : decode(props.schema.default)
            }
            onChange={event => {
              props.onChange(encode(event.target.value));
            }}
          />
        </div>
      );
    }
  };
};
export const labelWid = () => {
  const labelWidget = {
    'ui:widget': () => {
      return <div />;
    }
  };
  return labelWidget;
};
