import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { withSelect } from '@wordpress/data';
import compareVersions from 'compare-versions';

import SmartBannerPreview from './components/SmartBannerPreview';
import SmartBannerDetails from './components/SmartBannerDetails';

import { getAppSettings, updateAppSetting } from '../../../../lib/apiFunctions';
import { trackEvent } from '../../../../utils/tracker';

const SmartBanner = ({
  project,
  pluginResponse: { version },
  user: { email }
}) => {
  const [settings, setSettings] = useState({});
  const [loading, setLoading] = useState(true);

  async function updateSettings(updatedSettings) {
    setSettings(updatedSettings);
  }

  async function saveSettings(updatedSettings) {
    try {
      trackEvent(
        'promote_app_saved',
        {
          project_id: project.id,
          email,
          type: 'smart_banner',
          state: updatedSettings.smart_banner_app_store_enabled
            ? 'enabled'
            : 'disabled'
        },
        {
          mautic: true
        }
      );
    } catch (error) {}
    await updateAppSetting(project.id, updatedSettings);
  }

  useEffect(() => {
    async function loadSettings() {
      setLoading(true);
      const settingsResponse = await getAppSettings(project.id);
      const {
        smart_banner_app_store_enabled = false,
        smart_banner_app_store_url = '',
        smart_banner_play_store_url = '',
        smart_banner_logo_url = project.icon,
        smart_banner_title = project.name,
        smart_banner_description = '',
        smart_banner_cta_text = 'Download',
        force_smart_banner = false,
        limit_smart_banner_preview = ''
      } = settingsResponse.values;

      setSettings({
        smart_banner_app_store_enabled,
        smart_banner_app_store_url,
        smart_banner_play_store_url,
        smart_banner_logo_url,
        smart_banner_title,
        smart_banner_description,
        smart_banner_cta_text,
        force_smart_banner,
        limit_smart_banner_preview
      });
      setLoading(false);
    }
    loadSettings();
  }, [project]);

  return (
    <div className="row wrap overflow-auto">
      <Helmet>
        <title>Smart banner - Appmaker dashboard </title>
      </Helmet>
      <div className="col-md-6 col-12">
        <div className="bg-white p-4 rounded-lg dashboard-box">
          {compareVersions(version, '1.25.3') > -1 ? (
            <SmartBannerDetails
              updateSettings={updateSettings}
              saveSettings={saveSettings}
              settings={settings}
              loading={loading}
            />
          ) : (
            <div className="text-center py-5">
              <h4>
                <i className="fas fa-exclamation-triangle mr-2 text-primary" />
              </h4>
              <h5>Please update plugin to 1.25.3</h5>
              <a
                href={`${project.meta.URL}wp-admin/plugin-install.php?tab=plugin-information&plugin=appmaker-woocommerce-mobile-app-manager`}
                className="btn btn-primary mt-4"
                target="_blank"
              >
                Update plugin
              </a>
            </div>
          )}
        </div>
        <div className="bg-white p-4 rounded-lg dashboard-box my-3">
          <h6>
            <i className="fas fa-info-circle mr-2" /> Smart banner
          </h6>
          <img
            src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2020/04/ce140b0b-appflyer.png"
            alt="info"
            className="img-fluid my-3"
          />
          <p>
            On a mobile site, a smart banner is a banner at the top of the
            screen that presents an option to download or open the app if the
            user chooses. It contains a smart link that includes all the routing
            logic to automatically open up the app when it’s installed or fall
            back to an App Store page if not.
          </p>
          <p>
            At their core, smart banners use a fraction of the screen on a given
            mobile website to inform and encourage users to open the native app,
            or install it if they don’t if they don’t have it. Converting your
            mobile web traffic into native mobile app users is more important
            than ever, as apps have been shown to carry 285% more products
            viewed, 120% more purchases, and 11% more value per customer
            purchase than their mobile web counterparts
          </p>
        </div>
      </div>
      <div className="col-md-6 col-12">
        <div className="bg-white p-4 rounded-lg dashboard-box">
          <h6 className="text-muted text-uppercase">Preview</h6>
          <SmartBannerPreview
            iconUrl={settings.smart_banner_logo_url}
            title={settings.smart_banner_title}
            description={settings.smart_banner_description}
            btnContent={settings.smart_banner_cta_text}
          />
        </div>
      </div>
    </div>
  );
};

export default withSelect(select => {
  const project = select('appmaker/core').getCurrentProject();
  const user = select('appmaker/core').getUser();
  const pluginResponse = select(
    'appmaker/core'
  ).getCurrentProjectPluginResponse();
  return { project, pluginResponse, user: user || {} };
})(SmartBanner);
