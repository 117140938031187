import React, { useState } from 'react';
import './style.css';
import ChoosePlan from '../subscriptionData/choosePlan';
import constants from '../../../lib/constants';

const ChatWidget = ({
  feature = {},
  project: { paymentStatus, id, template, configure: { highValue: isHighValue = false } }
}) => {
  const { disableIntercomChat = false } = feature;
  const [show, setShow] = useState(false);
  const paymentRef = React.createRef();
  // const disableIntercomChat = true;
  if (
    !disableIntercomChat ||
    isHighValue ||
    paymentStatus === constants.PROJECT_PAYMENT_TRIAL ||
    paymentStatus === constants.PROJECT_PAYMENT_EXPIRED ||
    paymentStatus === constants.PROJECT_PAYMENT_EXPIRED_COMPLETE
  ) {
    if (window.Intercom) {
      window.Intercom('update', {
        hide_default_launcher: false
      });
    }
    return null;
  }
  return (
    <React.Fragment>
      <div className="appmaker-chat-widget">
        <button type="button" onClick={() => setShow(!show)}>
          <i className="fas fa-comment-alt text-white" />
        </button>
        {show && (
          <div className="widget-chat-screen">
            <div className="p-3 bg-primary">
              <h3 className="mb-0 text-white">Hi</h3>
            </div>
            <div className="p-3 bg-white overflow-auto">
              <div className="bg-light text-center rounded-lg py-4 shadow-sm mb-2">
                <img
                  src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2021/03/744a2360-customer-service-1-1.png"
                  alt="support"
                  className="img-fluid w-25 mb-2"
                />
                <h5>Create a support ticket</h5>
                <p>
                  <small>
                    Create a ticket with our support team get assistance from
                    appmaker team.
                  </small>
                </p>
                <a
                  id="dashboard-chat-freshdesk-link"
                  href="https://appmaker.freshdesk.com/support/tickets/new"
                  className="btn btn-primary text-white"
                  target="_blank"
                >
                  <i className="fas fa-paper-plane mr-2" />
                  Create a ticket
                </a>
              </div>
              <div className="bg-dark text-center rounded-lg py-4 shadow-sm">
                <h5 className="text-white mb-4">Live chat with us</h5>
                <input
                  onClick={() => {
                    paymentRef.current.toggle();
                    setShow(false);
                  }}
                  className="btn btn-light"
                  target="_blank"
                  type="button"
                  value="Upgrade plan"
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        <ChoosePlan
          template={template}
          showPaymentButton={false}
          projectId={id}
          ref={paymentRef}
        />
      </div>
    </React.Fragment>
  );
};

export default ChatWidget;
