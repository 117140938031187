import React, { useState, useRef } from 'react';

import { Button } from 'reactstrap';

import ChoosePlan from '../../subscriptionData/choosePlan';
import CreateStoreAccount from './CreateStoreAccount';
import AuthKeyUpload from './AuthKeyUpload';
import IPAUpload from './IPAUpload.js';
import { linkToPaymentPage } from '../../../../constants/routes';


const IOSInstructions = ({ project, app }) => {
  let paymentRef = React.createRef();
  const { paymentStatus, id } = project;

  function redirectToSubscription() {
    if (project.template === 'woocommerce') {
      paymentRef.current.toggle();
    } else {
      window.location.href = linkToPaymentPage();
    }
  }


  const createStoreAccountRef = useRef();
  const authKeyUploadRef = useRef();
  const ipaUploadRef = useRef();

  function showInitial() {
    if (app.iosCertUploaded !== '0') {
      showIpaUpload();
      return;
    }
    createStoreAccountRef.current.toggle();
  }

  function showAuthKeyUpload() {
    authKeyUploadRef.current.toggle();
  }

  function showIpaUpload() {
    ipaUploadRef.current.toggle();
  }

  return (
    <React.Fragment>
      <Button
        color="button"
        className="btn btn-dark text-uppercase ml-2"
        onClick={() => {
          paymentStatus === '5' ? showInitial() : redirectToSubscription();
        }}
      >
        <i className="fab fa-app-store-ios mr-1" />
        Upload to store
      </Button>

      <AuthKeyUpload
        ref={authKeyUploadRef}
        project={project} 
        projectId={project.id}
        appId={app.id}
        showIpaUpload={showIpaUpload}
        className="instruction-modal"
        showButton={false}
      />

      <CreateStoreAccount
        ref={createStoreAccountRef}
        showAuthKeyUpload={showAuthKeyUpload}
        project={project}
        appId={app.id}
        className="appupload-card"
        showButton={false}
      />

      <IPAUpload
        ref={ipaUploadRef}
        showAuthKeyUpload={showAuthKeyUpload}
        project={project}
        projectId={project.id}
        appId={app.id}
        className="instruction-modal"
        showButton={false}
      />

      <ChoosePlan showPaymentButton={false} projectId={id} ref={paymentRef} />
    </React.Fragment>
  );
};

export default IOSInstructions;
