export const REDUCER_KEY = 'postlist';
export const action = [
  {
    id: 'CATEGORY',
    title: 'Open Product by category',
    template: 'woocommerce',
    subAction: {
      id: 'ID',
      title: 'Choose category',
      type: 'select',
      dataSource: {
        kind: 'root',
        name: 'category',
        link: 'category',
        store: REDUCER_KEY
      }
    }
  },
  {
    id: 'TAG',
    title: 'Open Product by tag',
    template: 'woocommerce',
    subAction: {
      id: 'ID',
      title: 'Choose product Tag',
      type: 'select',
      dataSource: {
        kind: 'root',
        name: 'tag',
        link: 'tag',
        store: REDUCER_KEY
      }
    }
  },
  {
    id: 'RECENT',
    title: 'Recent Posts',
    template: 'wordpress'
  }
];
export const getDisplayValue = value => {
  const data = {
    RECENT: { display_value: 'Recent Posts', label: 'Select Post List' },
    CATEGORY: {
      display_value: 'Open Posts in a category',
      label: 'Select Post List',
      hasChild: true
    },
    TAG: {
      display_value: 'Open Posts in a tag',
      label: 'Select Post List',
      hasChild: true
    }
  };
  try {
    return data[value];
  } catch (error) {
    return undefined;
  }
};
