import React from 'react';

const SliderImage = ({ imageSrc, children }) => {
  return (
    <div className="image-container ">
      <img src={imageSrc} width="100%" className="image-uploaded " />
      {children && <span className="image-title">{children}</span>}
    </div>
  );
};
export default SliderImage;
