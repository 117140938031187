/* eslint-disable import/prefer-default-export */
import rudderanalytics from '../rudderstack';

export const sendToRudder = (eventName, params) => {
  try {
    rudderanalytics.track(eventName, params);
  } catch (error) {
    console.error(error);
  }
};
