import React from 'react';
import {
  InnerBlocks,
  InspectorControls,
  RichText
} from '@wordpress/block-editor';
import { PanelBody, ToggleControl } from '@wordpress/components';
import { __ } from '@wordpress/i18n';

export default props => {
  const { attributes, onChangeAttribute = false } = props;
  const { showTitle, showViewMoreButton } = attributes;
  return (
    <InspectorControls>
      <PanelBody>
        <ToggleControl
          label={__('Show View more button', 'newspack-blocks')}
          checked={showViewMoreButton}
          onChange={() => {
            if (onChangeAttribute !== false)
              onChangeAttribute({ showViewMoreButton: !showViewMoreButton });
          }}
        />
        <ToggleControl
          label={__('Show Title', 'newspack-blocks')}
          checked={showTitle}
          onChange={() => {
            if (onChangeAttribute !== false)
              onChangeAttribute({ showTitle: !showTitle });
          }}
        />
      </PanelBody>
    </InspectorControls>
  );
};
