import React, { useState, useEffect } from 'react';
import { creditLimitCustomerTab } from '../../../../lib/apiFunctions';
import Loading from './Loading';

export default ({ projectId, addToast = () => {} }) => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await creditLimitCustomerTab(projectId);
      if (response.status && response.body) {
        setData(response.body);
      }
    } catch (error) {}
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      {isLoading && <Loading bubble />}
      {!isLoading && data !== false && (
        <React.Fragment>
          {Number(data.totalSalesAppLastYear) < 10000 && (
            <span className="text-center">
              <h4 className="font-weight-bold mb-0">
                {10000 - Number(data.totalSalesAppLastYear)}
              </h4>
              <small className="text-grey">Credit left</small>
            </span>
          )}
          {Number(data.totalSalesAppLastYear) > 10000 && (
            <span className="text-center">
              <h4 className="font-weight-bold mb-0">
                Limit exceeded (used: 
{' '}
{data.totalSalesAppLastYear})
              </h4>
              <small className="text-grey">Credit</small>
            </span>
          )}
        </React.Fragment>
      )}
    </div>
  );
};
