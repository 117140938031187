export function setMenu(menuList) {
  return {
    type: 'SET_MENU',
    menuList
  };
}

export function* setMenuKey(menuKey) {
  yield {
    type: 'SET_MENU_KEY',
    menuKey
  };
}

export function* saveMenu(menuList, menuKey) {
  const path =
    menuKey === undefined
      ? 'navigationMenu/mainmenu'
      : `navigationMenu/${menuKey}`;
  const request = {
    path,
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(menuList)
  };
  const response = yield {
    type: 'API_FETCH',
    request
  };
  yield {
    type: 'SET_MENU',
    menu: response
  };
  return response;
}

export function fetchFromAPI(path) {
  return {
    type: 'FETCH_FROM_API',
    path
  };
}
