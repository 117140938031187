import { select } from '@wordpress/data';
import * as actions from './actions';
import { checkPluginStatus } from '../../lib/apiFunctions';

export async function getCurrentProjectPluginResponse() { //eslint-disable-line
  const project = select('appmaker/core').getCurrentProject();
  let response;
  try {
    response = await checkPluginStatus(project.id);
  } catch(e) {
    response = false;
  }
  return actions.setCurrentProjectPluginResponse(response);
}
