import React from 'react';
import { Link } from 'react-router-dom';
import { UpdateIcon } from '../Icons';

const DocComponent = props => {
  const { subscriptionExpired } = props;
  return (
    <li className="nav-item">
      {/* <NavLink
            to={ROUTES.linkToUpdateApp()}
            activeClassName="active"
            className="vertical-nav-hover nav-link text-dark rounded-lg text-decoration-none"
          >
            <UpdateIcon fill="#202020" />
            Update App
          </NavLink> */}
      {!subscriptionExpired && (
        <a
          className="vertical-nav-hover nav-link text-dark rounded-lg text-decoration-none"
          href={props.link}
          target="_blank"
          activeClassName="active"
        >
          <UpdateIcon fill="#202020" />
          Update App
        </a>
      )}
      {subscriptionExpired && (
        <Link
          to={`/apps/${props.appId}`}
          className="vertical-nav-hover nav-link text-dark rounded-lg text-decoration-none"
        >
          <UpdateIcon fill="#202020" />
          Update App
        </Link>
      )}
    </li>
  );
};
export default DocComponent;
