import React from 'react';
import {
  InspectorControls,
  RichText,
  MediaPlaceholder,
  MediaUpload
} from '@wordpress/block-editor';
import { Input, FormGroup, Label, FormText } from 'reactstrap';
import { PanelBody } from '@wordpress/components';
import { __ } from '@wordpress/i18n';
import ProfileCardImage from '../../../components/ProfileCardImage';
import DeleteActionButton from '../../../components/Toolbar';
import { ProfileImagePlace } from '../Icon';
import '../style.css';

export default props => {
  const { attributes, setAttributes } = props;
  const {
    title,
    subtitle,
    description,
    image,
    imageContainerStyle
  } = attributes;
  const onSelect = data => {
    setAttributes({
      image: {
        url: data.url,
        fileName: data.filename,
        id: data.id,
        title: data.title
      },
      imageContainerStyle: {
        height: data.meta !== undefined ? data.meta.height : '100',
        width: data.meta !== undefined ? data.meta.width : '100'
      }
    });
  };
  let length = description ? description.length : 0;
  return (
    <div>
      <DeleteActionButton
        action={() => {
          setAttributes({ image: undefined });
        }}
      />
      <InspectorControls>
        <PanelBody title="Profile Card Settings">
          <FormGroup>
            <Label htmlFor="profile_title">Title</Label>
            <Input
              id="profile_title"
              value={title}
              onChange={event => {
                setAttributes({ title: event.target.value });
              }}
              placeholder="Title"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="profile_subtitle">Subtitle</Label>
            <Input
              id="profile_subtitle"
              value={subtitle}
              onChange={event => {
                setAttributes({ subtitle: event.target.value });
              }}
              placeholder="Subtitle"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="profile_description">Description</Label>
            <Input
              id="profile_description"
              value={description}
              type="textarea"
              maxLength="70"
              placeholder="Description"
              onChange={event => {
                setAttributes({ description: event.target.value });
              }}
            />
            <FormText>Charecters: {70 - length}</FormText>
          </FormGroup>
        </PanelBody>
      </InspectorControls>
      <div className="profile-card row mx-0">
        <div className="profile-image col-4 p-0">
          {image === undefined && (
            <MediaUpload
              onSelect={onSelect}
              render={({ open }) => {
                return (
                  <label
                    className="hover-pointer p-2 bg-light my-auto border image-container"
                    onClick={event => {
                      event.stopPropagation();
                      open();
                    }}
                  >
                    <span className="m-auto m-0 px-1">
                      <ProfileImagePlace />
                    </span>
                  </label>
                );
              }}
            />
          )}
          {image && <ProfileCardImage imageSrc={image.url} />}
        </div>
        <div className="profile-content col-8 p-0 position-relative p-2 d-block">
          <div className="profile-title position-relative mb-2">
            <h5 className="mb-0 ml-3">{title || 'Title'}</h5>
            {subtitle && <small className="text-muted ml-3">{subtitle}</small>}
          </div>
          <p>{description || 'Description'}</p>
        </div>
      </div>
    </div>
  );
};