/* eslint-disable class-methods-use-this */
/* eslint-disable no-return-assign */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Badge,
  Table,
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import { getFormattedDate, dateExtract } from '../../../lib/helper';
import { getInvoice } from '../../../lib/apiFunctions';

import constants from '../../../lib/constants';
import { print } from './helper';

export function orderStatus(status) {
  switch (status) {
    case constants.ORDER_STATUS_DRAFT:
      return <Badge color="warning">Draft</Badge>;
    case constants.ORDER_STATUS_PENDING:
      return <Badge color="warning">Pending</Badge>;
    case constants.ORDER_STATUS_ERROR:
      return <Badge color="danger">Error</Badge>;
    case constants.ORDER_STATUS_COMPLETE:
      return <Badge color="success">Completed</Badge>;
    case constants.ORDER_STATUS_EXPIRED:
      return <Badge color="danger">Expired</Badge>;
    case constants.ORDER_STATUS_EXPIRED_COMPLETE:
      return <Badge color="danger">Expired</Badge>;
    case constants.ORDER_STATUS_CANCELED:
      return <Badge color="warning">Canceled</Badge>;
    default:
      return <Badge color="danger">Not available</Badge>;
  }
}
class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      modal: false,
      invoice: {},
      billingAddress: {},
      errorStatus: false
    };
    this.toggle = this.toggle.bind(this);
    this.callBackFunction = this.callBackFunction.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  print() {
    print();
    // window.print();
  }

  callBackFunction(status, data) {
    if (status) {
      this.setState({
        loading: false,
        invoice: data.invoice,
        billingAddress: data.billingAddress
      });
    }
    if (!status) {
      this.setState({
        loading: false,
        invoice: [],
        billingAddress: data.billingAddress,
        errorStatus: true
      });
    }
  }

  componentDidMount() {
    const { invoiceId } = this.props;
    getInvoice(invoiceId, this.callBackFunction);
  }

  render() {
    const { loading, errorStatus, invoice, billingAddress, modal } = this.state;
    const { invoiceId, buttonLabel, className } = this.props;
    if (loading) return <div>Loading</div>;
    if (errorStatus) return <div>Somthing went Wrong</div>;
    let i = 0;
    const invoiceTableRows = invoice.items.items.map(data => {
      return (
        <tr key={i}>
          <td>{(i += 1)}</td>
          <td>{data.name}</td>
          <td>{`${data.cost} ${invoice.currency}`}</td>
          <td>{`${data.total} ${invoice.currency}`}</td>
        </tr>
      );
    });
    return (
      <div>
        <Button
          outline
          color="primary"
          onClick={this.toggle}
          size="sm"
          className="mr-2"
        >
          {buttonLabel}
        </Button>
        <Modal
          isOpen={modal}
          toggle={this.toggle}
          className={className}
          scrollable
        >
          <ModalHeader
            toggle={this.toggle}
            className="border-bottom-0 bg-light"
          >
            <div>
              Invoice # 
{' '}
{invoiceId}
              <Button
                color="dark"
                size="sm"
                onClick={this.print}
                id="printButton"
              >
                <i className="fas fa-print" /> Print
              </Button>
            </div>
          </ModalHeader>
          <ModalBody className="position-relative">
            <div id="printSection" className="invoice bg-white p-3">
              <div className="appmaker-address d-flex justify-content-between pt-3 mb-3">
                <div>
                  <img
                    src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/02/32357a5c-c65321b4-logo.png"
                    alt=""
                    className="mb-3"
                  />
                  <p className="mb-0">Cofye Innovations PVT LTD</p>
                  <p className="mb-0">Bengaluru</p>
                  <p className="mb-0">India</p>
                  <p className="mb-0">Telephone: +91 9995556560</p>
                </div>
                <div className="text-right">
                  <h4 className="mb-3">
                    <b>INVOICE</b>
                  </h4>
                  <h6>
                    <span className="text-muted">Invoice No</span>
                    <br />
                    <b>#{invoiceId}</b>
                  </h6>
                  <p>
                    <span className="text-muted">Invoice Date</span>
                    <br />
                    {getFormattedDate(invoice.create_date)}
                  </p>
                </div>
              </div>
              <div className="to-address border p-3 mb-3 w-50">
                <p className="mb-0">
                  <strong>TO:</strong>
                </p>
                <h6 className="font-weight-bold">
                  {billingAddress.first_name}
                  {billingAddress.last_name}
                </h6>
                <p className="text-muted mb-0">
                  {billingAddress.address}
                  <br />
                  {billingAddress.city}
                  <br />
                  {billingAddress.state}
                  <br />
                  {billingAddress.country}
                  <br />
                  {billingAddress.phone}
                </p>
              </div>
              <div className="order-details mb-3">
                <p className="mb-0">
                  Order date:
{' '}
                  <strong>
                    {/* {dateExtract(invoice.create_date).date}
{' '}
-
{dateExtract(invoice.create_date).month} -
                    {dateExtract(invoice.create_date).year} */}
                    {getFormattedDate(invoice.create_date)}
                  </strong>
                </p>
                <p className="mb-0">
                  Order status: 
{' '}
{orderStatus(String(invoice.status))}
                </p>
                <p className="mb-0">
                  Order ID: 
{' '}
<strong>
#{invoice.order_id}</strong>
                </p>
              </div>
              <Table striped className="border">
                <thead>
                  <tr>
                    <th>QTY</th>
                    <th>DESCRIPTION</th>
                    <th>UNIT PRICE</th>
                    <th>TOTAL</th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceTableRows}
                  <tr>
                    <td colSpan="3" className="text-right">
                      <strong>Subtotal</strong>
                    </td>
                    <td>{`${invoice.subtotal} ${invoice.currency}`}</td>
                  </tr>
                  {invoice.discount !== 0 && (
                    <tr>
                      <td colSpan="3" className="text-right">
                        <strong>Discount</strong>
                      </td>
                      <td>{`${invoice.discount} ${invoice.currency}`}</td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan="3" className="text-right">
                      <strong>Total</strong>
                    </td>
                    <td>
                      <strong>{`${invoice.total}  ${invoice.currency}`}</strong>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
Invoice.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  className: PropTypes.string
};
Invoice.defaultProps = {
  buttonLabel: 'View Invoice',
  className: ''
};
export default Invoice;
