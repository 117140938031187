import React from 'react';
import Select from 'react-select';
import Languages from '../../lib/languages';

const convertOption = values => {
  const option = [];
  option.push({
    value: 'default',
    label: Languages.default
  });
  values.forEach(data => {
    try {
      option.push({
        value: data,
        label: Languages[data]
      });
    } catch (error) {}
  });
  return option;
};
const getCurrentOption = data => {
  const option = {
    value: data,
    label: Languages[data]
  };
  return option;
};
export default props => {
  const { onChange = false, defaultValue, isDisabled } = props;
  // console.log({ id: defaultValue, label: Languages[`${defaultValue}`] });
  return (
    <Select
      isDisabled={isDisabled}
      value={getCurrentOption(defaultValue)}
      options={convertOption(props.languages)}
      onChange={value => {
        if (onChange !== false) {
          onChange(value);
        }
      }}
    />
  );
};
