import React from 'react';
import { DocsIcon } from '../Icons';

const DocComponent = props => {
  return (
    <li className="nav-item">
      <a
        href="https://appmaker.xyz/docs"
        target="_blank"
        rel="noopener"
        className="vertical-nav-hover nav-link text-dark rounded-lg text-decoration-none"
      >
        <DocsIcon fill="#202020" />
        Docs
      </a>
    </li>
  );
};
export default DocComponent;
