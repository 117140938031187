import React, { Component } from 'react';


class IconListing extends Component {
    state = {
        icons: []
    }
    componentDidMount() {
        this.loadImage(undefined)

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.query.search !== this.props.query.search)
            this.loadImage(nextProps.query.search)
    }
    loadImage = (search) => {
        const key = '058b6f5e36ed6b7502e24b8f83f8badaf529e88609981f3315d5c751e8d623a7';
        const url = search !== undefined ? 'https://api.unsplash.com/search/photos?client_id=' + key + '&query=' + search : 'https://api.unsplash.com/photos?client_id=' + key
        fetch(url)
            .then(res => res.json())
            .then((data) => {
                if (search != undefined)
                    this.setState({ icons: data.results });
                else
                    this.setState({ icons: data });
            })
    }

    render() {
        return (
            <div className="image-listing d-flex flex-wrap p-3">
                {this.state.icons.map((item, idx) =>
                    <div className="select-img mr-2 mb-2 border border-dark rounded" key={`unsplash_${idx}`}>
                        <input type="radio" id={`unsplash_${idx}`} className="d-none" value="" name="unsplash_image" />
                        <label htmlFor={`unsplash_${idx}`} className="image-container position-relative my-auto">
                            <img src={item.urls.small} alt="" onClick={() => {
                                this.props.action && this.props.action({ id: item.id, url: item.urls.small });
                            }} />
                            <div className="tritick-container">
                                <div className="tick small-label-text"><i className="fa fa-check"></i></div>
                            </div>
                        </label>
                    </div>
                )}
            </div>
        );
    }
}

export default IconListing;