/**
 * convert the blocks action to the app widget
 * @param {*} action
 */
export const convertActionToWidgets = action => {
  if (action === undefined) {
    return {
      action: {
        value: 'NO_ACTION',
        display_value: 'No Action',
        label: 'Action',
        display: true
      },
      action_value: ''
    };
  }
  switch (action.action) {
    case 'OPEN_URL': {
      return {
        action: {
          value: 'OPEN_URL',
          display_value: 'Open URL',
          label: 'Action',
          display: true
        },
        action_value: {
          value: action.OPEN_URL_url,
          display_value: action.OPEN_URL_url,
          label: 'URL',
          display: true
        }
      };
    }
    case 'OPEN_IN_WEBVIEW': {
      return {
        action: {
          value: 'OPEN_IN_WEB_VIEW',
          display_value: 'Open WebView',
          label: 'Action',
          display: true
        },
        action_value: {
          value: action.OPEN_IN_WEBVIEW_url,
          display_value: action.OPEN_IN_WEBVIEW_url,
          label: 'URL',
          display: true
        }
      };
    }
    case 'OPEN_AUTHENTICATED_WEBVIEW': {
      return {
        action: {
          value: 'OPEN_AUTHENTICATED_WEBVIEW',
          display_value: 'Open Authenticated WebView',
          label: 'Action',
          display: true
        },
        action_value: {
          value: action.OPEN_AUTHENTICATED_WEBVIEW_url,
          display_value: action.OPEN_AUTHENTICATED_WEBVIEW_url,
          label: 'URL',
          display: true
        }
      };
    }
    case 'LANGUAGE_SWITCHER': {
      return {
        action: {
          value: 'OPEN_LANGUAGES_PAGE',
          display_value: 'Language Switcher',
          label: 'Action',
          display: true
        },
        action_value: ''
      };
    }
    case 'CURRENCY_SWITCHER': {
      return {
        action: {
          value: 'OPEN_CURRENCY_PAGE',
          display_value: 'Currency Switcher',
          label: 'Action',
          display: true
        },
        action_value: ''
      };
    }
    case 'OPEN_ZOPIM_CHAT': {
      return {
        action: {
          value: 'OPEN_ZENDESK_CHAT',
          display_value: 'Open Zopim Chat (Works only if add-on is enabled)',
          label: 'Action',
          display: true
        },
        action_value: ''
      };
    }
    case 'OPEN_INAPP_PAGE': {
      return {
        action: {
          value: 'OPEN_IN_APP_PAGE',
          display_value: 'Open In-App Page',
          label: 'Action',
          display: true
        },
        action_value: {
          value: action.OPEN_INAPP_PAGE_id.id,
          display_value: action.OPEN_INAPP_PAGE_id.label,
          label: 'Choose In-App Page',
          display: true
        }
      };
    }
    // FIXME Change the action.value after changing the api calls
    // case 'OPEN_PRODUCT_BY_CATEGORY': {
    //   return {
    //     action: {
    //       value: 'LIST_PRODUCT',
    //       display_value: 'Product Category',
    //       label: 'Action',
    //       display: true,
    //     },
    //     action_value: {
    //       dependent_value: {
    //         value: action.OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID.ORDER_TYPE.value,
    //         display_value:
    //           action.OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID.ORDER_TYPE.label,
    //         label: 'Order type',
    //         display: true,
    //         params: {
    //           ...action.OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID.ORDER_TYPE.params,
    //         },
    //       },
    //       value: action.OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID.PRODUCT_ID.id,
    //       display_value:
    //         action.OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID.PRODUCT_ID.label,
    //       label: 'Choose Product by Category',
    //       display: true,
    //     },
    //   };
    // }
    case 'OPEN_PRODUCT_BY_CATEGORY': {
      return {
        action: {
          value: 'LIST_PRODUCT',
          display_value: 'Product Category',
          label: 'Action',
          display: true
        },
        action_value: {
          value: action.OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID.id,
          display_value: action.OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID.label,
          label: 'Choose Product by Category',
          display: true
        }
      };
    }
    case 'OPEN_CATEGORY': {
      return {
        action: {
          value: 'LIST_POST',
          display_value: 'Open Post Category',
          label: 'Action',
          display: true
        },
        action_value: {
          value: action.OPEN_CATEGORY_PRODUCT_ID.id,
          display_value: action.OPEN_CATEGORY_PRODUCT_ID.label,
          label: 'Choose Category',
          display: true
        }
      };
    }
    case 'OPEN_POST_PAGE': {
      return {
        action: {
          value: 'OPEN_POST',
          display_value: 'Open Post Category',
          label: 'Action',
          display: true
        },
        action_value: {
          value: action.OPEN_POST_PAGE_PRODUCT_ID.id,
          display_value: action.OPEN_POST_PAGE_PRODUCT_ID.label,
          label: 'Choose Post / Page',
          display: true
        }
      };
    }
    // //FIXME Change the action.value after changing the api calls
    case 'LIST_PRODUCT': {
      return {
        action: {
          value: 'OPEN_POST',
          display_value: 'Product Category',
          label: 'Action',
          display: true
        },
        action_value: {
          value: action.OPEN_CATEGORY_PRODUCT_ID.id,
          display_value: action.OPEN_CATEGORY_PRODUCT_ID.label,
          label: 'Choose Product Category',
          display: true
        }
      };
    }
    case 'OPEN_PRODUCT': {
      return {
        action: {
          value: 'OPEN_PRODUCT',
          display_value: 'Product Category',
          label: 'Action',
          display: true
        },
        action_value: {
          value: action.OPEN_PRODUCT_PRODUCT_ID.id,
          display_value: action.OPEN_PRODUCT_PRODUCT_ID.label,
          label: 'Choose Product',
          display: true
        }
      };
    }
    case 'OPEN_ATTRIBUTE': {
      const actions = {
        value: 'OPEN_ATTRIBUTE',
        display_value: ' Open Attribute',
        label: 'Action',
        display: true
      };
      const action_value = {
        dependent_value: {
          value: action.OPEN_ATTRIBUTE_ATTRIBUTE_ID.ATTRIBUTE_ID.id,
          display_value: action.OPEN_ATTRIBUTE_ATTRIBUTE_ID.ATTRIBUTE_ID.label,
          label: 'Attribute Value',
          display: true
        },
        value: action.OPEN_ATTRIBUTE_ATTRIBUTE_ID.TERM_ID.id,
        display_value: action.OPEN_ATTRIBUTE_ATTRIBUTE_ID.TERM_ID.label,
        label: 'Term value',
        display: true
      };
      return { action: actions, action_value };
    }
    case 'OPEN_VENDORS': {
      return {
        action: {
          value: 'OPEN_VENDORS',
          display_value: 'Open vendor list',
          label: 'Action',
          display: true
        },
        action_value: ''
      };
    }
    case 'CATEGORY': {
      const data = {
        action: {
          value: 'CATEGORY',
          display_value: 'Open Posts in a category',
          label: 'Select Post List',
          display: true
        }
      };
      if (action.CATEGORY_ID !== undefined) {
        data.action_value = {
          value: action.CATEGORY_ID.id,
          label: 'action',
          display: true,
          display_value: action.CATEGORY_ID.label
        };
      }
      return data;
    }
    case 'TAG': {
      const data = {
        action: {
          value: 'TAG',
          display_value: 'Open Posts in a tag',
          label: 'Select Post List',
          display: true
        }
      };
      if (action.TAG_ID) {
        data.action_value = {
          value: action.TAG_ID.id,
          label: 'action',
          display: true,
          display_value: action.TAG_ID.label
        };
      }
      return data;
    }
    case 'RECENT': {
      return {
        action: {
          value: 'RECENT',
          display_value: 'Recent Posts',
          label: 'Select Post List',
          display: true
        },
        action_value: ''
      };
    }
    case 'OPEN_TAG': {
      return {
        action: {
          value: 'LIST_POST_TAG',
          display_value: 'Open Tag',
          label: 'Action',
          display: true
        },
        action_value: {
          value: action.OPEN_TAG_ID.id,
          label: 'Select Tag',
          display: true,
          display_value: action.OPEN_TAG_ID.label
        }
      };
    }
    case 'OPEN_SHOP_PAGE': {
      return {
        action: {
          value: 'OPEN_SHOP_PAGE',
          display_value: 'Open shop page',
          label: 'Action',
          display: true
        },
        action_value: {
          value: action.OPEN_SHOP_PAGE_PRODUCT_ID.id,
          label: 'Select Categories',
          display: true,
          display_value: action.OPEN_SHOP_PAGE_PRODUCT_ID.label
        }
      };
    }
    case 'OPEN_PRODUCT_TAG': {
      return {
        action: {
          value: 'LIST_PRODUCT_TAG',
          display_value: 'Product Tag',
          label: 'action',
          display: 'true'
        },
        action_value: {
          value: action.OPEN_PRODUCT_TAG_PRODUCT_ID.id,
          display_value: action.OPEN_PRODUCT_TAG_PRODUCT_ID.label,
          label: 'Choose Product Tag',
          display: true
        }
      };
    }
    case 'OPEN_BRANDS': {
      return {
        action: {
          value: 'OPEN_BRANDS',
          display_value: 'Open brands',
          label: 'action',
          display: true
        },
        action_value: ''
      };
    }
    case 'OPEN_BY_BRAND': {
      return {
        action: {
          value: 'OPEN_BY_BRAND',
          display_value: 'Open by brand',
          label: 'action',
          display: true
        },
        action_value: {
          value: action.OPEN_BY_BRAND_CATEGORY_ID.id,
          display_value: action.OPEN_BY_BRAND_CATEGORY_ID.label,
          label: 'Choose category',
          display: true
        }
      };
    }
    case 'OPEN_PRODUCT_BRAND': {
      return {
        action: {
          value: 'OPEN_PRODUCT_BRAND',
          display_value: 'Open by brand',
          label: 'action',
          display: true
        },
        action_value: {
          value: action.OPEN_PRODUCT_BRAND_BRAND_ID.id,
          display_value: action.OPEN_PRODUCT_BRAND_BRAND_ID.label,
          label: 'Choose brand',
          display: true
        }
      };
    }
    // case 'LIST_NEW_PRODUCTS': {
    //   return {
    //     action: {
    //       value: 'LIST_NEW_PRODUCTS',
    //       display_value: 'List New Products',
    //       label: 'Action',
    //       display: true
    //     },
    //     action_value: {
    //       dependent_value: {
    //         value: action.LIST_NEW_PRODUCTS_PRODUCT_ID.ORDER_TYPE.value,
    //         display_value:
    //           action.LIST_NEW_PRODUCTS_PRODUCT_ID.ORDER_TYPE.label,
    //         label: 'Order type',
    //         display: true,
    //         params: {
    //           ...action.LIST_NEW_PRODUCTS_PRODUCT_ID.ORDER_TYPE.params
    //         }
    //       },
    //       value: action.LIST_NEW_PRODUCTS_PRODUCT_ID.PRODUCT_ID.id,
    //       display_value:
    //         action.LIST_NEW_PRODUCTS_PRODUCT_ID.PRODUCT_ID.label,
    //       label: 'Choose Product by Category',
    //       display: true
    //     }
    //   }
    // }
    case 'LIST_NEW_PRODUCTS': {
      return {
        action: {
          value: 'LIST_NEW_PRODUCTS',
          display_value: 'List New Products',
          label: 'Action',
          display: true
        },
        action_value: {
          value: action.LIST_NEW_PRODUCTS_PRODUCT_ID.id,
          display_value: action.LIST_NEW_PRODUCTS_PRODUCT_ID.label,
          label: 'Choose Product by Category',
          display: true
        }
      };
    }
    case 'OPEN_PRE_BUILD': {
      return {
        action: {
          value: 'OPEN_PRE_BUILD',
          display_value: 'Open Pre-build Products',
          label: 'Action',
          display: true
        },
        action_value: {
          value: action.OPEN_PRE_BUILD_OPTION_ID.value,
          display_value: action.OPEN_PRE_BUILD_OPTION_ID.label,
          label: 'Choose Options',
          display: true
        }
      };
    }
    default:
      return {
        action: {
          value: 'NO_ACTION',
          display_value: 'No Action',
          label: 'Action',
          display: true
        },
        action_value: ''
      };
  }
};
/**
 *convert the action of old widget to blocks
 * @param {*} action
 * @param {*} action_value
 *
 */
export const convertActionToBlock = (action, action_value) => {
  if (action === undefined) {
    if (action_value !== undefined) {
      return {
        action: 'NO_ACTION',
        PRODUCT_ID: {
          value: action_value.value,
          id: action_value.value,
          label: action_value.display_value
        }
      };
    }
    return { action: 'NO_ACTION' };
  }
  switch (action.value) {
    case 'OPEN_URL': {
      return {
        action: 'OPEN_URL',
        OPEN_URL_url: action_value.value,
        params: { value: action_value.value }
      };
    }
    case 'OPEN_IN_WEB_VIEW': {
      return {
        action: 'OPEN_IN_WEBVIEW',
        OPEN_IN_WEBVIEW_url: action_value.value,
        params: { value: action_value.value }
      };
    }
    case 'OPEN_LANGUAGES_PAGE': {
      return {
        action: 'LANGUAGE_SWITCHER',
        params: {}
      };
    }
    case 'OPEN_CURRENCY_PAGE': {
      return { action: 'CURRENCY_SWITCHER', params: {} };
    }
    case 'OPEN_ZENDESK_CHAT': {
      return { action: 'OPEN_ZOPIM_CHAT', params: {} };
    }
    case 'OPEN_IN_APP_PAGE': {
      return {
        action: 'OPEN_INAPP_PAGE',
        OPEN_INAPP_PAGE_id: {
          value: action_value.value,
          id: action_value.value,
          label: action_value.display_value
        },
        params: { value: action_value.value }
      };
    }
    // case 'LIST_PRODUCT': {
    //   return {
    //     action: 'OPEN_PRODUCT_BY_CATEGORY',
    //     OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID: {
    //       PRODUCT_ID: {
    //         value: action_value.value,
    //         id: action_value.value,
    //         label: action_value.display_value,
    //       },
    //       ORDER_TYPE: {
    //         value:
    //           action_value.dependent_value !== undefined
    //             ? action_value.dependent_value.value
    //             : 'default',
    //         id:
    //           action_value.dependent_value !== undefined
    //             ? action_value.dependent_value.value
    //             : 'default',
    //         label:
    //           action_value.dependent_value !== undefined
    //             ? action_value.dependent_value.display_value
    //             : 'Default',
    //         params: {
    //           order:
    //             action_value.dependent_value !== undefined &&
    //             action_value.dependent_value.params !== undefined
    //               ? action_value.dependent_value.params.order
    //               : 'asc',
    //           orderby:
    //             action_value.dependent_value !== undefined &&
    //             action_value.dependent_value.params !== undefined
    //               ? action_value.dependent_value.params.orderby
    //               : 'default',
    //         },
    //       },
    //     },
    //     params: { value: action_value.value },
    //   };
    // }
    case 'LIST_PRODUCT': {
      return {
        action: 'OPEN_PRODUCT_BY_CATEGORY',
        OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID: {
          value: action_value.value,
          id: action_value.value,
          label: action_value.display_value
        },
        params: { value: action_value.value }
      };
    }
    case 'OPEN_POST': {
      return {
        action: 'OPEN_POST_PAGE',
        OPEN_POST_PAGE_PRODUCT_ID: {
          value: action_value.value,
          id: action_value.value,
          label: action_value.display_value
        },
        params: { value: action_value.value }
      };
    }
    case 'LIST_POST': {
      return {
        action: 'OPEN_CATEGORY',
        OPEN_CATEGORY_PRODUCT_ID: {
          value: action_value.value,
          id: action_value.value,
          label: action_value.display_value
        },
        params: { value: action_value.value }
      };
    }
    case 'OPEN_PRODUCT': {
      return {
        action: 'OPEN_PRODUCT',
        OPEN_PRODUCT_PRODUCT_ID: {
          value: action_value.value,
          id: action_value.value,
          label: action_value.display_value
        },
        params: { value: action_value.value }
      };
    }
    case 'OPEN_ATTRIBUTE': {
      return {
        action: 'OPEN_ATTRIBUTE',
        OPEN_ATTRIBUTE_ATTRIBUTE_ID: {
          TERM_ID: {
            value: action_value.value,
            id: action_value.value,
            label: action_value.display_value
          },
          ATTRIBUTE_ID: {
            id: action_value.dependent_value.value,
            value: action_value.dependent_value.value,
            label: action_value.dependent_value.display_value
          }
        },
        params: { value: action_value.value }
      };
    }
    case 'OPEN_VENDORS': {
      return {
        action: 'OPEN_VENDORS',
        params: {}
      };
    }
    case 'CATEGORY': {
      // NOTE WORDPRESS postlist
      const data = {
        action: 'CATEGORY'
      };
      if (action_value !== '' && action_value !== undefined) {
        data.CATEGORY_ID = {
          id: action_value.value,
          label: action_value.display_value
        };
      }
      return data;
    }
    case 'TAG': {
      // NOTE WORDPRESS postlist
      const data = {
        action: 'TAG'
      };
      if (action_value !== '' && action_value !== undefined) {
        data.TAG_ID = {
          id: action_value.value,
          label: action_value.display_value
        };
        data.params = {
          value: action_value.value
        };
      }
      return data;
    }
    case 'RECENT': {
      // NOTE WORDPRESS postlist
      return { action: 'RECENT' };
    }
    case 'OPEN_SHOP_PAGE': {
      const convertAction = { action: 'OPEN_SHOP_PAGE' };
      let actionValues;
      if (action_value && action_value !== '') {
        actionValues = {
          OPEN_SHOP_PAGE_PRODUCT_ID: {
            value: action_value.value === undefined ? '' : action_value.value,
            id: action_value.value === undefined ? '' : action_value.value,
            label:
              action_value.display_value === undefined
                ? 'All'
                : action_value.display_value
          },
          param: {
            value: action_value.value === undefined ? '' : action_value.value
          }
        };
      } else {
        actionValues = {
          OPEN_SHOP_PAGE_PRODUCT_ID: {
            value: '',
            id: '',
            label: 'All'
          },
          param: { value: '' }
        };
      }
      return { ...convertAction, ...actionValues };
    }
    case 'LIST_POST_TAG': {
      return {
        action: 'OPEN_TAG',
        OPEN_TAG_ID: {
          value: action_value.value,
          id: action_value.value,
          label: action_value.display_value
        },
        params: { value: action_value.value }
      };
    }
    case 'LIST_PRODUCT_TAG': {
      return {
        action: 'OPEN_PRODUCT_TAG',
        OPEN_PRODUCT_TAG_PRODUCT_ID: {
          value: action_value.value,
          id: action_value.value,
          label: action_value.display_value
        },
        params: { value: action_value.value }
      };
    }
    case 'OPEN_BRANDS': {
      return {
        action: 'OPEN_BRANDS'
      };
    }
    case 'OPEN_BY_BRAND': {
      return {
        action: 'OPEN_BY_BRAND',
        OPEN_BY_BRAND_CATEGORY_ID: {
          value: action_value.value,
          id: action_value.value,
          label: action_value.display_value
        },
        params: { value: action_value.value }
      };
    }
    case 'OPEN_PRODUCT_BRAND': {
      return {
        action: 'OPEN_PRODUCT_BRAND',
        OPEN_PRODUCT_BRAND_BRAND_ID: {
          value: action_value.value,
          id: action_value.value,
          label: action_value.display_value
        },
        params: { value: action_value.value }
      };
    }
    case 'OPEN_AUTHENTICATED_WEBVIEW': {
      return {
        action: 'OPEN_AUTHENTICATED_WEBVIEW',
        OPEN_AUTHENTICATED_WEBVIEW_url: action_value.value,
        params: { value: action_value.value }
      };
    }
    // case 'LIST_NEW_PRODUCTS': {
    //   return {
    //     action: 'LIST_NEW_PRODUCTS',
    //     LIST_NEW_PRODUCTS_PRODUCT_ID: {
    //       PRODUCT_ID: {
    //         value: action_value.value,
    //         id: action_value.value,
    //         label: action_value.display_value
    //       },
    //       ORDER_TYPE: {
    //         value:
    //           action_value.dependent_value !== undefined
    //             ? action_value.dependent_value.value
    //             : 'default',
    //         id:
    //           action_value.dependent_value !== undefined
    //             ? action_value.dependent_value.value
    //             : 'default',
    //         label:
    //           action_value.dependent_value !== undefined
    //             ? action_value.dependent_value.display_value
    //             : 'Default',
    //         params: {
    //           order:
    //             action_value.dependent_value !== undefined &&
    //               action_value.dependent_value.params !== undefined
    //               ? action_value.dependent_value.params.order
    //               : 'asc',
    //           orderby:
    //             action_value.dependent_value !== undefined &&
    //               action_value.dependent_value.params !== undefined
    //               ? action_value.dependent_value.params.orderby
    //               : 'default'
    //         }
    //       }
    //     }
    //   }
    // }
    case 'LIST_NEW_PRODUCTS': {
      return {
        action: 'LIST_NEW_PRODUCTS',
        LIST_NEW_PRODUCTS_PRODUCT_ID: {
          value: action_value.value,
          id: action_value.value,
          label: action_value.display_value
        }
      };
    }
    case 'OPEN_PRE_BUILD': {
      return {
        action: 'OPEN_PRE_BUILD',
        OPEN_PRE_BUILD_OPTION_ID: {
          value: action_value.value,
          id: action_value.value,
          label: action_value.display_value
        }
      };
    }
    default: {
      return { action: 'NO_ACTION', params: {} };
    }
  }
};
