import React from 'react';
import '../style.css';

const SmartBannerPreview = ({ iconUrl, title, description, btnContent }) => {
  return (
    <div className="smart-banner-preview-container">
      <svg width="450" viewBox="0 0 513 950">
        <defs>
          <linearGradient
            id="a"
            x1="0.5"
            x2="0.5"
            y2="1"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#fff" />
            <stop offset="1" stopColor="#e3e3e3" />
          </linearGradient>
          <filter
            id="b"
            x="0"
            y="0"
            width="513"
            height="950"
            filterUnits="userSpaceOnUse"
          >
            <feOffset input="SourceAlpha" />
            <feGaussianBlur stdDeviation="15" result="c" />
            <feFlood floodColor="#d4d4d4" floodOpacity="0.161" />
            <feComposite operator="in" in2="c" />
            <feComposite in="SourceGraphic" />
          </filter>
          <linearGradient
            id="d"
            x1="0.5"
            x2="0.5"
            y2="1"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#f6f6f6" />
            <stop offset="1" stopColor="#ececec" />
          </linearGradient>
          <linearGradient
            id="e"
            x1="0.5"
            x2="0.5"
            y2="1"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#fff" />
            <stop offset="1" stopColor="#f3f3f3" />
          </linearGradient>
          <linearGradient
            id="f"
            x1="0.5"
            x2="0.5"
            y2="1"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#fff" />
            <stop offset="1" stopColor="#e5e5e5" />
          </linearGradient>
          <linearGradient
            id="g"
            x1="1"
            y1="0.377"
            x2="0"
            y2="0.377"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#fff" stop-opacity="0" />
            <stop offset="0.322" stopColor="#fdfdfd" stop-opacity="0" />
            <stop offset="1" stopColor="#dadada" />
          </linearGradient>
          <linearGradient
            id="n"
            x1="0.5"
            x2="0.5"
            y2="1"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#f3f3f3" />
            <stop offset="1" stopColor="#fff" />
          </linearGradient>
        </defs>

        <g transform="translate(17776 -8378)">
          <g transform="translate(-17734 8423)">
            <g transform="matrix(1, 0, 0, 1, -42, -45)" filter="url(#b)">
              <path
                d="M57,0H366a57,57,0,0,1,57,57V803a57,57,0,0,1-57,57H57A57,57,0,0,1,0,803V57A57,57,0,0,1,57,0Z"
                transform="translate(45 45)"
                fill="url(#a)"
              />
            </g>
            <path
              d="M47,0H360a47,47,0,0,1,47,47V797a47,47,0,0,1-47,47H47A47,47,0,0,1,0,797V47A47,47,0,0,1,47,0Z"
              transform="translate(11 8)"
              fill="url(#d)"
            />
            <path
              d="M44.441,0H358.559A44.326,44.326,0,0,1,403,44.211V795.789A44.326,44.326,0,0,1,358.559,840H44.441A44.326,44.326,0,0,1,0,795.789V44.211A44.326,44.326,0,0,1,44.441,0Z"
              transform="translate(13 10)"
              fill="url(#e)"
            />
            <g transform="translate(569 -1330)">
              <g transform="translate(-144 1530)">
                <path
                  d="M0,0H3A2,2,0,0,1,5,2V94a2,2,0,0,1-2,2H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                  fill="url(#f)"
                />
                <path
                  d="M0,0H3A2,2,0,0,1,5,2V94a2,2,0,0,1-2,2H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                  fill="url(#g)"
                />
              </g>
            </g>
            <g transform="translate(569 -1330)">
              <path
                d="M2,0H5A0,0,0,0,1,5,0V60a0,0,0,0,1,0,0H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0Z"
                transform="translate(-569 1510)"
                fill="url(#f)"
              />
              <path
                d="M0,0H3A2,2,0,0,1,5,2V58a2,2,0,0,1-2,2H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                transform="translate(-564 1570) rotate(180)"
                fill="url(#g)"
              />
            </g>
            <g transform="translate(569 -1330)">
              <path
                d="M2,0H5A0,0,0,0,1,5,0V60a0,0,0,0,1,0,0H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0Z"
                transform="translate(-569 1588)"
                fill="url(#f)"
              />
              <path
                d="M0,0H3A2,2,0,0,1,5,2V58a2,2,0,0,1-2,2H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                transform="translate(-564 1648) rotate(180)"
                fill="url(#g)"
              />
            </g>
            <g transform="translate(569 -1330)">
              <path
                d="M1,0H5A0,0,0,0,1,5,0V32a0,0,0,0,1,0,0H1a1,1,0,0,1-1-1V1A1,1,0,0,1,1,0Z"
                transform="translate(-569 1447)"
                fill="url(#f)"
              />
              <path
                d="M0,0H4A1,1,0,0,1,5,1V31a1,1,0,0,1-1,1H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                transform="translate(-564 1479) rotate(180)"
                fill="url(#g)"
              />
            </g>
            <g transform="translate(257.133 25.74)">
              <ellipse
                cx="7.5"
                cy="7.5"
                rx="7.5"
                ry="7.5"
                transform="translate(0.867 -0.74)"
                fill="url(#n)"
              />
              <circle
                cx="6.5"
                cy="6.5"
                r="6.5"
                transform="translate(1.867 0.26)"
                fill="url(#e)"
              />
              <ellipse
                cx="4.5"
                cy="4.5"
                rx="4.5"
                ry="4.5"
                transform="translate(3.867 2.26)"
                fill="#f3f3f3"
              />
              <ellipse
                cx="1.5"
                cy="1"
                rx="1.5"
                ry="1"
                transform="translate(6.867 2.26)"
                fill="#fff"
              />
            </g>
            <path
              d="M4,.5H59a3.5,3.5,0,0,1,0,7H4a3.5,3.5,0,0,1,0-7Z"
              transform="translate(183 29)"
              fill="#f3f3f3"
              stroke="#f9f8f8"
              strokeWidth="1"
            />
            <path
              d="M337,812H38A38.043,38.043,0,0,1,0,774V38A38.043,38.043,0,0,1,38,0H78.263a6.812,6.812,0,0,1,5.23,5.17c.164.724.336,1.671.535,2.766.383,2.109.858,4.725,1.566,7.368.915,3.42,4.955,14.588,18.529,14.728,5.12.053,37.044.064,62.923.064,47.606,0,102.231-.038,104.786-.064,13.574-.14,17.614-11.308,18.529-14.728.709-2.649,1.185-5.268,1.567-7.373.2-1.1.371-2.042.534-2.761A6.592,6.592,0,0,1,297.647,0H337a38.043,38.043,0,0,1,38,38V774A38.043,38.043,0,0,1,337,812Z"
              transform="translate(27 24)"
              fill="#eee"
            />
            <path
              d="M2.5,0h125a2.5,2.5,0,0,1,0,5H2.5a2.5,2.5,0,0,1,0-5Z"
              transform="translate(150 819)"
              fill="#e2e2e2"
            />
          </g>
          <g transform="translate(-17707 8447)">
            <path
              d="M376,44H0V38A37.969,37.969,0,0,1,38,0H78.164a6.2,6.2,0,0,1,3.61,2.073,7.338,7.338,0,0,1,1.629,3.1c.165.724.337,1.67.537,2.767.383,2.1.86,4.722,1.57,7.367.917,3.419,4.968,14.587,18.578,14.727,5.131.053,37.142.064,63.092.064,47.733,0,102.5-.038,105.064-.064,13.609-.14,17.662-11.307,18.58-14.727.71-2.648,1.188-5.268,1.571-7.374.2-1.094.371-2.038.535-2.76A6.607,6.607,0,0,1,298.122,0H338a37.968,37.968,0,0,1,38,38v6Z"
              fill="#fff"
            />
            <path
              d="M70.995,7.359a9.772,9.772,0,0,1,6.329,2.306L78.5,8.395a11.176,11.176,0,0,0-15-.009l1.174,1.272a9.771,9.771,0,0,1,6.321-2.3Zm0,3.666a5.939,5.939,0,0,1,3.8,1.366l1.245-1.344a7.722,7.722,0,0,0-10.092-.005l1.243,1.346a5.937,5.937,0,0,1,3.8-1.362Zm2.56,2.71a3.92,3.92,0,0,0-5.117,0L70.994,16.5l2.56-2.766Z"
              transform="translate(253.5 11.5)"
              fillRule="evenodd"
            />
            <path
              d="M14.069.576A.588.588,0,0,1,14.662,0h1.745A.58.58,0,0,1,17,.576v9.849a.588.588,0,0,1-.593.576H14.662a.58.58,0,0,1-.593-.576Zm-4.69,2.9A.591.591,0,0,1,9.972,2.9h1.745a.594.594,0,0,1,.593.583v6.94a.591.591,0,0,1-.593.583H9.972a.594.594,0,0,1-.593-.583ZM4.69,5.787a.588.588,0,0,1,.593-.576H7.028a.579.579,0,0,1,.593.576v4.637A.588.588,0,0,1,7.028,11H5.283a.579.579,0,0,1-.593-.576ZM0,8.1a.587.587,0,0,1,.593-.574H2.338a.579.579,0,0,1,.593.574v2.327A.587.587,0,0,1,2.338,11H.593A.579.579,0,0,1,0,10.428Z"
              transform="translate(293.819 17)"
              fillRule="evenodd"
            />
            <g transform="translate(338 18)">
              <path
                d="M23,3.5a1.852,1.852,0,0,1,1.5,1.75A1.852,1.852,0,0,1,23,7Z"
                transform="translate(-3 -0.5)"
                fill="rgba(0,0,0,0.5)"
                fillRule="evenodd"
              />
              <rect width="15" height="5.5" rx="1" transform="translate(2 2)" />
              <g
                transform="translate(0 0)"
                fill="none"
                stroke="#000"
                strokeWidth="1"
                opacity="0.4"
              >
                <rect width="19" height="9.5" rx="2.5" stroke="none" />
                <rect
                  x="0.5"
                  y="0.5"
                  width="18"
                  height="8.5"
                  rx="2"
                  fill="none"
                />
              </g>
            </g>
            <text
              transform="translate(49.396 28.436)"
              fontSize="15"
              fontFamily="SegoeUI, Segoe UI"
            >
              <tspan x="-13.755" y="0">
                9:41
              </tspan>
            </text>
          </g>
          <rect
            width="350"
            height="295"
            rx="7"
            transform="translate(-17694 8567)"
            fill="#fff"
            opacity="0.33"
          />
          <rect
            width="350"
            height="83"
            rx="7"
            transform="translate(-17694 8871)"
            fill="#fff"
            opacity="0.33"
          />
          <rect
            width="350"
            height="83"
            rx="7"
            transform="translate(-17694 8963)"
            fill="#fff"
            opacity="0.33"
          />
          <rect
            width="350"
            height="83"
            rx="7"
            transform="translate(-17694 9055)"
            fill="#fff"
            opacity="0.33"
          />
          <rect
            width="350"
            height="83"
            rx="7"
            transform="translate(-17694 9147)"
            fill="#fff"
            opacity="0.33"
          />
        </g>
      </svg>
      <div id="appmaker-smart-banner" className="appbanner-main-container">
        <div className="appbanner-close-container appbanner-container">
          <div className="close-accessibility-overlay"></div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1 1"
            version="1.1"
            width="7"
            height="7"
          >
            <path
              d="M0 0 L1 1 M1 0 L0 1"
              strokeWidth="0.1"
              stroke="#000"
            ></path>
          </svg>
        </div>
        <div className="appbanner-logo-container appbanner-container">
          <img
            className="apmkr-img"
            src={
              iconUrl ||
              'https://appilder_cdn.storage.googleapis.com/app_files/2230c0510a725ec54fb9f41f4e1eb18b/appicon_ios_android.png_150x150.png'
            }
            alt="icon"
          />
        </div>
        <div className="appbanner-content-container appbanner-container">
          <h6 className="appbanner-title mb-0">{title || 'App Name'}</h6>
          <small className="appbanner-discription">{description || ''}</small>
        </div>
        <div className="appbanner-button-container appbanner-container">
          <button disabled>{btnContent || 'Download App'}</button>
        </div>
      </div>
    </div>
  );
};

export default SmartBannerPreview;
