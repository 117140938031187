import React from 'react';

export default () => (
    <svg width="24" height="24" viewBox="0 0 24 24">
        <g id="menu-gutIcon" transform="translate(-13118 -6800)">
            <rect id="Rectangle_547" data-name="Rectangle 547" width="24" height="24" transform="translate(13118 6800)" fill="none" />
            <rect id="Rectangle_548" data-name="Rectangle 548" width="4" height="4" rx="2" transform="translate(13119 6804)" fill="#6c63ff" />
            <rect id="Rectangle_561" data-name="Rectangle 561" width="4" height="4" rx="2" transform="translate(13119 6810)" fill="#4b6efc" />
            <rect id="Rectangle_563" data-name="Rectangle 563" width="4" height="4" rx="2" transform="translate(13119 6816)" fill="#6c63ff" opacity="0.9" />
            <rect id="Rectangle_559" data-name="Rectangle 559" width="15" height="2" rx="1" transform="translate(13126 6805)" fill="#6c63ff" />
            <rect id="Rectangle_560" data-name="Rectangle 560" width="15" height="2" rx="1" transform="translate(13126 6811)" fill="#4b6efc" />
            <rect id="Rectangle_562" data-name="Rectangle 562" width="15" height="2" rx="1" transform="translate(13126 6817)" fill="#6c63ff" opacity="0.9" />
        </g>
    </svg>
);