import { dispatch } from '@wordpress/data';
// import { REDUCER_KEY } from './store/name';
const REDUCER_KEY = 'wordpress';
const coreActions = [
  {
    id: 'OPEN_IN_WEBVIEW',
    title: 'Open WebView',
    template: 'common',
    subAction: {
      id: 'url',
      title: 'Choose URL',
      type: 'text'
    }
  },
  {
    id: 'OPEN_URL',
    title: 'Open URL',
    template: 'common',
    subAction: {
      id: 'url',
      title: 'Choose URL',
      type: 'text'
    }
  },
  { id: 'NO_ACTION', title: 'No Action', template: 'customapp' }
];
// {
//   id: 'OPEN_PUSH_HISTORY',
//     title: 'Open push notification history',
//       template: 'woocommerce'
// }
export function registerActionType(actionType) {
  return dispatch('core/actions').addActionTypes(actionType);
}
export function registerActionTypes() {
  coreActions.map(registerActionType);
}
