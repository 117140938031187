import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import {
  getAppData,
  download,
  uploadAppToCloud
} from '../../../../lib/pushAutomationApi';
import LoadingPage from '../helperComponents/loadingPage';
import appCreate from '../helperComponents/appCreate';

export default ({
  projectName,
  accessToken,
  id,
  addToast = () => {},
  nextStep = () => {},
  previousStep = () => {}
}) => {
  const [isLoading, setLoading] = useState(false);
  const [isAndroidHasApp, setIsAndroidHasApp] = useState(false);
  const [isIosHasApp, setIsIosHasApp] = useState(false);
  const [isAppCreating, setAppCreating] = useState(false);

  // To get the firebase android and ios project Data
  const fetchProjectData = async () => {
    if (!accessToken) {
      // return history.push(CONSTANT.linkToPushAutomationRoutes(id, ''));
    }
    try {
      setLoading(true);
      const response = await getAppData({ accessToken, projectName, id });
      if (response.status && response.body) {
        const {
          androidResponse = {},
          iosResponse = {},
          packageName
        } = response.body;
        /**
         * Check Android ,if android app is available -> gets in 'if'
         */
        if (Object.keys(androidResponse).length > 0) {
          try {
            const { apps = [] } = androidResponse;
            // Check the package name in firebase app and in appmaker db
            const filterAndroidApp = apps.filter(
              item => item.packageName === packageName
            );
            if (filterAndroidApp.length === 0) {
              addToast(
                `Android Package name mismatch in firebase project (${projectName})`,
                {
                  appearance: 'error',
                  autoDismiss: false
                }
              );
            } else {
              const uploadResponse = await uploadAppToCloud({
                accessToken,
                projectName,
                id,
                platform: 'android'
              });

              addToast(
                `Android app with same package name exist in firebase project (${projectName})`,
                {
                  appearance: 'info',
                  autoDismiss: false
                }
              );
              setIsAndroidHasApp(true);
            }
          } catch (error) {
            addToast('Something went wrong', {
              appearance: 'error',
              autoDismiss: true
            });
          }
        } else {
          /**
           * Create android app
           */
          addToast('Android app create started', {
            appearance: 'success',
            autoDismiss: true
          });
          appCreate({
            accessToken,
            platform: 'android',
            addToast,
            projectId: projectName,
            id,
            afterComplete: setIsAndroidHasApp,
            setLoading: setAppCreating
          });
        }
        /**
         * Check IOS ,if IOS app is available -> gets in 'if'
         */
        if (Object.keys(iosResponse).length > 0) {
          try {
            const { apps = [] } = iosResponse;
            // Check the package name in firebase app and in appmaker db
            const filterIosApp = apps.filter(
              item => item.bundleId === packageName
            );
            if (filterIosApp.length === 0) {
              addToast(
                `IOS bundle-id mismatch in firebase project (${projectName})`,
                {
                  appearance: 'error',
                  autoDismiss: false
                }
              );
            } else {
              const uploadResponse = await uploadAppToCloud({
                accessToken,
                projectName,
                id,
                platform: 'ios'
              });

              addToast(
                `IOS app with bundle-id exist in firebase project (${projectName})`,
                {
                  appearance: 'info',
                  autoDismiss: false
                }
              );
              setIsIosHasApp(true);
            }
          } catch (error) {
            addToast('Something went wrong', {
              appearance: 'error',
              autoDismiss: true
            });
          }
        } else {
          /**
           * Create IOS app
           */
          addToast('IOS app create started', {
            appearance: 'success',
            autoDismiss: true
          });
          appCreate({
            accessToken,
            platform: 'ios',
            addToast,
            projectId: projectName,
            id,
            afterComplete: setIsIosHasApp,
            setLoading: setAppCreating
          });
        }
      } else {
        addToast('Something went wrong', {
          appearance: 'error',
          autoDismiss: true
        });
      }
    } catch (error) {
      addToast(error.message || 'Something went wrong', {
        appearance: 'error',
        autoDismiss: true
      });
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchProjectData();
  }, []);
  if (isAppCreating) {
    return <LoadingPage loading={isAppCreating} />;
  }
  if ((isIosHasApp || isAndroidHasApp) && isLoading === false) {
    nextStep();
    return null;
  }
  if (
    isIosHasApp === false &&
    isAndroidHasApp === false &&
    isLoading === false
  ) {
    return (
      <Button
        disabled={isLoading}
        size="sm"
        className="px-4 mt-4"
        color="secondary"
        onClick={previousStep}
      >
        <i className="fas fa-angle-left mr-2" />
        Back
      </Button>
    );
  }
  if (isLoading) {
    return <LoadingPage loading={isLoading} />;
  }
  return <div />;
};
