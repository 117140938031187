import React from 'react';
import ActionSelect from '../../hooks/action/components/ActionSelect';

export const preBuildProductScrollerAction = [
  { label: 'Sale Products', value: 'sale' },
  { label: 'Featured Products', value: 'featured' },
  { label: 'Recent Products', value: 'recent' },
  { label: 'Best Selling Products', value: 'best_selling' },
  { label: 'Top Rated Products', value: 'top_rated' },
  {
    label: 'Open Products in a category',
    value: 'category',
    link: 'product_category',
    hasChild: true
  },
  {
    label: 'Open Products By Tag',
    value: 'tag',
    hasChild: true,
    link: 'product_tag'
  }
];
export const getLink = id => {
  switch (id) {
    case 'category':
      return 'category';
    case 'tag':
      return 'tag';
    default:
      return false;
  }
};
// Not used for future if changing
export const config = {
  schema: {
    properties: {
      action: {
        type: 'string',
        title: 'Choose an action',
        enumNames: [
          'Sale Products',
          'Featured Products',
          'Recent Products',
          'Best Selling Products',
          'Top Rated Products',
          'Open Products in a category',
          'Open Products By Tag'
        ],
        enum: [
          'sales',
          'featured',
          'recent',
          'best_selling',
          'top_rated',
          'category',
          'tag'
        ]
      }
    },
    dependencies: {
      action: {
        oneOf: [
          {
            properties: {
              action: {
                enum: ['category']
              },
              category: {
                type: 'string',
                title: 'Choose Category',
                link: 'product_category'
              }
            }
          },
          {
            properties: {
              action: {
                enum: ['tag']
              },
              tag: {
                type: 'string',
                title: 'Choose Tag',
                link: 'product_tag'
              }
            }
          }
        ]
      }
    }
  },
  uiSchema: {
    category: {
      'ui:widget': props => {
        return (
          <div>
            <ActionSelect
              projectId={38605}
              dataSource={{ link: props.schema.link }}
              onChange={data => {
                console.log('in change:', data);
              }}
            />
          </div>
        );
      }
    },
    tag: {
      'ui:widget': props => {
        return (
          <div>
            <ActionSelect
              projectId={38605}
              dataSource={{ link: props.schema.link }}
              onChange={data => {
                console.log('in change:', data);
              }}
            />
          </div>
        );
      }
    }
  }
};
