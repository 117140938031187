/**
 * WordPress dependencies
 */
import '@wordpress/editor'; // This shouldn't be necessary
import * as React from 'react';
import '@wordpress/components/build-style/style.css';
import '@wordpress/block-editor/build-style/style.css';
import '@wordpress/block-library/build-style/style.css';
import '@wordpress/block-library/build-style/editor.css';
import '@wordpress/block-library/build-style/theme.css';
import '@wordpress/format-library/build-style/style.css';
// import './style.css';

import { useEffect, useState } from '@wordpress/element';
import {
  BlockEditorKeyboardShortcuts,
  BlockEditorProvider,
  BlockList,
  BlockInspector,
  WritingFlow,
  ObserveTyping
} from '@wordpress/block-editor';
import {
  Popover,
  SlotFillProvider,
  DropZoneProvider
} from '@wordpress/components';
import { registerCoreBlocks } from '@wordpress/block-library';
import '@wordpress/format-library';

/**
 * Internal dependencies
 */
// import './style.scss';

function App() {
  const [blocks, updateBlocks] = useState([]);

  useEffect(() => {
    registerCoreBlocks();
  }, []);

  return (
    <div className="row">
      <SlotFillProvider>
        <DropZoneProvider>
          <div className="row">
            <BlockEditorProvider
              value={blocks}
              onInput={updateBlocks}
              onChange={updateBlocks}
            >
              <div className="col-3">
                <BlockInspector />
              </div>
              <div className="col-6">
                <BlockEditorKeyboardShortcuts />
                <WritingFlow>
                  <ObserveTyping>
                    <BlockList />
                  </ObserveTyping>
                </WritingFlow>
              </div>
              <Popover.Slot />
            </BlockEditorProvider>
          </div>
        </DropZoneProvider>
      </SlotFillProvider>
    </div>
  );
}
export default App;
