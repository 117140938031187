import React from 'react';
import '../style.css';
import Loading from './Loading';

const Card = ({ attributes, children }) => {
  const {
    title,
    headerRight,
    headerRightLoading = false,
    bubble = false
  } = attributes;
  return (
    <div className="appmaker-card">
      <div className="appmaker-card-header">
        {title && <h6 className="mb-0">{title}</h6>}
        {!headerRightLoading && headerRight && headerRight}
        {headerRightLoading && <Loading bubble={bubble} />}
      </div>
      <div className="appmaker-card-content">{children}</div>
    </div>
  );
};

export default Card;
