import React, { Component } from 'react';
import Select from 'react-select';
import Async from 'react-select/lib/Async';
import { select, withSelect } from '@wordpress/data';
import { find } from 'lodash';
import { fromAPI } from '../utils/convertor';
import { apiProxy} from '../../../lib/apiFunctions';

class ActionSelect extends Component {
  constructor(props) {
    super(props);
  }
  promiseOptions = input => {
    // NOTE Removed the api proxy from the store and makes this as api call
    return new Promise(resolve => {
      const queryParams = {};
      const { dataSource, languageKey } = this.props;
      let extraParams=undefined;
      if(dataSource!==undefined && dataSource.link !==undefined && dataSource.store!=='core'){
        if (dataSource.appendLanguage === true) {
          extraParams = `language=${languageKey}`
        }
        apiProxy(this.props.projectId, dataSource.link, input,extraParams).then(value => {
          let result = value.body;
          if (dataSource.showAll && result !== undefined )
          {
            result.push({ id: "", label: "All" })
          }
          return resolve(value.body)
        })
      }
      if(dataSource.store==='core' && dataSource.name ==='inAppPageList')
      {
        return resolve(this.props.inAppPageList)
      }
    //   if (input) queryParams.search = input;
    //   const newProps = { options: [] };
    //   if (dataSource !== undefined && select(dataSource.store)) {
    //     let optionsList = null; // TODO Fix this later
    //     const getList = setInterval(() => {
    //       optionsList = select(dataSource.store).getEntityRecords(
    //         dataSource.kind,
    //         dataSource.name,
    //         queryParams
    //       );
    //       console.log(optionsList,'))))')
    //       if (optionsList !== null) {
    //         clearInterval(getList);
    //       }
    //       if (optionsList && optionsList.length > 0) {
    //         newProps.options = fromAPI(optionsList);
    //         return resolve(newProps.options);
    //       } else if (optionsList && optionsList.length == 0) {
    //         newProps.options = [];
    //         return resolve(newProps.options);
    //       }
    //     }, 500);
    //   } else {
    //     return {};
    //   }
    });
  };

  /**
   * Action SelectBox render. This will render with default options as params or provide dataSource as and argument to get data from @wordpress/data soruce
   * and searchItems function must be defind in the store
   * @param {*} props
   */
  render() {
    const { options, value, onChange } = this.props;
    if (options) {
      return (
        <Select
          {...this.props}
          value={find(options, { value: value })}
          options={options}
        />
      );
    }
    return (
      <Async
        cacheOptions={true}
        defaultOptions
        getOptionLabel={option => option.label}
        getOptionValue={option => option.id}
        name="test"
        loadOptions={this.promiseOptions}
        value={value}
        onChange={onChange}
      />
    );
  }
}

// // const MyAuthorsList = ;
// export default withSelect((select, props) => {
//   // console.log(props);
//   const queryParams = {};
//   if (props.value) queryParams.include = props.value;
//   const newProps = { options: [] };
//   const { dataSource } = props;
//   if (dataSource !== undefined) {
//     const optionsList =
//       select(dataSource.store) &&
//       select(dataSource.store).getEntityRecords(
//         dataSource.kind,
//         dataSource.name,
//         queryParams
//       );
//     if (optionsList && optionsList.length > 0)
//       newProps.options = fromAPI(optionsList);
//     else {
//       newProps.options = [];
//     }
//     console.log(queryParams, optionsList);
//     return newProps;
//   }
// })(ActionSelect);
export default withSelect(select=>{
  const inAppPageList = select('appmaker/in-app-page').getInAppPageList();
  const languageKey = select('appmaker/in-app-page').getCurrentLanguageKey();
  return { inAppPageList, languageKey};
})(ActionSelect)